import React from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../Common/Button";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Select, Text, Checkbox } from "../../../Inputs/react-hook-form";
import { BASE_MAP_SOURCE_TYPE, COMMON_BASE_MAP_STYLES } from "common/constants";
import { AccountBaseMapSettings } from "./__queries__/getAccountBaseMaps";

export type BaseMapForm = {
  source: BASE_MAP_SOURCE_TYPE;
  name: string;
  url: string;
  username: string;
  password: string;
  authorization: string;
  tokenGenerationUrl: string;
  transparent: boolean;
  mapboxStyleURL: string;
};

const filterChangedFormFields = <T extends FieldValues>(
  allFields: T,
  dirtyFields: Partial<Record<keyof T, boolean>>
): Partial<T> => {
  const changedFieldValues = Object.keys(dirtyFields).reduce(
    (acc, currentField) => {
      return {
        ...acc,
        [currentField]: allFields[currentField],
      };
    },
    {} as Partial<T>
  );

  return changedFieldValues;
};

export type RequiredFormData = Partial<BaseMapForm> &
  Pick<BaseMapForm, "mapboxStyleURL" | "name" | "url" | "source">;

export const BaseMapForm = ({
  onSave,
  onCancel,
  loading,
  submitText,
  baseMap,
}: {
  onSave: (data: RequiredFormData) => Promise<void>;
  onCancel: () => void;
  loading: boolean;
  submitText: string;
  baseMap?: AccountBaseMapSettings;
}) => {
  const formMethods = useForm<BaseMapForm>({
    defaultValues: baseMap
      ? {
          source: baseMap.sourceType,
          name: baseMap.name,
          url: baseMap.url || "",
          mapboxStyleURL: baseMap.mapboxStyleURL,
        }
      : {},
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    control,
  } = formMethods;

  const onSubmit = async (data: BaseMapForm) => {
    const dirtyFields = filterChangedFormFields(
      data,
      formMethods.formState.dirtyFields
    );

    void onSave(dirtyFields as RequiredFormData);
  };

  return (
    <FormProvider {...formMethods}>
      <Container>
        <HeaderSection>
          <h1>{`${submitText} Base Map`}</h1>
        </HeaderSection>
        <FormSection>
          <ContentSection>
            <InputRow>
              <Select
                label="Source"
                name="source"
                placeholder="Select source..."
                size="medium"
                control={control}
                rules={{
                  required: "Source is required",
                }}
                required
                options={[
                  {
                    label: "Map Server",
                    value: BASE_MAP_SOURCE_TYPE.MAP_SERVER,
                  },
                  {
                    label: "Image Server",
                    value: BASE_MAP_SOURCE_TYPE.IMAGE_SERVER,
                  },
                  { label: "Slippy", value: BASE_MAP_SOURCE_TYPE.SLIPPY },
                ]}
                error={errors.source?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Name"
                data-testid="nameInput"
                size="medium"
                required
                {...register("name", {
                  required: "Name is required",
                })}
                error={errors.name?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="URL"
                data-testid="urlInput"
                size="medium"
                required
                {...register("url", {
                  required: "URL is required",
                })}
                error={errors.url?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Mapbox Style"
                name="mapboxStyleURL"
                placeholder="Select style..."
                size="medium"
                control={control}
                rules={{
                  required: "Mapbox Style is required",
                }}
                required
                options={[
                  {
                    label: "Satellite - Grey Scale",
                    value: COMMON_BASE_MAP_STYLES.SATELLITE_V2,
                  },
                  {
                    label: "Satellite - Colorized",
                    value: COMMON_BASE_MAP_STYLES.SATELLITE_COLORIZED,
                  },
                ]}
                error={errors.mapboxStyleURL?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Username"
                size="medium"
                {...register("username")}
                error={errors.username?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Password"
                size="medium"
                {...register("password")}
                error={errors.password?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Authorization"
                size="medium"
                {...register("authorization")}
                error={errors.authorization?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Token generation URL"
                size="medium"
                {...register("tokenGenerationUrl")}
                error={errors.tokenGenerationUrl?.message}
              />
            </InputRow>
            <InputRow>
              <Checkbox
                name="transparent"
                control={control}
                label="Transparent?"
              />
            </InputRow>
          </ContentSection>
          <ButtonSection>
            <PrimaryButtons>
              <Button styleVariant="secondary" size="medium" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                styleVariant="primary"
                disabled={!isValid && !loading}
                size="medium"
                onClick={handleSubmit(onSubmit)}
              >
                {submitText}
              </Button>
            </PrimaryButtons>
          </ButtonSection>
        </FormSection>
      </Container>
    </FormProvider>
  );
};
