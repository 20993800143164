import React, { CSSProperties, ReactNode } from "react";
import type Stitches from "@stitches/react";

import { Icon } from "./Icons/LucideIcons";
import { Body } from "./Typography";
import { colors } from "../../stitches.config";

import {
  BANNER_TYPES,
  Banner as BannerStyledComponent,
  IconWrapper,
  LoadingWrapper,
} from "./__styles__/Banner";

interface BannerProps
  extends Stitches.VariantProps<typeof BannerStyledComponent> {
  items?: Array<ReactNode>;
  loading?: boolean;
  showInfoIcon?: boolean;
  type?: BANNER_TYPES;
  title?: string;
  style?: CSSProperties;
  children?: ReactNode;
  testId?: string;
}

export const BannerItem = ({
  loading,
  type = BANNER_TYPES.INFO,
  children,
}: {
  loading?: boolean;
  type?: BANNER_TYPES;
  children?: ReactNode;
}) => {
  let iconColor: keyof typeof colors = "contentInfo";

  switch (type) {
    case BANNER_TYPES.INFO:
      iconColor = "contentInfo";
      break;
    case BANNER_TYPES.NEUTRAL:
      iconColor = "contentPrimary";
      break;
    case BANNER_TYPES.ATTENTION:
      iconColor = "contentAttention";
      break;
    case BANNER_TYPES.CRITICAL:
      iconColor = "contentCritical";
      break;
  }

  const BannerItemIcon = () => (
    <Icon
      iconName={loading ? "loading" : "info"}
      size={16}
      color={iconColor}
      style={{ margin: "2px 0px" }}
      data-testid="banner-item-icon"
    />
  );

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <BannerItemIcon />
        </LoadingWrapper>
      ) : (
        <IconWrapper>
          <BannerItemIcon />
        </IconWrapper>
      )}
      <Body type="regular" size="default" as="div">
        {children}
      </Body>
    </>
  );
};

const Banner: React.FC<BannerProps> = ({
  children,
  type = BANNER_TYPES.INFO,
  items,
  title,
  loading,
  style,
  testId,
}) => {
  const bannerItems = items ?? [children];

  return (
    <BannerStyledComponent
      type={type}
      style={style}
      data-testid={testId ?? "banner"}
    >
      {title && (
        <Body style={{ gridColumn: "span 2" }} type="emphasis" size="default">
          {title}
        </Body>
      )}

      {bannerItems.map((item, index) => (
        <BannerItem key={index} loading={loading} type={type}>
          {item}
        </BannerItem>
      ))}
    </BannerStyledComponent>
  );
};

export default Banner;
