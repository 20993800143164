import { useContext, useEffect } from "react";
import { ApolloError } from "@apollo/client";
import { UiSchema } from "@rjsf/utils";
import {
  DefaultedValuesArgs,
  DefaultedValueArgs,
  useCommonDefaultedValue,
  useCommonDefaultedValues,
} from "common-client/utils/submissions";
import { SubmissionFormContext } from "./Form";
import { formatCoordinates } from "common/utils/coordinates";
import { AuthContext } from "../Authorization/AuthContext";
import { useLocation } from "react-router";
import { buildLink } from "common/routing";
import { SUBMISSION_CATEGORY } from "common/constants";
import { SUBMISSION_CATEGORY_TO_LABEL } from "common/utils/submissions";

export const useDefaultedValues = <
  Keys extends string = string,
  Values extends Record<Keys, any> = Record<Keys, any>,
  Schema extends Record<keyof Values, UiSchema | undefined> = Record<
    keyof Values,
    UiSchema | undefined
  >
>(
  args: DefaultedValuesArgs<Keys, Values, Schema>
) => {
  const { editing, defaultValues } = useContext(SubmissionFormContext);
  return useCommonDefaultedValues({
    ...args,
    useEffect,
    defaultValues,
    editing,
  });
};

export const useDefaultedValue = <Value,>(args: DefaultedValueArgs<Value>) => {
  const { editing, defaultValues } = useContext(SubmissionFormContext);
  return useCommonDefaultedValue({
    ...args,
    useEffect,
    defaultValues,
    editing,
  });
};

export const generateUpsertErrorMessage = (error: ApolloError) => {
  const message =
    error.message.includes("Invalid PDF file") ||
    error.message.includes("A record with the unique ID already exists.")
      ? error.message
      : "Your submission failed to process. Please try again.";

  return message;
};

export const castEmptyStringToNull = ({
  value,
  required,
}: {
  value: Maybe<string>;
  required: boolean;
}) => {
  // see commit message for why this `if` is necessary
  if (required) {
    return value === "" ? null : value;
  } else {
    return value;
  }
};

export const hasSummary = ({ summaries }: { summaries: Array<unknown> }) => {
  return summaries.length > 0;
};

type GetObjectDisplayProps = {
  property?: {
    fullAddress?: string | null;
    longitude: number;
    latitude: number;
  } | null;
  customMapGeometry?: {
    label: string;
  } | null;
};

export const getObjectDisplay = ({
  property,
  customMapGeometry,
}: GetObjectDisplayProps) => {
  if (property) {
    return property.fullAddress ?? formatCoordinates({ ...property });
  } else if (customMapGeometry) {
    return customMapGeometry.label;
  }
  return undefined;
};

export const useSubmissionPrevLocation = () => {
  const { isGuest } = useContext(AuthContext);
  const location =
    useLocation<Maybe<{ prevLocation?: string; search?: string }>>();
  let prevLocation: string;
  if (location.state?.prevLocation) {
    prevLocation = location.state.prevLocation;
  } else if (isGuest) {
    prevLocation = buildLink("properties");
  } else {
    prevLocation = `${buildLink("map")}${location.state?.search ?? ""}`;
  }

  return prevLocation;
};

export const SUBMISSION_CATEGORY_OPTIONS: Array<{
  value: SUBMISSION_CATEGORY;
  label: string;
}> = Object.entries(SUBMISSION_CATEGORY_TO_LABEL).map(([value, label]) => ({
  value: value as SUBMISSION_CATEGORY,
  label,
}));
