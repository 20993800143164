import React, { ReactNode, useContext } from "react";

import { useGetPublicWebsiteSettingsQuery } from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { PublicWebsiteSettingsForm } from "./PublicWebsiteSettingsForm";
import {
  InsufficientPermissionsBanner,
  SettingsPanel,
} from "../../Common/Settings";

export const PublicWebsite = () => {
  const { authorized } = useContext(AuthContext);

  const { data, loading, error } = useGetPublicWebsiteSettingsQuery();

  const contactInformation = data?.account?.contactInformation;
  const publicAccountPortal = data?.account?.publicPortal;

  const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
      <>
        {!canUpdateAccountInformation && <InsufficientPermissionsBanner />}
        <SettingsPanel title="Public website settings">
          {children}
        </SettingsPanel>
      </>
    );
  };

  if (loading) {
    return <Wrapper>loading...</Wrapper>;
  }

  if (error || !contactInformation || !publicAccountPortal) {
    return <div />;
  }

  const canUpdateAccountInformation = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "update",
  });
  return (
    <Wrapper>
      <PublicWebsiteSettingsForm
        canUpdateAccountInformation={canUpdateAccountInformation}
        settings={{
          ...contactInformation,
          ...publicAccountPortal,
        }}
      />
    </Wrapper>
  );
};

export default PublicWebsite;
