import React, { useEffect } from "react";
import {
  AuthContextType,
  CurrentAdmin,
  MixpanelCompany,
  MixpanelSession,
  SecurityConfiguration,
} from "./types";
import { FEATURE_FLAG_NAME } from "common/constants";
import {
  useGetSecurityConfigurationQuery,
  useTouchSessionMutation,
} from "../../generated/graphql";
import {
  MINUTES_TO_MILLISECONDS,
  YEAR_IN_MILLISECONDS,
  WEEK_IN_MILLISECONDS,
} from "common/utils/dates";
import { useLocation } from "react-router";
import { buildLink } from "common/routing";
import { authorized, buildFeatureFlagChecker } from "common/authorization";
import { useIdleTimer } from "react-idle-timer";
import * as Sentry from "@sentry/browser";
import { MainRouteProps } from "../MainRoutes";
import { getAppPrefix } from "common/utils/url";
import { buildLoginURLWithPrevLocation } from "../../utils/params";
import FullPageLoadingScreen from "../Common/FullPageLoadingScreen";
import { LOGOUT_REASON } from "common/constants";
import { accountSupportsElevationCertificates } from "../../utils/documentUploads";

export const AuthContext = React.createContext<AuthContextType>({
  account: null,
  user: null,
  admin: null,
  isGuest: true,
  authorized: () => false,
  setAdmin: (_admin: CurrentAdmin) => {},
  isFeatureEnabled: (_: FEATURE_FLAG_NAME) => false,
  supportsElevationCertificates: false,
});

export const _trackEntity = (entity?: Maybe<MixpanelSession>) => {
  if (!entity) return;

  const { account, isAdmin, id, email, createdAt, isBeingImpersonated } =
    entity;

  const name = `${entity.firstName} ${entity.lastName}`;
  Sentry.setUser({ id, email, name });

  let company: MixpanelCompany = {};
  if (account) {
    company.id = account.id;
    company.name = account.name;
  }

  const trackingData = {
    name,
    email,
    createdAt,
    isAdmin: isAdmin || false,
    company,
    isPilot: account?.isPilot,
    isBeingImpersonated,
  };

  window.analytics?.identify(id, trackingData);
};

export const AuthContextProvider = ({
  children,
  user,
  admin,
}: React.PropsWithChildren<MainRouteProps>) => {
  const location = useLocation();
  const [currentAdmin, setCurrentAdmin] = React.useState<Maybe<CurrentAdmin>>(
    admin ?? null
  );

  const [sessionTimeout, setSessionTimeout] =
    React.useState<number>(YEAR_IN_MILLISECONDS);
  const [securityConfiguration, setSecurityConfiguration] = React.useState<
    SecurityConfiguration | undefined
  >(undefined);

  useGetSecurityConfigurationQuery({
    skip: !user && !admin,
    onCompleted: data => {
      if (data.account) {
        if (user) {
          const sessionTimeoutMinutes =
            data.account.securityConfiguration.sessionTimeoutMinutes;
          if (sessionTimeoutMinutes) {
            setSessionTimeout(sessionTimeoutMinutes * MINUTES_TO_MILLISECONDS);
          }
        }
        if (admin) {
          setSessionTimeout(WEEK_IN_MILLISECONDS);
        }
        setSecurityConfiguration(data.account.securityConfiguration);
      }
    },
  });

  const [touchSessionMutation] = useTouchSessionMutation({
    ignoreResults: true, // We do this to avoid rerendering all of the child components of AuthRoute
  });

  const onIdle = () => {
    window.location.href = buildLink(
      "logout",
      {},
      { logoutReason: LOGOUT_REASON.INACTIVITY }
    );
  };

  const onAction = async () => {
    await touchSessionMutation();
  };

  useIdleTimer({
    onIdle,
    onAction,
    timeout: sessionTimeout,
    throttle: 30000,
  });

  const entity = user || admin;

  if (!entity) {
    const prefix = getAppPrefix();

    const pathWithPreviousLocation = buildLoginURLWithPrevLocation(location);

    const newlink = `${prefix}${pathWithPreviousLocation}`;

    window.location.href = newlink;
    return <FullPageLoadingScreen />;
  }

  useEffect(() => {
    _trackEntity(entity);
  }, [entity]);

  const account = entity.account;
  const supportsElevationCertificates =
    accountSupportsElevationCertificates(account);
  const featureFlags = account?.featureFlags || [];

  return (
    <AuthContext.Provider
      value={{
        setAdmin: setCurrentAdmin,
        user: user ?? null,
        account: account ? { ...account, securityConfiguration } : null,
        admin: currentAdmin ?? null,
        isGuest: false,
        authorized: authDetails => authorized({ entity, ...authDetails }),
        isFeatureEnabled: buildFeatureFlagChecker(featureFlags),
        supportsElevationCertificates,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
