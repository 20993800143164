import React, { ReactNode } from "react";
import {
  IconWrapper,
  SelectionCardTextBlock,
} from "../../Inputs/__styles__/SelectionCardGroup";
import { Body } from "../../Common/Typography";
import { Icon } from "../../Common/Icons/LucideIcons";
import { NavigationCard as StyledNavigationCard } from "./__styles__/NavigationCard";
import { useHistory } from "react-router-dom";

const NavigationCard = ({ children }: { children?: ReactNode }) => {
  const history = useHistory();
  return (
    <StyledNavigationCard
      type="button"
      data-testid="navigation-card"
      onClick={() => history.goBack()}
    >
      <IconWrapper>
        <Icon iconName="chevron-left" color={"contentSecondary"} size={16} />
      </IconWrapper>
      <SelectionCardTextBlock>
        <Body as="div" size="default" type="emphasis">
          {children}
        </Body>
      </SelectionCardTextBlock>
    </StyledNavigationCard>
  );
};

export default NavigationCard;
