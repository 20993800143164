import React, { useContext } from "react";
import List from "../AddressPanel/DocumentUploads/List";
import { ObjectInfoPanelTabProps } from "./types";
import { useGetDocumentsForCustomMapGeometryQuery } from "../../generated/graphql";
import ListItem from "../AddressPanel/DocumentUploads/ListItem";
import { canDeleteDocumentUpload } from "common-client/utils/documentUploads";
import { AuthContext } from "../Authorization/AuthContext";
import { ContentWrapper } from "./__styles__/ObjectInformationPanel";
import { OBJECT_TYPE } from "common/constants";

const Files = ({ customMap, geometry }: ObjectInfoPanelTabProps) => {
  const { authorized } = useContext(AuthContext);
  const { data, loading, refetch } = useGetDocumentsForCustomMapGeometryQuery({
    variables: { geometryId: geometry.id, isGuest: false },
  });

  if (loading) {
    return (
      <ContentWrapper>
        <em>Loading...</em>
      </ContentWrapper>
    );
  }

  const documentUploads = data?.customMapGeometry?.documentUploads || [];

  return (
    <ContentWrapper>
      <List
        object={{
          id: geometry.id,
          objectType: OBJECT_TYPE.CUSTOM_MAP_GEOMETRY,
          objectTags: customMap.tags,
          objectLabel: geometry.label,
          parentId: customMap.id,
        }}
      >
        {documentUploads.map(du => (
          <ListItem
            key={du.id}
            {...du}
            documentUploadId={du.id}
            isDeletable={canDeleteDocumentUpload({
              documentUpload: du,
              authorized,
            })}
            documentUploadMimeType={du.mimeType}
            issuedAt={du.issuedAt}
            createdAt={du.createdAt}
            filename={du.originalFilename}
            pathname={location.pathname}
            search={location.search}
            onUpdate={refetch}
            objectId={geometry.id}
          />
        ))}
      </List>
    </ContentWrapper>
  );
};

export default Files;
