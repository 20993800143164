import React, { useContext, useEffect, useState } from "react";
import length from "@turf/length";
import { lineString } from "@turf/helpers";
import { useTranslation } from "react-i18next";

import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { LayerContext } from "../layers";
import { Button } from "../../Common/Button";
import { MeasureInfo } from "./Shared";
import { Body } from "../../Common/Typography";

import {
  ActionButtons,
  Calculation,
} from "../../Common/__styles__/BottomBanner";

export type DistanceMeasureToolProps = {
  clipboard?: Pick<Clipboard, "writeText">;
};

export const DistanceMeasureTool = ({
  clipboard = navigator.clipboard,
}: DistanceMeasureToolProps) => {
  const { t } = useTranslation();
  const { addSuccessToast } = useStatusToasts();
  const { measureToolDispatch, measureToolState } = useContext(LayerContext);
  const [measurement, setMeasurement] = useState<string>("0.0");

  useEffect(() => {
    if (measureToolState.measureToolMode === "distance") {
      let newMeasureDistance: number;
      if (measureToolState.coordinates.length < 2) {
        newMeasureDistance = 0;
      } else {
        const line = lineString(
          measureToolState.coordinates.map(coordinates => [
            coordinates.longitude,
            coordinates.latitude,
          ])
        );
        newMeasureDistance = length(line, { units: "feet" });
      }

      setMeasurement(newMeasureDistance.toFixed(1));
    }
  }, [measureToolState.coordinates]);

  const onClose = () => {
    measureToolDispatch({
      type: "setMeasureMode",
      data: { measureToolMode: "off" },
    });
  };

  const onCopy = async () => {
    if (measurement) {
      await clipboard.writeText(measurement);
      addSuccessToast(t("common-value-copied"));
    }
  };

  return (
    <MeasureInfo
      title={t("measure-distance")}
      subtitle={t("measure-distance-subtext")}
      onClose={onClose}
    >
      <Body as="div" type="emphasis" size="default" color="contentPrimaryDark">
        <Calculation>
          {t("measure-distance-calculation", { measurement })}
        </Calculation>
      </Body>
      <ActionButtons>
        <Button
          styleVariant="outlineDark"
          size="small"
          onClick={onCopy}
          leftIconName="copy"
          tabIndex={-1}
        >
          {t("measure-copy-value")}
        </Button>
      </ActionButtons>
    </MeasureInfo>
  );
};
