import React, { useRef } from "react";
import { NetworkStatus, WatchQueryFetchPolicy } from "@apollo/client";
import { uniqBy } from "lodash";
import Collapsible from "react-collapsible";
import { Icon } from "../Common/Icons/LucideIcons";
import { useGetAccountActivityQuery } from "../../generated/graphql";
import ActivityList from "./ActivityList";
import { Button } from "../Common/Button";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import {
  AccountActivityFeedContainer,
  LoadingFeedContainer,
  FeedContainer,
  MinimizedFeedContainer,
  Toggle,
  ViewMoreContainer,
} from "./__styles__/AccountActivityFeed";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import { Activity } from "./types";
import { Body } from "../Common/Typography";
import Loading from "../Common/Icons/Loading";
import { EmptyState } from "../Common/EmptyState";
import { spacing } from "../../stitches.config";

const LoadingFeed = (
  <LoadingFeedContainer data-testid="loading-indicator">
    <Loading />
  </LoadingFeedContainer>
);

const AccountActivityFeed = ({
  fetchPolicy = "cache-and-network",
  pageSize = 5,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  pageSize?: number;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [activities, setActivities] = React.useState<Array<Activity>>([]);
  const [deletedActivityId, setDeletedActivityId] = React.useState("");

  const { user, admin } = React.useContext(AuthContext);

  const wrapperRef = useRef(null as null | HTMLDivElement);
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => setIsOpen(false),
  });

  const { data, networkStatus, error, previousData, refetch } =
    useGetAccountActivityQuery({
      variables: { page, pageSize },
      fetchPolicy,
      onCompleted: data => {
        setActivities(
          uniqBy(
            [
              ...activities.filter(
                activity => activity.id !== deletedActivityId
              ),
              ...data.accountActivities.data.map(activity => ({
                ...activity,
              })),
            ],
            "id"
          )
        );
      },
    });

  if (((!previousData && !data) || error) && isOpen) {
    return LoadingFeed;
  }

  const onUpdate = async (deletedId?: string) => {
    setDeletedActivityId(deletedId ?? "");
    await refetch();
  };

  const MinimizedFeed = (
    <MinimizedFeedContainer
      data-testid="minimized-feed"
      onClick={() => setIsOpen(!isOpen)}
    >
      <Body size="large" type="emphasis">
        Activity Feed
      </Body>
      <Toggle rotate={isOpen}>
        <Icon iconName="chevron-down" color="contentSecondary" size="16px" />
      </Toggle>
    </MinimizedFeedContainer>
  );

  return (
    <AccountActivityFeedContainer
      ref={wrapperRef}
      onScroll={event => {
        event.stopPropagation();
      }}
    >
      <Collapsible trigger={MinimizedFeed} open={isOpen} transitionTime={75}>
        {activities.length === 0 ? (
          <EmptyState
            icon="no-activity-inbox"
            title="No activity"
            body="Updates will appear here."
            paddingBottom={spacing.l.value}
          />
        ) : (
          <FeedContainer>
            <ActivityList
              activities={activities}
              linkToObject={true}
              truncateComments={true}
              allowEdits={false}
              feedSource="Account"
              onUpdate={onUpdate}
            />
            <ViewMoreContainer>
              {data?.accountActivities.pageInfo.nextPage && (
                <Button
                  disabled={networkStatus === NetworkStatus.loading}
                  styleVariant="secondary"
                  size="small"
                  onClick={() => {
                    setPage(page + 1);
                    track("User loaded more activity", {
                      userId: user?.id ?? admin?.id,
                      userEmail: user?.email ?? admin?.email,
                    });
                  }}
                >
                  View more
                </Button>
              )}
            </ViewMoreContainer>
          </FeedContainer>
        )}
      </Collapsible>
    </AccountActivityFeedContainer>
  );
};

export default AccountActivityFeed;
