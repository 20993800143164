import React, { useContext } from "react";
import { Section, Wrapper } from "./__styles__/AddressPanel";
import InfoPanelActivityFeed from "../ActivityFeed/InfoPanelActivityFeed";
import { AddressPanelContext } from "./AddressPanelContext";
import { AddressPanelTabProps } from ".";
import MissingProperty from "./MissingProperty";
import { OBJECT_TYPE } from "common/constants";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { Loading } from "../ActivityFeed/__styles__/Activity";

const Activity = ({ address, latitude, longitude }: AddressPanelTabProps) => {
  const { property, loadingProperty } = useContext(AddressPanelContext);
  const content = property ? (
    <InfoPanelActivityFeed
      objectType={OBJECT_TYPE.PROPERTY}
      object={property}
    />
  ) : loadingProperty ? (
    <Loading>
      <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
    </Loading>
  ) : (
    <MissingProperty
      title="No activity tracking available"
      address={address}
      latitude={latitude}
      longitude={longitude}
    />
  );

  return (
    <Wrapper>
      <Section>{content}</Section>
    </Wrapper>
  );
};

export default Activity;
