import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  LayerConfig,
  LayerHookBuilder,
  LayerHook,
  findRelevantFeatures,
  EventFeature,
} from "./types";
import ClickObjectMenuLayer, {
  ClickMenuDisplayConfig,
  MenuCategory,
  ObjectDisplayProps,
} from "../layers/clickObjectMenu";
import { keyBy, mapValues, uniqBy } from "lodash";
import { LayerContext } from "../layers";
import { CUSTOM_MAPS_SOURCE_ID_PREFIX } from "../layers/customMap";
import { id as parcelLayerId } from "../layers/parcels";
import {
  PROPERTIES_SOURCE_ID_PREFIX,
  interactiveLayerIds as propertyLayerIds,
} from "../layers/properties";
import {
  CustomMapLayerConfig,
  getInteractiveLayerIds as getInteractiveCustomMapLayerIds,
} from "./customMapLayer";
import { MapEvent } from "react-map-gl";
import {
  CLICK_OBJECT_MENU_ITEMS_MAX_HEIGHT,
  CLICK_OBJECT_MENU_WIDTH,
} from "../layers/__styles__/clickObjectMenu";
import {
  getInteractiveLayerIds as getInteractiveSavedViewLayerIds,
  SavedViewsLayerConfig,
} from "./savedViewsLayer";
import { viewsLayerId } from "../layers/savedViews";
import { formatCoordinates } from "common/utils/coordinates";
import {
  arrayHasAtLeastTwoItems,
  arrayHasExactlyOneItem,
} from "common/utils/arrays";

export type ClickObjectMenuLayerConfig = LayerConfig & {
  maps: CustomMapLayerConfig["maps"];
  savedViews: SavedViewsLayerConfig["value"];
};

type PausedClickInfo = {
  globalOnClick: (event: MapEvent) => void;
  lastClickEvent: MapEvent;
};

type ExtractPropsArgs = {
  feature: EventFeature;
  allFeatures: EventFeature[];
  pausedClickInfo: PausedClickInfo | null;
  closeMenu: () => void;
};

export const PROPERTY_HEADER = "Property";
export const PARCEL_HEADER = "Parcel";
const UNKNOWN_LABEL = "Unknown";

const extractPropertyFeatureProps = ({
  feature,
  allFeatures,
  pausedClickInfo,
  closeMenu,
}: ExtractPropsArgs): ObjectDisplayProps => {
  const parcelFeature = allFeatures.find(({ layer }) => layer.id === "parcels");
  return {
    id: feature.properties.propertyId,
    label:
      feature.properties.address ||
      formatCoordinates({
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
      }),
    onClick: () => {
      if (pausedClickInfo) {
        pausedClickInfo.globalOnClick({
          ...pausedClickInfo.lastClickEvent,
          features: [feature, ...(parcelFeature ? [parcelFeature] : [])],
        });
      }
      closeMenu();
    },
  };
};

const extractParcelFeatureProps = ({
  feature,
  pausedClickInfo,
  closeMenu,
}: Omit<ExtractPropsArgs, "allFeatures">): ObjectDisplayProps => {
  return {
    id: feature.properties.id,
    label:
      feature.properties.address ||
      feature.properties.parcelNumber ||
      UNKNOWN_LABEL,
    onClick: () => {
      if (pausedClickInfo) {
        pausedClickInfo.globalOnClick({
          ...pausedClickInfo.lastClickEvent,
          features: [feature],
        });
      }
      closeMenu();
    },
  };
};

const extractCustomMapGeometryFeatureProps = ({
  feature,
  pausedClickInfo,
  closeMenu,
}: Omit<ExtractPropsArgs, "allFeatures">): ObjectDisplayProps => {
  return {
    id: feature.properties.id,
    label: feature.properties.label,
    onClick: () => {
      if (pausedClickInfo) {
        pausedClickInfo.globalOnClick({
          ...pausedClickInfo.lastClickEvent,
          features: [feature],
        });
      }
      closeMenu();
    },
  };
};

const extractUnknownFeatureProps = ({
  feature,
  closeMenu,
}: Pick<ExtractPropsArgs, "feature" | "closeMenu">): ObjectDisplayProps => ({
  id: feature.properties.id,
  label: feature.properties.id,
  onClick: () => {
    closeMenu();
  },
});

const SpecialCaseCategories = {
  [PROPERTY_HEADER]: 0,
  [PARCEL_HEADER]: 1,
} as const;

const menuCategoryHeaderComparator = (a: string, b: string) => {
  if (a in SpecialCaseCategories && b in SpecialCaseCategories) {
    return (
      SpecialCaseCategories[a as keyof typeof SpecialCaseCategories] -
      SpecialCaseCategories[b as keyof typeof SpecialCaseCategories]
    );
  } else if (a in SpecialCaseCategories) {
    return -1;
  } else if (b in SpecialCaseCategories) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};

export const categorizeMenuFeatures = ({
  allFeatures,
  pausedClickInfo,
  mapIdToName,
  closeMenu,
}: Omit<ExtractPropsArgs, "feature" | "updateUrl"> & {
  mapIdToName: Record<string, string>;
}): MenuCategory[] => {
  const categories: Record<string, ObjectDisplayProps[]> = {};
  for (const feature of allFeatures) {
    const source = feature.source as string;
    if (
      source.startsWith(PROPERTIES_SOURCE_ID_PREFIX) ||
      source.startsWith(viewsLayerId)
    ) {
      const featureProps = extractPropertyFeatureProps({
        feature,
        allFeatures,
        pausedClickInfo,
        closeMenu,
      });
      categories[PROPERTY_HEADER] ??= [];
      categories[PROPERTY_HEADER]!.push(featureProps);
    } else if ((feature.source as string).startsWith(parcelLayerId)) {
      const featureProps = extractParcelFeatureProps({
        feature,
        pausedClickInfo,
        closeMenu,
      });
      categories[PARCEL_HEADER] ??= [];
      categories[PARCEL_HEADER]!.push(featureProps);
    } else if (
      (feature.source as string).startsWith(CUSTOM_MAPS_SOURCE_ID_PREFIX)
    ) {
      const mapId = feature.source.replace(CUSTOM_MAPS_SOURCE_ID_PREFIX, "");
      const mapName = mapIdToName[mapId] ?? "Unknown Map";
      const featureProps = extractCustomMapGeometryFeatureProps({
        feature,
        pausedClickInfo,
        closeMenu,
      });
      categories[mapName] ??= [];
      categories[mapName]!.push(featureProps);
    } else {
      const featureProps = extractUnknownFeatureProps({ feature, closeMenu });
      categories[UNKNOWN_LABEL] ??= [];
      categories[UNKNOWN_LABEL]!.push(featureProps);
    }
  }

  // if both parcels and properties are present, only show properties
  if (PROPERTY_HEADER in categories && PARCEL_HEADER in categories) {
    delete categories[PARCEL_HEADER];
  }

  return Object.keys(categories)
    .sort(menuCategoryHeaderComparator)
    .map(key => ({
      header: key,
      objects: categories[key]!.sort((a, b) => a.label.localeCompare(b.label)),
    }));
};

const calculateMenuHeight = (menuCategories: MenuCategory[]) => {
  const headerHeight = 33;
  const menuItemsTopPadding = 8;
  const categoryHeaderHeight = 16;
  const objectDisplayHeight = 24;
  const categoryBottomPadding = 8;
  return Math.min(
    CLICK_OBJECT_MENU_ITEMS_MAX_HEIGHT,
    headerHeight +
      menuItemsTopPadding +
      menuCategories.reduce((currentSum, category) => {
        return (
          currentSum +
          categoryHeaderHeight +
          objectDisplayHeight * category.objects.length +
          categoryBottomPadding
        );
      }, 0)
  );
};

const clickObjectMenuHook: LayerHookBuilder<ClickObjectMenuLayerConfig> = ({
  config,
  helpers,
}) => {
  const [menuDisplayConfig, setMenuDisplayConfig] =
    useState<ClickMenuDisplayConfig | null>(null);

  const [time, setTime] = useState<number>(Date.now());
  const [menuFeatures, setMenuFeatures] = useState<EventFeature[]>([]);
  const [pausedClickInfo, setPausedClickInfo] =
    useState<PausedClickInfo | null>(null);

  const closeMenu = useCallback(
    (features?: EventFeature[], event?: MapEvent) => {
      if (features?.length && event) {
        helpers.setCursor("pointer");
        features.map(feat =>
          helpers.emit({
            type: "setFeatureState",
            data: {
              field: "hover",
              source: feat.source,
              sourceLayer: feat.sourceLayer,
              id: feat.properties.id ?? feat.properties.propertyId,
            },
          })
        );
        const categorizedFeatures = boundCategorizeMenuFeatures(features, null);
        helpers.emit({
          type: "setTooltip",
          data: {
            text: categorizedFeatures[0]!.objects[0]!.label,
            ...event.offsetCenter,
          },
        });
      } else {
        helpers.setCursor("grab");
      }
      setMenuDisplayConfig(null);
      setMenuFeatures([]);
      setPausedClickInfo(null);
    },
    [setMenuDisplayConfig, setMenuFeatures, setPausedClickInfo, helpers]
  );

  const suppressHoverBehavior = useCallback(
    (features: EventFeature[]) => {
      helpers.setCursor("unset");
      helpers.emit({ type: "removeTooltip", data: {} });
      features.map(feat =>
        helpers.emit({
          type: "removeFeatureState",
          data: {
            field: "hover",
            source: feat.source,
            sourceLayer: feat.sourceLayer,
            id: feat.properties.id ?? feat.properties.propertyId,
          },
        })
      );
    },
    [helpers]
  );

  const { isLayerVisible } = useContext(LayerContext);

  const allInteractiveLayerIds = getInteractiveCustomMapLayerIds(
    config.maps,
    isLayerVisible
  ).concat(
    getInteractiveSavedViewLayerIds(config.savedViews, isLayerVisible),
    parcelLayerId,
    propertyLayerIds
  );

  const mapIdToName = useMemo(
    () => mapValues(keyBy(config.maps, "id"), map => map.name),
    [config.maps]
  );

  const boundCategorizeMenuFeatures = useCallback(
    (allFeatures: EventFeature[], clickInfo: PausedClickInfo | null) =>
      categorizeMenuFeatures({
        allFeatures,
        pausedClickInfo: clickInfo,
        mapIdToName,
        closeMenu,
      }),
    [mapIdToName, closeMenu, helpers]
  );

  const menuCategories = useMemo(
    () => boundCategorizeMenuFeatures(menuFeatures, pausedClickInfo),
    [menuFeatures, pausedClickInfo, boundCategorizeMenuFeatures]
  );

  return useMemo<LayerHook>(() => {
    return {
      // -2 so that the markers show above the PIP's marker
      zIndex: -2,
      render: () => {
        return (
          <ClickObjectMenuLayer
            key={"click-object-menu"}
            menuDisplayConfig={menuDisplayConfig}
            time={time}
            menuCategories={menuCategories}
            closeMenu={closeMenu}
          />
        );
      },
      canHandleHover: () => !!config.interactive?.hover && !!menuDisplayConfig,
      onHover: ({ event }) => {
        if (menuDisplayConfig) {
          const features = uniqBy(
            findRelevantFeatures(event, allInteractiveLayerIds),
            "id"
          );
          // suppress hover states and tooltip while menu opens
          suppressHoverBehavior(features);
          return true;
        }
        return false;
      },
      canHandleClick: event => {
        return (
          !!config.interactive?.click &&
          (!!menuDisplayConfig || (event.features?.length ?? 0) > 1)
        );
      },
      onClick: ({ event, globalOnClick: newGlobalOnClick }) => {
        const features = uniqBy(
          findRelevantFeatures(event, allInteractiveLayerIds),
          "id"
        );

        if (menuDisplayConfig) {
          // re-add hover states and tooltip after menu closes
          closeMenu(features, event);
          return true;
        } else if (features.length > 1) {
          // dedupe properties and parcels
          const menuCategories = boundCategorizeMenuFeatures(features, null);
          // we only open the menu if there are multiple categories,
          // or if there are multiple objects in the single category
          if (
            !(
              arrayHasAtLeastTwoItems(menuCategories) ||
              (arrayHasExactlyOneItem(menuCategories) &&
                menuCategories[0].objects.length > 1)
            )
          ) {
            return false;
          }
          const menuHeight = calculateMenuHeight(menuCategories);

          // remove hover states and tooltip when menu opens
          suppressHoverBehavior(features);
          setMenuFeatures(features);
          if (newGlobalOnClick) {
            setPausedClickInfo({
              lastClickEvent: event,
              globalOnClick: newGlobalOnClick!,
            });
          }
          setMenuDisplayConfig({
            longitude: event.lngLat[0]!,
            latitude: event.lngLat[1]!,
            displayOnLeft:
              event.offsetCenter.x >
              window.innerWidth - (CLICK_OBJECT_MENU_WIDTH + 100),
            topPixelOffset:
              event.offsetCenter.y > window.innerHeight - (100 + menuHeight)
                ? -menuHeight
                : null,
          });
          setTime(Date.now());
          return true;
        }

        return false;
      },
    };
  }, [menuDisplayConfig, time, menuCategories, allInteractiveLayerIds]);
};

export default clickObjectMenuHook;
