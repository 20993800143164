import React, { useContext } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useGetSubmissionQuery } from "../../generated/graphql";
import { buildLink } from "common/routing";
import FullPageLoadingScreen from "../Common/FullPageLoadingScreen";
import { AuthContext } from "../Authorization/AuthContext";
import { useSubmissionPrevLocation } from "./utils";

// this is a shim to redirect to *either* the edit page
// of the submission or its summary page, which makes it a
// bit easier to link to the submission from other parts of the app
// without those parts having to know if they need to go to the
// edit or summary page. Note that we want *distinct* URLs for
// editing and viewing because we want:
// a) the ability to link directly to the edit page
// b) the ability to link directly to the summary page
// c) the back button to work between those two pages

interface URLParams {
  submissionId: string;
}

type ViewSubmissionProps = RouteComponentProps<
  URLParams,
  {},
  Maybe<{ prevLocation?: string; search?: string }>
>;
const ViewSubmission = ({ match }: ViewSubmissionProps) => {
  const { isGuest } = useContext(AuthContext);
  const submissionId = match.params.submissionId;
  const history = useHistory();
  const prevLocation = useSubmissionPrevLocation();

  useGetSubmissionQuery({
    variables: { submissionId, isGuest },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const hasSummaries = data.submission?.summaries.length;
      const path = hasSummaries ? "submissionSummary" : "editSubmission";

      history.replace({
        pathname: buildLink(path, { submissionId }),
        state: { prevLocation },
      });
    },
  });

  return <FullPageLoadingScreen />;
};

export default ViewSubmission;
