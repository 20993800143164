import React, { useContext, useState } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../../../Modal";
import { useDeleteSavedViewMutation } from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { TableContext } from "../../TableContext";
import { useUpdateSavedView } from "../../utils";
import { Body } from "../../../Typography";
import { CommonModal } from "../../../CommonModal";
import { SavedView } from "../utils";

const DeleteSavedViewModal = ({
  savedView,
  hideModal,
  currentView,
  defaultView,
}: {
  savedView: SavedView;
  hideModal: () => void;
  currentView: SavedView;
  defaultView: SavedView;
}) => {
  const { updateSavedView } = useUpdateSavedView();

  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const [deleteSavedView, { loading }] = useDeleteSavedViewMutation({
    onCompleted: () => {
      const deletedCurrentView = savedView.id === currentView.id;

      if (deletedCurrentView) {
        updateSavedView(defaultView.id);
      }

      hideModal();

      addSuccessToast(`Your table view was successfully deleted`);
    },
    onError: error => {
      const errorMessage = error.graphQLErrors
        .map(error => error.message)
        .join("\n");
      addErrorToast(errorMessage);
    },
  });

  return (
    <CommonModal
      title={`Delete ${savedView.name} view?`}
      primaryButtonText="I understand, delete this view"
      variant="destructive"
      onSubmit={() => {
        void deleteSavedView({
          variables: { id: savedView.id },
          refetchQueries: ["GetSavedViews"],
        });
      }}
      loading={loading}
      onCancel={hideModal}
      size="small"
      content={
        <>
          <Body size="default" type="regular">
            This action will result in the permanent deletion of this table view
            for everyone on the account.
          </Body>
        </>
      }
    />
  );
};

export const useDeleteSavedViewModal = () => {
  const { defaultView, currentView } = useContext(TableContext);
  const [savedView, setSavedView] = useState<SavedView | null>(null);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal onRequestClose={hideModal}>
        <DeleteSavedViewModal
          savedView={savedView!}
          hideModal={() => {
            hideModal();
            setSavedView(null);
          }}
          defaultView={defaultView!}
          currentView={currentView!}
        />
      </Modal>
    ),
    [defaultView, savedView, currentView]
  );

  const showDeleteSavedViewModal = (savedView: SavedView) => {
    setSavedView(savedView);
    showModal();
  };

  return showDeleteSavedViewModal;
};
