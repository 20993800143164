import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import { SubmissionsBuilderFieldSettings } from "../../types";

const TextWidgetSettings = ({
  fieldPath,
  isModuleInput,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={isModuleInput}
        readOnly={readOnly}
      />
      <div>
        <Label text={"Helper text"} htmlFor={`${fieldPath}.helperText`} />
        <Text {...register(`${fieldPath}.helperText`)} disabled={readOnly} />
      </div>
      <div>
        <Label text={"Placeholder text"} htmlFor={`${fieldPath}.placeholder`} />
        <Text {...register(`${fieldPath}.placeholder`)} disabled={readOnly} />
      </div>
      <div>
        <Label
          text={"Unique identifier"}
          htmlFor={`${fieldPath}.uniqueIdentifier`}
        />
        <Text
          {...register(`${fieldPath}.uniqueIdentifier`)}
          disabled={readOnly}
        />
      </div>
    </WidgetSettingsContainer>
  );
};

TextWidgetSettings.displayName = "TextWidgetSettings";

export default TextWidgetSettings;
