import React, { useContext } from "react";
import Table from "../../../Common/Tables/Table";
import {
  SubmissionType,
  useGetSubmissionTypesQuery,
} from "../../../../generated/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { AuthContext } from "../../../Authorization/AuthContext";
import { startCase } from "lodash";
import { ActionButton } from "./ActionButton";
import { SUBMISSION_CATEGORY_TO_LABEL } from "common/utils/submissions";

type SubmissionTypesTableProps = Pick<
  SubmissionType,
  "id" | "name" | "intakeSource" | "category" | "attachments" | "isDeletable"
>;

const SubmissionTypesTable = () => {
  const { account } = useContext(AuthContext);
  const {
    data: currentData,
    error,
    loading,
    refetch,
  } = useGetSubmissionTypesQuery({
    variables: { accountId: account!.id },
    // Need to do cache-and-network because refetch queries wasn't working for submission type create
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <div />;
  }
  const columns: Array<ColumnDef<SubmissionTypesTableProps>> = [
    {
      id: "name",
      header: "Record type",
      accessorKey: "name",
      enableSorting: false,
      minSize: 300,
    },
    {
      id: "category",
      header: "Record category",
      enableSorting: false,
      cell: ({ row }) => SUBMISSION_CATEGORY_TO_LABEL[row.original.category],
    },
    {
      id: "attachments",
      header: "Object type",
      enableSorting: false,
      cell: ({ row }) => {
        const formatTags = (
          attachment: SubmissionTypesTableProps["attachments"][number]
        ) => (attachment.tags ? `(${attachment.tags.join(", ")})` : "");
        return row.original.attachments
          .map(
            attachment =>
              `${startCase(attachment.type)} ${formatTags(attachment)}`
          )
          .join(", ");
      },
    },
    {
      id: "intakeSource",
      header: "Display setting",
      enableSorting: false,
      cell: ({ row }) => startCase(row.original.intakeSource),
    },
    {
      id: "actions",
      size: 75,
      maxSize: 75,
      enableSorting: false,
      cell: row => (
        <ActionButton
          submissionType={row.row.original}
          onDeleteSuccess={() => void refetch()}
        />
      ),
    },
  ];

  return (
    <Table<SubmissionTypesTableProps>
      columns={columns}
      currentData={currentData?.account?.submissionTypes ?? []}
      loadingDetails={{
        loading,
        loadingText: "Loading Record Types...",
        noDataText: "No Record Types Found",
      }}
      tableStyleDetails={{
        hasRowActions: true,
        isCompact: true,
        hasFlushedSides: true,
      }}
      excludePaginationNav
    />
  );
};

export default SubmissionTypesTable;
