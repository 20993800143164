import React from "react";

import LoginPage from "./LoginPage";
import { ADMIN_LOGIN } from "./__queries__";

const AdminLoginPage = () => (
  <LoginPage
    loginMutation={ADMIN_LOGIN}
    fallbackPath="/admin"
    event="Admin logged in"
    initialTitle="Admin log in"
  />
);

export default AdminLoginPage;
