import React, { useContext } from "react";

import App from "./App";
import AdminApp from "./AdminApp";
import { AuthContext } from "./Authorization/AuthContext";
import { pick } from "lodash";

// AppSelector chooses between different App components based on
// the authenticated user's account configuration.
export default function AppSelector() {
  const context = useContext(AuthContext);

  const selectAppProps = pick(context, ["user", "admin", "account"]);

  const SelectedApp = selectAppProps.admin ? AdminApp : App;

  return <SelectedApp {...selectAppProps} />;
}
