import React, { useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "../../App";
import BackOffice from "./BackofficeApp";
import { AuthContext } from "../../Authorization/AuthContext";
import FullPageLoadingScreen from "../../Common/FullPageLoadingScreen";
import { useGetAccountQuery } from "../../../generated/graphql";
import { colors } from "../../../stitches.config";

export default function AccountDashboardShim() {
  const { admin, setAdmin } = useContext(AuthContext);
  const { data, loading } = useGetAccountQuery({
    onCompleted: data => {
      if (admin && data.account) {
        setAdmin({ ...admin, account: data.account });
      }
    },
  });

  if (loading) return <FullPageLoadingScreen />;
  const account = data?.account;
  if (!account)
    return (
      <div style={{ color: colors.contentPrimaryDark.toString() }}>
        Invalid account
      </div>
    );
  if (!admin?.allowedToViewDashboard)
    return (
      <div style={{ color: colors.contentPrimaryDark.toString() }}>
        Not allowed
      </div>
    );

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/backoffice" component={BackOffice} />
        <Route
          render={() => <App account={account} admin={admin} user={null} />}
        />
      </Switch>
    </BrowserRouter>
  );
}
