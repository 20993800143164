import React, {
  useState,
  useEffect,
  useRef,
  ComponentProps,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import {
  Category,
  CategoryFieldValues,
  Field,
  SearchFormProps,
  buildCategoriesForAccount,
} from "common-client/utils/Search";
import {
  SearchCategory,
  useGetSearchResultsLazyQuery,
} from "../../generated/graphql";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import type { Point } from "../Maps/LayeredMap/types";
import { InternalMapContext } from "../Maps/InternalMapContextProvider";
import { AuthContext } from "../Authorization/AuthContext";
import { TranslationKeys } from "common/utils/i18n/fallbackEnTranslations";
import { SearchField } from "./SearchField";
import { ResultsDropdown } from "./ResultsDropdown";
import { CategoryDropdown } from "./CategoryDropdown";
import { Icon } from "../Common/Icons/LucideIcons";
import { Body } from "../Common/Typography";

import { CategoryWrapper, InputRow, Wrapper } from "./__styles__/Search";

export const SEARCH_TO_TRANSLATION_KEY = {
  address: "common-address",
  blockLot: "search-block-lot",
  block: "search-block",
  lot: "search-lot",
  city: "common-city",
} as const satisfies Record<
  SearchCategory.BLOCK_LOT | keyof Omit<SearchFormProps, "parcelId">,
  TranslationKeys
>;

export interface SearchResultProps {
  point: Point;
  address: string;
  propertyId?: Maybe<string>;
  geocacheParcelId?: Maybe<string>;
  streetAddress?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
}

export type Status =
  | "idle"
  | "loading"
  | "search_complete"
  | "selecting_category";

export const Search = ({
  handleResultClick,
  originalValue,
  accountId,
  addressOnly = false,
}: {
  handleResultClick: ComponentProps<
    typeof ResultsDropdown
  >["handleResultClick"];
  originalValue?: string;
  accountId?: Maybe<string>;
  addressOnly?: boolean;
}) => {
  const { setResetSearchCategory } = useContext(InternalMapContext);
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();
  const [status, setRawStatus] = useState<Status>("idle");
  const [previousStatus, setPreviousStatus] = useState<Status>(status);
  const [focused, setFocused] = useState<boolean>(false);

  const setStatus = (newStatus: Status) => {
    setPreviousStatus(status);
    setRawStatus(newStatus);
  };

  const { categories, defaultCategory } = buildCategoriesForAccount(account);

  const [selectedCategory, setSelectedCategory] =
    useState<Category>(defaultCategory);

  useEffect(() => {
    if (setResetSearchCategory) {
      setResetSearchCategory(() => {
        setSelectedCategory(defaultCategory);
        reset();
        onClear();
      });
    }
  }, []);

  const multipleFieldSearch = selectedCategory.fields.length > 1;
  const wrapperRef = useRef(null as null | HTMLDivElement);
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => {
      setFocused(false);
      if (status === "selecting_category") setStatus(previousStatus);
    },
    dependencies: [status, previousStatus],
  });

  const { getValues, setValue, control, resetField, reset } =
    useForm<SearchFormProps>({
      defaultValues: {
        address: originalValue,
      },
    });

  const [getSearchResults, { data, loading }] = useGetSearchResultsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: () => {
      setStatus("search_complete");
    },
  });

  useEffect(() => {
    setValue("address", originalValue);
  }, [originalValue]);

  useEffect(() => {
    if (loading) {
      setStatus("loading");
    }
  }, [loading]);

  const handleOnChange = () => {
    const fields = getValues();

    if (
      Object.entries(fields).find(([key, value]) => {
        const selectedField = selectedCategory!.fields.find(
          field => field.value === key
        ) as Field | undefined;
        return selectedField
          ? value && value.length >= selectedField.minChars
          : false;
      })
    ) {
      const variables = {
        ...fields,
        category: selectedCategory.value,
        accountId,
      };

      void getSearchResults({
        variables,
      });
    } else {
      setStatus("idle");
    }
  };

  const onClear = () => {
    const values = Object.values(getValues());

    if (values.every(v => isEmpty(v))) {
      setStatus("idle");
    } else {
      handleOnChange();
    }
  };

  const onSearchFieldClear = (value: CategoryFieldValues) => {
    resetField(value);
    onClear();
  };

  const deactivateCategorySelectionStatus = () => {
    if (status === "selecting_category") {
      setStatus(previousStatus);
    }
  };

  const updateCategorySelectionStatus = () => {
    status !== "selecting_category" && !addressOnly
      ? setStatus("selecting_category")
      : setStatus(previousStatus);
  };

  const selectedCategoryCopy =
    selectedCategory.value === SearchCategory.PARCEL_ID
      ? selectedCategory.label
      : t(SEARCH_TO_TRANSLATION_KEY[selectedCategory.value]);

  return (
    <div ref={wrapperRef}>
      <div onFocus={() => setFocused(true)}>
        <Wrapper position={multipleFieldSearch ? "first" : undefined}>
          <CategoryWrapper
            clickable={!addressOnly}
            onClick={updateCategorySelectionStatus}
            onKeyDown={e => {
              if (e.key === "Enter") {
                updateCategorySelectionStatus();
              }
            }}
            tabIndex={addressOnly ? -1 : 0}
            data-testid="category-wrapper"
          >
            <Icon iconName="search" color="contentSecondary" size="16" />
            <Body
              size="large"
              type="regular"
              testId="selected-category"
              as="div"
            >
              {selectedCategoryCopy}
            </Body>
            {!addressOnly && (
              <Icon
                iconName={
                  status === "selecting_category"
                    ? "chevron-up"
                    : "chevron-down"
                }
                color="contentSecondary"
                size="16"
              />
            )}
          </CategoryWrapper>
          <InputRow partialRow>
            {!multipleFieldSearch && (
              <SearchField
                listedField={selectedCategory.fields![0]!}
                control={control}
                handleOnChange={handleOnChange}
                deactivateCategorySelectionStatus={
                  deactivateCategorySelectionStatus
                }
                onSearchFieldClear={onSearchFieldClear}
              />
            )}
          </InputRow>
        </Wrapper>
        {multipleFieldSearch && (
          <div style={{ position: "relative", zIndex: 1 }}>
            {selectedCategory.fields!.map((field: Field, index: number) => {
              const labelCopy =
                field.value === SearchCategory.PARCEL_ID
                  ? field.label
                  : t(SEARCH_TO_TRANSLATION_KEY[field.value]);
              return (
                <Wrapper
                  position={
                    selectedCategory.fields!.length - 1 === index
                      ? "last"
                      : "middle"
                  }
                  key={field.value}
                >
                  <InputRow>
                    <Body size="large" type="regular">
                      {labelCopy}
                    </Body>
                    <SearchField
                      listedField={field}
                      control={control}
                      handleOnChange={handleOnChange}
                      deactivateCategorySelectionStatus={
                        deactivateCategorySelectionStatus
                      }
                      onSearchFieldClear={onSearchFieldClear}
                    />
                  </InputRow>
                </Wrapper>
              );
            })}
          </div>
        )}
      </div>
      {status === "selecting_category" && focused && (
        <CategoryDropdown
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onClear={onClear}
          reset={reset}
        />
      )}
      {(status === "loading" || status === "search_complete") && focused && (
        <ResultsDropdown
          results={data?.getSearchResults || []}
          onClear={onClear}
          handleResultClick={handleResultClick}
          reset={reset}
          status={status}
          resetSearchCategory={() => setSelectedCategory(defaultCategory)}
        />
      )}
    </div>
  );
};
