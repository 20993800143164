import React, { useEffect } from "react";

import { EnumOptionsType, WidgetProps as RJSFWidgetProps } from "@rjsf/utils";

import { Datepicker, Select, Textarea, Text, Checkbox } from "../Inputs";

import { Input } from "./__styles__/Inputs";
import { castEmptyStringToNull, useDefaultedValue } from "./utils";
import { isNil, merge } from "lodash";
import { Fieldset } from "../Inputs/__styles__/Wrapper";
import { LabelMeta, Legend } from "../Inputs/__styles__/Label";
import { InformationalTooltip } from "../Common/Tooltip";

export type WidgetProps = RJSFWidgetProps & { isSubmissionBuilder?: boolean };

export const CustomSelect = (props: WidgetProps) => {
  const { value } = useDefaultedValue(props);

  const tooltipText = props.uiSchema?.["ui:tooltipText"];

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Select
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value => props.onChange(isNil(value) ? undefined : value)}
        disabled={props.disabled}
        options={props.options.enumOptions!.map((option: EnumOptionsType) => {
          return { label: option.label, value: option.value };
        })}
        placeholder={props.placeholder}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        tooltip={tooltipText}
        css={!!tooltipText ? { width: "100%" } : {}}
        isClearable={true}
      />
    </Input>
  );
};

export const CustomTextarea = (props: WidgetProps) => {
  const { value } = useDefaultedValue({
    ...props,
    // in case someone picks a non-string default
    onChange: value => props.onChange(value ? value.toString() : value),
  });

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Textarea
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value => {
          props.onChange(
            castEmptyStringToNull({
              value,
              required: props.required ?? false,
            })
          );
        }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        tooltip={props.uiSchema?.["ui:tooltipText"]}
      />
    </Input>
  );
};

export const CustomText = (props: WidgetProps) => {
  const { value } = useDefaultedValue({
    ...props,
    // in case someone picks a non-string default
    onChange: value => props.onChange(value ? value.toString() : value),
  });

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Text
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value =>
          props.onChange(
            castEmptyStringToNull({
              value,
              required: props.required ?? false,
            })
          )
        }
        disabled={props.disabled}
        placeholder={props.placeholder}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        tooltip={props.uiSchema?.["ui:tooltipText"]}
      />
    </Input>
  );
};

export const CustomDatePicker = (props: WidgetProps) => {
  const defaultToToday = props.uiSchema?.["ui:defaultToToday"];
  const value = useDefaultedValue({
    ...props,
    uiSchema: merge(
      { ...props.uiSchema },
      defaultToToday ? { "ui:defaultValuePath": "date.today" } : {}
    ),
  }).value;

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Datepicker
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value =>
          value ? props.onChange(value) : props.onChange(null)
        }
        disabled={props.disabled}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        placeholderText={props.placeholder}
        tooltip={props.uiSchema?.["ui:tooltipText"]}
      />
    </Input>
  );
};

export const DamageDegree = (props: WidgetProps) => {
  return <CustomSelect {...props} />;
};

export const CustomCheckbox = (props: WidgetProps) => {
  const { value } = useDefaultedValue(props);

  // It looked like the initial value wasn't displaying correctly,
  // using this useEffect cleaned it up
  useEffect(() => {
    props.onChange(value);
  }, []);

  const tooltipText = props.uiSchema?.["ui:tooltipText"];

  const tooltip = tooltipText ? (
    <InformationalTooltip tooltipText={tooltipText} place="right" />
  ) : undefined;

  const hasTooltip = !!tooltip;

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Fieldset>
        {/* 
          If you want to use functionality Wrapper provides, such as errors or tooltips,
          you will need to update the component to optionally use Legend instead of Label.
          Legend is semantically correct for checkboxes because it isn't associated with the input itself
        */}
        <Legend>
          {props.label.trim()}
          {hasTooltip && <LabelMeta>{tooltip}</LabelMeta>}
        </Legend>
      </Fieldset>
      <Checkbox
        name={props.id}
        value={value}
        onChange={props.onChange}
        disabled={props.disabled}
        label={props.uiSchema?.["ui:checkboxText"]}
        required={props.required && !props.disabled}
      />
    </Input>
  );
};
