import React from "react";

import { ObjectDisplay } from "./__styles__/Submission";
import { Header } from "../Common/__styles__/FullPageFormLayout";
import { SubmissionType } from "../../generated/graphql";
import { AccountLogo } from "../SideNav/__styles__/SideNav";
import { FlexColumn, FlexRow } from "../Common/__styles__/Layout";
import { Body, Title } from "../Common/Typography";

type SubmissionHeaderProps = {
  logoUrl: string;
  accountName: string;
  objectDisplay?: string;
  submissionType: Pick<SubmissionType, "name">;
};

const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({
  logoUrl,
  accountName,
  objectDisplay,
  submissionType,
  children,
}) => {
  const address = objectDisplay ? (
    <ObjectDisplay>{objectDisplay}</ObjectDisplay>
  ) : null;

  return (
    <Header>
      <FlexRow>
        <FlexColumn>
          {logoUrl && (
            <AccountLogo
              src={logoUrl}
              alt={`${accountName} Logo`}
              displayPrintOnly={true}
              style={{ marginRight: "16px" }}
            />
          )}
        </FlexColumn>
        <FlexColumn>
          <Title
            size="large"
            type="regular"
            color="contentPrimary"
            style={{ marginBottom: "8px" }}
          >
            {submissionType.name}
          </Title>
          <Body size="default" type="regular" color="contentSecondary">
            {address}
          </Body>
          {children}
        </FlexColumn>
      </FlexRow>
    </Header>
  );
};

export default SubmissionHeader;
