import {
  ClientSideConfigEntry,
  QueryDescription,
} from "common/utils/queryBuilder";
import { createContext } from "react";
import { SavedView } from "./TableSettings/utils";

type TableContextType = {
  name: string;
  currentQuery?: QueryDescription;
  currentView?: Maybe<SavedView>;
  defaultView?: Maybe<SavedView>;
  columnConfig?: Array<ClientSideConfigEntry>;
};
export const TableContext = createContext<TableContextType>({
  name: "",
});
