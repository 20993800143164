import React from "react";
import { uniqBy } from "lodash";
import { StylesConfig } from "react-select";
import { formatCoordinates } from "common/utils/coordinates";

import { Select } from "../../Inputs";
import { InformationalTooltip } from "../../Common/Tooltip";
import { AuthContext } from "../../Authorization/AuthContext";
import { useSharePropertyInfoModal } from "./SharePropertyModal";
import { track } from "../../../utils/tracking";
import PropertyOptions from "./PropertyOptions";
import SharePropertyInfoButton from "./SharePropertyInfoButton";
import { AddressPanelParcel, AddressPanelProperty } from "../types";
import { HeaderParcel } from "./types";
import { Icon as LucideIcon } from "../../Common/Icons/LucideIcons";

import {
  Address,
  AddressSelector,
  ButtonRow,
  Header,
  ParcelIdRow,
  PrivatedInfo,
} from "./__styles__/Header";
import { getValueForParcelFieldNameOverride } from "common-client/utils/parcels";
import { colors } from "../../../stitches.config";

const MultiplePropertiesTooltip = () => {
  return (
    <div>
      <InformationalTooltip
        tooltipText="Warning: There are multiple addresses within this parcel."
        place="bottom"
        iconProps={{ color: "contentSecondaryDark" }}
      />
    </div>
  );
};

interface AddressSelectOption {
  value: string;
  label: string;
}

const getAddressOrCoords = (
  property: NonNullable<AddressPanelParcel>["properties"][0]
) => {
  return property.fullAddress ?? formatCoordinates(property);
};

interface AddressPanelHeaderProps {
  loading: boolean;
  property: Maybe<AddressPanelProperty>;
  properties: Array<NonNullable<AddressPanelParcel>["properties"][0]>;
  parcel: HeaderParcel;
  latitude: number;
  longitude: number;
  onClose: () => void;
  selectProperty: (
    property: NonNullable<AddressPanelParcel>["properties"][0]
  ) => void;
}

export default ({
  loading,
  property,
  properties,
  parcel,
  latitude,
  longitude,
  onClose,
  selectProperty,
}: AddressPanelHeaderProps) => {
  const { account } = React.useContext(AuthContext);

  let options = properties.map(property => {
    const label = getAddressOrCoords(property);
    return {
      value: property.id,
      label,
      // Despite the SelectOption type not including this in its definition,
      // we need to be able to access property data once the option is selected
      property,
    };
  });

  options = uniqBy(options, "value");

  const styles: StylesConfig<AddressSelectOption, false> = {
    option: (provided, state) => {
      const backgroundColor = state.isFocused
        ? colors.bgUiInteraction.toString()
        : undefined;
      return {
        ...provided,
        backgroundColor,
        color: colors.contentPrimary.toString(),
      };
    },
    control: provided => {
      const style = {
        backgroundColor: colors.bgUiInteractionDark.toString(),
        borderStyle: undefined,
      };
      return { ...provided, ...style };
    },
    singleValue: provided => {
      return { ...provided, color: colors.contentPrimaryDark.toString() };
    },
    indicatorsContainer: () => {
      return {};
    },
  };

  const showAddressSelect = options.length > 1;

  const [showSharePropertyModal, hideSharePropertyModal] =
    useSharePropertyInfoModal({
      onCancel: () => {
        hideSharePropertyModal();
      },
      onSubmit: () => {
        hideSharePropertyModal();
      },
      property,
    });

  const propertyHiddenFromPublic = !!property?.hiddenFromPublic;
  const parcelIdLabel = getValueForParcelFieldNameOverride({
    overrides: account?.parcelFieldNameOverrides,
    name: "parcelId",
  });

  return (
    <Header data-testid="header">
      <ParcelIdRow>
        <div style={{ display: "flex", alignItems: "center" }}>
          {`${parcelIdLabel}: `}
          {parcel?.parcelNumber || "--"}
          {showAddressSelect && <MultiplePropertiesTooltip />}
        </div>
        <LucideIcon
          iconName="x"
          color="contentSecondaryDark"
          size={18}
          onClick={onClose}
          data-testid="close-icon"
        />
      </ParcelIdRow>
      <AddressSelector>
        {!loading && showAddressSelect && property ? (
          <Select
            name="type"
            options={options}
            value={property.id}
            styles={styles}
            dropdownIndicatorColor={"contentPrimaryDark"}
            onChange={selectedValue => {
              const selectedProperty = options.find(
                ({ value }) => value === selectedValue
              )?.property;

              if (selectedProperty) {
                selectProperty(selectedProperty);
              }
            }}
          />
        ) : (
          <Address>
            {loading ? (
              <div>
                <em>Loading...</em>
              </div>
            ) : (
              property && getAddressOrCoords(property)
            )}
          </Address>
        )}
      </AddressSelector>
      <ButtonRow data-testid="header-buttons">
        {property && account?.publicPortal.enabled && (
          <SharePropertyInfoButton
            onClick={() => {
              track("Share property info button clicked", {
                streetAddress: property.streetAddress,
                city: property.city,
              });
              showSharePropertyModal();
            }}
            disabled={propertyHiddenFromPublic}
          />
        )}
        {!loading && (
          <PropertyOptions
            latitude={latitude}
            longitude={longitude}
            parcel={parcel}
            property={property}
          />
        )}
      </ButtonRow>
      {propertyHiddenFromPublic && (
        <PrivatedInfo>
          <LucideIcon
            iconName="eye-off"
            color="contentSecondaryDark"
            size={16}
          />
          <div>Property hidden from public</div>
        </PrivatedInfo>
      )}
    </Header>
  );
};
