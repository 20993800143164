import {
  borderStyles,
  colors,
  cornerRadius,
  spacing,
  styled,
} from "../../../stitches.config";

export const InlineBlock = styled("div", {
  display: "inline-block",
});

export const FlexRow = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
});

export const Row = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  padding: "12px 0",
});

export const Col = styled("div", {
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: "100%",
  boxSizing: "border-box",
  padding: "0 12px",
});

export const Grid = styled("div", {
  margin: "-12px",
});

export const Box = styled("div", {
  border: borderStyles.solid,
  borderRadius: cornerRadius.radiusSm,
  boxShadow: "0 2px 4px rgba(221, 221, 221, 0.5)",
  background: colors.bgUiSurface,
});

export const StyledSections = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: spacing.l,
});

export const StyledSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
});
