import React, { useContext } from "react";
import { upperFirst } from "lodash";
import { NetworkStatus } from "@apollo/client";

import { Box } from "../../../Common/__styles__/Layout";
import Divider from "../../../Common/Divider";
import { EmptyState } from "../../../Common/EmptyState";
import RecentImportTasksTable, {
  RecentImportTask,
} from "../RecentImportTasksTable";
import { Icons } from "../../../Common/Icons";
import {
  useAccountRepetitiveLossImportsQuery,
  useGetRepetitiveLossIssueCountQuery,
} from "../../../../generated/graphql";
import ActionButton from "./ImportActionButton";
import { DropdownMenu } from "../../../Inputs";
import { useCSVImportModal } from "./CSVImportModal";

import {
  Header,
  Section,
  HeaderTitle,
  SectionTitle,
} from "../../__styles__/Content";
import { AuthContext } from "../../../Authorization/AuthContext";
import { buildLink } from "common/routing";
import { useLocation } from "react-router";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { RESOURCE_NAME } from "common/authorization";
import { InsufficientPermissions } from "../../../Common/InsufficientPermissions";
import { useManualPaginationConfig } from "../../../Common/Tables/hooks";
import { Button } from "../../../Common/Button";
import { spacing } from "../../../../stitches.config";

export const RepetitiveLossData = () => {
  const { authorized, account } = useContext(AuthContext);
  const manualPaginationConfig = useManualPaginationConfig({
    pageSize: 10,
  });

  const canListRepetitiveLossDataImports = authorized({
    resource: RESOURCE_NAME.REPETITIVE_LOSS_IMPORT_JOB,
    permission: "list",
  });

  const canUpdateRepetitiveLossData = authorized({
    resource: RESOURCE_NAME.REPETITIVE_LOSS,
    permission: "update",
  });

  const canViewRepetitiveLossData = authorized({
    resource: RESOURCE_NAME.REPETITIVE_LOSS,
    permission: "view",
  });

  const {
    data: currentData,
    networkStatus,
    loading,
    error,
    previousData,
    refetch,
  } = useAccountRepetitiveLossImportsQuery({
    variables: {
      page: manualPaginationConfig.pagination.pageIndex + 1,
      pageSize: manualPaginationConfig.pagination.pageSize,
      // it's possible for you to be able to see imports
      // but *not* the data that was imported
      includeRepetitiveLoss: canViewRepetitiveLossData,
    },
    skip: !canListRepetitiveLossDataImports,
  });

  const location = useLocation();

  const {
    data: issueData,
    loading: loadingIssues,
    error: issuesError,
  } = useGetRepetitiveLossIssueCountQuery({
    skip: !canUpdateRepetitiveLossData,
  });

  const [showCSVImportModal] = useCSVImportModal({
    onUpdate: refetch,
  });

  if (error) return <div>Error loading repetitive loss data imports.</div>;

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  const repetitiveLossImports = data?.account?.repetitiveLossImports.data;

  const hasRepetitiveLossImports =
    repetitiveLossImports && repetitiveLossImports.length > 0;

  const hasRepetitiveLossIssues =
    !loadingIssues &&
    !issuesError &&
    issueData?.account &&
    issueData.account.repetitiveLossIssueCount > 0;

  const actions = [
    {
      label: "Import .csv file",
      disabled: !account?.repetitiveLossEnabled,
      onClick: () => showCSVImportModal(),
    },
  ] as Array<ActionsProps>;

  if (canViewRepetitiveLossData) {
    actions.push({
      label: "Review all imported data",
      to: {
        pathname: buildLink("repetitiveLosses"),
        state: {
          prevLocation: location.pathname,
        },
      },
    });
    if (hasRepetitiveLossIssues) {
      actions.push({
        label: "Review issues",
        to: {
          pathname: buildLink("repetitiveLosses"),
          search: "?hasIssues=true",
          state: {
            prevLocation: location.pathname,
          },
        },
      });
    }
  }

  const customButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <Button
      size="small"
      styleVariant="outlineLight"
      rightIconName="chevron-down"
      onClick={onClick}
      disabled={!canUpdateRepetitiveLossData}
      data-testid="actions"
    >
      Actions
    </Button>
  );

  return (
    <Box>
      <Header>
        <HeaderTitle>Repetitive loss data settings</HeaderTitle>
        {!loading && (
          <DropdownMenu
            actions={actions}
            disabled={!canUpdateRepetitiveLossData}
            customButton={customButton}
          />
        )}
      </Header>
      <Divider />
      {!canListRepetitiveLossDataImports && <InsufficientPermissions />}
      {canListRepetitiveLossDataImports &&
        !loading &&
        (hasRepetitiveLossImports ? (
          <Section>
            <SectionTitle>Recent imports</SectionTitle>
            <RecentImportTasksTable
              recentImportTasks={repetitiveLossImports.map(
                repetitiveLossImport => {
                  const hasFailedRepetitiveLosses =
                    repetitiveLossImport.failedRepetitiveLosses &&
                    repetitiveLossImport.failedRepetitiveLosses.length > 0;
                  const csvDownloadHref = hasFailedRepetitiveLosses
                    ? `/api/repetitive-loss-import/${repetitiveLossImport.id}/failed-repetitive-losses`
                    : null;

                  return {
                    id: repetitiveLossImport.id,
                    name: repetitiveLossImport.name,
                    error: repetitiveLossImport.error,
                    createdAt: repetitiveLossImport.createdAt,
                    status: upperFirst(repetitiveLossImport.status),
                    csvDownloadHref,
                    disabled: !canViewRepetitiveLossData,
                    icon: Icons.REPETITIVE_LOSS,
                    actionButton:
                      repetitiveLossImport.canDownloadFromS3 &&
                      canViewRepetitiveLossData
                        ? ActionButton
                        : null,
                  } as RecentImportTask;
                }
              )}
              manualPaginationConfig={{
                ...manualPaginationConfig,
                pageCount:
                  data.account?.repetitiveLossImports.pageInfo.totalPages ?? 1,
              }}
              loading={loading}
            />
          </Section>
        ) : (
          <EmptyState
            icon="add-file"
            title="No repetitive loss data for this account"
            body="Import file to get started."
            paddingTop={spacing["2xl"].value}
            paddingBottom={spacing["2xl"].value}
          />
        ))}
    </Box>
  );
};

export default RepetitiveLossData;
