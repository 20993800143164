import React from "react";

import {
  Text as BaseText,
  Radio as BaseRadio,
  Datepicker as BaseDatepicker,
  Label as BaseLabel,
  Select as BaseSelect,
  Checkbox as BaseCheckbox,
} from "../../../Inputs";

import "./__styles__/Common.scss";
import { Body } from "../../../Common/Typography";

export function Form({ children }) {
  const handleSubmit = event => event.preventDefault();

  return (
    <form styleName="form" onSubmit={handleSubmit}>
      {children}
    </form>
  );
}

export function Alert({ children }) {
  return <p styleName="alert">{children}</p>;
}

export function FormTitle({ children }) {
  return <h1 styleName="form-title">{children}</h1>;
}

export function SectionTitle({ children }) {
  return <h2 styleName="section-title">{children}</h2>;
}

export function Fieldset({ legend, children }) {
  return (
    <fieldset styleName="fieldset">
      {legend && <legend>{legend}</legend>}
      {children}
    </fieldset>
  );
}

export function TextInput({ ...props }) {
  return (
    <div styleName="input-wrapper">
      <BaseText {...props} labelTabIndex={-1} />
    </div>
  );
}

export function RadioInput({ ...props }) {
  if (props.valueFormatter) {
    props.value = props.valueFormatter(props.value);
  }

  return (
    <div styleName="radio-input">
      <BaseRadio {...props} />
    </div>
  );
}

export function NullableRadioInput({ ...props }) {
  return (
    <RadioInput
      name={props.name}
      value={props.value?.toString()}
      onChange={value => {
        const lookup = { true: true, false: false };
        props.onChange(lookup[value] ?? null);
      }}
      error={props.errors}
      options={[
        { value: "true", text: "Yes" },
        { value: "false", text: "No" },
      ]}
    />
  );
}

export function DateInput({ ...props }) {
  // https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
  // since the backend often times returns transcribed dates, which can be incorrect,
  // we make sure the date picker doesn't blow up by sending in only valid dates. Note that we have to
  // check for the year being between 1000 and 3000 because Safari, for some reason, thinks
  // 07-03-15 is the 15th of March 0007, while Chrome correctly thinks it's the 3rd of July 2015.
  const parsedDate = new Date(props.value);
  const validDate =
    !isNaN(parsedDate.getTime()) &&
    parsedDate.getFullYear() > 1000 &&
    parsedDate.getFullYear() < 3000;

  const value = validDate ? props.value : null;

  return (
    <div styleName="input-wrapper">
      <BaseDatepicker {...props} value={value} styleName="datepicker" />
    </div>
  );
}

export function Label(props) {
  return (
    <div styleName="input-wrapper">
      <BaseLabel tabIndex={-1} {...props} />
    </div>
  );
}

export function InputRow({ children }) {
  return <div styleName="input-row">{children}</div>;
}

export function Note({ children }) {
  return <small styleName="note">{children}</small>;
}

export function Callout({ children }) {
  return <p styleName="callout">{children}</p>;
}

export function Select(props) {
  return <BaseSelect {...props} labelTabIndex={-1} />;
}

export function Checkbox({ ...props }) {
  return (
    <div styleName="input-wrapper">
      <BaseCheckbox {...props} />
    </div>
  );
}

export function Attachments({ set, get }) {
  return (
    <>
      <SectionTitle>Attachments</SectionTitle>
      <Body size="default" type="regular" style={{ marginBottom: "10px" }}>
        Is there a V-Zone Design Certificate present?
      </Body>
      <Note>
        Note: This document would appear after the Elevation Certificate. If no
        document with this title appears on the document, please leave blank.
      </Note>
      <div styleName="breakaway-walls">
        <BaseCheckbox
          name="hasBreakawayWalls"
          value={get("breakawayWalls") || false}
          onChange={value => {
            set("breakawayWalls")(value);
          }}
          label="Yes"
        />
      </div>
    </>
  );
}
