import React, { useContext } from "react";
import { Title } from "../../../../../Common/Typography";
import Divider from "../../../../../Common/Divider";
import { spacing } from "../../../../../../stitches.config";
import { SubmissionsBuilderContextInstance } from "../context";
import { FlexRow } from "../../../../../Common/__styles__/Layout";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { formatDateString } from "common/utils/dates";
import { ColumnWrapper } from "../__styles__/SubmissionsBuilder";
import SelectionCardGroup from "../../../../../Inputs/SelectionCardGroup";
import { SubmissionTypeVersion } from "../../../../../../generated/graphql";
import { pluralize } from "common/utils/strings";

const generateTitle = ({
  isCurrent,
  version,
}: {
  version: Pick<SubmissionTypeVersion, "version" | "updatedAt">;
  isCurrent: boolean;
}): string => {
  if (isCurrent) {
    return `Current version`;
  }

  return `Version ${version.version} · ${formatDateString({
    dateString: version.updatedAt,
    format: "MM D, YYYY H:MM",
  })}`;
};

const generateDescription = ({
  version,
  isCurrent,
}: {
  version: Pick<SubmissionTypeVersion, "submissionCount">;
  isCurrent: boolean;
}): string => {
  const noun = pluralize({
    count: version.submissionCount,
    options: { singular: "record", plural: "records" },
  });

  return `${version.submissionCount} ${noun}${isCurrent ? " created" : ""}`;
};

export const RightSidebarColumn = ({ onClose }: { onClose: () => void }) => {
  const {
    selectedVersionId,
    setSelectedVersionId,
    toggleFormSettingsMode,
    versions,
  } = useContext(SubmissionsBuilderContextInstance);
  const maxVersion = Math.max(...versions.map(v => v.version));

  return (
    <ColumnWrapper>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Title size={"large"} type={"semiBold"}>
          Version history
        </Title>
        <Icon
          iconName="x"
          size={16}
          color="contentSecondary"
          onClick={onClose}
          testId="close-version-history"
        />
      </FlexRow>
      <Divider margins={`0px -${spacing.l.value}`} />
      <SelectionCardGroup
        value={selectedVersionId ?? undefined}
        options={versions.map(version => ({
          id: version.id,
          value: version.id,
          title: generateTitle({
            version,
            isCurrent: version.version === maxVersion,
          }),
          description: generateDescription({
            version,
            isCurrent: version.version === maxVersion,
          }),
          ghost: selectedVersionId !== version.id,
        }))}
        data-orientation="vertical"
        onChange={value => {
          toggleFormSettingsMode();
          setSelectedVersionId(value);
        }}
      />
    </ColumnWrapper>
  );
};
