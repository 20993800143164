import React, { useContext } from "react";
import { TABLE_NAMES } from "common/utils/queryBuilder";
import { NetworkStatus } from "@apollo/client";
import { formatAddressFromAddressParts } from "common/utils/address";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import { ColumnDef } from "@tanstack/table-core/build/lib/types";

import {
  GetQueryPropertiesQueryVariables,
  useGetPropertyColumnConfigQuery,
  useGetQueryPropertiesLazyQuery,
  useGetSavedViewsQuery,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../Common/Tables/hooks";
import { QueryPropertyTableResult } from "./__queries__/table";
import { ActionsProps } from "../Inputs/DropdownMenu";
import { useDeletePropertyModal } from "../AddressPanel/Header/DeletePropertyModal";
import { ACTION_COLUMN_DEF_CONSTANTS, ActionCell } from "../Common/ActionCell";
import { useLocation } from "react-router";
import { useLocalTableDisplayConfig } from "../../hooks/useTableDisplayConfig";
import {
  MinimalColumnDef,
  fieldId,
  initializeColumns,
  mergeFieldGroups,
  sanityCheckLocalColumnOrder,
} from "../Common/FullWidthTable/utils";
import { buildGuestPropertyURL } from "common/utils/url";
import LinkCell from "../Common/LinkCell";
import { Attribute } from "../Common/FullWidthTable/types";
import {
  buildColumnDefinitions,
  CellRenderConfig,
} from "../Common/Tables/utils";
import { FullWidthTable } from "../Common/FullWidthTable/FullWidthTable";
import { filesCell, pillsCell } from "../Common/FullWidthTable/customCells";
import ExportDataButton from "../Exports/ExportDataButton";
import {
  INITIAL_SUBMISSION_TYPE_REPORTS,
  SDE_DATABASE_REPORT,
} from "../Exports/constants";
import { buildInitialReportTypes } from "../Exports/ExportDataForm";

export const PROPERTIES_TABLE_REQUIRED_FIELDS = [
  { table: TABLE_NAMES.PROPERTIES, name: "id" },
  { table: TABLE_NAMES.PROPERTIES, name: "latitude" },
  { table: TABLE_NAMES.PROPERTIES, name: "longitude" },
  { table: TABLE_NAMES.PROPERTIES, name: "zipcode" },
  { table: TABLE_NAMES.PROPERTIES, name: "city" },
  { table: TABLE_NAMES.PROPERTIES, name: "state" },
  { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
] as const;

const propertyLink = ({ admin }: { admin: boolean }) => {
  return {
    cell: ({ row }: { row: { original: QueryPropertyTableResult } }) => {
      const { account } = useContext(AuthContext);
      const props = admin
        ? row.original["Properties.adminLink"]
        : row.original["Properties.userLink"];

      if (!props) {
        return null;
      }

      const { latitude, longitude, propertyId, streetAddress } = props;

      const url = buildLink(
        "map",
        { accountId: account?.id! },
        {
          propertyId,
          lat: latitude,
          lng: longitude,
          address: streetAddress,
        }
      );

      return <LinkCell href={url} label={streetAddress} target={"_blank"} />;
    },
  };
};

export const CUSTOM_PROPERTY_LINK_CELLS = {
  "Properties.adminLink": propertyLink({ admin: true }),
  "Properties.publicLink": {
    cell: ({ row }: { row: { original: QueryPropertyTableResult } }) => {
      const linkProps = row.original["Properties.publicLink"];
      const address = row.original["Properties.streetAddress"];

      if (!linkProps) {
        return null;
      }

      const url = buildGuestPropertyURL({
        subdomain: linkProps.subdomain,
        propertyId: linkProps.propertyId,
      });

      return <LinkCell href={url} target={"_blank"} label={address} />;
    },
  },
  "Properties.streetAddress": {
    cell: ({ row }: { row: { original: QueryPropertyTableResult } }) => {
      const address = row.original["Properties.streetAddress"];
      const url = buildLink(
        "map",
        {},
        {
          lat: row.original["Properties.latitude"],
          lng: row.original["Properties.longitude"],
          propertyId: row.original["Properties.id"],
        }
      );
      if (!url) {
        return address;
      }
      return <LinkCell href={url} target="_blank" label={address} />;
    },
  },
  "Properties.userLink": propertyLink({ admin: false }),
};

export const PROPERTY_TABLE_CUSTOM_CELLS: Record<string, CellRenderConfig> = {
  "Properties.warnings": {
    cell: ({ row }) => {
      return pillsCell({
        row: row.original["Properties.warnings"]?.map(
          (w: { title: string }) => w.title
        ),
        iconName: "alert-circle",
      });
    },
  },
  "Properties.files": {
    cell: ({ row }) => {
      return filesCell({ row: row.original["Properties.files"] });
    },
  },
  ...CUSTOM_PROPERTY_LINK_CELLS,
};

const PropertyActions = ({
  propertyData,
  onDelete,
}: {
  propertyData: {
    "Properties.id": string;
    "Properties.streetAddress": Maybe<string>;
    "Properties.city": Maybe<string>;
    "Properties.latitude": number;
    "Properties.longitude": number;
    "Properties.state": Maybe<string>;
    "Properties.zipcode": Maybe<string>;
  };
  onDelete: () => void;
}) => {
  const id = propertyData["Properties.id"];
  const streetAddress = propertyData["Properties.streetAddress"];
  const city = propertyData["Properties.city"];
  const latitude = propertyData["Properties.latitude"];
  const longitude = propertyData["Properties.longitude"];

  const { authorized, account } = useContext(AuthContext);
  const [showDeletePropertyModal, hideDeletePropertyModal] =
    useDeletePropertyModal({
      property: {
        id,
        streetAddress,
        city,
        fullAddress: formatAddressFromAddressParts({
          streetAddress,
          city,
          state: propertyData["Properties.state"],
          zipcode: propertyData["Properties.zipcode"],
        }),
        latitude,
        longitude,
      },
      updateMap: () => {},
      closeModal: () => {
        hideDeletePropertyModal();
      },
      onDelete: () => {
        hideDeletePropertyModal();
        onDelete();
      },
    });

  const actions: Array<ActionsProps> = [
    {
      label: "View",
      href: buildLink(
        "map",
        { accountId: account?.id! },
        {
          lat: latitude.toString(),
          lng: longitude.toString(),
          address: streetAddress ?? "",
          propertyId: id,
        }
      ),
      target: "_blank",
    },
    {
      label: "Delete",
      onClick: () => {
        showDeletePropertyModal();
      },
      variant: "red",
      disabled: !authorized({
        resource: RESOURCE_NAME.PROPERTY,
        permission: "delete",
      }),
    },
  ];

  return <ActionCell actions={actions} />;
};

export const PropertiesTable = () => {
  const { user, admin, account } = useContext(AuthContext);

  const {
    data: savedViewsResponse,
    loading: loadingSavedViews,
    refetch: refetchSavedViews,
  } = useGetSavedViewsQuery({
    variables: { table: TABLE_NAMES.PROPERTIES },
    fetchPolicy: "cache-and-network",
  });

  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetPropertyColumnConfigQuery({ fetchPolicy: "network-only" });

  const [
    queryProperties,
    { previousData, data: currentData, networkStatus, loading, error, refetch },
  ] = useGetQueryPropertiesLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const actionsColumn: MinimalColumnDef<
    typeof PROPERTIES_TABLE_REQUIRED_FIELDS
  > = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      const property = row.original;
      return <PropertyActions propertyData={property} onDelete={refetch} />;
    },
  };

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    currentView,
  } = useQueryDescription({
    defaultSort: [{ id: "Properties.streetAddress", desc: false }],
    savedViews: savedViewsResponse?.account?.savedViews,
  });

  const generatedColumnDefinitions = buildColumnDefinitions({
    columnConfig: columnConfigResponse?.getPropertyTableConfig.data || [],
    cellRenderers: PROPERTY_TABLE_CUSTOM_CELLS,
  });

  const { pathname } = useLocation();

  const tableId = `${user?.id ?? admin?.id}-${pathname.replace("/", "")}`;
  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: [
          ...queryDescription.fields.map(field => fieldId(field)),
          ACTION_COLUMN_DEF_CONSTANTS.id,
        ],
        columnSizing: {},
        sorting: [],
      },
    });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.queryProperties.pageInfo.totalPages,
    previousTotalPages: previousData?.queryProperties.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<ColumnDef<QueryPropertyTableResult>> =
    [...generatedColumnDefinitions, actionsColumn];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (
    loadingColumnConfig ||
    !columnConfigResponse?.getPropertyTableConfig.data ||
    loadingSavedViews ||
    !currentView
  ) {
    return null;
  }

  const loadingDetails = {
    loading,
    loadingText: "Loading properties",
    noDataText: "No properties found",
  };

  const search = ({ page }: { page: number }) => {
    const variables: GetQueryPropertiesQueryVariables = {
      description: {
        table: TABLE_NAMES.PROPERTIES,
        fields: mergeFieldGroups(
          queryDescription.fields,
          PROPERTIES_TABLE_REQUIRED_FIELDS
        ),
        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void queryProperties({
      variables,
    });
  };

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: queryDescription.fields.map(field => fieldId(field)),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = initializeColumns({
    initialTableState,
    tanstackColumnDefinitions,
    initialQueryDescription: queryDescription,
    actionsColumn,
  });

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  const initialReportTypes = buildInitialReportTypes({
    initialReportTypes: [SDE_DATABASE_REPORT],
    account,
    submissionTypeNames: [INITIAL_SUBMISSION_TYPE_REPORTS.SI_SD_REPORT],
  });

  const actions = <ExportDataButton initialReportTypes={initialReportTypes} />;

  return (
    <FullWidthTable
      columns={initialColumns}
      previousData={previousData?.queryProperties.data}
      currentData={data?.queryProperties.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.queryProperties.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      filterable={{
        newFilterConfiguration: columnConfigResponse.getPropertyTableConfig
          .data as Array<Attribute>,
        search,
      }}
      columnSettingProps={{
        columnConfiguration: columnConfigResponse.getPropertyTableConfig.data,
        columnDefinitions: tanstackColumnDefinitions,
      }}
      timeoutError={timeoutError}
      queryDescription={queryDescription}
      currentView={currentView}
      updateQueryDescription={updateQueryDescription}
      savedViews={savedViewsResponse?.account?.savedViews}
      refetchSavedViews={refetchSavedViews}
      actions={actions}
      clientSideColumnConfig={columnConfigResponse.getPropertyTableConfig.data}
      dataSourceComputationsAffectingQuery={
        data?.queryProperties.dataSourceComputationsAffectingQuery
      }
    />
  );
};
