import React, { useState } from "react";
import { useGetUserAccountsLazyQuery } from "../../../generated/graphql";
import FullPageBackgroundWithLogo from "../../Common/FullPageBackgroundWithLogo";
import { Container } from "../CommonLoginComponents/__styles__/FormWithFullPageBackground";
import { ProvideEmail } from "./ProvideEmail";
import { arrayHasExactlyOneItem } from "common/utils/arrays";
import { getClientAppPrefix } from "common/utils/url";
import { buildFullLink } from "common/routing";
import {
  EmailParamInput,
  getLogoutReasonMessage,
  parsePrevLocationParam,
  PrevLocationParamInput,
} from "../../../utils/params";

export type Account = {
  id: string;
  name: string;
  logoUrl: string;
  subdomain: string;
};

export type LoginFormStructure = {
  email?: Maybe<string>;
};

const IdentifyUser = () => {
  const startingError = getLogoutReasonMessage("login");

  const [error, setError] = useState<Maybe<{ message: string }>>(
    startingError ?? null
  );

  const [getAccounts, { loading: getAccountsLoading }] =
    useGetUserAccountsLazyQuery();

  const prev = parsePrevLocationParam("login");

  const onSelectAccount = ({
    email,
    account,
  }: {
    email: string;
    account: Account;
  }) => {
    const prefix = getClientAppPrefix(account.subdomain);

    const loginUrl = buildFullLink("provideCredentials", {
      params: {},
      queryParams: {
        prev,
        email,
      } satisfies PrevLocationParamInput & EmailParamInput,
      prefix,
    });

    window.location.href = loginUrl;
  };

  const findAccounts = async (email: string) => {
    await getAccounts({
      variables: { email },
      onError: () => {
        setError({
          message:
            "An error occurred. Please try again. If the problem persists, please email us at support@withforerunner.com.",
        });
      },
      onCompleted: data => {
        if (arrayHasExactlyOneItem(data.getUserAccountsByEmail)) {
          onSelectAccount({
            email,
            account: data.getUserAccountsByEmail[0],
          });
        } else {
          setError({
            message:
              "That Forerunner account doesn’t exist. Enter a different account or reach out to your account manager if you need assistance.",
          });
        }
      },
    });
  };

  return (
    <FullPageBackgroundWithLogo>
      <Container>
        <ProvideEmail
          error={error}
          onSubmit={findAccounts}
          loading={getAccountsLoading}
        />
      </Container>
    </FullPageBackgroundWithLogo>
  );
};

export default IdentifyUser;
