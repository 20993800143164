import React from "react";
import { useFormContext } from "react-hook-form";
import {
  UserLoginErrorCode,
  useSendMfaCodeMutation,
} from "../../generated/graphql";
import { Button } from "../Common/Button";
import { Text } from "../Inputs/react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { LoginFormStructure } from "./LoginPage";
import { Body, Title } from "../Common/Typography";
import { Section, Sections } from "../Common/Layout";

export const ProvidePhoneNumber = ({
  dispatch,
  error,
}: {
  dispatch: React.Dispatch<MFAFlowAction>;
  error?: Maybe<{ message: string; code?: UserLoginErrorCode }>;
}) => {
  const { addErrorToast } = useStatusToasts();
  const { register, handleSubmit, watch } = useFormContext();

  const phoneNumber = watch("phoneNumber");
  const disabled = !phoneNumber;

  const [sendCode] = useSendMfaCodeMutation({
    onCompleted: data => {
      dispatch({ type: "sendCode", data: data.sendMFACode });
    },
    onError: () => {
      addErrorToast(
        `There was an issue sending the code. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleLogin = async (loginData: LoginFormStructure) => {
    await sendCode({
      variables: {
        data: {
          email: loginData.email,
          password: loginData.password,
          phoneNumber: loginData.phoneNumber!,
        },
      },
    });
  };

  return (
    <Sections as="form" onSubmit={handleSubmit(handleLogin)}>
      {error && <ErrorMessage error={error} />}
      <Section>
        <Title type="semiBold" size="large">
          Setup Multi-Factor Authentication
        </Title>
        <Text
          label="Mobile phone number"
          {...register("phoneNumber")}
          placeholder="(555) 555-5555"
          autoComplete="off"
        />
        <Body size="small" type="regular" color="contentSecondary">
          Enter a phone number where we can send an authentication code via SMS.
        </Body>
      </Section>
      <Section>
        <Button
          type={"submit"}
          disabled={disabled}
          size="medium"
          styleVariant="primary"
          stretch
        >
          Send code
        </Button>
      </Section>
    </Sections>
  );
};
