import React, { useContext } from "react";
import { Body } from "../../../../Common/Typography";
import { Required } from "../../../../Inputs/__styles__/Label";
import { useFormContext } from "react-hook-form";
import { EditSectionFormStructure } from "./EditSection";
import { Checkbox, Text } from "../../../../Inputs/react-hook-form";
import { FlexColumn } from "../../../../Common/__styles__/Layout";
import { Label } from "../../../../Inputs";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { AuthContext } from "../../../../Authorization/AuthContext";

export const SectionSettings = ({
  code,
}: {
  code: PROPERTY_INFORMATION_SECTION_CODE;
}) => {
  const { account } = useContext(AuthContext);
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<EditSectionFormStructure>();

  const isRepLoss = code === PROPERTY_INFORMATION_SECTION_CODE.REPETITIVE_LOSS;
  const publicPortalEnabled = account?.publicPortal.enabled;

  const repLossDisplaySettings = (
    <Checkbox
      name="isPii"
      disabled={true}
      control={control}
      label="Personally Identifiable Information"
    />
  );

  return (
    <FlexColumn style={{ gap: "16px" }}>
      <Body size={"large"} type={"emphasis"}>
        All fields are required<Required>*</Required>
      </Body>
      <Text
        {...register("label", { required: "This is a required field" })}
        label="Section name"
        error={errors.label?.message}
      />
      {(publicPortalEnabled || isRepLoss) && (
        <FlexColumn>
          <Label text="Display setting" />
          {isRepLoss && repLossDisplaySettings}
          {publicPortalEnabled && (
            <Checkbox
              name="displayToPublic"
              control={control}
              disabled={isRepLoss}
              label="Display on public website"
              description="Attribute display values are maintained but overridden if section is hidden"
            />
          )}
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
