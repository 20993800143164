import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import List from "./List";
import Summary from "./Summary";
import MissingProperty from "../MissingProperty";
import { track } from "../../../utils/tracking";
import {
  DisclaimerName,
  PropertyWarning,
  PropertyWarningGroup,
  SubmissionCategory,
  SubmissionIntakeSource,
  SubmissionModule,
  useGetImprovementsForMapQuery,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { isApplicableImprovementWarning } from "common/services/warningsHelpers";
import { AddressPanelTabProps } from "..";
import {
  DEFAULT_USER_SISD_DISCLAIMER,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";

import { HeaderContainer, SummaryInfo, Title } from "./__styles__";
import Disclaimer from "../../Common/Disclaimer";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { EmptyState } from "../../Common/EmptyState";
import { AddressPanelContext } from "../AddressPanelContext";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";
import { OBJECT_TYPE, SUPPORT_EMAIL } from "common/constants";
import { createSubmissionRedirect } from "../../Common/CreateSubmissionButton/utils";
import { WarningListItemIcon } from "../../Warnings/WarningList";
import { ExpandableList } from "../../Common/ExpandableList";
import { getObjectDisplay } from "common-client/utils/customObject";

type Warnings = Array<
  NonNullable<Pick<PropertyWarning, "severity" | "title" | "message">>
>;

const Warnings = ({ warnings }: { warnings: Warnings }) => {
  const { t } = useTranslation();
  if (!warnings.length) return null;

  return (
    <>
      <HeaderContainer>
        <Title tabIndex={0}>{t("property-warnings-header")}</Title>
      </HeaderContainer>
      <ExpandableList
        list={warnings.map(warning => ({
          title: warning.title,
          expandableContent: warning.message,
          leftContent: <WarningListItemIcon severity={warning.severity} />,
        }))}
      />
    </>
  );
};

const Improvements = ({
  address,
  latitude,
  longitude,
  publicOnly = false,
}: AddressPanelTabProps & {
  publicOnly?: boolean;
}) => {
  const { account, authorized, isGuest } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { property: contextProperty, loadingProperty } =
    useContext(AddressPanelContext);
  const propertyId = contextProperty?.id;

  const { data, loading, refetch, error } = useGetImprovementsForMapQuery({
    variables: {
      propertyId: propertyId!,
      publicOnly,
    },
    fetchPolicy: "cache-and-network",
    skip: loadingProperty || !propertyId,
  });

  useEffect(() => {
    track("Opened improvements", { address });
  }, [address]);

  if (loading || loadingProperty) return <em>{t("common-loading")}</em>;

  if (error || !data) return <em>{t("property-sisd-error-state")}</em>;

  const property = data.property;
  if (!property) {
    return (
      <MissingProperty
        title={t("property-sisd-missing-property")}
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  const disclaimer = getDisclaimerText({
    disclaimerOverrides: account?.disclaimerOverrides,
    name: DisclaimerName.SISD,
    isPublic: isGuest,
    defaultDisclaimers: {
      public: t("disclaimers-sisd"),
      internal: DEFAULT_USER_SISD_DISCLAIMER,
    },
  });

  if (!property.sisd.rule) {
    return (
      <div style={{ marginBottom: "32px" }}>
        {isGuest ? (
          <EmptyState
            icon="error-puzzle"
            title={t("property-sisd-empty-state-no-rules")}
            body={t("property-files-empty-state-text")}
            primaryButtonProps={{
              label: t("get-help"),
              onClick: () => history.push(buildLink("getHelp")),
            }}
          />
        ) : (
          <EmptyState
            icon="error-puzzle"
            title="No SI/SD tracking configured"
            body="Please try a different location or reach out to your customer support representative to learn more."
            primaryButtonProps={{
              label: "Contact us",
              onClick: () => window.open(`mailto:${SUPPORT_EMAIL}`),
            }}
          />
        )}
      </div>
    );
  }

  const summary = property.sisd;
  const warnings = property.warnings.filter(
    warning =>
      isApplicableImprovementWarning(warning) ||
      (warning.group === PropertyWarningGroup.PRELIMINARY_DAMAGE_ASSESSMENT &&
        warning.applies)
  );

  const canCreateImprovement = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "create",
  });

  const showSummary = isGuest ? !account?.publicPortal.hideSISD : true;

  return (
    <div style={{ overflow: "hidden" }}>
      <Warnings warnings={warnings} />
      <HeaderContainer topSpacing={!!warnings.length}>
        <Title>{t("property-sisd-header")}</Title>
        {!isGuest && (
          <CreateSubmissionButton
            disabled={!canCreateImprovement || loadingProperty}
            objectDisplay={getObjectDisplay({
              objectType: OBJECT_TYPE.PROPERTY,
              object: { fullAddress: address, latitude, longitude },
            })}
            onSubmit={submissionTypeId =>
              createSubmissionRedirect({
                history,
                submissionTypeId,
                objectId: propertyId,
                objectType: OBJECT_TYPE.PROPERTY,
              })
            }
            submissionTypeFilter={submissionType =>
              submissionType.category ===
                SubmissionCategory.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE &&
              submissionType.modules.includes(
                SubmissionModule.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
              ) &&
              submissionType.intakeSource === SubmissionIntakeSource.INTERNAL &&
              submissionType.attachments
                .map(attachment => attachment.type)
                .includes(OBJECT_TYPE.PROPERTY)
            }
          />
        )}
      </HeaderContainer>
      <List
        address={address}
        improvements={summary.improvements}
        onUpdate={refetch}
      />
      {showSummary && (
        <SummaryInfo>
          <HeaderContainer>
            <Title tabIndex={0}>{t("property-sisd-summary")}</Title>
          </HeaderContainer>
          <Summary
            projectThresholdPercentage={property.sisd.rule!.threshold}
            projectTracking={property.sisd.rule!.trackingPeriod}
            cumulativeTrackingPeriod={
              property.sisd.rule!.cumulativeTrackingLength
            }
            improvementTotal={summary.improvementTotal}
            cumulativeImprovementProgress={
              summary.cumulativeImprovementProgress
            }
            status={summary.status}
          />
        </SummaryInfo>
      )}

      <Disclaimer message={disclaimer} testId="sisd-disclaimer" />
    </div>
  );
};

export default Improvements;
