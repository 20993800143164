import React, { Fragment } from "react";
import { isNil } from "lodash";

import {
  AccountDocumentType,
  ActivityTargetType,
  ActivityType,
  UpdatedFirmFieldsType,
} from "../../generated/graphql";
import {
  INFO_PANEL_TAB_NAME,
  buildLink,
  RECORDS_TAB_NAME,
} from "common/routing";
import { useLocation } from "react-router-dom";
import { getCurrentURL } from "../../utils/url";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import {
  buildGuestDocumentUploadDetailURL,
  buildGuestPropertyURL,
} from "common/utils/url";
import { documentLinkText } from "../../utils/documentUploads";
import { certificateLinkText } from "../../utils/certificates";

import { StyledAnchor, StyledLink } from "./__styles__/Message";
import { ACTIVITY_TARGET_TYPE, SUBMISSION_CATEGORY } from "common/constants";
import {
  getInfoPanelLinkForAttached,
  getObjectDisplayForAttached,
} from "common-client/utils/customObject";
import { Activity } from "./types";
import { Body } from "../Common/Typography";

export type FeedSource = "Account" | "Property";

type Target = Activity["target"];

interface CertificateTarget {
  issuedAt?: string;
  documentUpload: {
    id: string;
  };
}

interface DocumentUploadTarget {
  issuedAt?: string;
  accountDocumentType: AccountDocumentType;
}

interface SubmissionTarget {
  id: string;
  submissionTypeVersion: {
    id: string;
    submissionType: { id: string; name: string; category: SUBMISSION_CATEGORY };
  };
}

interface PropertyWarningTarget {
  title: string;
}

const isCertificateUploadTarget = (
  target: Target
): target is CertificateTarget => {
  return target?.__typename === "Certificate";
};

const isDocumentUploadTarget = (
  target: Target
): target is DocumentUploadTarget => {
  return target?.__typename === "DocumentUpload";
};

const isSubmissionTarget = (target: Target): target is SubmissionTarget => {
  return target?.__typename === "Submission";
};

const isPropertyWarningTarget = (
  target: Target
): target is PropertyWarningTarget => {
  return target?.__typename === "PropertyWarning";
};

const isTargetDeleted = (activity: Activity) => {
  const targetIsDeletable = [
    ActivityTargetType.CERTIFICATE,
    ActivityTargetType.DOCUMENT_UPLOAD,
    ActivityTargetType.LOG,
    ActivityTargetType.SUBMISSION,
  ].includes(activity.targetType);

  return targetIsDeletable && isNil(activity.target);
};

export interface MessageProps {
  creator: string;
  activity: Activity;
  linkToObject?: Maybe<boolean>;
  feedSource: FeedSource;
}

interface MessagePartBuilderArgs {
  activity: MessageProps["activity"];
  targetId?: string;
  accountId: string;
  subdomain: string;
  feedSource: FeedSource;
}

type MessagePartBuilder = (props: MessagePartBuilderArgs) => {
  action?: string;
  indefiniteArticle: string;
  targetTextParts: {
    linkProps?: Maybe<{ pathname: string; search?: string }>;
    href?: string;
    text: string;
  }[];
};

const MessagePartBuilders: Record<ActivityTargetType, MessagePartBuilder> = {
  [ActivityTargetType.CERTIFICATE]: ({ activity }) => {
    let action: string;
    switch (activity.type) {
      case ActivityType.UPDATED:
        action = "updated extracted fields from";
        break;
      case ActivityType.CREATED:
        action = "finished processing";
        break;
      default:
        action = activity.type;
    }

    const id = activity.targetInfo.id ?? undefined;

    let targetDescription: string, pathname: string;
    if (isCertificateUploadTarget(activity.target)) {
      targetDescription = certificateLinkText(activity.target.issuedAt ?? null);
      pathname = buildLink("documentUploadDetail", {
        id: activity.target.documentUpload.id,
      });
    } else {
      targetDescription = "a certificate";
      pathname = buildLink("elevationCertificateDetail", { id });
    }

    return {
      indefiniteArticle: "",
      targetTextParts: [{ linkProps: { pathname }, text: targetDescription }],
      action,
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.DOCUMENT_UPLOAD]: ({ activity, targetId, subdomain }) => {
    let action: string;
    let indefiniteArticle: string;

    switch (activity.type) {
      case ActivityType.CREATED:
        action = "generated";
        indefiniteArticle = "a";
        break;
      case ActivityType.UPDATED:
        action = "updated fields";
        indefiniteArticle = "from";
        break;
      case ActivityType.SHARED:
        action = "shared";
        indefiniteArticle = "";
        break;
      default:
        action = activity.type;
        indefiniteArticle = "a";
    }

    const targetDescription = isDocumentUploadTarget(activity.target)
      ? documentLinkText({
          issuedAt: activity.target.issuedAt ?? null,
          accountDocumentType: activity.target.accountDocumentType,
        })
      : "document";

    if (activity.type === ActivityType.SHARED) {
      const href = buildGuestDocumentUploadDetailURL({
        subdomain,
        documentUploadId: targetId!,
      });

      return {
        action,
        indefiniteArticle,
        targetTextParts: [{ href, text: targetDescription }],
      } satisfies ReturnType<MessagePartBuilder>;
    } else {
      const linkProps = {
        pathname: buildLink("documentUploadDetail", { id: targetId }),
      };

      return {
        action,
        indefiniteArticle,
        targetTextParts: [{ linkProps, text: targetDescription }],
      } satisfies ReturnType<MessagePartBuilder>;
    }
  },
  [ActivityTargetType.LOG]: ({ targetId, activity }) => {
    const linkProps =
      activity.type === ActivityType.DELETED
        ? null
        : { pathname: buildLink("editLog", { id: targetId }) };

    return {
      indefiniteArticle: "a",
      targetTextParts: [{ linkProps, text: "log" }],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.FIRM]: ({ activity }) => {
    const indefiniteArticle = "the";
    if (activity.targetInfo.attributeLabel) {
      return {
        action: "updated",
        indefiniteArticle,
        targetTextParts: [{ text: activity.targetInfo.attributeLabel }],
      } satisfies ReturnType<MessagePartBuilder>;
    }

    const propertyPanelLink = generateInfoPanelLink({
      activity,
      tab: INFO_PANEL_TAB_NAME.OVERVIEW,
    });

    switch (activity.targetInfo.updatedFIRMFields) {
      case UpdatedFirmFieldsType.FLOODZONE_AND_BFE: {
        return {
          indefiniteArticle,
          targetTextParts: [
            { linkProps: propertyPanelLink, text: "flood zone" },
            { text: " and " },
            { linkProps: propertyPanelLink, text: "Base Flood Elevation" },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      }
      case UpdatedFirmFieldsType.FLOODZONE_AND_BFD: {
        return {
          indefiniteArticle,
          targetTextParts: [
            { linkProps: propertyPanelLink, text: "flood zone" },
            { text: " and " },
            { linkProps: propertyPanelLink, text: "Base Flood Depth" },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      }
      case UpdatedFirmFieldsType.DATUM: {
        return {
          indefiniteArticle,
          targetTextParts: [{ text: "datum", linkProps: propertyPanelLink }],
        } satisfies ReturnType<MessagePartBuilder>;
      }
      case UpdatedFirmFieldsType.BASE_FLOOD_DEPTH: {
        return {
          indefiniteArticle,
          targetTextParts: [
            { text: "Base Flood Depth", linkProps: propertyPanelLink },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      }
      case UpdatedFirmFieldsType.BASE_FLOOD_ELEVATION: {
        return {
          indefiniteArticle,
          targetTextParts: [
            { text: "Base Flood Elevation", linkProps: propertyPanelLink },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      }
      default: {
        return {
          indefiniteArticle,
          targetTextParts: [
            { linkProps: propertyPanelLink, text: "FIRM panel" },
            { text: " and " },
            { linkProps: propertyPanelLink, text: "Effective Date" },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      }
    }
  },
  [ActivityTargetType.COMMENT]: () => {
    return {
      indefiniteArticle: "a",
      targetTextParts: [{ text: "comment" }],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.DESIGN_FLOOD_ELEVATION]: ({ activity }) => {
    const propertyPanelLink = generateInfoPanelLink({
      activity,
      tab: INFO_PANEL_TAB_NAME.OVERVIEW,
    });
    return {
      indefiniteArticle: "the",
      targetTextParts: [
        {
          linkProps: propertyPanelLink,
          text: "Design Flood Elevation",
        },
      ],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.PROPERTY]: ({ subdomain, activity, feedSource }) => {
    const propertyTypeOptions: Partial<
      Record<ActivityType, () => ReturnType<MessagePartBuilder>>
    > = {
      [ActivityType.SHARED]: () => {
        const name =
          activity.targetInfo.recipientName ??
          `${activity.targetInfo.recipientFirstName} 
          ${activity.targetInfo.recipientLastName}`;
        return {
          indefiniteArticle: "a",
          targetTextParts: [
            {
              text: "public property link",
              href: buildGuestPropertyURL({
                subdomain,
                propertyId: activity.objectId!,
              }),
            },
            { text: ` with ${name}` },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      },
      [ActivityType.CREATED]: () => {
        return {
          action: "added",
          indefiniteArticle: feedSource === "Account" ? "a" : "this",
          targetTextParts: [
            { text: feedSource === "Account" ? "new property" : "property" },
          ],
        } satisfies ReturnType<MessagePartBuilder>;
      },
      [ActivityType.DELETED]: () => {
        return {
          indefiniteArticle: "a",
          targetTextParts: [{ text: "property" }],
        } satisfies ReturnType<MessagePartBuilder>;
      },
      [ActivityType.UPDATED]: () => {
        return {
          action: "edited",
          indefiniteArticle: "",
          targetTextParts: [{ text: "property details" }],
        } satisfies ReturnType<MessagePartBuilder>;
      },
      [ActivityType.HID]: () => {
        return {
          action: "hid",
          indefiniteArticle: "",
          targetTextParts: [{ text: "property from the public" }],
        } satisfies ReturnType<MessagePartBuilder>;
      },
      [ActivityType.DISPLAYED]: () => {
        return {
          action: "displayed",
          indefiniteArticle: "",
          targetTextParts: [{ text: "property to the public" }],
        } satisfies ReturnType<MessagePartBuilder>;
      },
    };

    const typeOption = propertyTypeOptions[activity.type]?.();

    return {
      action: typeOption?.action ?? activity.type,
      indefiniteArticle: typeOption?.indefiniteArticle ?? "a",
      targetTextParts: typeOption?.targetTextParts ?? [{ text: "" }],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.BATCH_CERTIFICATE_UPLOAD]: ({ activity }) => {
    const batchCertificateUploadCount =
      activity.targetInfo.batchCertificateUploadCount;
    return {
      indefiniteArticle: `${batchCertificateUploadCount}`,
      targetTextParts: [
        {
          text: `Elevation Certificate${
            batchCertificateUploadCount! > 1 ? "s" : ""
          }`,
        },
      ],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.PROPERTY_WARNING]: ({ activity }) => {
    const urlToTarget = generateInfoPanelLink({ activity });
    const warningTitle = isPropertyWarningTarget(activity.target)
      ? `"${activity.target.title}"`
      : "";

    return {
      indefiniteArticle: "the",
      targetTextParts: [
        {
          linkProps: urlToTarget,
          text: warningTitle ? `${warningTitle} warning` : "warning",
        },
      ],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.REPETITIVE_LOSS]: ({}) => {
    return {
      indefiniteArticle: "the",
      targetTextParts: [{ text: "Repetitive Loss Data" }],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ActivityTargetType.SUBMISSION]: ({ activity }) => {
    if (
      [ActivityType.INCLUDED, ActivityType.EXCLUDED].includes(activity.type)
    ) {
      const urlToTarget = generateInfoPanelLink({
        activity,
        tab: INFO_PANEL_TAB_NAME.RECORDS,
        recordTab: RECORDS_TAB_NAME.IMPROVEMENTS,
      });
      return {
        indefiniteArticle: "the",
        action:
          activity.type === ActivityType.EXCLUDED
            ? `excluded an SI/SD record from`
            : `included an SI/SD record for`,
        targetTextParts: [{ linkProps: urlToTarget, text: "SI/SD summary" }],
      } satisfies ReturnType<MessagePartBuilder>;
    }

    const urlToTarget = activity.targetInfo.id
      ? {
          pathname: buildLink("viewSubmission", {
            submissionId: activity.targetInfo.id,
          }),
        }
      : null;

    const targetDescription = isSubmissionTarget(activity.target)
      ? activity.target.submissionTypeVersion.submissionType.name
      : // A deleted submission activity will have submission type name stored on targetInfo
        activity.targetInfo.submissionTypeName!;

    // Because submission type names are dynamic, we aren't using indefinite articles
    // in the display, as to prevent gramatical errors
    return {
      indefiniteArticle: "",
      targetTextParts: [{ linkProps: urlToTarget, text: targetDescription }],
    } satisfies ReturnType<MessagePartBuilder>;
  },
  [ACTIVITY_TARGET_TYPE.SAVED_VIEW]: ({ activity }) => {
    return {
      indefiniteArticle: "the",
      targetTextParts: [{ text: activity.targetInfo.savedViewDescription! }],
    } satisfies ReturnType<MessagePartBuilder>;
  },
} as const;

const Message = ({
  creator,
  feedSource,
  activity,
  linkToObject,
}: MessageProps) => {
  const { user, admin, account } = React.useContext(AuthContext);

  const trackClick = () => {
    track("Clicked link in activity feed", {
      userId: user?.id ?? admin?.id,
      userEmail: user?.email ?? admin?.email,
      source: feedSource,
    });
  };

  const location = useLocation();
  const messagePartBuilder =
    MessagePartBuilders[activity.targetType as ActivityTargetType];

  const { action, indefiniteArticle, targetTextParts } = messagePartBuilder({
    activity,
    targetId: activity.targetInfo.id ?? undefined,
    accountId: activity.accountId,
    subdomain: account!.subdomain,
    feedSource,
  });

  const targetDeleted = isTargetDeleted(activity);

  const TargetText = (
    <>
      {targetTextParts.map(({ linkProps, text, href }, index) => {
        const key = `${activity.id}-${index}`;
        if (targetDeleted) {
          return <Fragment key={key}>{text}</Fragment>;
        }

        if (!!linkProps) {
          return (
            <StyledLink
              key={key}
              to={{
                ...linkProps,
                state: { prevLocation: getCurrentURL(location) },
              }}
              onClick={trackClick}
            >
              {text}
            </StyledLink>
          );
        }

        if (!!href) {
          return (
            <StyledAnchor
              href={href}
              onClick={trackClick}
              target="_blank"
              key={key}
            >
              {text}
            </StyledAnchor>
          );
        }

        return <Fragment key={key}>{text}</Fragment>;
      })}
    </>
  );

  const objectLabel = getObjectDisplayForAttached(activity);

  const objectLink = objectLabel && linkToObject && (
    <>
      {[
        ActivityType.EXCLUDED,
        ActivityType.INCLUDED,
        ActivityType.HID,
        ActivityType.DISPLAYED,
      ].includes(activity.type)
        ? "at"
        : "for"}{" "}
      <StyledLink
        to={{
          state: { prevLocation: getCurrentURL(location) },
          ...generateInfoPanelLink({ activity }),
        }}
        onClick={trackClick}
      >
        {objectLabel}
      </StyledLink>
    </>
  );

  const openingClause = `${creator} ${
    action ?? activity.type
  } ${indefiniteArticle} `;

  return (
    <Body size="default" type="regular" color="contentSecondary">
      {openingClause} {TargetText} {objectLink}
    </Body>
  );
};

type GenerateInfoPanelLinkProps = {
  activity: Activity;
  tab?: INFO_PANEL_TAB_NAME;
  recordTab?: RECORDS_TAB_NAME;
};

const generateInfoPanelLink = ({
  activity: { objectType, property, customMapGeometry },
  tab,
  recordTab,
}: GenerateInfoPanelLinkProps) => {
  return getInfoPanelLinkForAttached({
    objectType,
    property,
    customMapGeometry: customMapGeometry
      ? {
          id: customMapGeometry.id,
          customMapId: customMapGeometry.customMap.id,
        }
      : undefined,
    tab,
    recordTab,
  });
};

export default Message;
