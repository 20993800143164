import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { map, startCase, upperCase } from "lodash";

import {
  Select,
  Text,
  Number,
  SingleFileUpload,
} from "../../Inputs/react-hook-form";
import { Button } from "../../Common/Button";
import { MIME_TYPE, STATES } from "common/constants";
import {
  CoastalADetermination,
  Datum,
  useCreateAccountMutation,
} from "../../../generated/graphql";
import { FIRMType } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { buildFullLink, getPath } from "common/routing";
import FullPageFormLayout from "../../Common/FullPageFormLayout";

import { Remove, Row, Error } from "./__styles__/CreateAccount";
import {
  Header,
  Section,
  SectionDivider,
} from "../../Common/__styles__/FullPageFormLayout";
import { getClientAppPrefix } from "common/utils/url";

export type AccountCreationForm = {
  name: string;
  state: string;
  fiveDigitPanelPrefix: string;
  datumConversionOffset: Maybe<number>;
  communityNames: Array<{ name: string }>;
  communityNumber: string;
  coastalADeterminationMethod: CoastalADetermination;
  subdomain: string;
  boundaries: { blob: any };
  publicPortal: { color: string };
  firms: Array<{
    isDefault: boolean;
    type: FIRMType;
    datum: Datum;
  }>;
};

interface Props {
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
  wait?: typeof setTimeout;
}

export const CreateAccount = ({ useDropzone, wait = setTimeout }: Props) => {
  const formMethods = useForm<AccountCreationForm>();

  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const [createAccount, { loading: creatingAccount }] =
    useCreateAccountMutation({
      onCompleted: data => {
        addSuccessToast(
          "Account created successfully! Please wait while you get redirected to the account's setting page."
        );
        // this is a hack to make sure that, when the
        // user is redirected to the new account, the
        // account and all its data have been created
        // we have to wait for the new domain to be live
        // The Route53 docs say this could take up to 1 min
        wait(() => {
          const url = buildFullLink("settings", {
            params: {},
            prefix: getClientAppPrefix(data.createAccount.subdomain),
          });

          window.location.href = url;
        }, 60000);
      },
      onError: () => {
        addErrorToast("Failed to create account! Please contact engineering.");
      },
    });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = formMethods;

  const {
    fields: firms,
    append: appendFIRM,
    remove: removeFIRM,
  } = useFieldArray({
    control,
    name: "firms",
    rules: {
      validate: value => {
        if (
          value.map(firm => firm.type).length !==
          new Set(value.map(firm => firm.type)).size
        ) {
          return "You cannot have duplicate FIRM types";
        }

        return true;
      },
    },
  });

  const {
    fields: communityNames,
    append: appendCommunityName,
    remove: removeCommunityName,
  } = useFieldArray({
    control,
    name: "communityNames",
    rules: {
      required: "You must have at least one community name",
    },
  });

  const onSubmit = async (data: AccountCreationForm) => {
    await createAccount({
      variables: {
        data: {
          ...data,
          boundaries: data.boundaries.blob,
          communityNames: map(data.communityNames, "name"),
        },
      },
    });
  };

  const whiteSpaceValidation = {
    value: /^[^\s]+(\s+[^\s]+)*$/,
    message: "Entered value cannot start/end with whitespace",
  };

  const rightContainer = (
    <div>
      <Button
        size="small"
        styleVariant="primary"
        onClick={handleSubmit(onSubmit)}
        loading={creatingAccount}
      >
        Create account
      </Button>
    </div>
  );

  return (
    <FormProvider {...formMethods}>
      <FullPageFormLayout
        subtitle="Create Account"
        prevLocation={getPath("adminAccounts")}
        rightContainer={rightContainer}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header>
            <h1>Account details</h1>
          </Header>
          <Section grid>
            <Text
              label="Name"
              error={errors.name?.message}
              description={"Example: Bay Head, NJ"}
              {...register("name", {
                required: "Name is required",
                pattern: whiteSpaceValidation,
              })}
            />
            <Select
              name="state"
              control={control}
              description={"Example: New Jersey"}
              error={errors.state?.message}
              rules={{ required: "State is required" }}
              options={Object.values(STATES).map(option => {
                return {
                  label: startCase(option.name),
                  value: option.name,
                };
              })}
              label="State"
            />
            <Text
              label="Five Digit Panel Prefix"
              description="Example: 12015"
              error={errors.fiveDigitPanelPrefix?.message}
              {...register("fiveDigitPanelPrefix", {
                pattern: whiteSpaceValidation,
              })}
            />
            <Text
              description="Example: 120062"
              label="Community Number"
              error={errors.communityNumber?.message}
              {...register("communityNumber", {
                required: "Community Number is required",
                pattern: whiteSpaceValidation,
              })}
            />
            <Number
              name="datumConversionOffset"
              label="Datum Conversion Offset"
              register={register}
              rules={{ required: "Datum Conversion Offset is required" }}
              error={errors.datumConversionOffset?.message}
            />
            <Select
              name="coastalADeterminationMethod"
              control={control}
              error={errors.coastalADeterminationMethod?.message}
              rules={{ required: "Coastal A determination method is required" }}
              options={Object.values(CoastalADetermination).map(option => {
                return { label: startCase(option), value: option };
              })}
              label="Coastal A determination method"
            />
            <Text
              label="Subdomain"
              description="Example: bayheadnj"
              error={errors.subdomain?.message}
              {...register("subdomain", {
                required: "Subdomain is required",
                pattern: whiteSpaceValidation,
              })}
            />
            <Text
              label="Public Account Color"
              description="Example: #000000"
              error={errors.publicPortal?.color?.message}
              {...register("publicPortal.color", {
                required: "Public Account Color is required",
                pattern: { value: /^#[0-9A-F]{6}$/i, message: "Invalid color" },
              })}
            />
          </Section>
          <SectionDivider />
          <Header>
            <h1>FIRMs</h1>
          </Header>
          {errors.firms?.root && <Error>{errors.firms.root.message}</Error>}
          <Section>
            {firms.map((_firm, index) => {
              return (
                <Row key={index}>
                  <Select
                    name={`firms.${index}.type`}
                    control={control}
                    options={Object.values(FIRMType).map(option => {
                      return { label: startCase(option), value: option };
                    })}
                    label="Type"
                  />
                  <Select
                    name={`firms.${index}.datum`}
                    control={control}
                    options={Object.values(Datum).map(option => {
                      return { label: upperCase(option), value: option };
                    })}
                    label="Datum"
                  />
                  <Select
                    name={`firms.${index}.isDefault`}
                    control={control}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    label="Default FIRM"
                  />
                  <Remove onClick={() => removeFIRM(index)}>X</Remove>
                </Row>
              );
            })}
            <Button
              styleVariant="primary"
              size="medium"
              onClick={() =>
                appendFIRM({
                  isDefault: false,
                  type: FIRMType.EFFECTIVE,
                  datum: Datum.NAVD_1988,
                })
              }
            >
              Add FIRM
            </Button>
          </Section>
          <SectionDivider />
          <Header>
            <h1>Community names</h1>
          </Header>
          {errors.communityNames?.root && (
            <Error>{errors.communityNames.root.message}</Error>
          )}
          <Section>
            {communityNames.map((_community, index) => {
              return (
                <Row key={index}>
                  <Text
                    data-testid={`community-name-${index}`}
                    error={errors.communityNames?.[index]?.name?.message}
                    {...register(`communityNames.${index}.name`, {
                      required: "Name is required",
                      pattern: whiteSpaceValidation,
                    })}
                  />
                  <Remove onClick={() => removeCommunityName(index)}>X</Remove>
                </Row>
              );
            })}
            <Button
              styleVariant="primary"
              size="medium"
              onClick={() => appendCommunityName({ name: "" })}
            >
              Add Community Name
            </Button>
          </Section>
          <SectionDivider />
          <Header>
            <h1>Account boundary</h1>
          </Header>
          {errors.boundaries && <Error>{errors.boundaries.message}</Error>}
          <Section>
            <SingleFileUpload
              name="boundaries"
              useDropzone={useDropzone}
              control={control}
              allowedMimeTypes={[MIME_TYPE.GEOJSON]}
              rules={{
                required: "Please upload an account boundary file",
              }}
            />
          </Section>
        </form>
      </FullPageFormLayout>
    </FormProvider>
  );
};
export default CreateAccount;
