import React from "react";
import { groupBy } from "lodash";

import { formatDate } from "./dateHelper";
import ListItem from "./ListItem";
import { FeedSource } from "./Message";

import { ActivityDateGroup, List } from "./__styles__/ActivityList";
import { Activity } from "./types";
import { Body } from "../Common/Typography";

export interface ComponentProps {
  onUpdate: (deletedId?: string) => void;
  activities: Activity[];
  linkToObject: boolean;
  truncateComments: boolean;
  allowEdits: boolean;
  feedSource: FeedSource;
}

const ActivityList = ({
  onUpdate,
  activities,
  linkToObject,
  truncateComments,
  allowEdits,
  feedSource,
}: ComponentProps) => {
  const groupedActivities = groupBy(activities, activity => {
    return formatDate({ dateString: activity.occurredAt });
  });

  return (
    <>
      {Object.entries(groupedActivities).map(([dateString, activities]) => (
        <ActivityDateGroup key={dateString}>
          <Body size="default" type="emphasis">
            {dateString}
          </Body>
          <List>
            {activities.map(activity => (
              <ListItem
                key={activity.id}
                activity={activity}
                feedSource={feedSource}
                onUpdate={onUpdate}
                linkToObject={linkToObject}
                truncateComments={truncateComments}
                allowEdits={allowEdits}
              />
            ))}
          </List>
        </ActivityDateGroup>
      ))}
    </>
  );
};

export default ActivityList;
