import React from "react";
import { useFormContext } from "react-hook-form";
import {
  CustomNodeDefinition,
  CustomNodeProps,
  JsonEditor,
} from "json-edit-react";
import { SubmissionsBuilderFormDataStructure } from "../types";
import {
  DefaultValuePaths,
  SampleInputs,
  LabelSizes,
} from "common/services/formBuilderService";
import { entries, isNil } from "lodash";
import { Select } from "../../../../../Inputs";
import { NODE_ENV } from "common/env";

const OptionSelect: React.FC<
  CustomNodeProps & { options: Array<{ label: string; value: string }> }
> = ({ value, setValue, isEditing, options }) => {
  return isEditing ? (
    <div style={{ minWidth: "200px" }}>
      <Select
        name="default"
        options={options}
        value={value as string}
        onChange={value => setValue(value!)}
      />
    </div>
  ) : (
    <div>"{value}"</div>
  );
};

const generateCustomNodeDefinitions = ({
  accountDocumentTypes,
}: {
  accountDocumentTypes: Array<{ id: string; name: string }>;
}) => {
  return entries(SampleInputs)
    .map(
      ([name, defaultValue]): CustomNodeDefinition => ({
        name: `${name} input`,
        defaultValue,
        condition: () => false,
        element: () => {
          return <div>You shouldn't see this</div>;
        },
        showOnView: false,
        showOnEdit: true,
        showInTypesSelector: true,
      })
    )
    .concat(
      {
        name: "Default Value Path",
        defaultValue: "units.feet",
        showOnView: false,
        showOnEdit: true,
        condition: ({ key, value }) =>
          (isNil(value) || typeof value === "string") &&
          key === "defaultValuePath",

        element: props => {
          const options = DefaultValuePaths.map(path => ({
            label: path,
            value: path,
          }));

          return <OptionSelect {...props} options={options} />;
        },
      },
      {
        name: "Label Size",
        defaultValue: "medium",
        showOnView: false,
        showOnEdit: true,
        condition: ({ key, value }) =>
          (isNil(value) || typeof value === "string") && key === "labelSize",
        element: props => {
          const options = LabelSizes.map(size => ({
            label: size,
            value: size,
          }));

          return <OptionSelect {...props} options={options} />;
        },
      },
      {
        name: "Account Document Type",
        defaultValue: "medium",
        showOnView: false,
        showOnEdit: true,
        condition: ({ key, value }) =>
          (isNil(value) || typeof value === "string") &&
          key === "accountDocumentType",
        element: props => {
          const options = accountDocumentTypes.map(adt => ({
            label: adt.name,
            value: adt.id,
          }));

          return <OptionSelect {...props} options={options} />;
        },
      }
    );
};

const accountNodeDefinitions = generateCustomNodeDefinitions({
  accountDocumentTypes: [],
});

const FormJSONPreview = () => {
  const { getValues, reset } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  const formValues = getValues();

  const canEdit = NODE_ENV === "development";

  return (
    <div>
      <JsonEditor
        maxWidth={"100%"}
        restrictEdit={!canEdit}
        restrictAdd={!canEdit}
        restrictDelete={!canEdit}
        data={formValues}
        customNodeDefinitions={accountNodeDefinitions}
        onUpdate={({ newData }) => {
          reset(newData);
        }}
      />
    </div>
  );
};

export default FormJSONPreview;
