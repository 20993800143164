import React from "react";
import { Section } from "../Common/Layout";
import { Body, Title } from "../Common/Typography";

export const RequestMFAResetConfirmation = () => {
  return (
    <Section>
      <Title type="semiBold" size="large">
        MFA Reset Requested
      </Title>
      <Body size="default" type="regular">
        Your reset request has been sent! You will receive an email in your
        inbox once the request has been approved with instructions on how to
        complete the reset process.
      </Body>
    </Section>
  );
};
