import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { pick } from "lodash";

import { Button } from "../../Common/Button";
import { Text } from "../../Inputs/react-hook-form";
import { PHONE_REGEX, isValidEmail } from "common/utils/strings";
import { SettingsForm, SettingsGrid } from "../../Common/__styles__/Settings";
import { SettingsSection } from "../../Common/Settings";
import { SUPPORTED_LANGUAGES } from "common/constants";
import { SupportedLanguages } from "common-client/generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { Checkbox } from "../../Inputs";
import { FlexColumn } from "../../Common/__styles__/Layout";
import { useUpdatePublicWebsiteSettingsMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

const LANGUAGES = [
  {
    label: "English",
    value: SUPPORTED_LANGUAGES.ENGLISH,
  },
  {
    label: "Spanish",
    value: SUPPORTED_LANGUAGES.SPANISH,
  },
];

interface FormStructure {
  name?: Maybe<string>;
  email?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  title?: Maybe<string>;
  homepageTitle: string;
  navBarTitle: string;
  supportedLanguages: Array<SUPPORTED_LANGUAGES>;
}

export const PublicWebsiteSettingsForm = ({
  settings,
  canUpdateAccountInformation,
}: {
  settings: FormStructure;
  canUpdateAccountInformation: boolean;
}) => {
  const { admin } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormStructure>({
    defaultValues: pick(settings, [
      "name",
      "email",
      "phoneNumber",
      "title",
      "homepageTitle",
      "navBarTitle",
      "supportedLanguages",
    ]),
  });

  const [updatePublicWebsiteSettings, { loading: updating }] =
    useUpdatePublicWebsiteSettingsMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your public website settings were successfully updated."
        );
      },
      onError: () => {
        addErrorToast(
          "There was a problem updating your public website settings. Please try again or contact support@withforerunner.com"
        );
      },
    });

  const validateTitleMessage =
    "If title is provided you must provide either a name, phone, or email";

  return (
    <SettingsForm
      onSubmit={handleSubmit(async data => {
        await updatePublicWebsiteSettings({ variables: { data } });
      })}
    >
      <SettingsSection
        title="Website title"
        subtitle="This will show up on the public website homepage and navigation bar."
      >
        <SettingsGrid>
          <Text
            label="Homepage title"
            error={errors.homepageTitle?.message}
            {...register("homepageTitle", {
              maxLength: {
                value: 60,
                message: "Homepage title must be less than 60 characters",
              },
            })}
          />
          <Text
            label="Navigation bar title"
            error={errors.navBarTitle?.message}
            {...register("navBarTitle", {
              maxLength: {
                value: 25,
                message: "Navigation bar title must be less than 25 characters",
              },
            })}
          />
        </SettingsGrid>
      </SettingsSection>
      <SettingsSection
        title="Contact information"
        subtitle="This will appear at the top of your public website’s Get Help page for residents to reach out directly."
      >
        <SettingsGrid>
          <Text
            label="Name"
            error={errors.name?.message}
            {...register("name")}
          />
          <Text
            label="Title"
            error={errors.title?.message}
            {...register("title", {
              validate: (val: Maybe<string | undefined>) => {
                if (
                  val &&
                  !getValues("name") &&
                  !getValues("email") &&
                  !getValues("phoneNumber")
                ) {
                  return validateTitleMessage;
                }
                return;
              },
            })}
          />
          <Text
            label="Phone number"
            error={errors.phoneNumber?.message}
            {...register("phoneNumber", {
              validate: (val: Maybe<string | undefined>) => {
                if (val && !PHONE_REGEX.test(val)) {
                  return "Phone number is invalid. Please use the following format: 123-456-7890";
                }
                return;
              },
            })}
          />
          <Text
            label="Email address"
            error={errors.email?.message}
            {...register("email", {
              validate: (val: Maybe<string | undefined>) => {
                if (val && !isValidEmail(val)) {
                  return "Email is invalid";
                }
                return;
              },
            })}
          />
        </SettingsGrid>
      </SettingsSection>
      {admin && (
        <SettingsSection
          title="Language"
          subtitle="Select your public website’s supported languages."
        >
          <SettingsGrid>
            <Controller
              control={control}
              name="supportedLanguages"
              render={({ field }) => {
                return (
                  <FlexColumn style={{ gap: "8px" }}>
                    {LANGUAGES.map((language, index) => {
                      return (
                        <Checkbox
                          key={index}
                          value={field.value.includes(language.value)}
                          disabled={
                            language.value === SUPPORTED_LANGUAGES.ENGLISH
                              ? true
                              : false
                          }
                          label={language.label}
                          name={language.value}
                          onChange={value => {
                            if (value) {
                              field.onChange([...field.value!, language.value]);
                            } else {
                              field.onChange(
                                field.value.filter(
                                  (lang: SupportedLanguages) =>
                                    lang !== language.value
                                )
                              );
                            }
                          }}
                        />
                      );
                    })}
                  </FlexColumn>
                );
              }}
            />
          </SettingsGrid>
        </SettingsSection>
      )}
      <Button
        styleVariant="primary"
        size="medium"
        type="submit"
        disabled={!canUpdateAccountInformation || updating}
        loading={updating}
      >
        Update
      </Button>
    </SettingsForm>
  );
};
