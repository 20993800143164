import React from "react";

import Divider from "../../../Common/Divider";
import { Box } from "../../../Common/__styles__/Layout";

import { Header, Section, HeaderTitle } from "../../__styles__/Content";
import { EmptyState } from "../../../Common/EmptyState";
import { Button } from "../../../Common/Button";
import { useAddBaseMapModal } from "./AddBaseMapModal";
import Table from "./Table";
import { AuthContext } from "../../../Authorization/AuthContext";
import { useGetAccountBaseMapsQuery } from "../../../../generated/graphql";
import { spacing } from "../../../../stitches.config";

export const BaseMaps = () => {
  const { account } = React.useContext(AuthContext);
  const [showAddBaseMapModal] = useAddBaseMapModal();
  const { data, loading } = useGetAccountBaseMapsQuery({
    variables: {
      accountId: account!.id,
    },
  });

  const baseMapData = data?.account?.baseMaps || [];
  const hasBaseMaps = !loading && baseMapData.length > 0;

  return (
    <Box>
      <Header>
        <HeaderTitle>Base map settings</HeaderTitle>
        <Button
          styleVariant="outlineLight"
          onClick={showAddBaseMapModal}
          size="small"
          leftIconName="plus"
        >
          Add Base map
        </Button>
      </Header>
      <Divider></Divider>
      {!loading &&
        (hasBaseMaps ? (
          <Section>
            <Table baseMapData={baseMapData} />
          </Section>
        ) : (
          <EmptyState
            icon="add-raster"
            title="No base maps for this account"
            body="Add a base map to get started."
            paddingTop={spacing["2xl"].value}
            paddingBottom={spacing["2xl"].value}
          />
        ))}
    </Box>
  );
};

export default BaseMaps;
