import React from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { getPath } from "common/routing";
import { MIME_TYPE } from "common/constants";

import FullPageFormLayout from "../../Common/FullPageFormLayout";
import { Button } from "../../Common/Button";
import {
  useDispatchImportAttioAttendeesDataScriptJobMutation,
  useDispatchUploadTranslationDataFromCsvScriptJobMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Header, Section } from "../../Common/__styles__/FullPageFormLayout";
import { SingleFileUpload } from "../../Inputs/react-hook-form";
import { Error } from "../CreateAccount/__styles__/CreateAccount";
import { useMultipartFileUpload } from "../../../hooks/useMultipartFileUpload";

export type ImportType = "attio" | "translation";

type ImportDetails = {
  subTitle: string;
  headerTitle: string;
  description: string;
  csvHeaders: string;
  csvRequiredColumns: string;
  createExplanation: string;
  requiredRule: string;
  onDispatch: (key: string) => Promise<void>;
};

const ATTIO_IMPORT = ({
  dispatchImportAttioAttendeesDataScriptJob,
}: {
  dispatchImportAttioAttendeesDataScriptJob: any;
}): ImportDetails => ({
  subTitle: "Import attendee data into Attio",
  headerTitle: "Import conference and webinar data into Attio",
  description:
    "This form will import attendee data into Attio by creating or updating people records with appropriate webinar and/or conference tags. It can also update job titles, if those are provided.",
  csvHeaders:
    " 'email', 'webinar', 'conference', 'jobTitle', 'phoneNumber', 'cec', and 'assignment'",
  csvRequiredColumns:
    "The 'email' column is required, the rest of the columns are optional.",
  createExplanation:
    "If the value in a webinar or conference column does not exist as a select option within Attio, it will be created.",
  requiredRule: "Please upload an attendee data file",
  onDispatch: async (fileKey: string) => {
    await dispatchImportAttioAttendeesDataScriptJob({
      variables: {
        data: {
          inputFileKey: fileKey,
        },
      },
    });
  },
});

const TRANSLATION_IMPORT = ({
  dispatchUploadTranslationDataFromCsvScriptJob,
}: {
  dispatchUploadTranslationDataFromCsvScriptJob: any;
}): ImportDetails => ({
  subTitle: "Import translation data",
  headerTitle: "Import translation data",
  description:
    "This form will import translation data by creating or updating translation records via the key or en column.",
  csvHeaders: " 'key', 'en', 'es', 'static'",
  csvRequiredColumns:
    "The 'en', 'es' and 'static' columns are always required, the 'key' column is required if static is 'yes'. A copy is considered static if it cannot be overridden by a user/admin.",
  createExplanation:
    "If the translation does not exist, it will be created. If it does exist, it will update the 'en' and 'es' values for static copy and 'es' value for user overridable copy. If the copy did not change it will be skipped.",
  requiredRule: "Please upload a translation data file",
  onDispatch: async (fileKey: string) => {
    await dispatchUploadTranslationDataFromCsvScriptJob({
      variables: {
        data: {
          inputFileKey: fileKey,
        },
      },
    });
  },
});

export type ImportForm = {
  dataFile: { blob: File };
};

interface Props {
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
}

export const CsvImports = ({
  useDropzone,
  match,
}: Props & Pick<RouteComponentProps<{ importType: ImportType }>, "match">) => {
  const history = useHistory();
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const formMethods = useForm<ImportForm>();

  const [uploadSourceFile, { loading: isUploading }] = useMultipartFileUpload({
    onError: () => addErrorToast("Failed to upload input file!"),
  });

  const csvUploadMutationResultHandling = {
    onCompleted: () => {
      history.push(getPath("adminAccounts"));
      addSuccessToast(
        "Import submitted successfully! You will receive an email when the import is completed"
      );
    },
    onError: () => {
      addErrorToast("Failed to import data! Please contact engineering.");
    },
  };

  const [
    dispatchImportAttioAttendeesDataScriptJob,
    { loading: isDispatchingAttioJob },
  ] = useDispatchImportAttioAttendeesDataScriptJobMutation(
    csvUploadMutationResultHandling
  );

  const [
    dispatchUploadTranslationDataFromCsvScriptJob,
    { loading: isDispatchingTranslationJob },
  ] = useDispatchUploadTranslationDataFromCsvScriptJobMutation(
    csvUploadMutationResultHandling
  );

  const importType = match.params.importType;
  const IMPORT_TYPE_TO_IMPORT_DETAILS: Record<ImportType, ImportDetails> = {
    attio: ATTIO_IMPORT({ dispatchImportAttioAttendeesDataScriptJob }),
    translation: TRANSLATION_IMPORT({
      dispatchUploadTranslationDataFromCsvScriptJob,
    }),
  };
  const COPY_MAPPER = IMPORT_TYPE_TO_IMPORT_DETAILS[importType];

  const onSubmit = async (data: ImportForm) => {
    uploadSourceFile({
      file: data.dataFile.blob,
      onCompleted: async key => await COPY_MAPPER.onDispatch(key),
    });
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const rightContainer = (
    <div>
      <Button
        size="small"
        styleVariant="primary"
        onClick={handleSubmit(onSubmit)}
        loading={
          isDispatchingAttioJob || isDispatchingTranslationJob || isUploading
        }
        disabled={
          isDispatchingAttioJob || isDispatchingTranslationJob || isUploading
        }
      >
        Import data
      </Button>
    </div>
  );

  return (
    <FormProvider {...formMethods}>
      <FullPageFormLayout
        subtitle={COPY_MAPPER.subTitle}
        prevLocation={getPath("adminAccounts")}
        rightContainer={rightContainer}
      >
        <form>
          <Header>
            <h1>{COPY_MAPPER.headerTitle}</h1>
            <h2>
              {COPY_MAPPER.description}
              <br />
              <br />
              Import CSV guidelines:
              <ul>
                <li>
                  The first row of your CSV should contain the column headers:
                  {COPY_MAPPER.csvHeaders}
                </li>
                <li>{COPY_MAPPER.csvRequiredColumns}</li>
                <li>{COPY_MAPPER.createExplanation}</li>
              </ul>
              When the script is complete you will receive an email with a link
              to download a CSV containing any errors that occurred during the
              import.
            </h2>
          </Header>
          {errors.dataFile && <Error>{errors.dataFile.message}</Error>}
          <Section>
            <SingleFileUpload
              name="dataFile"
              useDropzone={useDropzone}
              control={control}
              allowedMimeTypes={[MIME_TYPE.CSV]}
              rules={{
                required: COPY_MAPPER.requiredRule,
              }}
            />
          </Section>
        </form>
      </FullPageFormLayout>
    </FormProvider>
  );
};

export default CsvImports;
