import React, { useContext } from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";
import {
  textFont,
  textColor,
  textHaloColor,
  textOpacity,
  customMapConfig,
  transparent,
} from "common-client/utils/mapLayers";
import { Tileset } from "../../../generated/graphql";
import { CUSTOM_MAPS_SOURCE_LAYER } from "common/utils/maps/constants";
import { NON_MAPBOX_TILESET_ID } from "common/constants";
import { NODE_ENV } from "common/env";
import { LayerId } from "../LayeredMap";

const group = "customMaps";
export const CUSTOM_MAPS_SOURCE_ID_PREFIX = "custom-maps-";

export const getInteractiveLayerById: (id: string) => Array<LayerId> = (
  id: string
) => [
  `${id}-lines`,
  `${id}-fill-color-polygons`,
  `${id}-fill-pattern-polygons`,
  `${id}-fill-points`,
];

const CustomMapLayer = ({
  id,
  tileset,
  labelsVisibleOnMap,
  isObject,
}: {
  id: string;
  tileset: Tileset;
  labelsVisibleOnMap: boolean;
  isObject: boolean;
}) => {
  const { isLayerVisible } = useContext(LayerContext);
  const useLocalTileData =
    NODE_ENV !== "production" && tileset.mapboxId === NON_MAPBOX_TILESET_ID;

  const visibility = isLayerVisible({ group, id }) ? "visible" : "none";
  const sourceLayer = useLocalTileData ? "src" : CUSTOM_MAPS_SOURCE_LAYER;

  return (
    <Source
      id={`${CUSTOM_MAPS_SOURCE_ID_PREFIX}${id}`}
      {...(useLocalTileData
        ? {
            tiles: [
              `${window.env.APPLICATION_URL}/api/tiles/customMaps/${id}/{z}/{x}/{y}`,
            ],
          }
        : { url: `mapbox://${tileset.mapboxId}` })}
      type="vector"
      minzoom={8}
      //@ts-ignore - the TS types for mapbox gl leave a lot to be desired
      promoteId="id"
    >
      <Layer
        id={`${id}-lines`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
          "line-cap": "round",
          "line-join": "round",
        }}
        paint={{
          "line-width": [
            "case",
            ["any", customMapConfig.clickFilter, customMapConfig.hoverFilter],
            customMapConfig.getLineInteractionWidth({ minWidth: 4 }),
            customMapConfig.getLineWidth(
              isObject ? { minWidth: 4 } : { defaultWidth: 2 }
            ),
          ],
          "line-color": customMapConfig.getFeatureColorForState({
            defaultColor: customMapConfig.lineColor,
          }),
        }}
        filter={customMapConfig.includeAllLineStringFilter}
      />
      <Layer
        id={`${id}-fill-points`}
        type="symbol"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
          "icon-image": customMapConfig.iconImageFilled,
          "icon-allow-overlap": true,
          "icon-size": customMapConfig.iconSize,
        }}
        paint={{
          "icon-color": [
            "case",
            ["any", customMapConfig.hoverFilter, customMapConfig.clickFilter],
            transparent,
            customMapConfig.fillColor,
          ],
          "icon-opacity": customMapConfig.fillOpacity,
        }}
        filter={customMapConfig.includeAllPointFilter}
      />
      <Layer
        id={`${id}-outline-points`}
        type="symbol"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
          "icon-image": customMapConfig.iconImage,
          "icon-allow-overlap": true,
          "icon-size": customMapConfig.iconSize,
        }}
        paint={{
          "icon-color": [
            "case",
            ["any", customMapConfig.hoverFilter, customMapConfig.clickFilter],
            transparent,
            customMapConfig.lineColor,
          ],
        }}
        filter={customMapConfig.includeAllPointFilter}
      />
      <Layer
        id={`${id}-fill-selected-points`}
        type="symbol"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
          "icon-image": customMapConfig.iconImageFilled,
          "icon-allow-overlap": true,
          "icon-size": customMapConfig.iconSize,
        }}
        paint={{
          "icon-color": [
            "case",
            ["any", customMapConfig.hoverFilter, customMapConfig.clickFilter],
            customMapConfig.fillColor,
            transparent,
          ],
          "icon-opacity": customMapConfig.fillOpacity,
        }}
        filter={customMapConfig.includeAllPointFilter}
      />
      <Layer
        id={`${id}-outline-selected-points`}
        type="symbol"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
          "icon-image": customMapConfig.iconImage,
          "icon-allow-overlap": true,
          "icon-size": customMapConfig.iconSize,
        }}
        paint={{
          "icon-color": customMapConfig.getFeatureColorForState({
            defaultColor: transparent,
          }),
        }}
        filter={customMapConfig.includeAllPointFilter}
      />
      <Layer
        id={`${id}-fill-color-polygons`}
        type="fill"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
        }}
        paint={{
          "fill-color": customMapConfig.fillColor,
          "fill-opacity": customMapConfig.fillOpacity,
        }}
        filter={customMapConfig.includeAllPolygonFilter}
      />
      <Layer
        id={`${id}-fill-pattern-polygons`}
        type="fill"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
        }}
        // we have to have two separate polygon fills because
        // we can't use fill-pattern and fill-color in the same layer
        // see: https://github.com/mapbox/mapbox-gl-js/issues/8514
        paint={{
          "fill-pattern": customMapConfig.fillPattern,
        }}
        filter={customMapConfig.includeAllPolygonFilter}
      />
      <Layer
        id={`${id}-outline-polygons`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
        }}
        paint={{
          "line-opacity": [
            "case",
            customMapConfig.clickFilter,
            0,
            customMapConfig.hoverFilter,
            0,
            1,
          ],
          "line-color": customMapConfig.lineColor,
          "line-width": customMapConfig.getLineWidth({ defaultWidth: 2 }),
        }}
        filter={customMapConfig.includeAllPolygonFilter}
      />
      <Layer
        id={`${id}-label-lines`}
        type="symbol"
        source-layer={sourceLayer}
        layout={{
          "symbol-placement": "line-center",
          visibility:
            isLayerVisible({ group, id }) && labelsVisibleOnMap
              ? "visible"
              : "none",
          "text-field": customMapConfig.textField,
          "text-padding": 10,
          "text-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            0,
            10,
            10,
            15,
            15,
            20,
          ],
          "text-font": textFont,
        }}
        paint={{
          "text-color": textColor,
          "text-halo-color": textHaloColor,
          "text-opacity": textOpacity,
          "text-halo-width": 1,
        }}
        filter={customMapConfig.includeAllLineStringFilter}
      />
      <Layer
        id={`${id}-label-polygons`}
        type="symbol"
        source-layer={sourceLayer}
        layout={{
          visibility:
            isLayerVisible({ group, id }) && labelsVisibleOnMap
              ? "visible"
              : "none",
          "text-field": customMapConfig.textField,
          "text-padding": 10,
          "text-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            0,
            10,
            10,
            15,
            15,
            20,
          ],
          "text-font": textFont,
          "symbol-avoid-edges": false,
        }}
        paint={{
          "text-color": textColor,
          "text-halo-color": textHaloColor,
          "text-opacity": textOpacity,
          "text-halo-width": 1,
        }}
        filter={customMapConfig.includeAllPolygonFilter}
      />
      <Layer
        id={`${id}-outline-click-polygons`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
        }}
        paint={{
          "line-opacity": ["case", customMapConfig.clickFilter, 1, 0],
          "line-color": customMapConfig.getFeatureColorForState({
            defaultColor: transparent,
          }),
          "line-width": customMapConfig.getLineWidth({ minWidth: 2 }),
        }}
        filter={customMapConfig.includeAllPolygonFilter}
      />
      <Layer
        id={`${id}-outline-hover-polygons`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility,
        }}
        paint={{
          "line-opacity": ["case", customMapConfig.hoverFilter, 1, 0],
          "line-color": customMapConfig.getFeatureColorForState({
            defaultColor: transparent,
          }),
          "line-width": customMapConfig.getLineWidth({ minWidth: 2 }),
        }}
        filter={customMapConfig.includeAllPolygonFilter}
      />
    </Source>
  );
};

export default CustomMapLayer;
