import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import * as Dropdown from "@radix-ui/react-dropdown-menu";

import { Icon, IconNames } from "./Icons/LucideIcons";
import { colors, styled } from "../../stitches.config";

import {
  PillContainer,
  ExpandableCellContainer,
  PillStyles,
  PillIconWrapper,
  PillLabelWrapper,
} from "./__styles__/ExpandableCell";

export type PillProps = {
  label: string;
  href?: string;
  to?: string;
  iconName?: IconNames;
  asDropdownItem?: boolean;
};

const Pill = ({ label, iconName, href, to, asDropdownItem }: PillProps) => {
  const history = useHistory();

  const contents = (
    <>
      {iconName && (
        <PillIconWrapper>
          <Icon iconName={iconName} color="contentSecondary" size={16} />
        </PillIconWrapper>
      )}
      <PillLabelWrapper
        style={{
          textDecoration: !!href || !!to ? "underline" : "none",
        }}
      >
        {label}
      </PillLabelWrapper>
    </>
  );

  if (asDropdownItem) {
    if (href) {
      return (
        <DropdownItem asChild>
          <a href={href} style={{ textDecoration: "none" }} target="_blank">
            {contents}
          </a>
        </DropdownItem>
      );
    }
    if (to) {
      const onSelect = () =>
        history.push({
          pathname: to,
          state: { prevLocation: history.location },
        });

      return <DropdownItem onSelect={onSelect}>{contents}</DropdownItem>;
    }
  }

  return <PillContainer>{contents}</PillContainer>;
};

export const ExpandableCell = ({ pills }: { pills?: Array<PillProps> }) => {
  const [open, setOpen] = React.useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  if (!pills) return null;

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const CellButton = () => {
    return (
      <ExpandableCellContainer
        onClick={() => setOpen(true)}
        ref={cellRef}
        data-testid="expanding-cell"
      >
        {pills.map((pill, index) => (
          <Pill
            key={index}
            label={pill.label}
            iconName={pill.iconName}
            href={pill.href}
            to={pill.to}
            asDropdownItem={false}
          />
        ))}
      </ExpandableCellContainer>
    );
  };

  return (
    <>
      <CellButton />
      <Dropdown.Root modal={false} open={open} onOpenChange={setOpen}>
        <Dropdown.Trigger asChild tabIndex={0}>
          <div />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <DropdownContent
            ref={dropdownRef}
            style={{
              width: cellRef.current?.offsetWidth! + 16,
              top: -cellRef.current?.offsetHeight! - 8,
            }}
            side="bottom"
            sideOffset={-cellRef.current?.offsetHeight! - 8}
            collisionPadding={16}
          >
            <ExpandableCellContainer data-testid="expanded-cell" expanded>
              {pills.map((pill, index) => (
                <Pill
                  key={index}
                  label={pill.label}
                  iconName={pill.iconName}
                  href={pill.href}
                  to={pill.to}
                  asDropdownItem={true}
                />
              ))}
            </ExpandableCellContainer>
          </DropdownContent>
        </Dropdown.Portal>
      </Dropdown.Root>
    </>
  );
};

const DropdownContent = styled(Dropdown.Content, {
  background: colors.bgUiSurface,
  border: 1,
  borderColor: colors.borderUiFocused,
  borderStyle: "solid",
  borderRadius: 4,
  zIndex: 2,
});

const DropdownItem = styled(Dropdown.Item, {
  ...PillStyles,
  "&:hover": {
    cursor: "pointer",
    outline: "unset",
  },
});
