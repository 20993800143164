import React, { useContext, useEffect } from "react";
import { ScrollWrapper } from "./__styles__/ScrollContainer";
import { SideNavWidthContext } from "../../SideNav";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AuthContext } from "../../Authorization/AuthContext";
import { GUEST_WIDTH } from "../../Guest/__styles__/Layout";

type ScrollContainerContextType = {
  scrolledToLeft: boolean;
  scrolledToRight: boolean;
  handleScroll: () => void;
};
export const ScrollContainerContext =
  React.createContext<ScrollContainerContextType>({
    scrolledToLeft: false,
    scrolledToRight: false,
    handleScroll: () => {
      return;
    },
  });

export type ScrollContainerProps = {
  children: React.ReactNode;
  height: string;
  scrollableRef: React.RefObject<HTMLDivElement>;
  loading?: boolean;
  withoutSideNav?: boolean;
};

export const ScrollContainer = ({
  children,
  height,
  scrollableRef,
  loading = false,
  withoutSideNav,
}: ScrollContainerProps) => {
  const [scrolledToRight, setScrolledToRight] = React.useState(false);
  const [scrolledToLeft, setScrolledToLeft] = React.useState(true);
  const { width } = useWindowSize();
  const { width: sideNavWidth } = useContext(SideNavWidthContext);
  const { isGuest } = useContext(AuthContext);

  const isScrolledToRight = (scrollable: Maybe<HTMLDivElement>) => {
    if (!scrollable) return false;
    return (
      scrollable.scrollLeft + scrollable.clientWidth >= scrollable.scrollWidth
    );
  };
  const isScrolledToLeft = (scrollable: Maybe<HTMLDivElement>) => {
    if (!scrollable) return true;
    return scrollable.scrollLeft <= 0;
  };

  const handleScroll = () => {
    const currentScrolledRight = isScrolledToRight(scrollableRef.current);
    const currentScrolledLeft = isScrolledToLeft(scrollableRef.current);

    if (currentScrolledRight !== scrolledToRight) {
      setScrolledToRight(currentScrolledRight);
    }

    if (currentScrolledLeft !== scrolledToLeft) {
      setScrolledToLeft(currentScrolledLeft);
    }
  };

  useEffect(() => {
    if (loading) {
      scrollableRef.current?.scrollTo(0, 0);
    }
  }, [loading]);

  window.addEventListener("resize", handleScroll);

  const scrollWrapperWidth = isGuest
    ? GUEST_WIDTH
    : width <= 1024 || withoutSideNav
    ? "100vw"
    : `calc(100vw - ${sideNavWidth}px)`; //1024 = $breakpoint-lg

  return (
    <ScrollContainerContext.Provider
      value={{ scrolledToRight, scrolledToLeft, handleScroll }}
    >
      <ScrollWrapper
        ref={scrollableRef}
        onScroll={handleScroll}
        className="scroll-wrapper"
        style={{
          height,
          overflow: loading ? "hidden" : "scroll",
          width: scrollWrapperWidth,
        }}
        data-testid="scroll-wrapper"
      >
        {children}
      </ScrollWrapper>
    </ScrollContainerContext.Provider>
  );
};
