import React, { useRef } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../../Common/Modal";
import UploadDocumentsForm from "../UploadDocumentsForm";
import { DOCUMENT_TYPE_NAME } from "common/constants";

export const useUploadDocumentsFormModal = (): ReturnType<typeof useModal> => {
  const [showUploadModal, hideUploadModal] = useModal(() => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const setContentRef = (instance: HTMLDivElement) =>
      (contentRef.current = instance);

    const handleAfterOpen = () => {
      if (contentRef.current) {
        contentRef.current
          .querySelector<HTMLAnchorElement>("#dropzone-container")
          ?.focus();
      }
    };

    return (
      <Modal
        contentRef={setContentRef}
        // using `onAfterOpen` because child component does NOT have
        // an intermediate loading state and we can query for the
        // container element immediately
        onAfterOpen={handleAfterOpen}
        onRequestClose={hideUploadModal}
        shouldReturnFocusAfterClose
        shouldFocusAfterRender
        aria={{
          labelledby: "upload-form-heading",
        }}
      >
        <UploadDocumentsForm
          // Temporary fix to allow uploading ECs from Certificates
          // List. We can't update the map if there this component
          // is not in the layer context provider
          updateMap={() => {}}
          closeModal={() => {
            hideUploadModal();
          }}
          accountDocumentTypeFilter={accountDocumentType =>
            accountDocumentType.documentType?.name ===
            DOCUMENT_TYPE_NAME.ELEVATION_CERTIFICATE
          }
        />
      </Modal>
    );
  });

  return [showUploadModal, hideUploadModal];
};
