import React, { useContext } from "react";
import { Select, Text, Textarea } from "../../../../../Inputs/react-hook-form";
import { Label } from "../../../../../Inputs";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../types";
import {
  DEFAULT_POSTAMBLE,
  DEFAULT_PREAMBLE,
  intakeSourceOptions,
} from "../constants";
import { SubmissionIntakeSource } from "../../../../../../generated/graphql";
import AttachmentSelect from "./AttachmentSelect";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { SubmissionsBuilderContextInstance } from "../context";
import { useIsSisdAndHidden } from "../utils";
import { SUBMISSION_CATEGORY_OPTIONS } from "../../../../../Submissions/utils";

const FormSettingsTab = () => {
  const { account } = useContext(AuthContext);
  const { readOnly } = useContext(SubmissionsBuilderContextInstance);
  const {
    control,
    formState: { defaultValues, errors },
    getValues,
    register,
    setValue,
    watch,
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  const intakeSource = watch("intakeSource");
  const modules = watch("modules");
  const visibilityDisabled = useIsSisdAndHidden(modules);

  const visibilityShown = account?.publicPortal.enabled;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {!readOnly && (
        <div>
          <Label text={"All fields are required"} required />
        </div>
      )}

      <Text
        {...register("name", { required: "Record type is required" })}
        label="Record type"
        error={errors.name?.message}
        disabled={readOnly}
      />
      <div>
        <Label text="Record category" htmlFor="category" />
        <Select
          disabled={!!defaultValues?.inputs?.length || readOnly}
          control={control}
          name="category"
          options={SUBMISSION_CATEGORY_OPTIONS}
          size="medium"
          required={true}
          rules={{ required: "Record category is required" }}
        />
      </div>

      <div>
        <Label text="Intake source" htmlFor="intakeSource" />
        <Select
          control={control}
          name="intakeSource"
          options={intakeSourceOptions}
          size="medium"
          required={true}
          rules={{ required: "Intake source is required" }}
          error={errors.intakeSource?.message}
          disabled={readOnly}
          onChange={value => {
            if (value === SubmissionIntakeSource.INTERNAL) {
              const name = getValues("name");
              setValue("preamble", null);
              setValue("postamble", null);
              setValue("successMessage", `Your ${name} has been saved`);
              setValue("notificationEmails", null);
            } else {
              setValue("postamble", DEFAULT_POSTAMBLE);
              setValue("preamble", DEFAULT_PREAMBLE);
              setValue("successMessage", null);
              setValue("notificationEmails", "");
            }
          }}
        />
      </div>
      {visibilityShown && (
        <div>
          <Label
            text="Hidden from public by default"
            htmlFor="hiddenFromPublicByDefault"
          />
          <Select
            control={control}
            name="hiddenFromPublicByDefault"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            size="medium"
            required={true}
            rules={{ required: "Hidden from public by default is required" }}
            error={errors.hiddenFromPublicByDefault?.message}
            disabled={visibilityDisabled || readOnly}
          />
        </div>
      )}

      <AttachmentSelect disabled={readOnly} />

      <div>
        <Label text={"Form description"} htmlFor="description" />
        <Textarea
          {...register("description")}
          error={errors.description?.message}
          disabled={readOnly}
        />
      </div>
      {intakeSource === SubmissionIntakeSource.INTERNAL && (
        <div>
          <Label text={"Success message"} htmlFor="successMessage" />
          <Text
            {...register("successMessage", {
              required: "Success message is required",
            })}
            error={errors.successMessage?.message}
            disabled={readOnly}
          />
        </div>
      )}
      {intakeSource === SubmissionIntakeSource.EXTERNAL && (
        <>
          <div>
            <Label text="Success page title" htmlFor="preamble" />
            <Text
              {...register("preamble", {
                required: "Preamble is required",
              })}
              error={errors.preamble?.message}
              disabled={readOnly}
            />
          </div>
          <div>
            <Label text="Success page message" htmlFor="postamble" />
            <Text
              {...register("postamble", {
                required: "Postamble is required",
              })}
              error={errors.postamble?.message}
              disabled={readOnly}
            />
          </div>
          <div>
            <Label
              text="Notification emails"
              htmlFor="notificationEmails"
              tooltip="Please enter each email on its own line"
            />
            <Textarea
              {...register("notificationEmails")}
              error={errors.notificationEmails?.message}
              data-testid="notification-emails"
              disabled={readOnly}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormSettingsTab;
