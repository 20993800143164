import React, { useContext, useState } from "react";
import { useModal } from "react-modal-hook";
import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import Modal from "../Common/Modal";
import { ExportDataForm, ReportType } from "./ExportDataForm";
import ExportDataButton from "../Common/ExportButton";
import { TableContext } from "../Common/FullWidthTable/TableContext";
import { ClientSideConfigEntry } from "common/utils/queryBuilder";
import { SavedView } from "../Common/FullWidthTable/TableSettings/utils";
interface ExportButtonProps {
  firms?: Array<{ id: string; name: string }>;
  currentView?: Maybe<SavedView>;
  initialReportTypes?: Array<ReportType>;
}

const useExportFormModal = ({
  firms,
  initialReportTypes,
}: ExportButtonProps) => {
  const [currentView, setCurrentView] = useState<Maybe<SavedView>>(null);
  const [columnConfig, setColumnConfig] = useState<
    Array<ClientSideConfigEntry> | undefined
  >([]);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal
        onRequestClose={hideModal}
        shouldReturnFocusAfterClose
        shouldFocusAfterRender
        aria={{
          labelledby: "export-form-heading",
        }}
      >
        <ExportDataForm
          firms={firms}
          closeModal={hideModal}
          currentView={currentView}
          columnConfig={columnConfig}
          initialReportTypes={initialReportTypes}
        />
      </Modal>
    ),
    [firms, initialReportTypes, currentView]
  );

  const showExportModal = (
    currentView?: Maybe<SavedView>,
    columnConfig?: Array<ClientSideConfigEntry>
  ) => {
    setCurrentView(currentView ?? null);
    setColumnConfig(columnConfig);
    showModal();
  };

  return showExportModal;
};

export default ({ firms, initialReportTypes }: ExportButtonProps) => {
  const { authorized } = useContext(AuthContext);
  const { currentView, columnConfig } = useContext(TableContext);
  const showExportModal = useExportFormModal({
    firms,
    currentView,
    initialReportTypes,
  });
  const canExportCertificates = authorized({
    resource: RESOURCE_NAME.CERTIFICATE,
    permission: "export",
  });

  const canExportSubmissions = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "export",
  });

  return (
    <ExportDataButton
      onClick={() => showExportModal(currentView, columnConfig)}
      disabled={!canExportCertificates && !canExportSubmissions}
    />
  );
};
