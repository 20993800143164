import React, { useEffect, useRef, useState } from "react";
import TabItem from "./TabItem";
import { TabGroupWrapper, ScrollButton, TabBar } from "./__styles__/TabGroup";
import { Icon } from "./Icons/LucideIcons";

export default <T extends string>({
  currentTab,
  setTab,
  tabs,
  color = "blue",
  activeTabStyle = "underline",
}: {
  currentTab: T;
  setTab: (value: T) => void;
  tabs: Array<{ label: string | JSX.Element; value: T }>;
  color?: "blue" | "grey";
  activeTabStyle?: "underline" | "outline";
}) => {
  const tabBarRef = useRef<HTMLDivElement>(null);

  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  const isScrollable = () => {
    if (!tabBarRef.current) return false;

    return tabBarRef.current.scrollWidth > tabBarRef.current.clientWidth;
  };

  const isScrolledToRight = (scrollable: HTMLDivElement) => {
    const remainingScroll =
      scrollable.scrollWidth - (scrollable.scrollLeft + scrollable.clientWidth);
    return remainingScroll <= 0.5;
  };

  const isScrolledToLeft = (scrollable: HTMLDivElement) => {
    return scrollable.scrollLeft <= 0;
  };

  const updateScrollState = () => {
    if (tabBarRef.current && isScrollable()) {
      setCanScrollRight(!isScrolledToRight(tabBarRef.current));
      setCanScrollLeft(!isScrolledToLeft(tabBarRef.current));
    }
  };

  const scrollTabs = (direction: "left" | "right") => {
    if (tabBarRef.current) {
      const currentScroll = tabBarRef.current.scrollLeft;

      tabBarRef.current.scrollTo({
        left: direction === "left" ? currentScroll - 200 : currentScroll + 200,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    updateScrollState();
  }, []);

  useEffect(() => {
    if (!tabBarRef.current) return;

    tabBarRef.current.addEventListener("scrollend", updateScrollState);
    return () => {
      tabBarRef.current?.removeEventListener("scrollend", updateScrollState);
    };
  }, [tabBarRef.current]);

  useEffect(() => {
    window.addEventListener("resize", updateScrollState);
    return () => {
      window.removeEventListener("resize", updateScrollState);
    };
  }, []);

  // Bring active tab into view when currentTab changes
  useEffect(() => {
    if (!tabBarRef.current || !isScrollable()) return;

    const activeTab = tabBarRef.current.querySelector(
      "button[aria-selected='true']"
    );
    if (activeTab) {
      activeTab.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [currentTab]);

  return (
    <TabGroupWrapper isScrollable={isScrollable()}>
      {isScrollable() && (
        <ScrollButton
          onClick={() => scrollTabs("left")}
          disabled={!canScrollLeft}
          aria-label="Scroll tabs left"
          direction="left"
        >
          <Icon iconName="chevron-left" color={"contentSecondary"} size={16} />
        </ScrollButton>
      )}

      <TabBar
        ref={tabBarRef}
        isScrollable={isScrollable()}
        data-testid="tab-group"
      >
        {tabs.map(tab => (
          <TabItem
            setTab={setTab}
            active={tab.value === currentTab}
            activeTabStyle={activeTabStyle}
            value={tab.value}
            key={tab.value}
            color={color}
          >
            {tab.label}
          </TabItem>
        ))}
      </TabBar>

      {isScrollable() && (
        <ScrollButton
          onClick={() => scrollTabs("right")}
          disabled={!canScrollRight}
          aria-label="Scroll tabs right"
          direction="right"
        >
          <Icon iconName="chevron-right" color={"contentSecondary"} size={16} />
        </ScrollButton>
      )}
    </TabGroupWrapper>
  );
};
