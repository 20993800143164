import React from "react";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";

import { Icons } from "../../../Common/Icons";
import { Box } from "../../../Common/__styles__/Layout";
import Divider from "../../../Common/Divider";

import {
  Header,
  Section,
  HeaderTitle,
  SectionTitle,
} from "../../__styles__/Content";
import {
  FirmImportErrorType,
  FirmImportStatus,
  FirmSource,
  useAccountFirmImportsQuery,
  useExportGeoJsonFirmsMutation,
  useGetAccountFirmsQuery,
} from "../../../../generated/graphql";
import FirmLayer from "./FirmLayer";
import { EmptyState } from "../../../Common/EmptyState";
import FirmImports from "./FirmImports";
import { AuthContext } from "../../../Authorization/AuthContext";
import { NetworkStatus } from "@apollo/client";
import { RecentImportTask } from "../RecentImportTasksTable";
import { useManualPaginationConfig } from "../../../Common/Tables/hooks";
import { Button } from "../../../Common/Button";
import { RESOURCE_NAME } from "common/authorization";
import { useFirmCreateImportModal } from "./forms/FirmCreateImport";
import { spacing } from "../../../../stitches.config";

const STATUS_TO_HUMAN_READABLE_STATUS: Record<FirmImportStatus, string> = {
  PROCESSING: "Processing",
  SUCCESS: "Success",
  FAILURE: "Failure",
  UNKNOWN: "Unknown",
};

export const Firms = ({ pollInterval = 10000 }: { pollInterval?: number }) => {
  const { account, authorized } = React.useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const {
    data: firmData,
    loading: loadingFirmData,
    error: firmError,
  } = useGetAccountFirmsQuery({ variables: { includeDisabledFirms: true } });

  const firms = firmData?.account?.firms;
  const hasFirms = !loadingFirmData && firms && firms.length;

  if (firmError) {
    throw new Error("Error loading firms");
  }

  const manualPaginationConfig = useManualPaginationConfig({ pageSize: 10 });
  let variables = {
    page: manualPaginationConfig.pagination.pageIndex + 1,
    pageSize: manualPaginationConfig.pagination.pageSize,
  };

  const {
    data: currentFirmImportsData,
    networkStatus,
    loading: loadingFirmImports,
    error: firmImportsError,
    previousData: previousFirmImportsData,
    refetch: refetchFirmImports,
  } = useAccountFirmImportsQuery({
    fetchPolicy: "no-cache",
    pollInterval,
    variables: {
      id: account?.id,
      ...variables,
    },
  });

  const [exportGeoJsonFirms] = useExportGeoJsonFirmsMutation({
    onCompleted: () => {
      addSuccessToast(
        `The FIRMs are being exported. You will receive an email with the link`
      );
    },
    onError: () => {
      addErrorToast(
        `There was an error exporting the FIRMs. Please try again. If the problem persists, please email support@withforerunner.com`
      );
    },
  });

  const firmImportsResponse =
    networkStatus === NetworkStatus.setVariables
      ? previousFirmImportsData
      : currentFirmImportsData;

  const canCreateFIRM = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "create",
  });

  const [openCreateFIRMModal] = useFirmCreateImportModal({
    onScheduledFirmImport: refetchFirmImports,
  });

  return (
    <Box>
      <Header>
        <HeaderTitle>FIRM settings</HeaderTitle>
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            onClick={async () => await exportGeoJsonFirms()}
            size="small"
            styleVariant="outlineLight"
            aria-label="Export FIRMs"
            leftIconName="download"
          >
            Export
          </Button>
          <Button
            styleVariant="outlineLight"
            disabled={!canCreateFIRM}
            onClick={openCreateFIRMModal}
            size="small"
            leftIconName="plus"
          >
            Create FIRM
          </Button>
        </div>
      </Header>
      <Divider />
      <Section>
        <SectionTitle>FIRM Layers</SectionTitle>
      </Section>
      {!loadingFirmData &&
        (hasFirms ? (
          firms.map(firm => (
            <Section>
              <FirmLayer
                firmLayer={firm}
                key={firm.name}
                onScheduledFirmImport={refetchFirmImports}
              />
            </Section>
          ))
        ) : (
          <EmptyState
            icon="add-raster"
            title="No FIRM data for this account"
            body="Create FIRM to get started."
            paddingTop={spacing["2xl"].value}
            paddingBottom={spacing["2xl"].value}
          />
        ))}
      <Section>
        <SectionTitle>Recent Imports</SectionTitle>
      </Section>
      <FirmImports
        recentImportTasks={
          firmImportsResponse?.account?.firmImports.data.map(firmImport => {
            return {
              id: firmImport.id,
              name: firmImport.firm.name,
              error: firmImport.error?.message,
              createdAt: firmImport!.createdAt,
              status: STATUS_TO_HUMAN_READABLE_STATUS[firmImport.status],
              csvDownloadHref:
                firmImport.error?.type ===
                FirmImportErrorType.GEOJSON_VALIDATION_FAILURE
                  ? `/api/firm-import/${
                      firmImport!.id
                    }/validation-errors?accountId=${account?.id}`
                  : null,
              icon:
                firmImport.firm.source === FirmSource.FEMA
                  ? Icons.ARC_GIS
                  : Icons.GPKG,
            } as RecentImportTask;
          }) ?? []
        }
        error={firmImportsError}
        loading={loadingFirmImports}
        manualPaginationConfig={{
          ...manualPaginationConfig,
          pageCount:
            firmImportsResponse?.account?.firmImports.pageInfo.totalPages ?? 1,
        }}
      />
    </Box>
  );
};

export default Firms;
