import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { QueryDescription } from "common/utils/queryBuilder";

import Modal from "../../../Modal";
import { useCreateSavedViewMutation } from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { Text } from "../../../../Inputs/react-hook-form";
import { TableContext } from "../../TableContext";
import { track } from "../../../../../utils/tracking";
import { useUpdateSavedView } from "../../utils";

import { InputRow } from "../../../__styles__/Modal";
import { Body } from "../../../Typography";
import { CommonModal } from "../../../CommonModal";
interface CreateSavedViewForm {
  name: string;
}

const CreateSavedViewModal = ({
  query,
  hideModal,
  onSave,
}: {
  query?: QueryDescription;
  hideModal: () => void;
  onSave: () => void;
}) => {
  const { updateSavedView } = useUpdateSavedView();
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [createSavedView, { loading }] = useCreateSavedViewMutation({
    onCompleted: data => {
      onSave();
      hideModal();
      updateSavedView(data.createSavedView.id);
      addSuccessToast(`Your table view was successfully created`);
    },
    onError: () => {
      addErrorToast(
        `There was an issue creating your table view. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm<CreateSavedViewForm>();

  if (!query) {
    return null;
  }

  const onSubmit = async (formData: CreateSavedViewForm) => {
    const data = {
      ...formData,
      query,
    };

    track("Created table view", {
      table: query.table,
      name: data.name,
    });

    await createSavedView({ variables: { data } });
  };

  return (
    <CommonModal
      title="Create new view"
      primaryButtonText="Create view"
      onSubmit={handleSubmit(onSubmit)}
      disabled={!isValid}
      loading={loading}
      onCancel={hideModal}
      size="large"
      content={
        <>
          <Body size="default" type="regular">
            Table views are visible to everyone and can only be edited by the
            person that created it. If filters or columns that reference
            sensitive data are present, only users with permissions will be able
            to see this view.
          </Body>
          <InputRow css={{ marginTop: "18px" }}>
            <Text
              {...register("name", { required: true })}
              error={errors.name?.message}
              size="small"
              label="Name"
              required
            />
          </InputRow>
        </>
      }
    />
  );
};

export const useCreateSavedViewModal = ({ onSave }: { onSave: () => void }) => {
  const { currentQuery, currentView } = useContext(TableContext);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal onRequestClose={hideModal}>
        <CreateSavedViewModal
          hideModal={hideModal}
          query={currentQuery}
          onSave={onSave}
        />
      </Modal>
    ),
    [currentQuery, currentView]
  );

  return [showModal, hideModal] as const;
};
