import { useModal } from "react-modal-hook";
import Modal from "../../../Common/Modal";
import React, { useEffect, useState } from "react";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateSisdRuleMutationFn,
  UpdateSisdRuleMutationFn,
  ProjectTracking,
  Filter,
  RuleMapFilterInput,
  RuleStringFilterInput,
} from "../../../../generated/graphql";
import { RULE_FILTER_TYPE, SAME_RULE_NAME_ERROR } from "common/constants";
import { Checkbox, Select, Text } from "../../../Inputs/react-hook-form";
import { Label } from "../../../Inputs";
import { each, isArray, isInteger, isNil, isNumber, omit, range } from "lodash";
import { AuthContext } from "../../../Authorization/AuthContext";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { HelpText, RequiredMessage } from "./__styles__/upsertDfeRuleModal";
import { InputGrid, PriorityContainer } from "./__styles__/floodInfoModals";
import { Required } from "../../../Inputs/__styles__/Label";
import LogicFilters from "./LogicFilters";
import { FlexRow } from "../../../Common/__styles__/Layout";
import { SISDFilterOptions } from "common/utils/rules/types";
import { buildFilterContext, FilterContext } from "./FilterContext";

const alwaysRequiredFields: Array<keyof UpsertSisdRuleFormStructure> = [
  "filters",
  "name",
  "trackingPeriod",
  "threshold",
  "adjustmentRatio",
  "defaultExclude",
];

const REQUIRED_FIELDS_BY_TYPE: Record<
  ProjectTracking,
  Array<keyof UpsertSisdRuleFormStructure>
> = {
  [ProjectTracking.BUILDING_LIFETIME]: [...alwaysRequiredFields],
  [ProjectTracking.CALENDAR_YEAR]: [...alwaysRequiredFields],
  [ProjectTracking.PER_PROJECT]: [...alwaysRequiredFields],
  [ProjectTracking.CUMULATIVE]: [
    ...alwaysRequiredFields,
    "cumulativeTrackingLength",
    "cumulativeTrackingReferenceField",
  ],
};

const trackingPeriodOptions = [
  { value: ProjectTracking.PER_PROJECT, label: "Per project" },
  { value: ProjectTracking.CUMULATIVE, label: "Cumulative" },
  { value: ProjectTracking.BUILDING_LIFETIME, label: "Building lifetime" },
  { value: ProjectTracking.CALENDAR_YEAR, label: "Calendar year" },
];

export type UpsertSisdRuleFormStructure = {
  filters: Array<Filter>;
  name: string;
  originalName?: string;
  trackingPeriod: ProjectTracking;
  cumulativeTrackingLength?: Maybe<number>;
  cumulativeTrackingReferenceField?: Maybe<string>;
  threshold: number;
  adjustmentRatio: number;
  defaultExclude: boolean;
  priority?: Maybe<number>;
};

export interface UpsertSisdRuleModalProps {
  onCancel: () => void;
  onSubmit: CreateSisdRuleMutationFn | UpdateSisdRuleMutationFn;
  onUpdate: () => void;
  existingRule?: UpsertSisdRuleFormStructure;
  filterOptions: Array<SISDFilterOptions>;
  maxPriority?: number;
}

export const useUpsertSisdRuleModal = ({
  onSubmit,
  onUpdate,
  filterOptions,
}: Omit<
  UpsertSisdRuleModalProps,
  "referenceAttribute" | "existingRule" | "onCancel" | "maxPriority"
>) => {
  const [props, setProps] =
    useState<
      Pick<
        UpsertSisdRuleModalProps,
        "existingRule" | "maxPriority" | "filterOptions"
      >
    >();
  const [show, hideUpsertSisdRuleModal] = useModal(
    () => (
      <Modal onRequestClose={hideUpsertSisdRuleModal}>
        <UpsertSisdRuleModal
          onSubmit={onSubmit}
          onCancel={hideUpsertSisdRuleModal}
          onUpdate={() => {
            hideUpsertSisdRuleModal();
            onUpdate();
          }}
          filterOptions={filterOptions}
          {...props}
        />
      </Modal>
    ),
    [props]
  );

  const showUpsertSisdRuleModal = ({
    existingRule,
    maxPriority,
    filterOptions,
  }: Pick<
    UpsertSisdRuleModalProps,
    "existingRule" | "maxPriority" | "filterOptions"
  >) => {
    setProps({ existingRule, maxPriority, filterOptions });
    show();
  };

  return [showUpsertSisdRuleModal, hideUpsertSisdRuleModal] as const;
};

export const UpsertSisdRuleModal = ({
  onSubmit,
  onCancel,
  onUpdate,
  existingRule,
  maxPriority,
  filterOptions,
}: UpsertSisdRuleModalProps) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const cumulativeTrackingReferenceFieldOptions = filterOptions
    .filter(option => option.inputType === "date")
    .map(option => ({ value: option.key, label: option.title }));

  const hasOneCumulativeTrackingReferenceFieldOption =
    cumulativeTrackingReferenceFieldOptions.length === 1;

  let defaultCumulativeTrackingReferenceField: Maybe<string> | undefined;
  if (existingRule) {
    defaultCumulativeTrackingReferenceField =
      existingRule.cumulativeTrackingReferenceField;
  } else {
    defaultCumulativeTrackingReferenceField =
      hasOneCumulativeTrackingReferenceFieldOption
        ? cumulativeTrackingReferenceFieldOptions[0]!.value
        : undefined;
  }

  const formMethods = useForm<UpsertSisdRuleFormStructure>({
    defaultValues: {
      ...omit(existingRule, "__typename"),
      filters:
        existingRule?.filters.map(filter => ({
          type: filter.type,
          filter: omit(filter.filter, "__typename"),
        })) ?? [],
      threshold: existingRule ? existingRule.threshold * 100 : undefined,
      cumulativeTrackingReferenceField: defaultCumulativeTrackingReferenceField,
    },
  });
  const priorityOptions = maxPriority
    ? range(1, maxPriority + 1).map(value => ({
        value,
        label: value.toString(),
      }))
    : [];

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = formMethods;

  const trackingPeriod = watch("trackingPeriod");
  useEffect(() => {
    if (trackingPeriod !== ProjectTracking.CUMULATIVE) {
      setValue("cumulativeTrackingLength", undefined);
      setValue("cumulativeTrackingReferenceField", undefined);
    }
  }, [trackingPeriod]);

  const [isValid, setIsValid] = useState(false);
  const [priorityMenuOpen, setPriorityMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    watch(value => {
      if (value.trackingPeriod) {
        let valid = REQUIRED_FIELDS_BY_TYPE[value.trackingPeriod].every(
          field => {
            const fieldValue = value[field];

            if (isArray(fieldValue)) {
              return fieldValue.length > 0;
            } else if (isNumber(fieldValue)) {
              return !isNaN(fieldValue);
            } else {
              return !isNil(fieldValue) && fieldValue !== "";
            }
          }
        );

        setIsValid(valid);

        if (
          value.trackingPeriod === ProjectTracking.CUMULATIVE &&
          !value.cumulativeTrackingReferenceField &&
          hasOneCumulativeTrackingReferenceFieldOption
        ) {
          setValue(
            "cumulativeTrackingReferenceField",
            cumulativeTrackingReferenceFieldOptions[0]!.value
          );
        }
      } else {
        setIsValid(false);
      }
    });
  }, []);

  const headerText = `${existingRule ? "Edit" : "Add"} SI/SD logic rule`;

  const handleSubmitCallback = async (data: UpsertSisdRuleFormStructure) => {
    const onError = (error: Error, verb: string) => {
      if (error.message === SAME_RULE_NAME_ERROR) {
        setError("name", {
          message: SAME_RULE_NAME_ERROR,
        });
      } else {
        addErrorToast(`Failed to ${verb} SI/SD rule.`);
      }
    };

    const thresholdAsDecimal = data.threshold / 100;

    const mapFilters: Array<RuleMapFilterInput> = [];
    const stringFilters: Array<RuleStringFilterInput> = [];
    each(data.filters, filter => {
      if (filter.type === RULE_FILTER_TYPE.MAP) {
        mapFilters.push(filter as RuleMapFilterInput);
      } else {
        stringFilters.push(filter as RuleStringFilterInput);
      }
    });

    if (existingRule) {
      await onSubmit({
        variables: {
          data: {
            ...omit(data, "filters"),
            mapFilters,
            stringFilters,
            accountId: account!.id,
            originalName: existingRule.name,
            threshold: thresholdAsDecimal,
          },
        },
        onCompleted: () => {
          addSuccessToast("SI/SD rule successfully updated.");
          onUpdate();
        },
        onError: error => onError(error, "update"),
      });
    } else {
      await (onSubmit as CreateSisdRuleMutationFn)({
        variables: {
          data: {
            ...omit(data, "filters"),
            mapFilters,
            stringFilters,
            accountId: account!.id,
            threshold: thresholdAsDecimal,
          },
        },
        onCompleted: () => {
          addSuccessToast("SI/SD rule successfully added.");
          onUpdate();
        },
        onError: error => onError(error, "add"),
      });
    }
  };

  return (
    <FilterContext.Provider value={buildFilterContext(filterOptions)}>
      <FormProvider {...formMethods}>
        <Container>
          <HeaderSection>
            <h1>{headerText}</h1>
          </HeaderSection>
          <FormSection>
            <ContentSection>
              <RequiredMessage>
                All fields are required<Required>*</Required>
              </RequiredMessage>
              <InputGrid style={{ marginTop: "24px" }}>
                <Text
                  label="Logic rule name"
                  error={errors.name?.message}
                  {...register("name")}
                />
                <LogicFilters />
                <div>
                  <Label
                    text="SI/SD tracking period"
                    htmlFor="trackingPeriod"
                  />
                  <Select
                    control={control}
                    name="trackingPeriod"
                    options={trackingPeriodOptions}
                    size="medium"
                  />
                </div>
                {trackingPeriod === ProjectTracking.CUMULATIVE && (
                  <InputGrid leftPadded>
                    <FlexRow style={{ gap: "16px" }}>
                      <Text
                        label="Length (years)"
                        error={errors.cumulativeTrackingLength?.message}
                        {...register("cumulativeTrackingLength", {
                          valueAsNumber: true,
                          validate: value => {
                            if (!value || !isInteger(value) || value < 1) {
                              return "Value must be a positive integer";
                            }

                            return;
                          },
                        })}
                      />
                      <Select
                        control={control}
                        name="cumulativeTrackingReferenceField"
                        options={cumulativeTrackingReferenceFieldOptions}
                        size="medium"
                        disabled={hasOneCumulativeTrackingReferenceFieldOption}
                        label="Reference field"
                      />
                    </FlexRow>
                  </InputGrid>
                )}

                <Text
                  label="SI/SD threshold %"
                  error={errors.threshold?.message}
                  tooltip={
                    "The SI/SD threshold value must be an integer between 0 and 100"
                  }
                  {...register("threshold", {
                    valueAsNumber: true,
                    validate: value => {
                      if (!isInteger(value) || value < 1 || value > 100) {
                        return "Value must be an integer between 1 and 100";
                      }

                      return;
                    },
                  })}
                />
                <Text
                  label="Improvement value adjustment ratio"
                  error={errors.adjustmentRatio?.message}
                  tooltip={`This value should be determined by a department in your government. Enter a value less than 1 for division or
                        a value greater than one for multiplication. For example if you enter "0.8", your market value will be
                        (improvement value / 0.8). If you enter 1.2, your market value will be (improvement value x 1.2).`}
                  {...register("adjustmentRatio", {
                    valueAsNumber: true,
                    validate: value => {
                      if (isNaN(value)) {
                        return;
                      }

                      if (!isNumber(value) || value <= 0) {
                        return "Value must be a positive number";
                      }

                      return;
                    },
                  })}
                />
                <Checkbox
                  name="defaultExclude"
                  control={control}
                  label="Default exclude records from SI/SD summary"
                />
                <PriorityContainer open={priorityMenuOpen}>
                  <Label text="Rule priority" htmlFor="priority" />
                  <Select
                    control={control}
                    name="priority"
                    options={priorityOptions}
                    size="medium"
                    onMenuOpen={() => setPriorityMenuOpen(true)}
                    onMenuClose={() => setPriorityMenuOpen(false)}
                  />
                  <HelpText style={{ margin: "8px 0 0 0" }}>
                    This is used in the event that two or more rules overlap on
                    a property.
                  </HelpText>
                </PriorityContainer>
              </InputGrid>
            </ContentSection>
          </FormSection>
          <ButtonSection>
            <PrimaryButtons>
              <Button size="medium" styleVariant="secondary" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                size="medium"
                styleVariant="primary"
                onClick={handleSubmit(handleSubmitCallback)}
                disabled={!isValid}
              >
                {existingRule ? "Update" : "Save"}
              </Button>
            </PrimaryButtons>
          </ButtonSection>
        </Container>
      </FormProvider>
    </FilterContext.Provider>
  );
};
