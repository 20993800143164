import React from "react";
import { UserLoginErrorCode } from "../../generated/graphql";
import Banner from "../Common/Banner";
import { BANNER_TYPES } from "../Common/__styles__/Banner";
import { Button } from "../Common/Button";
import { useHistory } from "react-router-dom";

export const ErrorMessage = ({
  error,
  passwordResetPath,
}: {
  error: { message: string; code?: UserLoginErrorCode };
  passwordResetPath?: string;
}) => {
  const history = useHistory();

  switch (error.code) {
    case UserLoginErrorCode.PASSWORD_EXPIRED: {
      return (
        <Banner type={BANNER_TYPES.CRITICAL}>
          Your password has expired. Please{" "}
          <Button
            type="button"
            onClick={() => history.push(passwordResetPath!)}
            styleVariant="buttonLink"
            size="small"
            style={{ display: "inline-block" }}
          >
            reset your password
          </Button>{" "}
          or contact support@withforerunner.com for assistance.
        </Banner>
      );
    }
    default: {
      return <Banner type={BANNER_TYPES.CRITICAL}>{error.message}</Banner>;
    }
  }
};
