import React from "react";

import { LOGS } from "./queries";
import LogMutationForm, { LogMutationFormStructure } from "./LogMutationForm";
import { track } from "../../utils/tracking";
import { RouteComponentProps, useLocation } from "react-router";
import {
  LogQuery,
  useLogQuery,
  useUpdateLogMutation,
} from "../../generated/graphql";
import { Button } from "../Common/Button";
import { ActionButton } from "./ActionButton";
import { FormProvider, useForm } from "react-hook-form";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../Authorization/AuthContext";
import { omit } from "lodash";
import { FlexRow } from "../Common/__styles__/Layout";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { buildLink } from "common/routing";

export type EditProps = Pick<RouteComponentProps<{ id: string }>, "match">;

const cleanupInformationRequest = (
  informationRequest: LogQuery["informationRequest"]
) => {
  return omit(informationRequest, ["__typename", "id", "property"]);
};

const Container = ({
  informationRequest,
}: {
  informationRequest: NonNullable<LogQuery["informationRequest"]>;
}) => {
  const location = useLocation<Maybe<{ prevLocation?: string }>>();
  const { authorized } = React.useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const form = useForm<LogMutationFormStructure>({
    defaultValues: cleanupInformationRequest(informationRequest),
  });
  const { handleSubmit, reset, formState } = form;

  const [update, { loading: updateLoading }] = useUpdateLogMutation({
    onCompleted: data => {
      track("Updated Log");

      addSuccessToast("Updated successfully");

      reset(cleanupInformationRequest(data.updateInformationRequest));
    },
    onError: () => {
      addErrorToast("Could not update log");
    },
    refetchQueries: () => [{ query: LOGS }],
  });

  const canUpdateLog = authorized({
    resource: RESOURCE_NAME.INFORMATION_REQUEST,
    permission: "update",
  });

  const onSubmit = async (data: LogMutationFormStructure) => {
    data = omit(data, "viaPublicSite", "sharedViaEmail");
    await update({
      variables: {
        id: informationRequest.id,
        data,
      },
    });
  };

  const rightContainer = (
    <FlexRow style={{ gap: "16px" }}>
      <Button
        size="small"
        styleVariant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!canUpdateLog || !formState.isValid || !formState.isDirty}
        loading={updateLoading}
      >
        Update
      </Button>
      <ActionButton
        infoRequest={informationRequest}
        isDetailView={true}
        prevLocation={location.state?.prevLocation ?? buildLink("map")}
      />
    </FlexRow>
  );

  return (
    <FormProvider {...form}>
      <LogMutationForm
        data={informationRequest}
        subtitle="Edit log"
        rightContainer={rightContainer}
        prevLocation={location.state?.prevLocation}
      />
    </FormProvider>
  );
};

export default ({ match }: EditProps) => {
  const { data, loading, error } = useLogQuery({
    variables: { id: match.params.id },
  });

  if (!data?.informationRequest || error || loading) return null;

  const { informationRequest } = data;

  return <Container informationRequest={informationRequest} />;
};
