import { Route, useHistory, useLocation } from "react-router-dom";
import { MainRouteProps } from "../MainRoutes";
import { LoginLocationState } from "../../utils/params";
import { getRedirectPath } from "../../utils/params";
import { RouteNames } from "common/routing";
import React from "react";
import FullPageLoadingScreen from "../Common/FullPageLoadingScreen";

export const UnauthedRoute = <
  T extends MainRouteProps & { routeName: RouteNames }
>(
  props: T
) => {
  const { user, admin, routeName } = props;

  const location = useLocation<LoginLocationState>();
  const history = useHistory();

  const fallbackPath = admin ? "/admin/accounts" : "/map";

  const redirectPath = getRedirectPath({
    routeName,
    routerLocation: location,
    fallbackPath,
  });

  if (user) {
    history.push(redirectPath);
    return <FullPageLoadingScreen />;
  }

  if (admin) {
    history.push(redirectPath);
    return <FullPageLoadingScreen />;
  }

  return <Route {...props} />;
};
