import React, { useContext } from "react";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../../../Authorization/AuthContext";
import { useGetPaginatedAccountDocumentTemplatesQuery } from "../../../../generated/graphql";
import { useHistory } from "react-router";

import Table from "./Table";
import Divider from "../../../Common/Divider";
import { Box } from "../../../Common/__styles__/Layout";
import { InsufficientPermissions } from "../../../Common/InsufficientPermissions";

import { Header, Section, HeaderTitle } from "../../__styles__/Content";
import { EmptyState } from "../../../Common/EmptyState";
import { buildLink } from "common/routing";
import { useManualPaginationConfig } from "../../../Common/Tables/hooks";
import { Button } from "../../../Common/Button";
import { spacing } from "../../../../stitches.config";

export const DocumentTemplates = () => {
  const history = useHistory();
  const { account, authorized } = useContext(AuthContext);
  const manualPaginationConfig = useManualPaginationConfig({ pageSize: 10 });

  const canListDocumentTemplates = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "list",
  });

  const canCreateDocumentTemplates = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "create",
  });

  const { data, error, loading, previousData, refetch } =
    useGetPaginatedAccountDocumentTemplatesQuery({
      variables: {
        accountId: account!.id,
        page: manualPaginationConfig.pagination.pageIndex + 1,
        pageSize: manualPaginationConfig.pagination.pageSize,
      },
      skip: !canListDocumentTemplates || !account?.id,
    });

  if (error) {
    throw new Error("Error loading document templates");
  }

  const documentTemplates = data?.getPaginatedAccountDocumentTemplates.data;
  const prevDocumentTemplates =
    previousData?.getPaginatedAccountDocumentTemplates.data;
  const hasDocumentTemplates = documentTemplates && documentTemplates.length;

  return (
    <Box>
      <Header>
        <HeaderTitle>Document template settings</HeaderTitle>
        {!loading && (
          <Button
            styleVariant="outlineLight"
            disabled={!canCreateDocumentTemplates}
            onClick={() =>
              history.push({ pathname: buildLink("createDocumentTemplate") })
            }
            size="small"
            leftIconName="plus"
          >
            Add template
          </Button>
        )}
      </Header>
      <Divider></Divider>
      {!canListDocumentTemplates && <InsufficientPermissions />}
      {canListDocumentTemplates &&
        !loading &&
        (hasDocumentTemplates ? (
          <Section>
            <Table
              previousDocumentTemplates={prevDocumentTemplates ?? []}
              documentTemplates={documentTemplates}
              loading={loading}
              manualPaginationConfig={{
                ...manualPaginationConfig,
                pageCount:
                  data.getPaginatedAccountDocumentTemplates.pageInfo.totalPages,
              }}
              onUpdate={refetch}
            />
          </Section>
        ) : (
          <EmptyState
            icon="add-file"
            title="No document templates for this account"
            body="Add a template to get started."
            paddingTop={spacing["2xl"].value}
            paddingBottom={spacing["2xl"].value}
          />
        ))}
    </Box>
  );
};

export default DocumentTemplates;
