import React, { ComponentProps, forwardRef, Ref, useState } from "react";
import {
  ButtonContentsWrapper,
  LoadingWrapper,
  StyledButton,
} from "./__styles__/Button";
import { Icon, IconNames } from "./Icons/LucideIcons";
import { colors, ExtractVariants } from "../../stitches.config";

export const BUTTON_STYLE_VARIANTS = [
  "primary",
  "secondary",
  "outlineLight",
  "outlineDark",
  "ghost",
  "buttonLink",
  "buttonLinkDark",
  "buttonLinkAlert",
  "buttonTag",
  "ghostWhite",
  "ghostGrey",
  "ghostAlert",
  "alert",
  "hoverOutline",
] satisfies ExtractVariants<typeof StyledButton>["styleVariant"][];

export const BUTTON_SIZES = [
  "xSmall",
  "small",
  "medium",
] satisfies ExtractVariants<typeof StyledButton>["size"][];

export type StyleVariant = typeof BUTTON_STYLE_VARIANTS[number];

const IconColorMap = {
  primary: "contentPrimaryDark",
  secondary: "contentPrimary",
  outlineLight: "contentPrimary",
  outlineDark: "contentPrimaryDark",
  hoverOutline: "contentPrimary",
  ghost: "contentInfo",
  buttonLink: "contentInfo",
  buttonLinkDark: "contentPrimaryDark",
  buttonLinkAlert: "contentCritical",
  buttonTag: "contentPrimary",
  ghostWhite: "contentPrimaryDark",
  ghostGrey: "contentSecondary",
  ghostAlert: "contentCritical",
  alert: "contentPrimaryDark",
  disabled: "contentDisabled",
} as const;

export type ButtonProps = Omit<
  ComponentProps<typeof StyledButton>,
  "css" | "styleVariant"
> & {
  styleVariant: StyleVariant;
  leftIconName?: IconNames;
  loading?: boolean;
  rightIconName?: IconNames;
};

const Button = forwardRef(
  (
    {
      leftIconName,
      loading,
      rightIconName,
      styleVariant,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const disabledState = props.disabled || loading;
    const [isHovered, setIsHovered] = useState(false);
    const getIconColor = (styleVariant: StyleVariant) => {
      if (
        isHovered &&
        !disabledState &&
        (styleVariant === "ghost" || styleVariant === "buttonLink")
      ) {
        return "bgButtonPrimaryHover";
      }

      if (
        (isHovered && !disabledState && styleVariant === "ghostWhite") ||
        styleVariant === "ghostGrey"
      ) {
        return "contentSecondaryDark";
      }

      if (disabledState && styleVariant === "buttonTag") {
        return "contentSecondary";
      }

      return IconColorMap[styleVariant];
    };
    const [iconColor, setIconColor] = useState<keyof typeof colors>(
      getIconColor(styleVariant)
    );

    React.useEffect(() => {
      setIconColor(getIconColor(styleVariant));
    }, [isHovered, styleVariant, disabledState]);

    let contents: React.ReactNode;

    if (loading) {
      contents = (
        <LoadingWrapper>
          <Icon iconName="loading" color={iconColor} size={16} />
        </LoadingWrapper>
      );
    } else {
      contents = (
        <ButtonContentsWrapper>
          {leftIconName && (
            <Icon iconName={leftIconName} color={iconColor} size={16} />
          )}
          {props.children}
          {rightIconName && (
            <Icon iconName={rightIconName} color={iconColor} size={16} />
          )}
        </ButtonContentsWrapper>
      );
    }

    return (
      <StyledButton
        {...props}
        ref={ref}
        styleVariant={styleVariant}
        disabled={props.disabled || loading}
        disabledStyles={props.disabled && !loading}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        tabIndex={0}
      >
        {contents}
      </StyledButton>
    );
  }
);

// including this so we can view the component name instead of forwardRef
// in our tooling (React DevTools, etc)
Button.displayName = "Button";

export { Button };
