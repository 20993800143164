import React from "react";
import { ModalProvider } from "react-modal-hook";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import { RESOURCE_NAME } from "common/authorization";
import { SUBMISSION_CATEGORY } from "common/constants";
import { buildLink, getPath } from "common/routing";
import { Account } from "../generated/graphql";
import { globalPrintStyles } from "./__styles__/globalStyles";
import { CurrentAdmin, CurrentUser } from "./Authorization/types";
import { withAuthorization } from "./Authorization/withAuthorization";
import { RouteContainer, RouteContent } from "./Common/__styles__/Routes";
import DocumentUploads from "./DocumentUploads";
import DocumentUploadDetailPage from "./DocumentUploads/DetailView";
import CertificateShim from "./DocumentUploads/ElevationCertificates/CertificateShim";
import PermitEdit from "./DocumentUploads/ElevationCertificates/PermitEdit";
import DownloadExport from "./Exports/DownloadExport";
import Logs from "./Logs";
import CreateLog from "./Logs/LogCreate";
import EditLog from "./Logs/LogEdit";
import Map from "./Maps";
import Properties from "./Property";
import CreateProperty from "./Property/CreateProperty";
import EditProperty from "./Property/EditProperty";
import GeocodeRepetitiveLoss from "./RepetitiveLoss/GeocodeRepetitiveLoss";
import Settings from "./Settings";
import EditSection from "./Settings/Account/PropertyData/OverviewSections/EditSection";
import SubmissionsBuilder from "./Settings/Account/Records/SubmissionsBuilder";
import CreateOrUpdateDocumentTemplateForm from "./Settings/DataImports/DocumentTemplates/CreateOrUpdateForm";
import DispatchBulkPropertyActionForm from "./Settings/DataImports/Operations/DispatchBulkPropertyActionForm";
import { DispatchBulkSubmissionActionForm } from "./Settings/DataImports/Operations/DispatchBulkSubmissionActionForm";
import DispatchUpdateAccountBoundaryForm from "./Settings/DataImports/Operations/UpdateAccountBoundaryForm";
import ParcelImportConfigWizard from "./Settings/DataImports/Parcels/ParcelImportWizard/ParcelImportConfiguration";
import RepetitiveLossTable from "./Settings/DataImports/RepetitiveLossImports/RepetitiveLossTable/RepetitiveLossTable";
import SideNav, { SideNavProps, SideNavWidthContext } from "./SideNav";
import CreateSubmissionContainer from "./Submissions/CreateSubmission";
import EditSubmission from "./Submissions/EditSubmission";
import SubmissionTables from "./Submissions/SubmissionsTable";
import SubmissionSummary from "./Submissions/SubmissionSummary";
import ViewSubmission from "./Submissions/ViewSubmission";

const ScrollToTop: React.FC<
  RouteComponentProps<
    {},
    StaticContext,
    Maybe<{ scrollToTop?: string | boolean }>
  >
> = ({ children, location }) => {
  React.useEffect(() => {
    if (!location.state || location.state.scrollToTop?.toString() === "true") {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return <>{children}</>;
};

const ScrollToTopContainer = withRouter(ScrollToTop);

const RoutesWithSideNav = (props: SideNavProps) => (
  <RouteContainer>
    <SideNav {...props} />

    <RouteContent>
      <ScrollToTopContainer>
        <Switch>
          <Redirect exact from="/" to={getPath("map")} />
          <Route path={getPath("map")} component={Map} />
          <Route path={getPath("logs")} component={Logs} />
          <Route
            path={getPath("documentUploads")}
            component={DocumentUploads}
          />
          <Route path={getPath("properties")} component={Properties} />
          <Route path={getPath("settings")} component={Settings} />
          <Route path={getPath("recordsTable")} component={SubmissionTables} />

          {/* Redirect legacy URLs */}
          <Redirect from="/assistance-requests" to="/logs" />
          <Redirect from="/crs/logs/:subpage" to="/logs/:subpage" />
          <Redirect from="/crs" to="/logs" />
          <Redirect
            from="/inspections"
            to={buildLink("recordsTable", {
              category: SUBMISSION_CATEGORY.INSPECTIONS,
            })}
          />
          <Redirect
            from="/submissions"
            to={buildLink("recordsTable", {
              category: SUBMISSION_CATEGORY.PERMITTING,
            })}
          />
        </Switch>
      </ScrollToTopContainer>
    </RouteContent>
  </RouteContainer>
);

export type AppProps = {
  account: Maybe<
    Pick<CurrentUser["account"], "logoUrl" | "name" | "subdomain"> & {
      submissionTypes: Pick<Account["submissionTypes"][0], "category">[];
    }
  >;
  user: Maybe<Pick<CurrentUser, "id">>;
  admin: Maybe<Pick<CurrentAdmin, "id">>;
};

const App = (props: AppProps) => {
  globalPrintStyles();

  const [sideNavWidth, setSideNavWidth] = React.useState(0);

  return (
    <SideNavWidthContext.Provider value={{ width: sideNavWidth }}>
      <ModalProvider>
        <Switch>
          <Route path="/files/:id" component={DocumentUploadDetailPage} />
          {/* Legacy URL */}
          <Redirect from="/documents/:id" to="/files/:id" />
          {/* Legacy URL */}
          <Route path="/certificates/:uuid" component={CertificateShim} />
          <Route
            path={getPath("createProperty")}
            component={withAuthorization(CreateProperty, {
              resource: RESOURCE_NAME.PROPERTY,
              permission: "create",
            })}
          />
          <Route
            path={getPath("geocodeRepetitiveLoss")}
            component={withAuthorization(GeocodeRepetitiveLoss, {
              resource: RESOURCE_NAME.REPETITIVE_LOSS,
              permission: "update",
            })}
          />
          <Route
            path={getPath("editProperty")}
            component={withAuthorization(EditProperty, {
              resource: RESOURCE_NAME.PROPERTY,
              permission: "update",
            })}
          />
          <Route
            path="/permits/edit/:certificateId"
            component={withAuthorization(PermitEdit, {
              resource: RESOURCE_NAME.PERMIT,
              permission: "update",
            })}
          />
          <Route
            path={getPath("submissionSummary")}
            component={withAuthorization(SubmissionSummary, {
              resource: RESOURCE_NAME.SUBMISSION,
              permission: "view",
            })}
          />
          <Route
            path={getPath("createSubmission")}
            component={CreateSubmissionContainer}
          />
          <Route
            path={getPath("editSubmission")}
            component={withAuthorization(EditSubmission, {
              resource: RESOURCE_NAME.SUBMISSION,
              // This route is for both view and edit
              // the edit button will be permissioned of edit while loading the page will be permissioned on view
              permission: "view",
            })}
          />
          <Route
            path={getPath("viewSubmission")}
            component={withAuthorization(ViewSubmission, {
              resource: RESOURCE_NAME.SUBMISSION,
              permission: "view",
            })}
          />
          <Route
            path={getPath("repetitiveLosses")}
            // the component is already wrapped with authorization
            // to make sure you can't accidentally use it without auth
            component={RepetitiveLossTable}
          />
          <Route
            path={getPath("createLog")}
            component={withAuthorization(CreateLog, {
              resource: RESOURCE_NAME.INFORMATION_REQUEST,
              permission: "private_create",
            })}
          />
          <Route
            path={getPath("editLog")}
            component={withAuthorization(EditLog, {
              resource: RESOURCE_NAME.INFORMATION_REQUEST,
              permission: "view",
            })}
          />
          <Route
            path={getPath("createDocumentTemplate")}
            component={withAuthorization(CreateOrUpdateDocumentTemplateForm, {
              resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
              permission: "create",
            })}
          />
          <Route
            path={getPath("updateDocumentTemplate")}
            component={withAuthorization(CreateOrUpdateDocumentTemplateForm, {
              resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
              permission: "update",
            })}
          />
          <Route
            path={getPath("dispatchBulkPropertyCreation")}
            component={withAuthorization(DispatchBulkPropertyActionForm, {
              resource: RESOURCE_NAME.SCRIPT_JOB,
              permission: "dispatch",
            })}
          />
          <Route
            path={getPath("dispatchBulkSubmissionCreation")}
            component={withAuthorization(DispatchBulkSubmissionActionForm, {
              resource: RESOURCE_NAME.SCRIPT_JOB,
              permission: "dispatch",
            })}
          />
          <Route
            path={getPath("dispatchUpdateAccountBoundary")}
            component={withAuthorization(DispatchUpdateAccountBoundaryForm, {
              resource: RESOURCE_NAME.SCRIPT_JOB,
              permission: "dispatch",
            })}
          />
          <Route
            exact
            path={[
              getPath("parcelImportConfigCreate"),
              getPath("parcelImportConfigEdit"),
            ]}
            component={withAuthorization(ParcelImportConfigWizard, {
              resource: RESOURCE_NAME.PARCEL_IMPORT_CONFIGURATION,
              permission: "create",
            })}
          />
          <Route
            exact
            path={getPath("submissionsBuilder")}
            component={SubmissionsBuilder}
          />
          <Route path={getPath("editSection")} component={EditSection} />
          <Route path={getPath("downloadExport")} component={DownloadExport} />
          <Route
            render={args =>
              RoutesWithSideNav({ ...args, ...props, setSideNavWidth })
            }
          />
        </Switch>
      </ModalProvider>
    </SideNavWidthContext.Provider>
  );
};

export default App;
