import React from "react";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import {
  Firm,
  FirmSource,
  useCanScheduleFirmImportLazyQuery,
  useExportGeoJsonFirmsMutation,
  useGetPresignedGeoJsonArchiveDownloadS3UrlQuery,
} from "../../../../generated/graphql";
import { DropdownMenu } from "../../../Inputs";
import { useFirmCreateImportModal } from "./forms/FirmCreateImport";
import Divider from "../../../Common/Divider";
import FileDisplay from "../../../Inputs/FileDisplay";
import Icon, { Icons } from "../../../Common/Icons";

import { Container, Info, Row } from "../__styles__/Row";
import { ActionsProps } from "../../../Inputs/DropdownMenu";

export type FirmLayerProps = {
  firmLayer: Pick<Firm, "id" | "name" | "source" | "type">;
  onScheduledFirmImport: () => void;
};

export default ({ firmLayer, onScheduledFirmImport }: FirmLayerProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [useCanScheduleFirmImportQuery] = useCanScheduleFirmImportLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      showFemaFirmImportModal();
    },
    onError: error => {
      addErrorToast(error.message);
    },
  });
  const [showFemaFirmImportModal] = useFirmCreateImportModal({
    existingFirm: firmLayer,
    onScheduledFirmImport,
  });

  const { data: presignedUrlData, loading: presignedUrlLoading } =
    useGetPresignedGeoJsonArchiveDownloadS3UrlQuery({
      skip: firmLayer.source === FirmSource.FEMA,
      variables: {
        firmId: firmLayer.id,
      },
    });

  const [exportGeoJsonFirms] = useExportGeoJsonFirmsMutation({
    onCompleted: () => {
      addSuccessToast(
        `The FIRM is being exported. You will receive an email with the link`
      );
    },
    onError: () => {
      addErrorToast(
        `There was an error exporting the FIRM. Please try again. If the problem persists, please email support@withforerunner.com`
      );
    },
  });

  const presignedUrl =
    presignedUrlData?.getPresignedGeoJsonArchiveDownloadS3Url ?? "";

  let fileIcon: Icon;
  let source: string;

  switch (firmLayer.source) {
    case FirmSource.FEMA:
      source = "FEMA NFHL";
      fileIcon = Icons.ARC_GIS;
      break;
    case FirmSource.GEOJSON:
      source = "Geojson";
      fileIcon = Icons.GPKG;
      break;
  }

  const reimportFirm = () => {
    void useCanScheduleFirmImportQuery({
      variables: {
        firmId: firmLayer.id,
      },
    });
  };

  const actions = [
    { label: "Reimport FIRM", onClick: reimportFirm },
  ] as Array<ActionsProps>;

  if (firmLayer.source === FirmSource.FEMA) {
    actions.push({
      label: "Export FEMA GeoJSON files",
      onClick: async () =>
        await exportGeoJsonFirms({
          variables: { firmId: firmLayer.id },
        }),
    });
  } else {
    actions.push({
      label: "Download GeoJSON files",
      disabled: presignedUrlLoading || !presignedUrl,
      href: presignedUrl,
      download: true,
    });
  }

  return (
    <Container>
      <Row>
        <FileDisplay fileIcon={fileIcon} titleText={firmLayer.name}>
          Source: {source}
        </FileDisplay>
        <Info>
          <DropdownMenu actions={actions} />
        </Info>
      </Row>
      <Divider />
    </Container>
  );
};
