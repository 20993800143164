import React, { useContext } from "react";

import { SubmissionsBuilderContextInstance } from "../../context";
import { BuilderFieldWrapper } from "../../__styles__/SubmissionsBuilder";
import { getNameFromId, withStopPropagation } from "../../utils";
import FieldActions, { FieldActionsProps } from "../FieldActions";
import { ObjectFieldTemplate } from "../../../../../../Submissions/Templates";
import { v4 as uuidV4 } from "uuid";
import useFieldActionsPropsUpdater from "../useFieldActionsPropsUpdater";
import { FieldContext } from "../../reducer";
import { useFormContext } from "react-hook-form";
import {
  SubmissionsBuilderFormDataStructure,
  NonEnumInputField,
} from "../../types";

export const isEmptyGroup = ({
  properties,
}: {
  properties: Array<unknown>;
}) => {
  return properties.length === 0;
};

export const EmptyGroupFieldContent = () => {
  return (
    <BuilderFieldWrapper key={uuidV4()}>
      <div style={{ padding: "2em" }} />
    </BuilderFieldWrapper>
  );
};

const ObjectFieldTemplatePreview = (
  props: Parameters<typeof ObjectFieldTemplate>[0]
) => {
  const formMethods = useFormContext<SubmissionsBuilderFormDataStructure>();
  const { fieldContextsByName, fieldActionsPropsByPath } = useContext(
    SubmissionsBuilderContextInstance
  );

  const id = props.idSchema.$id;
  const name = getNameFromId(id);
  const fieldContext = fieldContextsByName[name];

  if (id === "root") {
    useFieldActionsPropsUpdater(formMethods, "inputs");
    return <ObjectFieldTemplate {...props} />;
  }

  if (!fieldContext) {
    return null;
  }

  const fieldProps = fieldActionsPropsByPath[fieldContext.fieldPath];

  if (!fieldProps) {
    return null;
  }

  return (
    <GroupInputPreview
      fieldContext={fieldContext}
      fieldProps={fieldProps}
      name={name}
      {...props}
    />
  );
};

const GroupInputPreview = ({
  fieldContext,
  fieldProps,
  name,
  ...props
}: {
  fieldContext: FieldContext;
  fieldProps: FieldActionsProps;
  name: string;
} & Parameters<typeof ObjectFieldTemplate>[0]) => {
  const formMethods = useFormContext<SubmissionsBuilderFormDataStructure>();
  const { selectedField } = useContext(SubmissionsBuilderContextInstance);

  useFieldActionsPropsUpdater(
    formMethods,
    `${fieldContext.fieldPath}.properties` as NonEnumInputField
  );

  const { selectField } = fieldProps;

  const isSelected = selectedField?.name === name;

  return (
    <BuilderFieldWrapper
      onClick={withStopPropagation(selectField)}
      isSelected={isSelected}
      isGroupWrapper
      role="group"
      aria-selected={isSelected}
      aria-label="Field group"
    >
      <ObjectFieldTemplate {...props} />
      <FieldActions {...fieldProps} isGroup />
    </BuilderFieldWrapper>
  );
};

export default ObjectFieldTemplatePreview;
