import React, { useContext, useState } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";

import { RoleName } from "../../../../../generated/graphql";
import { Button } from "../../../Button";
import { AttributeRow, SavedView } from "../utils";
import { styled } from "../../../../../stitches.config";

import Divider from "../../../Divider";
import { useUpdateSavedView } from "../../utils";
import { TableContext } from "../../TableContext";
import { useDeleteSavedViewModal } from "./DeleteModal";

import {
  DropdownItemStyles,
  DropdownContentStyles,
  AttributeLabel,
} from "../__styles__/TableSettings";
import {
  canDeleteSavedView,
  canUpdateSavedView,
} from "common/authorization/customPolicies";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { useEditSavedViewModal } from "./EditModal";
import { Icon } from "../../../Icons/LucideIcons";
import { SavedViewItemIcon, SavedViewItemRow } from "./__styles__/SavedViews";
import { partition, sortBy } from "lodash";
import { Body } from "../../../Typography";

export const SavedViewsButton = ({
  savedViews,
  onAddNewView,
  addingNewView,
}: {
  savedViews?: Array<SavedView>;
  addingNewView: boolean;
  onAddNewView: () => void;
}) => {
  const { currentView } = useContext(TableContext);
  const showDeleteSavedViewModal = useDeleteSavedViewModal();
  const showEditSavedViewModal = useEditSavedViewModal();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (!currentView) {
    return null;
  }

  const [internalViews, publicViews] = partition(
    sortBy(savedViews, "name"),
    view => view.hiddenFromPublic
  );

  return (
    <div style={{ userSelect: "none" }}>
      <Button
        onClick={toggleMenu}
        rightIconName="chevron-down"
        leftIconName={currentView.hiddenFromPublic ? undefined : "users-round"}
        styleVariant="secondary"
        size="small"
        disabled={addingNewView}
      >
        {addingNewView ? "Adding view" : currentView.name}
      </Button>
      <Dropdown.Root open={menuOpen} onOpenChange={setMenuOpen} modal={false}>
        <Dropdown.Trigger asChild>
          <div />
        </Dropdown.Trigger>
        <DropdownContent align="start" sideOffset={4} loop>
          {!!internalViews.length && (
            <Dropdown.Sub>
              <SavedViewItemRow>
                <DropdownItem
                  key="internal-views"
                  disabled={true}
                  omitHoverStyles={true}
                  onSelect={() => {}}
                >
                  <Body size="small" type="emphasis" color="contentPlaceholder">
                    INTERNAL VIEWS
                  </Body>
                </DropdownItem>
              </SavedViewItemRow>
            </Dropdown.Sub>
          )}
          {internalViews.map(savedView => (
            <SavedViewItem
              savedView={savedView}
              key={savedView.id}
              onDelete={() => showDeleteSavedViewModal(savedView)}
              onEditName={() => showEditSavedViewModal(savedView)}
            />
          ))}
          {!!publicViews.length && (
            <Dropdown.Sub>
              <SavedViewItemRow>
                <DropdownItem
                  key="public-views"
                  disabled={true}
                  omitHoverStyles={true}
                  onSelect={() => {}}
                >
                  <Body size="small" type="emphasis" color="contentPlaceholder">
                    PUBLIC VIEWS
                  </Body>
                </DropdownItem>
              </SavedViewItemRow>
            </Dropdown.Sub>
          )}
          {publicViews.map(savedView => (
            <SavedViewItem
              savedView={savedView}
              key={savedView.id}
              onDelete={() => showDeleteSavedViewModal(savedView)}
              onEditName={() => showEditSavedViewModal(savedView)}
            />
          ))}
          <Divider extension={8} />
          <DropdownItem onSelect={onAddNewView}>
            <AttributeRow attribute={{ label: "Add new view", icon: "plus" }} />
          </DropdownItem>
        </DropdownContent>
      </Dropdown.Root>
    </div>
  );
};

const SavedViewItem = ({
  savedView,
  onDelete,
  onEditName,
}: {
  savedView: SavedView;
  onDelete: () => void;
  onEditName: () => void;
}) => {
  const { user, admin } = useContext(AuthContext);
  const { currentView, defaultView } = useContext(TableContext);
  const { updateSavedView } = useUpdateSavedView();

  const disabled = savedView.id === currentView!.id;

  const isDefaultView = savedView.id === defaultView?.id;
  const userEntity = user || admin;

  const canDelete =
    canDeleteSavedView({
      user: { role: userEntity?.role.name as RoleName, id: userEntity?.id! },
      createdById: savedView.createdById ?? null,
    }) && !isDefaultView;

  const canUpdate = canUpdateSavedView({
    user: { role: userEntity?.role.name as RoleName, id: userEntity?.id! },
    createdById: savedView.createdById,
  });

  return (
    <>
      <Dropdown.Sub>
        <SavedViewItemRow>
          <DropdownItem
            key={savedView.id}
            disabled={disabled}
            omitHoverStyles={disabled}
            onSelect={() => {
              updateSavedView(savedView.id);
            }}
          >
            <AttributeLabel disabled={disabled}>
              {savedView.name}
            </AttributeLabel>
          </DropdownItem>
          <Dropdown.SubTrigger asChild>
            <SavedViewItemIcon>
              <Icon
                iconName={"ellipsis-vertical"}
                color="contentSecondary"
                size={16}
                style={{
                  cursor: "pointer",
                }}
              />
            </SavedViewItemIcon>
          </Dropdown.SubTrigger>
        </SavedViewItemRow>
        <SubDropdownContent>
          <DropdownItem
            onSelect={() => {
              updateSavedView(savedView.id);
              onEditName();
            }}
            disabled={!canUpdate}
          >
            <AttributeLabel disabled={!canUpdate}>Edit name</AttributeLabel>
          </DropdownItem>
          <DropdownItem onSelect={onDelete} disabled={!canDelete}>
            <AttributeLabel disabled={!canDelete} red={canDelete}>
              Delete
            </AttributeLabel>
          </DropdownItem>
        </SubDropdownContent>
      </Dropdown.Sub>
    </>
  );
};

const DropdownContent = styled(Dropdown.Content, {
  ...DropdownContentStyles,
});
const SubDropdownContent = styled(Dropdown.SubContent, {
  ...DropdownContentStyles,
  width: "max-content",
});

const DropdownItem = styled(Dropdown.Item, {
  ...DropdownItemStyles,
});
