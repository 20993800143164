import { isUndefined, startCase } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { useUpdateSubmissionVisibilityMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { CommonModal } from "../../Common/CommonModal";

export type EditVisibilityProps = {
  submission: {
    id: string;
    hiddenFromPublic: boolean;
    property?: Maybe<{
      id: string;
    }>;
  };
  submissionTypeName: string;
  prevLocation?: string;
  closeModal: () => void;
  onUpdate?: () => void;
};

const EditVisibility = ({
  submission,
  submissionTypeName,
  prevLocation,
  closeModal,
  onUpdate,
}: EditVisibilityProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const history = useHistory();

  const [updateSubmissionVisibility, { loading }] =
    useUpdateSubmissionVisibilityMutation({
      variables: {
        data: {
          submissionId: submission.id,
          hiddenFromPublic: !submission.hiddenFromPublic,
        },
      },
      onCompleted: data => {
        addSuccessToast(
          `Your submission was successfully ${
            data.updateSubmissionVisibility.hiddenFromPublic
              ? "hidden"
              : "shown"
          }`
        );
        onUpdate?.();
        if (!isUndefined(prevLocation)) {
          history.push(prevLocation);
        }
        closeModal();
      },
      onError: () => {
        closeModal();
        addErrorToast(
          "There was an issue updating visibility. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      },
    });

  const verb = submission.hiddenFromPublic ? "show" : "hide";
  const title = `${startCase(verb)} on public website`;
  const content = `Are you sure you want to ${verb} this record on the public website?`;
  const primaryButtonText = `${startCase(verb)} record`;

  return (
    <CommonModal
      title={title}
      subtitle={submissionTypeName}
      content={content}
      onCancel={closeModal}
      onSubmit={updateSubmissionVisibility}
      primaryButtonText={primaryButtonText}
      loading={loading}
    />
  );
};

export default EditVisibility;
