import React from "react";
import {
  GetAccountDocumentTemplatesForMapQuery,
  useGenerateDocumentFromTemplateMutation,
} from "../../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { Radio } from "../../Inputs";
import { Button } from "../../Common/Button";

import { Controller, useForm } from "react-hook-form";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";
import { buildLink } from "common/routing";
import { useHistory } from "react-router-dom";
import { OBJECT_TYPE } from "common/constants";

type DocumentTemplates = NonNullable<
  GetAccountDocumentTemplatesForMapQuery["account"]
>["documentTemplates"];

export interface GenerateDocumentForm {
  documentTemplateId: string;
}

export interface GenerateDocumentFormProps {
  object: { id: string; objectLabel: string; objectType: OBJECT_TYPE };
  documentTemplates: DocumentTemplates;
  updateMap: () => void;
  closeModal: () => void;
}

export const GenerateDocumentForm = ({
  object: { objectLabel, id: objectId, objectType },
  documentTemplates,
  updateMap,
  closeModal,
}: GenerateDocumentFormProps) => {
  const history = useHistory();
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const { control, setValue, handleSubmit, watch } =
    useForm<GenerateDocumentForm>();

  const documentTemplateId = watch("documentTemplateId");

  const options = documentTemplates.map(documentTemplate => ({
    text: documentTemplate.name,
    value: documentTemplate.id,
  }));

  const [generateDocument, { loading: loadingGenerateDocument }] =
    useGenerateDocumentFromTemplateMutation({
      onCompleted: data => {
        closeModal();
        updateMap();
        addSuccessToast("Your document was successfully generated");
        const documentTemplate = documentTemplates.find(
          documentTemplate => documentTemplate.id === documentTemplateId
        );
        track("Generated document from template", {
          accountDocumentTypeId: documentTemplate?.accountDocumentType.id,
          accountDocumentTypeName: documentTemplate?.accountDocumentType.name,
          objectId: objectId,
          propertyFullAddress: objectLabel,
        });
        history.push(
          buildLink("documentUploadDetail", {
            id: data.generateDocumentFromTemplate.id,
          })
        );
      },
      onError: () => {
        const message =
          "There was an issue generating your document. Please refresh the page and try again. If the problem persists, please email us at support@withforerunner.com";
        addErrorToast(message);
      },
    });

  const onSubmit = async (data: GenerateDocumentForm) => {
    await generateDocument({
      variables: { templateId: data.documentTemplateId, objectId, objectType },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1 style={{ marginBottom: "8px" }}>Select document template</h1>
        <h2>
          Select the document template that you would like to create for{" "}
          {objectLabel}
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <Controller
            control={control}
            name="documentTemplateId"
            render={({ field, fieldState }) => {
              return (
                <Radio
                  value={field.value}
                  options={options}
                  name="documentTemplates"
                  required={true}
                  disabled={false}
                  onChange={value => setValue("documentTemplateId", value!)}
                  error={fieldState.error?.message}
                  fontSize="normal"
                  fontColor="black"
                />
              );
            }}
          />
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button
            styleVariant="secondary"
            size="medium"
            disabled={loadingGenerateDocument}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            size="medium"
            data-testid="generate"
            disabled={!documentTemplateId || loadingGenerateDocument}
            loading={loadingGenerateDocument}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
