import React, { useContext } from "react";

import {
  useGetAccountSecurityConfigurationQuery,
  useUpdateAccountMultifactorAuthMutation,
  useUpdateAccountSecurityConfigurationMutation,
} from "../../../generated/graphql";
import { SecurityConfigurationForm } from "./SecurityConfigurationForm";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";

import { MultifactorAuthSettingsForm } from "./MultifactorAuthSettingsForm";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { SettingsPanel } from "../../Common/Settings";

export const Security = () => {
  const { account, authorized } = useContext(AuthContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const { data, loading, error } = useGetAccountSecurityConfigurationQuery();

  const [updateAccountSecurityConfigurationMutation] =
    useUpdateAccountSecurityConfigurationMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your account security settings were successfully updated."
        );
      },
      onError: () => {
        addErrorToast(
          "There was a problem updating your account security settings. Please try again or contact support@withforerunner.com"
        );
      },
      update: (cache, result) => {
        const newSecurityConfiguration =
          result.data?.updateAccountSecurityConfiguration!;
        cache.modify({
          id: cache.identify(account!),
          fields: {
            securityConfiguration() {
              return { ...newSecurityConfiguration };
            },
          },
        });
      },
    });

  const [updateAccountMultifactorAuthMutation] =
    useUpdateAccountMultifactorAuthMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your account Multi-Factor Authentication settings were successfully updated."
        );
      },
      onError: () => {
        addErrorToast(
          "There was a problem updating your account multi-factor authentication settings. Please try again or contact support@withforerunner.com"
        );
      },
    });

  const securityConfiguration = data?.account?.securityConfiguration;

  if (error || (!loading && !securityConfiguration)) {
    throw new Error("Error loading security configruation");
  }

  const canUpdateAccountInformation = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "update",
  });

  return (
    <>
      <SettingsPanel
        title="Security settings"
        showInsufficientPermissionsBanner={!canUpdateAccountInformation}
      >
        {loading ? (
          "loading..."
        ) : (
          <SecurityConfigurationForm
            canUpdateAccountInformation={canUpdateAccountInformation}
            securityConfiguration={securityConfiguration!}
            onUpdate={updateAccountSecurityConfigurationMutation}
          />
        )}
      </SettingsPanel>
      <SettingsPanel title="Multi-Factor Authentication settings">
        {loading ? (
          "loading..."
        ) : (
          <MultifactorAuthSettingsForm
            canUpdateAccountInformation={canUpdateAccountInformation}
            securityConfiguration={securityConfiguration!}
            onUpdate={updateAccountMultifactorAuthMutation}
          />
        )}
      </SettingsPanel>
    </>
  );
};

export default Security;
