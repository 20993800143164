import { hot } from "react-hot-loader/root";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  useCurrentAdminQuery,
  useCurrentUserQuery,
} from "../generated/graphql";
import { CurrentAdmin, CurrentUser } from "./Authorization/types";
import { UnauthedRoute } from "./Authorization/UnAuthedRoute";
import { getPath } from "common/routing";
import UserLoginPage from "./Authentication/UserLoginPage";
import AdminLoginPage from "./Authentication/AdminLoginPage";
import UserInvite from "./UserInvite";
import PasswordReset from "./Authentication/PasswordReset";
import { AuthContextProvider } from "./Authorization/AuthContext";
import AppSelector from "./AppSelector";
import FullPageLoadingScreen from "./Common/FullPageLoadingScreen";
import { extractSubdomain } from "common/utils/url";
import { SUBDOMAIN_PATTERN } from "common/constants";
import UserLandingPage from "./Authentication/IdentifyUser/UserLandingPage";

export type MainRouteProps = {
  user?: Maybe<CurrentUser>;
  admin?: Maybe<CurrentAdmin>;
};

// we have to export this in isolation so that hot reloading works
export const MainRoutes = hot(() => {
  const { loading, data } = useCurrentUserQuery();
  const { loading: loadingAdmin, data: adminData } = useCurrentAdminQuery();

  if (loading || loadingAdmin) return <FullPageLoadingScreen />;

  const props = {
    user: data?.currentUser,
    admin: adminData?.currentAdmin,
  };

  const subdomain = extractSubdomain({
    url: window.location.href,
    pattern: SUBDOMAIN_PATTERN.LOGGED_IN_ENTITY,
  });

  const getLandingPage = () => {
    if (props.user) {
      return getPath("map");
    }
    if (props.admin) {
      return subdomain ? getPath("map") : getPath("adminAccounts");
    }
    return getPath("login");
  };

  return (
    <Switch>
      <Redirect exact from="/" to={getLandingPage()} />
      <UnauthedRoute
        {...props}
        path={getPath("login")}
        routeName="login"
        component={UserLandingPage}
      />
      <UnauthedRoute
        {...props}
        path={getPath("provideCredentials")}
        routeName="provideCredentials"
        component={UserLoginPage}
      />
      <UnauthedRoute
        {...props}
        path={getPath("adminLogin")}
        routeName="adminLogin"
        component={AdminLoginPage}
      />
      <Route path={getPath("acceptInvite")} component={UserInvite} />
      <Route path={getPath("passwordReset")} component={PasswordReset} />

      <AuthContextProvider {...props}>
        <AppSelector />
      </AuthContextProvider>
    </Switch>
  );
});
