import { Reference } from "@apollo/client";
import React, { useContext } from "react";
import { RESOURCE_NAME } from "common/authorization";
import {
  AccountPropertyWarningDefinitionInput,
  useCreateAccountPropertyWarningDefinitionMutation,
  useDeleteAccountPropertyWarningDefinitionMutation,
  useUpdateAccountPropertyWarningDefinitionMutation,
} from "../../../../generated/graphql";
import { track } from "../../../../utils/tracking";
import { AuthContext } from "../../../Authorization/AuthContext";
import Divider from "../../../Common/Divider";
import { Box } from "../../../Common/__styles__/Layout";
import { DropdownMenu } from "../../../Inputs";
import { Button } from "../../../Common/Button";

import { ActionsProps } from "../../../Inputs/DropdownMenu";
import WarningList, { EditableWarning } from "../../../Warnings/WarningList";
import { useDeleteModal } from "../../../Common/DeleteModal";
import { useWarningMutationModal } from "./WarningMutationModal";

import { Header, HeaderTitle, Section } from "../../__styles__/Content";
import { RightSection, VisibilityIconText } from "./__styles__/Warnings";
import { useWarningActiveStateModal } from "./WarningActiveStateModal";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { Body } from "../../../Common/Typography";
import { Icon } from "../../../Common/Icons/LucideIcons";
import { IconContainer } from "../../../Common/__styles__/ExpandableList";

const VisibilityText = ({ warning }: { warning: EditableWarning }) => {
  const { account } = React.useContext(AuthContext);

  if (!warning.isActive) {
    return (
      <VisibilityIconText>
        <IconContainer>
          <Icon iconName="eye-off" color="contentSecondary" size={16} />
        </IconContainer>
        <Body size="default" type="emphasis" as="div">
          Inactive
        </Body>
      </VisibilityIconText>
    );
  }

  if (!account?.publicPortal.enabled) {
    return null;
  }

  if (warning.isPublic) {
    return (
      <VisibilityIconText>
        <IconContainer>
          <Icon iconName="eye" color="contentSecondary" size={16} />
        </IconContainer>
        <Body size="default" type="emphasis" as="div">
          Shown to public
        </Body>
      </VisibilityIconText>
    );
  }

  return (
    <VisibilityIconText>
      <IconContainer>
        <Icon iconName="eye-off" color="contentSecondary" size={16} />
      </IconContainer>
      <Body size="default" type="emphasis" as="div">
        Hidden from public
      </Body>
    </VisibilityIconText>
  );
};

const WarningRightSection = ({
  warning,
  onSave,
  onDelete,
}: {
  warning: EditableWarning;
  onSave: ({
    data,
    warning,
  }: {
    data: AccountPropertyWarningDefinitionInput;
    warning?: EditableWarning;
  }) => void;
  onDelete: () => void;
}) => {
  const { authorized } = useContext(AuthContext);
  const [showWarningMutationModal] = useWarningMutationModal({
    warning,
    onSave,
  });
  const canUpdateWarning = authorized({
    resource: RESOURCE_NAME.ACCOUNT_PROPERTY_WARNING,
    permission: "update",
  });

  const [showWarningDeletionModal] = useDeleteModal({
    headerText: "Delete Warning",
    Content: (
      <p>
        Are you sure you would like to delete your <b>“{warning.title}” </b>
        warning?
      </p>
    ),
    deleteButtonText: "Delete Warning",
    onDelete,
  });
  const canDeleteWarning = authorized({
    resource: RESOURCE_NAME.ACCOUNT_PROPERTY_WARNING,
    permission: "delete",
  });

  const [showActiveStateModal] = useWarningActiveStateModal({ warning });

  const actions: Array<ActionsProps> = [
    {
      label: "Edit warning",
      onClick: showWarningMutationModal,
      disabled: !canUpdateWarning,
    },
    {
      label: `Make warning ${warning.isActive ? "inactive" : "active"}`,
      variant: warning.isActive ? "red" : undefined,
      onClick: showActiveStateModal,
      disabled: !canUpdateWarning,
    },
    {
      label: "Delete warning",
      variant: "red",
      onClick: showWarningDeletionModal,
      hidden: !warning.isCustom,
      disabled: !canDeleteWarning,
    },
  ];

  return (
    // this div is required to avoid certain styles of the accordion
    // from being applied to the right section
    <div style={{ marginLeft: "auto" }}>
      <RightSection>
        <VisibilityText warning={warning} />
        <DropdownMenu actions={actions} size="xSmall" />
      </RightSection>
    </div>
  );
};

const WarningSettings = () => {
  const { account, authorized } = useContext(AuthContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [createAccountPropertyWarningDefinition] =
    useCreateAccountPropertyWarningDefinitionMutation({
      onCompleted: warning => {
        addSuccessToast("Your warning has been successfully created.");
        track("Account Property Warning Updated", {
          ...warning.createAccountPropertyWarningDefinition,
        });
      },
      onError: () => {
        addErrorToast(
          "Your warning failed to be created. Please try again. If the problem persists, please email support@withforerunner.com"
        );
      },
      update: (cache, mutationResult) => {
        cache.modify({
          id: cache.identify(account!),
          fields: {
            accountPropertyWarningDefinitions: cachedDefinitions => {
              return [
                ...cachedDefinitions,
                mutationResult.data?.createAccountPropertyWarningDefinition,
              ];
            },
          },
        });
      },
    });
  const [updateAccountPropertyWarningDefinition] =
    useUpdateAccountPropertyWarningDefinitionMutation({
      onCompleted: warning => {
        addSuccessToast("Your warning has been successfully updated.");
        track("Account Property Warning Updated", {
          ...warning.updateAccountPropertyWarningDefinition,
        });
      },
      onError: () => {
        addErrorToast(
          "Your warning failed to be updated. Please try again. If the problem persists, please email support@withforerunner.com"
        );
      },
    });

  const onSave = async ({
    id,
    data,
  }: {
    id?: string;
    data: AccountPropertyWarningDefinitionInput;
  }) => {
    if (!!id) {
      await updateAccountPropertyWarningDefinition({
        variables: {
          id,
          data,
        },
      });
    } else {
      await createAccountPropertyWarningDefinition({
        variables: { data },
      });
    }
  };

  const [deleteAccountPropertyWarningDefinition] =
    useDeleteAccountPropertyWarningDefinitionMutation({
      onCompleted: ({ deleteAccountPropertyWarningDefinition }) => {
        track("Account Property Warning Definition Deleted", {
          ...deleteAccountPropertyWarningDefinition,
        });

        addSuccessToast("Your warning has been successfully deleted.");
      },
      update: (cache, mutationResult) => {
        const accountPropertyWarningId =
          mutationResult.data?.deleteAccountPropertyWarningDefinition.id;

        cache.modify({
          id: cache.identify(account!),
          fields: {
            accountPropertyWarningDefinitions: (
              cachedDefinitions,
              { readField }
            ) =>
              cachedDefinitions.filter((warningRef: Reference) => {
                let warningId = readField("id", warningRef);

                return warningId !== accountPropertyWarningId;
              }),
          },
        });
      },
      onError: () => {
        addErrorToast(
          "Your warning failed to be deleted. Please try again. If the problem persists, please email support@withforerunner.com"
        );
      },
    });

  const onDelete = (warning: EditableWarning) => async () => {
    await deleteAccountPropertyWarningDefinition({
      variables: {
        id: warning.id,
      },
    });
  };

  const [showWarningMutationModal] = useWarningMutationModal({
    onSave,
  });

  const canCreateWarning = authorized({
    resource: RESOURCE_NAME.ACCOUNT_PROPERTY_WARNING,
    permission: "create",
  });

  const accountPropertyWarningDefinitions =
    account?.accountPropertyWarningDefinitions;

  if (!accountPropertyWarningDefinitions) {
    throw new Error("Error loading account property warning definitions");
  }

  return (
    <Box>
      <Header>
        <HeaderTitle>Warning settings</HeaderTitle>
        <Button
          disabled={!canCreateWarning}
          styleVariant="outlineLight"
          onClick={showWarningMutationModal}
          size="medium"
          leftIconName="plus"
        >
          Add warning
        </Button>
      </Header>
      <Divider />
      <Section>
        <WarningList
          warnings={accountPropertyWarningDefinitions}
          warningRightSection={(warning, key) => (
            <WarningRightSection
              warning={warning}
              key={key}
              onSave={onSave}
              onDelete={onDelete(warning)}
            />
          )}
        />
      </Section>
    </Box>
  );
};

export default WarningSettings;
