import React, { useContext } from "react";
import {
  FloodInfoBasePropertyAttribute,
  Property,
} from "common-client/utils/firms";
import { LayerContext } from "../../../Maps/layers";
import { RESOURCE_NAME } from "common/authorization";
import { RasterUnitOfMeasurement } from "../../../../generated/graphql";
import { StoreRasterMeasureTool } from "../../../Maps/MeasureTool";
import { RASTER_UNITS_SIGNIFIER } from "common/constants";
import { AuthContext } from "../../../Authorization/AuthContext";
import { RenderMode } from "../FIRMInfo/types";
import { PropertyOverviewTooltip } from "./PropertyOverviewTooltip";
import { TableCell, TableRow } from "../../../Common/__styles__/StripedTable";
import { Label } from "../__styles__/";

export const RasterRow = ({
  accountPropertyAttribute,
  size,
  property,
}: {
  accountPropertyAttribute: Omit<FloodInfoBasePropertyAttribute, "name">;
  size: RenderMode;
  property?: Maybe<Pick<Property, "id" | "additionalFields">>;
}) => {
  const { authorized, isGuest } = useContext(AuthContext);
  const { measureToolDispatch } = useContext(LayerContext);

  const canUpdateProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "update",
  });

  if (isGuest && !accountPropertyAttribute.isPublic) {
    return null;
  }

  const tooltipElement = PropertyOverviewTooltip({
    tooltipMarkdown: accountPropertyAttribute.tooltip,
  });

  const rasterClick = () => {
    measureToolDispatch({
      type: "setMeasureMode",
      data: {
        measureToolMode: "raster",
        dataMode: "store",
        render: () => (
          <StoreRasterMeasureTool
            attribute={accountPropertyAttribute}
            property={property!}
          />
        ),
      },
    });
  };

  const RasterValue = () => {
    let valueToDisplay: string;
    if (value) {
      valueToDisplay = `${value}${
        RASTER_UNITS_SIGNIFIER[
          property.additionalFields[accountPropertyAttribute.id]!
            .units as RasterUnitOfMeasurement
        ]
      }`;
    } else if (property && canUpdateProperty) {
      valueToDisplay = "Add";
    } else {
      valueToDisplay = "-";
    }

    if (property && canUpdateProperty) {
      return <a onClick={() => rasterClick()}>{valueToDisplay}</a>;
    } else {
      return <>{valueToDisplay}</>;
    }
  };

  const value = property?.additionalFields[accountPropertyAttribute.id]?.value;

  return (
    <TableRow header={false}>
      <TableCell containsTooltip>
        <div style={{ overflowX: "hidden", whiteSpace: "normal" }}>
          <Label size={size}>{accountPropertyAttribute.label}</Label>
          {tooltipElement}
        </div>
      </TableCell>
      <TableCell>
        <RasterValue />
      </TableCell>
    </TableRow>
  );
};
