import React, { useEffect } from "react";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { RequiredMessage } from "../../PropertyData/__styles__/upsertDfeRuleModal";
import { Select, Text } from "../../../../Inputs/react-hook-form";
import { Required } from "../../../../Inputs/__styles__/Label";
import { InputGrid } from "../../PropertyData/__styles__/floodInfoModals";
import { Button } from "../../../../Common/Button";
import { useModal } from "react-modal-hook";
import Modal from "../../../../Common/Modal";
import { useHistory } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "./types";
import { Label } from "../../../../Inputs";
import { intakeSourceOptions } from "./constants";
import AttachmentSelect from "./SidebarColumn/AttachmentSelect";
import {
  SubmissionsBuilderContext,
  SubmissionsBuilderContextInstance,
} from "./context";
import { Attachment } from "common/utils/objectAttachments";
import { SUBMISSION_CATEGORY_OPTIONS } from "../../../../Submissions/utils";

export type CreateSubmissionTypeFormStructure = Pick<
  SubmissionsBuilderFormDataStructure,
  "name" | "category" | "intakeSource" | "attachments"
>;

type CreateSubmissionTypeModalProps = {
  onCancel: () => void;
  onSubmit: ({}: CreateSubmissionTypeFormStructure) => void;
  context: SubmissionsBuilderContext;
};

export const useCreateSubmissionTypeModal = ({
  onSubmit,
  context,
}: Pick<CreateSubmissionTypeModalProps, "onSubmit" | "context">) => {
  const history = useHistory();
  const [show, hide] = useModal(
    () => (
      <Modal>
        <CreateSubmissionTypeModal
          onCancel={() => history.push("/settings/account/records")}
          onSubmit={(values: CreateSubmissionTypeFormStructure) => {
            onSubmit(values);
            hide();
          }}
          context={context}
        />
      </Modal>
    ),
    [context, onSubmit]
  );

  return [show, hide] as const;
};

export const CreateSubmissionTypeModal = ({
  onCancel,
  onSubmit,
  context,
}: CreateSubmissionTypeModalProps) => {
  // We're using a new form here, because we want a subset of the fields and to have the nicety of things like errors and submit button disabling
  const formMethods = useForm<CreateSubmissionTypeFormStructure>();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = formMethods;

  // we don't get JSON schema validation for free, so have to handroll
  useEffect(() => {
    register("attachments", {
      validate: {
        minLength: (value: Maybe<Attachment[]>) =>
          value && value.length > 0 ? true : "must NOT have fewer than 1 items",
      },
    });
  });

  const handleSubmitCallback = (data: CreateSubmissionTypeFormStructure) =>
    onSubmit(data);

  return (
    <Container overflows>
      <HeaderSection>
        <h1>Add record type</h1>
      </HeaderSection>
      <SubmissionsBuilderContextInstance.Provider value={context}>
        <FormProvider {...formMethods}>
          <FormSection overflows>
            <ContentSection overflows>
              <RequiredMessage>
                All fields are required<Required>*</Required>
              </RequiredMessage>

              <InputGrid style={{ marginTop: "24px" }}>
                <Text
                  label="Record type"
                  error={errors.name?.message}
                  {...register("name", { required: "Record type is required" })}
                />

                <div>
                  <Label text="Record category" htmlFor="category" />
                  <Select
                    control={control}
                    name="category"
                    options={SUBMISSION_CATEGORY_OPTIONS}
                    size="medium"
                    required={true}
                    error={errors.category?.message}
                    rules={{ required: "Record category is required" }}
                  />
                </div>
                <div>
                  <Label text="Intake source" htmlFor="intakeSource" />
                  <Select
                    control={control}
                    name="intakeSource"
                    options={intakeSourceOptions}
                    size="medium"
                    required={true}
                    error={errors.intakeSource?.message}
                    rules={{ required: "Intake source is required" }}
                  />
                </div>
                <AttachmentSelect disabled={false} />
              </InputGrid>
            </ContentSection>
          </FormSection>
        </FormProvider>
      </SubmissionsBuilderContextInstance.Provider>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            onClick={handleSubmit(handleSubmitCallback)}
            disabled={!isValid}
          >
            Add
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
