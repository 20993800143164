import React from "react";

import { AuthContext } from "../Authorization/AuthContext";
import { ActivityTargetType, ActivityType } from "../../generated/graphql";
import Comment from "./Comment";
import Bullet from "./Bullet";
import Message, { FeedSource } from "./Message";
import { canDeleteComment } from "common/authorization/customPolicies";
import { Icon } from "../Common/Icons/LucideIcons";
import {
  ActivityRow,
  Container,
  DeleteActivity,
  TextContainer,
  IconAndText,
} from "./__styles__/ListItem";
import { useModal } from "react-modal-hook";
import Modal from "../Common/Modal";
import { DeleteActivityConfirmation } from "./DeleteActivityConfirmation";
import { captureMessage } from "@sentry/browser";
import {
  Activity,
  isAdminActivity,
  isApiActivity,
  isCommentActivity,
  isGuestActivity,
  isUserActivity,
} from "./types";

interface ListItemProps {
  activity: Activity;
  onUpdate: (deletedId?: string) => void;
  linkToObject?: boolean;
  truncateComments: boolean;
  allowEdits: boolean;
  feedSource: FeedSource;
}

const ListItem = ({
  activity,
  onUpdate,
  linkToObject,
  feedSource,
  truncateComments,
  allowEdits,
}: ListItemProps) => {
  const { user, admin, account } = React.useContext(AuthContext);
  const [showDeleteActivityModal, hideDeleteActivityModal] = useModal(() => (
    <Modal onRequestClose={hideDeleteActivityModal}>
      <DeleteActivityConfirmation
        activity={activity}
        closeModal={hideDeleteActivityModal}
        onUpdate={onUpdate}
      />
    </Modal>
  ));

  const currentUser = user || admin;
  let creator: string;
  if (
    isAdminActivity(activity) ||
    (activity.targetType === ActivityTargetType.CERTIFICATE &&
      activity.type === ActivityType.CREATED)
  ) {
    creator = "Forerunner";
  } else if (isGuestActivity(activity)) {
    creator = "Guest";
  } else if (isApiActivity(activity)) {
    creator = activity.createdBy?.name ?? "API";
  } else if (isUserActivity(activity) && activity.createdBy) {
    const isCreator = activity.createdBy.id === currentUser?.id;
    creator = isCreator
      ? "You"
      : `${activity.createdBy.firstName} ${activity.createdBy.lastName}`;
  } else {
    captureMessage(`Unknown activity creator type for activity ${activity.id}`);
    creator = "Forerunner";
  }

  return (
    <Container>
      <ActivityRow>
        <IconAndText>
          <Bullet activity={activity} />
          <TextContainer>
            <Message
              creator={creator}
              activity={activity}
              linkToObject={linkToObject}
              feedSource={feedSource}
            />
          </TextContainer>
        </IconAndText>
        {currentUser?.role.name === "admin" &&
          !(isCommentActivity(activity) && feedSource === "Property") && (
            <DeleteActivity
              data-testid="deleteActivity"
              onClick={showDeleteActivityModal}
            >
              <Icon iconName="x" color="contentSecondary" size="16px" />
            </DeleteActivity>
          )}
        {canDeleteComment({
          user: {
            id: currentUser!.id,
            role: currentUser!.role.name,
            accountId: account!.id,
          },
          comment: {
            createdBy: activity!.createdBy!,
            property: { account: { id: account!.id } },
          },
        }) &&
          allowEdits &&
          isCommentActivity(activity) && (
            <DeleteActivity
              data-testid="deleteActivity"
              onClick={showDeleteActivityModal}
            >
              <Icon iconName="x" color="contentSecondary" size="16px" />
            </DeleteActivity>
          )}
      </ActivityRow>
      {isCommentActivity(activity) && activity.target && (
        <Comment
          content={activity.target.content}
          truncate={truncateComments}
        />
      )}
    </Container>
  );
};

export default ListItem;
