import React, { useEffect, useRef, useState } from "react";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import { isNil, mapValues } from "lodash";
import { Text, Label, Select, Checkbox, Datepicker } from "../Inputs";
import {
  ApplyButton,
  Container,
  FilterCount,
  Form,
  Row,
} from "./__styles__/Filters";
import { Button } from "./Button";

export enum FILTER_TYPE {
  CHECKBOX = "checkbox",
  DATE = "date",
  SELECT = "select",
  TEXT = "text",
}

export type Filter = {
  type: FILTER_TYPE;
  label: string;
  key: string;
  options?: { label: string; value: Maybe<string> }[];
};

export type FilterState = Record<string, unknown>;

export type FiltersProps = {
  filterConfigurations: Array<Filter>;
  filterValues: Record<string, any>;
  onFilterChange: (filters: FilterState) => void;
  handleKeyDown: ({
    keyDownFn,
    event,
  }: {
    keyDownFn: ((event: unknown) => void) | undefined;
    event: React.KeyboardEvent;
  }) => void;
};

export const Filters = ({
  filterConfigurations,
  filterValues,
  onFilterChange,
  handleKeyDown,
}: FiltersProps) => {
  const wrapperRef = useRef(null as null | HTMLDivElement);
  const [panelVisible, setPanelVisible] = useState(false);
  const [localFilterValues, setLocalFilterValues] = useState(filterValues);
  const [inDatepicker, setInDatepicker] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && !inDatepicker) {
        setPanelVisible(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // Because the inner method is technically a callback, it doesn't get updated `inDatepicker` values
    // unless we trigger it as a useEffect dependency, or we could useRef
  }, [inDatepicker]);

  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => setPanelVisible(false),
  });

  const filtersApplied = Object.keys(filterValues).filter(
    key => !isNil(filterValues[key])
  );

  const filtersAllowedSet = new Set(
    filterConfigurations.map(filterConfiguration => filterConfiguration.key)
  );

  //we only want to show selected filters based on what filters are configurable
  const selectedFiltersCount = filtersApplied.filter(filterApplied =>
    filtersAllowedSet.has(filterApplied)
  ).length;

  const handleApply = () => {
    setPanelVisible(false);
    onFilterChange(localFilterValues);
  };

  const handleDiscard = () => {
    setPanelVisible(false);
    onFilterChange(mapValues(filterValues, () => null));
  };

  useEffect(() => {
    setLocalFilterValues(filterValues);
  }, [filterValues]);

  return (
    <Container ref={wrapperRef}>
      <Button
        styleVariant="outlineLight"
        data-testid="filter-button"
        size="small"
        leftIconName="filter"
        onClick={() => {
          setPanelVisible(!panelVisible);
        }}
        tabIndex={0}
        onKeyDown={event =>
          handleKeyDown({
            event,
            keyDownFn: () => setPanelVisible(!panelVisible),
          })
        }
      >
        {!selectedFiltersCount ? (
          "Filter"
        ) : (
          <span>
            Filter<FilterCount>{selectedFiltersCount}</FilterCount>
          </span>
        )}
      </Button>
      {panelVisible && (
        <Form tabIndex={0}>
          <Row>
            <strong>Filter By</strong>
          </Row>

          {filterConfigurations.map(filter => {
            return (
              <Row key={filter.key}>
                <Label text={filter.label} htmlFor={filter.key} tabIndex={-1} />
                {filter.type === FILTER_TYPE.CHECKBOX && (
                  <Checkbox
                    name={filter.key}
                    value={localFilterValues[filter.key]}
                    onChange={value => {
                      setLocalFilterValues({
                        ...localFilterValues,
                        [filter.key]: value,
                      });
                    }}
                  />
                )}
                {filter.type === FILTER_TYPE.DATE && (
                  <div>
                    <Label
                      text="between"
                      htmlFor={`${filter.key}Start`}
                      tabIndex={-1}
                    />
                    <Datepicker
                      label=""
                      name={`${filter.key}Start`}
                      value={localFilterValues[`${filter.key}Start`]}
                      onChange={value =>
                        setLocalFilterValues({
                          ...localFilterValues,
                          [`${filter.key}Start`]: value || undefined,
                        })
                      }
                      onFocus={() => setInDatepicker(true)}
                      onBlur={() => setInDatepicker(false)}
                      size="smaller"
                    />
                    <Label
                      text="and"
                      htmlFor={`${filter.key}End`}
                      tabIndex={-1}
                    />
                    <Datepicker
                      label=""
                      name={`${filter.key}End`}
                      value={localFilterValues[`${filter.key}End`]}
                      onChange={value =>
                        setLocalFilterValues({
                          ...localFilterValues,
                          [`${filter.key}End`]: value || undefined,
                        })
                      }
                      onFocus={() => setInDatepicker(true)}
                      onBlur={() => setInDatepicker(false)}
                      size="smaller"
                    />
                  </div>
                )}
                {filter.type === FILTER_TYPE.SELECT && (
                  <Select
                    name={filter.key}
                    value={localFilterValues[filter.key]}
                    className="filter-select"
                    onChange={value =>
                      setLocalFilterValues({
                        ...localFilterValues,
                        [filter.key]: value,
                      })
                    }
                    options={filter.options!}
                    size="small"
                  />
                )}
                {filter.type === FILTER_TYPE.TEXT && (
                  <Text
                    value={localFilterValues[filter.key]}
                    onChange={value =>
                      setLocalFilterValues({
                        ...localFilterValues,
                        [filter.key]: value,
                      })
                    }
                    name={filter.key}
                    size="smaller"
                  />
                )}
              </Row>
            );
          })}

          <Row>
            <Button
              size="small"
              styleVariant="secondary"
              onClick={handleDiscard}
            >
              Discard
            </Button>
            <ApplyButton
              styleVariant="primary"
              size="small"
              onClick={handleApply}
              disabled={!Object.keys(localFilterValues).length}
            >
              Apply
            </ApplyButton>
          </Row>
        </Form>
      )}
    </Container>
  );
};
