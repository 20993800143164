import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ModalProvider } from "react-modal-hook";
import { RESOURCE_NAME } from "common/authorization";
import { getPath } from "common/routing";
import { extractSubdomain } from "common/utils/url";
import { SUBDOMAIN_PATTERN } from "common/constants";

import TranscriptionApp from "./TranscriptionApp";
import AdminLandingPage from "./AdminLandingPage";
import AccountDashboardShim from "./AccountsApp/AccountDashboardShim";
import CreateAccount from "./CreateAccount";
import DownloadExport from "../Exports/DownloadExport";
import CsvImports from "./CsvImports";
import { withAuthorization } from "../Authorization/withAuthorization";
import { isNotNil } from "common/utils/tools";

export default function AdminApp() {
  const subdomain = extractSubdomain({
    url: window.location.href,
    pattern: SUBDOMAIN_PATTERN.LOGGED_IN_ENTITY,
  });

  return isNotNil(subdomain) ? (
    <ModalProvider>
      <Route path="/" component={AccountDashboardShim} />
    </ModalProvider>
  ) : (
    <div>
      <ModalProvider>
        <Switch>
          <Redirect exact from="/" to="/admin" />
          <Route path="/admin/tasks" component={TranscriptionApp} />
          <Route path="/admin/accounts/create" component={CreateAccount} />
          <Route path={getPath("downloadExport")} component={DownloadExport} />
          <Route
            path="/admin/csv-imports/:importType"
            component={withAuthorization(CsvImports, {
              resource: RESOURCE_NAME.SCRIPT_JOB,
              permission: "dispatch",
            })}
          />
          <Route path="/admin" component={AdminLandingPage} />
        </Switch>
      </ModalProvider>
    </div>
  );
}
