import { styled } from "../../../stitches.config";

export const PrintOnly = styled("div", {
  "@media screen": {
    display: "none",
  },
  "@media print": {
    display: "block",
    pageBreakInside: "avoid",
    breakInside: "avoid",
  },
  variants: {
    width: {
      full: {
        width: "100%",
      },
      auto: {
        width: "auto",
      },
    },
    flexRow: {
      true: {
        "@media print": {
          display: "flex",
          flexDirection: "row",
        },
      },
    },
  },
});
