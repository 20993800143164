import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Checkbox, Text } from "../../../../../../Inputs/react-hook-form";
import {
  SubmissionsBuilderFormDataStructure,
  SubmissionsBuilderFieldSettings,
} from "../../types";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";
import { SelectedSubmissionsBuilderField } from "../../reducer";

const DateWidgetSettings = ({
  fieldPath,
  isModuleInput,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { register, control } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={isModuleInput}
        readOnly={readOnly}
      />
      <div>
        <Label text={"Helper text"} htmlFor={`${fieldPath}.helperText`} />
        <Text {...register(`${fieldPath}.helperText`)} disabled={readOnly} />
      </div>
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} disabled={readOnly} />
      </div>
      <Checkbox
        name={`${fieldPath}.defaultToToday`}
        control={control}
        label="Default to current date"
        disabled={readOnly}
      />
    </WidgetSettingsContainer>
  );
};

DateWidgetSettings.displayName = "DateWidgetSettings";

export default DateWidgetSettings;
