import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import {
  SubmissionsBuilderFieldSettings,
  SubmissionsBuilderFormDataStructure,
} from "../../types";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";

const DamageDegreeWidgetSettings = ({
  fieldPath,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      {/* This should always be a module input */}
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={true}
        readOnly={readOnly}
      />
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} disabled={readOnly} />
      </div>
    </WidgetSettingsContainer>
  );
};

export default DamageDegreeWidgetSettings;
