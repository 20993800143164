import React from "react";
import Banner from "./Banner";
import { BANNER_TYPES } from "./__styles__/Banner";
import { BannerWrapper } from "./__styles__/AITranslationWarningBanner";
import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "common/constants";

const AITranslationWarningBanner = () => {
  const { i18n, t } = useTranslation();

  if (i18n.language === SUPPORTED_LANGUAGES.ENGLISH) {
    return null;
  }

  return (
    <BannerWrapper>
      <Banner type={BANNER_TYPES.NEUTRAL}>
        {t("common-ai-translation-message")}
      </Banner>
    </BannerWrapper>
  );
};

export default AITranslationWarningBanner;
