import { spacing, styled } from "../../../stitches.config";

export const List = styled("ul", {
  paddingTop: spacing.s,
  listStyleType: "none",
  paddingLeft: "0",
  margin: "0",
});

export const ActivityDateGroup = styled("div", {
  paddingBottom: spacing.m,
});
