import { createContext } from "react";
import { AddressPanelProperty } from "./types";

export type AddressPanelContextType = {
  loadingProperty: boolean;
  property: AddressPanelProperty;
};

export const AddressPanelContext = createContext<AddressPanelContextType>({
  property: null,
  loadingProperty: true,
});
