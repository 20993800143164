import React, { useEffect } from "react";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { useModal } from "react-modal-hook";

import ActivityList from "./ActivityList";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { track } from "../../utils/tracking";
import { useGetActivitiesQuery } from "../../generated/graphql";

import AddCommentForm from "./AddCommentForm";
import Modal from "../Common/Modal";
import { Body } from "../Common/Typography";
import { Button } from "../Common/Button";
import { EmptyState } from "../Common/EmptyState";
import { FlexRow } from "../Common/__styles__/Layout";
import { OBJECT_TYPE } from "common/constants";
import { AddressPanelProperty } from "../AddressPanel/types";
import { Loading } from "./__styles__/Activity";
import { CustomMapGeometry as ObjectPanelCustomMapGeometry } from "../ObjectInformationPanel/types";
import { FeedContainer } from "./__styles__/InfoPanelActivityFeed";

type PropertyObject = {
  objectType: OBJECT_TYPE.PROPERTY;
  object: NonNullable<AddressPanelProperty>;
};

type GeometryObject = {
  objectType: OBJECT_TYPE.CUSTOM_MAP_GEOMETRY;
  object: ObjectPanelCustomMapGeometry;
};

const InfoPanelActivityFeed = ({
  objectType,
  object,
  fetchPolicy = "cache-and-network",
}: (PropertyObject | GeometryObject) & {
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const [showAddCommentModal, hideAddCommentModal] = useModal(
    () => (
      <Modal onRequestClose={hideAddCommentModal}>
        <AddCommentForm
          propertyId={object.id}
          onCancel={hideAddCommentModal}
          onAdd={() => {
            hideAddCommentModal();
            void refetch();
          }}
        />
      </Modal>
    ),
    [objectType, object]
  );

  const { data, loading, error, refetch } = useGetActivitiesQuery({
    variables: { objectId: object.id, objectType },
    fetchPolicy,
  });

  useEffect(() => {
    track("Opened activity", {
      objectType,
      objectId: object.id,
      label:
        // TODO: factor out this label logic, used in many places
        objectType === OBJECT_TYPE.PROPERTY ? object.fullAddress : object.label,
    });
  }, [object]);

  if (!data || loading)
    return (
      <Loading>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </Loading>
    );

  if (error) return <em>Problem loading activity.</em>;

  return (
    <>
      <FlexRow
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Body size={"large"} type={"emphasis"}>
          Activity
        </Body>
        {objectType === OBJECT_TYPE.PROPERTY && (
          // TODO: enable creating comments for custom map geometries
          <Button
            size={"small"}
            styleVariant={"outlineLight"}
            leftIconName="plus"
            onClick={showAddCommentModal}
            data-testid="addCommentButton"
          >
            Add comment
          </Button>
        )}
      </FlexRow>
      {!data.activities.length ? (
        <EmptyState
          icon="add-file"
          title="No activity"
          // TODO: remove conditional when comments are enabled for geometries
          body={
            objectType === OBJECT_TYPE.PROPERTY
              ? "Add a comment to get started."
              : "Updates will appear here."
          }
        />
      ) : (
        <FeedContainer>
          <ActivityList
            activities={data.activities}
            onUpdate={() => refetch()}
            linkToObject={false}
            truncateComments={false}
            allowEdits={true}
            feedSource={"Property"}
          />
        </FeedContainer>
      )}
    </>
  );
};

export default InfoPanelActivityFeed;
