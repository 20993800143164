import { useContext } from "react";
import { SubmissionsBuilderField, SubmissionsBuilderGroupField } from "./types";
import { omit, pick } from "lodash";
import {
  GetSubmissionTypeForBuilderQuery,
  SubmissionType,
} from "../../../../../generated/graphql";
import {
  convertJSONSchemaObjectToFormStructure,
  SubmissionsFormInputDescription,
} from "common/services/formBuilderService";
import { DEFAULT_POSTAMBLE, DEFAULT_PREAMBLE } from "./constants";
import {
  INTAKE_SOURCE,
  OBJECT_TYPE,
  SUBMISSION_TYPE_MODULE,
} from "common/constants";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { makeUniqueFieldName } from "./helpers";

const isGroupField = (
  field: SubmissionsBuilderField
): field is SubmissionsBuilderGroupField => {
  return field.inputType === "group" && "properties" in field;
};

export const deepDuplicateField = (
  name: string,
  originalField: SubmissionsBuilderField,
  appendCopySuffix = true
): SubmissionsBuilderField => {
  const result: SubmissionsBuilderField = {
    // providing generic params to specify that result is NOT a Partial<>
    ...omit<SubmissionsBuilderField, ["id"]>(originalField, "id"),
    name,
    title: appendCopySuffix
      ? `${originalField.title} Copy`
      : originalField.title,
  };

  if (isGroupField(originalField) && originalField.properties.length > 0) {
    result.properties = originalField.properties.reduce((acc, field) => {
      const name = makeUniqueFieldName();
      acc.push(deepDuplicateField(name, field, false));
      return acc;
    }, [] as SubmissionsBuilderField[]);
  }

  return result;
};

export const getNameFromId = (id: string) => {
  if (id === "root") {
    return "";
  }

  const index = id.lastIndexOf("_");
  if (index === -1) {
    return "root"; // Underscore not found
  }

  return id.substring(index + 1);
};

export const withStopPropagation =
  (eventHandler: () => void) => (event: React.SyntheticEvent) => {
    event.stopPropagation();
    eventHandler();
  };

type Input = { name: string; inputType: string; properties?: Array<Input> };

export const getAllInputs = (
  inputs: ReadonlyArray<Input> | Array<Input>
): Array<Input> => {
  const fields = [];
  for (const input of inputs) {
    if (input.properties?.length) {
      fields.push(...getAllInputs(input.properties));
    }
    fields.push(input);
  }
  return fields;
};

export const getAllInputNames = (
  inputs: ReadonlyArray<Input> | Array<Input>
): Array<string> => {
  const flattenedInputs = getAllInputs(inputs);
  return flattenedInputs.map(input => input.name);
};

export const getValidPostambleForIntakeSource = ({
  intakeSource,
  postamble,
}: Pick<SubmissionType, "intakeSource" | "postamble">) => {
  return intakeSource === INTAKE_SOURCE.INTERNAL
    ? null
    : postamble ?? DEFAULT_POSTAMBLE;
};

export const getValidPreambleForIntakeSource = ({
  intakeSource,
  preamble,
}: Pick<SubmissionType, "intakeSource" | "preamble">) => {
  return intakeSource === INTAKE_SOURCE.INTERNAL
    ? null
    : preamble ?? DEFAULT_PREAMBLE;
};

// Based off the form validation schema (./resolvers/schema.ts), we want to set
// the correct form values based on the intake source
export const getValidIntakeSourceSecondaryValues = ({
  intakeSource,
  name,
  notificationEmails,
  postamble,
  preamble,
  successMessage,
}: Pick<
  SubmissionType,
  | "intakeSource"
  | "name"
  | "notificationEmails"
  | "postamble"
  | "preamble"
  | "successMessage"
>) => {
  return {
    postamble: getValidPostambleForIntakeSource({ intakeSource, postamble }),
    preamble: getValidPreambleForIntakeSource({ intakeSource, preamble }),
    notificationEmails:
      intakeSource === INTAKE_SOURCE.INTERNAL
        ? null
        : notificationEmails?.join("\n") ?? "",
    successMessage:
      intakeSource === INTAKE_SOURCE.INTERNAL
        ? successMessage ?? `Your ${name} has been saved`
        : null,
  };
};

export const generateDefaultValues = ({
  existingSubmissionType,
  versionId,
}: {
  existingSubmissionType?: GetSubmissionTypeForBuilderQuery["submissionType"];
  versionId: Maybe<string>;
}): {
  modules: Array<SUBMISSION_TYPE_MODULE>;
  inputs: Array<SubmissionsFormInputDescription>;
  attachments: Array<{ type: OBJECT_TYPE; tags?: Array<string> }>;
  intakeSource: INTAKE_SOURCE;
  hiddenFromPublicByDefault: boolean;
} => {
  if (!existingSubmissionType || !versionId) {
    return {
      modules: [],
      inputs: [],
      attachments: [],
      intakeSource: INTAKE_SOURCE.INTERNAL,
      hiddenFromPublicByDefault: true,
    };
  }

  const { formStructure } = existingSubmissionType.versions.find(
    version => version.id === versionId
  )!;

  const existingSubmissionTypeFields = pick(existingSubmissionType, [
    "name",
    "description",
    "category",
    "intakeSource",
    "modules",
    "hiddenFromPublicByDefault",
  ]);

  return {
    ...existingSubmissionTypeFields,
    inputs: convertJSONSchemaObjectToFormStructure(formStructure),
    attachments: existingSubmissionType.attachments.map(({ type, tags }) => ({
      type,
      ...(tags ? { tags } : {}),
    })),
    ...getValidIntakeSourceSecondaryValues(existingSubmissionType),
  };
};

export const buildNotificationEmailsArray = ({
  intakeSource,
  notificationEmails,
}: {
  intakeSource: INTAKE_SOURCE;
  notificationEmails?: Maybe<string>;
}) => {
  return intakeSource === INTAKE_SOURCE.INTERNAL
    ? null
    : // Need to filter out empty string
      (notificationEmails?.split("\n") ?? [])
        .map(email => email.trim())
        .filter(email => !!email);
};

export const useIsSisdAndHidden = (modules: Array<SUBMISSION_TYPE_MODULE>) => {
  const { account } = useContext(AuthContext);

  return (
    modules.includes(
      SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
    ) && !!account?.publicPortal.hideSISD
  );
};
