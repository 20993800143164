import "core-js/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { AppScaffold } from "./components/AppScaffold";

import { setupEntrypoint } from "./utils/entrypoint";

import "./styles/base.scss";
import { MainRoutes } from "./components/MainRoutes";
import { StagingBanner } from "./components/AdminApp/AccountsApp/StagingBanner";
import { createI18nInstance } from "./utils/i18n/i18n";
import { initDatadogRum } from "./utils/datadogRum";
const { history } = setupEntrypoint();

if (window.env.NODE_ENV === "production" || window.env.NODE_ENV === "staging") {
  void initDatadogRum();
}
void createI18nInstance();

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <AppScaffold history={history}>
      <StagingBanner />
      <MainRoutes />
    </AppScaffold>
  </Suspense>,
  document.getElementById("app")
);
