import React, { useContext } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { AddressPanelTabProps } from "..";
import {
  SubmissionCategory,
  SubmissionIntakeSource,
  useGetSubmissionsForPropertyQuery,
} from "../../../generated/graphql";
import { EmptyState } from "../../Common/EmptyState";
import MissingProperty from "../MissingProperty";
import { Layout } from "../Records";
import ListItem from "./ListItem";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { canCreateInternalSubmission } from "common/authorization/customPolicies";
import { AuthContext } from "../../Authorization/AuthContext";
import { AddressPanelContext } from "../AddressPanelContext";
import { OBJECT_TYPE } from "common/constants";
import { createSubmissionRedirect } from "../../Common/CreateSubmissionButton/utils";
import { getObjectDisplay } from "common-client/utils/customObject";

const Submissions = ({
  address,
  latitude,
  longitude,
  title,
  titleWithinCopy,
  category,
}: AddressPanelTabProps & {
  category: SubmissionCategory;
  title: string;
  titleWithinCopy: string;
}) => {
  const { user, admin, isGuest } = useContext(AuthContext);
  const { property, loadingProperty } = useContext(AddressPanelContext);
  const history = useHistory();
  const { t } = useTranslation();

  const propertyId = property?.id;
  const variables = {
    propertyId,
    category,
    isGuest,
  };

  const { data, loading, error, refetch } = useGetSubmissionsForPropertyQuery({
    variables,
    skip: loadingProperty || !propertyId,
    fetchPolicy: "cache-and-network",
  });

  if (loading || loadingProperty) {
    return (
      <Layout title={title}>
        <em>{t("common-loading")}</em>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title={title}>
        <span>
          {t("property-record-loading-error", { title: titleWithinCopy })}
        </span>
      </Layout>
    );
  }

  if (!propertyId || !data?.getProperty) {
    return (
      <MissingProperty
        title={t("property-record-no-property", { title: titleWithinCopy })}
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  const currentUser = user || admin;
  const canCreateSubmission = canCreateInternalSubmission({
    role: currentUser?.role.name,
  });

  const actionButton = isGuest ? (
    <></>
  ) : (
    <CreateSubmissionButton
      disabled={!canCreateSubmission}
      objectDisplay={getObjectDisplay({
        objectType: OBJECT_TYPE.PROPERTY,
        object: { fullAddress: address, latitude, longitude },
      })}
      onSubmit={submissionTypeId =>
        createSubmissionRedirect({
          history,
          submissionTypeId,
          objectId: propertyId,
          objectType: OBJECT_TYPE.PROPERTY,
        })
      }
      submissionTypeFilter={submissionType =>
        submissionType.category === category &&
        submissionType.intakeSource === SubmissionIntakeSource.INTERNAL &&
        submissionType.attachments
          .map(attachment => attachment.type)
          .includes(OBJECT_TYPE.PROPERTY)
      }
    />
  );

  const submissions = data.getProperty.submissions;
  const body = isGuest
    ? t("property-no-record-subtext", { title: titleWithinCopy })
    : `Add ${titleWithinCopy} to get started.`;
  if (!submissions.length) {
    return (
      <React.Fragment>
        <Layout title={title} actionButton={actionButton}>
          <EmptyState
            icon="add-file"
            title={t("property-no-record-header", { title: titleWithinCopy })}
            body={body}
          />
        </Layout>
      </React.Fragment>
    );
  }

  return (
    <Layout title={title} actionButton={actionButton}>
      {submissions.map(submission => (
        <ListItem
          key={submission.id}
          submission={submission}
          onUpdate={refetch}
          objectIsHiddenFromPublic={property.hiddenFromPublic}
        />
      ))}
    </Layout>
  );
};

export default Submissions;
