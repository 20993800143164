import React from "react";
import { useForm } from "react-hook-form";

import { track } from "../../utils/tracking";
import { useCreateCommentMutation } from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { CommonModal } from "../Common/CommonModal";
import { Textarea } from "../Inputs/react-hook-form";

export interface AddCommentFormProps {
  propertyId: string;
  onAdd: () => void;
  onCancel: () => void;
}

type CommentFormValues = {
  comment: string;
};

const AddCommentForm = ({
  propertyId,
  onCancel,
  onAdd,
}: AddCommentFormProps) => {
  const { addErrorToast } = useStatusToasts();

  const { watch, handleSubmit, register } = useForm<CommentFormValues>({
    defaultValues: {
      comment: "",
    },
  });

  const [createComment, { loading }] = useCreateCommentMutation({
    onCompleted: response => {
      if (response.createComment.id) {
        track("Comment Created");
      }
      onAdd();
    },
    onError: () => {
      addErrorToast(
        `There was an error adding this comment. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const addComment = async ({ comment }: CommentFormValues) => {
    if (loading) return;
    const cleanContent = comment.trim();
    await createComment({
      variables: { data: { content: cleanContent, propertyId } },
    });
  };

  return (
    <CommonModal
      height="short"
      dataTestId="add-comment-form"
      title={"Add comment"}
      onSubmit={handleSubmit(addComment)}
      primaryButtonText="Add"
      onCancel={onCancel}
      loading={loading}
      disabled={watch("comment").trim().length === 0}
      size="small"
      content={
        <Textarea
          label={"Description"}
          {...register("comment")}
          required={true}
          styleOverride={{
            resize: "none",
          }}
          size="small"
          minRows={3}
          autoFocus
        />
      }
    />
  );
};

export default AddCommentForm;
