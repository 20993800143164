import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { buildLink } from "common/routing";
import {
  Submission,
  SubmissionTag,
  SubmissionType,
} from "../../../generated/graphql";
import { formatDateString } from "../../../utils/dates";
import ActionButton from "../../Submissions/ActionButton";
import { hasSummary } from "../../Submissions/utils";
import { MODULE_CONFIGURATIONS } from "../../Submissions/modules";
import { isNotNil } from "common/utils/tools";
import { StyleVariant, Tag } from "../../Common/Tag";
import { Body } from "../../Common/Typography";

import { TagsWrapper } from "../Improvements/__styles__/ListItem";
import { Row } from "../DocumentUploads/__styles__/ListItem";
import {
  SubmissionLink,
  ActionButtonWrapper,
  SubmissionSubtitle,
} from "./__styles__/Submissions";

type SubmissionTypeVersion = {
  submissionType: Pick<SubmissionType, "name" | "category" | "modules"> & {
    // AI actions are optional because of guest users
    aiActions?: SubmissionType["aiActions"];
  };
};

type RelatedSubmissionTypeVersion = {
  submissionType: Pick<
    SubmissionTypeVersion["submissionType"],
    "name" | "modules" | "category"
  >;
};

export type SubmissionListItemProps = {
  submission: Pick<
    Submission,
    | "id"
    | "createdAt"
    | "updatedAt"
    | "status"
    | "formData"
    | "hiddenFromPublic"
  > & { tags: Array<Omit<SubmissionTag, "uiSchemaPath">> } & {
    summaries: Array<{ __typename: string }>;
    submissionTypeVersion: SubmissionTypeVersion;
    relatedSubmissions: Array<
      Pick<Submission, "id"> & {
        submissionTypeVersion: RelatedSubmissionTypeVersion;
      }
    >;
  };
  onUpdate: () => void;
  objectIsHiddenFromPublic: boolean;
};

export default ({
  submission,
  onUpdate,
  objectIsHiddenFromPublic,
}: SubmissionListItemProps) => {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  const prevLocation = `${pathname}${search}`;
  const dateText =
    submission.createdAt === submission.updatedAt
      ? t("property-record-list-item-subtext")
      : t("property-record-last-edited");

  const listItemDescription =
    submission.submissionTypeVersion.submissionType.modules
      .map(module => {
        return MODULE_CONFIGURATIONS[module]?.buildListItemDescription(
          submission
        );
      })
      .filter(isNotNil)
      .join(" - ");

  return (
    <Row>
      <div style={{ margin: "8px 0" }}>
        <SubmissionLink
          to={{
            pathname: buildLink("viewSubmission", {
              submissionId: submission.id,
            }),
            state: { prevLocation },
          }}
        >
          <Body size="default" type="emphasis">
            {submission.submissionTypeVersion.submissionType.name}
          </Body>

          <SubmissionSubtitle>
            {dateText}{" "}
            {formatDateString({
              format: "MM/DD/YYYY",
              dateString: submission.updatedAt,
            })}{" "}
            {listItemDescription && `- ${listItemDescription}`}
          </SubmissionSubtitle>
        </SubmissionLink>
        <TagsWrapper role="list">
          {submission.tags.map(({ text, display }) => {
            return (
              <Tag styleVariant={display as StyleVariant} key={text}>
                {text}
              </Tag>
            );
          })}
        </TagsWrapper>
      </div>

      <ActionButtonWrapper>
        <ActionButton
          submission={{
            ...submission,
            hasSummary: hasSummary(submission),
            category: submission.submissionTypeVersion.submissionType.category,
          }}
          onUpdate={onUpdate}
          objectIsHiddenFromPublic={objectIsHiddenFromPublic}
        />
      </ActionButtonWrapper>
    </Row>
  );
};
