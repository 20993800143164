import React from "react";
import {
  OptionsContainer,
  InputContainer,
  ValueInputContainer,
  ActionIconContainer,
  LabelContainer,
  LVLabel,
} from "./__styles__/WidgetSettings";
import { Text as TextInput } from "../../../../../../Inputs";
import { Icon } from "../../../../../../Common/Icons/LucideIcons";

const LabelValueOptionsInput = ({
  dropdownOptions,
  setDropdownOption,
  removeDropdownOption,
  valueEditingDisabled = false,
  disabled,
}: {
  dropdownOptions: Array<{ label: string; value: string }>;
  setDropdownOption: (index: number, label: string, value: string) => void;
  removeDropdownOption: (index: number) => void;
  valueEditingDisabled?: boolean;
  disabled: boolean;
}) => {
  return (
    <div>
      <LabelContainer>
        <LVLabel text={"Label"} />
        <LVLabel text={"Value"} />
      </LabelContainer>
      {dropdownOptions.map((option, index) => (
        <OptionsContainer key={`option.${index}`}>
          <InputContainer>
            <TextInput
              onChange={label =>
                setDropdownOption(
                  index,
                  label,
                  valueEditingDisabled ? option.value : label
                )
              }
              name={`label.${index}`}
              value={option.label}
              disabled={disabled}
            />
          </InputContainer>
          <ValueInputContainer>
            <TextInput
              name={`value.${index}`}
              disabled={!option.label || valueEditingDisabled || disabled}
              value={option.value}
              onChange={value => setDropdownOption(index, option.label, value)}
            />
          </ValueInputContainer>
          <ActionIconContainer
            hidden={dropdownOptions.length === 1 || disabled}
          >
            <Icon
              iconName="x"
              color="contentPrimary"
              size="18"
              onClick={() => removeDropdownOption(index)}
            />
          </ActionIconContainer>
        </OptionsContainer>
      ))}
    </div>
  );
};

export default LabelValueOptionsInput;
