import React, { useContext } from "react";
import Warnings from "../Warnings";
import { IssueBanner } from "../../Issues";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { AddressPanelTabProps } from "..";
import { Section, Wrapper } from "../__styles__/AddressPanel";
import { PropertyCarousel } from "./Carousel";
import Divider from "../../Common/Divider";
import { AddressPanelContext } from "../AddressPanelContext";
import { PropertyOverviewContextProvider } from "./PropertyOverviewContext";
import { PropertyOverviewSections } from "./PropertyOverviewSections";
import { EmptyState } from "../../Common/EmptyState";

export default ({ address, longitude, latitude }: AddressPanelTabProps) => {
  const { admin, authorized } = useContext(AuthContext);
  const { property, loadingProperty } = useContext(AddressPanelContext);

  const propertyId = property?.id ?? null;

  const hasRepetitiveLoss = !!property?.hasRepetitiveLoss;

  const canEditWarnings = authorized({
    resource: RESOURCE_NAME.WARNING,
    permission: "update",
  });

  return (
    <Wrapper>
      {propertyId || loadingProperty ? (
        <>
          {!loadingProperty && (
            <PropertyCarousel
              latitude={latitude}
              longitude={longitude}
              address={address}
              propertyId={propertyId}
              filterHidden={false}
            />
          )}
          <Section hasNoPaddingBottom>
            <Warnings
              address={address}
              propertyId={propertyId}
              loading={loadingProperty}
              publicOnly={false}
              canEditWarnings={canEditWarnings}
            />
          </Section>
          <Divider />
        </>
      ) : (
        <>
          <Section>
            <EmptyState compact title="This property is missing an address" />
          </Section>
          <Divider />
        </>
      )}
      <PropertyOverviewContextProvider
        latitude={latitude}
        longitude={longitude}
      >
        <PropertyOverviewSections shouldShowRepLoss={hasRepetitiveLoss} />
      </PropertyOverviewContextProvider>
      <IssueBanner
        propertyId={propertyId}
        address={address}
        coordinates={[longitude, latitude]}
        showEditButton={!!admin}
      />
    </Wrapper>
  );
};
