import React, { Fragment, useContext } from "react";
import {
  BuilderFieldButton,
  BuilderFieldCategoryName,
  BuilderFieldListItem,
  BuilderFieldsList,
} from "../__styles__/SubmissionsBuilder";
import {
  fieldCategoryToFieldTypesMap,
  fieldTypeToSchemaMap,
} from "../constants";
import { SubmissionsBuilderContextInstance } from "../context";
import { makeUniqueFieldName } from "../helpers";

const FieldsList = () => {
  const { appendFnsByFieldArrayPath, selectedField } = useContext(
    SubmissionsBuilderContextInstance
  );

  const fieldArrayPath =
    selectedField === null ? "inputs" : `${selectedField.fieldPath}.properties`;
  const append = appendFnsByFieldArrayPath[fieldArrayPath];

  if (!append) {
    return null;
  }

  return (
    <div>
      {Object.entries(fieldCategoryToFieldTypesMap).map(
        ([category, fieldTypes]) => (
          <Fragment key={category}>
            <BuilderFieldCategoryName>{category}</BuilderFieldCategoryName>
            <BuilderFieldsList>
              {fieldTypes.map(fieldType => (
                <BuilderFieldListItem key={fieldType.name}>
                  <BuilderFieldButton
                    onClick={() => {
                      append({
                        ...fieldTypeToSchemaMap[fieldType.name],
                        name: makeUniqueFieldName(),
                      });
                    }}
                    styleVariant="ghost"
                    leftIconName={fieldType.icon}
                    aria-label={`Add ${fieldType.label} field`}
                  >
                    {fieldType.label}
                  </BuilderFieldButton>
                </BuilderFieldListItem>
              ))}
            </BuilderFieldsList>
          </Fragment>
        )
      )}
    </div>
  );
};

export default FieldsList;
