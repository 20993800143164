import React from "react";
import LoginPage from "./LoginPage";
import { USER_LOGIN } from "./__queries__";
import { getEmailParamFromURL } from "../../utils/params";
import { Redirect } from "react-router";

const UserLoginPage = () => {
  const email = getEmailParamFromURL("provideCredentials");

  if (!email) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginPage
      loginMutation={USER_LOGIN}
      fallbackPath="/map"
      event="Logged in"
      initialTitle="Sign in to your account"
      passwordResetPath="/password-reset"
      startingEmail={email}
    />
  );
};

export default UserLoginPage;
