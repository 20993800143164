import React from "react";
import {
  AlertCircle,
  AlignJustify,
  ArrowLeftRight,
  ArrowRightSquare,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Download,
  LogOut,
  ClipboardCheck,
  ClipboardPen,
  Copy,
  Eye,
  EyeOff,
  FileText,
  Filter,
  FolderDown,
  HelpCircle,
  Home,
  Info,
  Layers,
  Library,
  Link,
  Loader2,
  LucideProps,
  Mail,
  MoreHorizontal,
  PanelsTopLeft,
  Plus,
  Settings,
  Trash,
  X,
  XCircle,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  TriangleAlert,
  GripHorizontal,
  Users,
  PencilRuler,
  SquareCheckBig,
  Hash,
  Calendar,
  Clock,
  Upload,
  MapPin,
  Group,
  ChevronUp,
  ArrowRightFromLine,
  CornerDownRight,
  Paperclip,
  Bold,
  Construction,
  Trash2,
  GripVertical,
  Pencil,
  Expand,
  FolderClosed,
  FolderCheck,
  FolderUp,
  CircleHelp,
  History,
  Image,
  IndentIncrease,
  IndentDecrease,
  Italic,
  LandPlot,
  Languages,
  SquareArrowUpRight,
  List,
  ListOrdered,
  LocateFixed,
  Earth,
  Ruler,
  Ellipsis,
  EllipsisVertical,
  Minus,
  PenTool,
  Pin,
  Printer,
  Redo2,
  Search,
  Sparkles,
  ArrowDownUp,
  TableProperties,
  UsersRound,
  Underline,
  Undo2,
  CircleUserRound,
  Waves,
  Tags,
  Component,
  FileImage,
  FileVideo,
  Circle,
  UserSearch,
  Globe,
  Check,
  File,
  CircleAlert,
  HandCoins,
  DollarSign,
  Mailbox,
  ShieldAlert,
  ShieldCheck,
  GanttChart,
} from "lucide-react";
import { colors } from "../../../stitches.config";

export const Icons = {
  "alert-circle": AlertCircle,
  "align-justify": AlignJustify,
  "arrow-down-up": ArrowDownUp,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-left-right": ArrowLeftRight,
  "arrow-right-square": ArrowRightSquare,
  "arrow-right-from-line": ArrowRightFromLine,
  "arrow-up": ArrowUp,
  "arrow-down": ArrowDown,
  bold: Bold,
  calendar: Calendar,
  "chart-gantt": GanttChart,
  check: Check,
  "check-circle": CheckCircle2,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  circle: Circle,
  "circle-alert": CircleAlert,
  "circle-help": CircleHelp,
  "circle-user-round": CircleUserRound,
  "clipboard-check": ClipboardCheck,
  "clipboard-pen": ClipboardPen,
  clock: Clock,
  construction: Construction,
  component: Component,
  copy: Copy,
  "corner-down-right": CornerDownRight,
  download: Download,
  "dollar-sign": DollarSign,
  earth: Earth,
  ellipsis: Ellipsis,
  "ellipsis-vertical": EllipsisVertical,
  "eye-off": EyeOff,
  eye: Eye,
  expand: Expand,
  file: File,
  "file-text": FileText,
  filter: Filter,
  "folder-check": FolderCheck,
  "folder-closed": FolderClosed,
  "folder-down": FolderDown,
  "folder-up": FolderUp,
  globe: Globe,
  "grip-horizontal": GripHorizontal,
  "grip-vertical": GripVertical,
  group: Group,
  hash: Hash,
  "hand-coins": HandCoins,
  "help-circle": HelpCircle, //deprecated same as circle-help
  history: History,
  home: Home, //this is the "house" icon
  image: Image,
  "indent-decrease": IndentDecrease,
  "indent-increase": IndentIncrease,
  info: Info,
  italic: Italic,
  "land-plot": LandPlot,
  languages: Languages,
  layers: Layers,
  library: Library,
  link: Link,
  list: List,
  "list-ordered": ListOrdered,
  loading: Loader2,
  "locate-fixed": LocateFixed,
  logout: LogOut,
  mail: Mail,
  mailbox: Mailbox,
  "map-pin": MapPin,
  minus: Minus,
  "more-horizontal": MoreHorizontal, //deprecated: same as ellipsis
  "panels-top-left": PanelsTopLeft,
  paperclip: Paperclip,
  pencil: Pencil,
  "pencil-ruler": PencilRuler,
  "pen-tool": PenTool,
  pin: Pin,
  plus: Plus,
  printer: Printer,
  redo: Redo2,
  ruler: Ruler,
  search: Search,
  settings: Settings,
  "shield-alert": ShieldAlert,
  "shield-check": ShieldCheck,
  sparkles: Sparkles,
  "square-arrow-up-right": SquareArrowUpRight,
  "square-check-big": SquareCheckBig,
  "table-properties": TableProperties,
  tags: Tags,
  trash: Trash, //deprecated
  "trash-2": Trash2,
  "triangle-alert": TriangleAlert,
  underline: Underline,
  undo: Undo2,
  upload: Upload,
  "user-search": UserSearch,
  users: Users, //deprecated
  "users-round": UsersRound,
  waves: Waves,
  x: X,
  "x-circle": XCircle,
  "file-image": FileImage,
  "file-video": FileVideo,
};

export type IconNames = keyof typeof Icons;

export const iconNames = Object.keys(Icons);

export interface IconProps extends Partial<LucideProps> {
  color: keyof typeof colors;
  iconName: keyof typeof Icons;
  testId?: string;
}

export const Icon = ({
  iconName,
  color,
  size,
  strokeWidth,
  cursor,
  onClick,
  testId,
  ...props
}: IconProps) => {
  const Icon = Icons[iconName];

  const stitchesColor = colors[color].value;

  return (
    <Icon
      color={stitchesColor}
      size={size}
      strokeWidth={strokeWidth}
      cursor={cursor}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "inherit" }}
      data-testid={testId}
      {...props}
    />
  );
};

export const IconButton = ({
  iconName,
  onClick,
  style,
  testId,
}: Omit<IconProps, "color">) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Icon
      testId={testId}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      iconName={iconName}
      color={hovered ? "contentPrimary" : "contentSecondary"}
      size={24}
      onClick={onClick}
      style={{ ...style, flexShrink: 0, cursor: "pointer" }}
    />
  );
};
