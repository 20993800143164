import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";

import Modal from "../../../../Common/Modal";
import { Firm, FirmSource } from "../../../../../generated/graphql";

import CreateFirmStage, { CreateFirmStageType } from "./stages/CreateFirmStage";
import UploadGeoJsonComponentsStage, {
  DefaultComponents,
  UploadGeoJsonComponentsStageType,
  UseDropzones,
} from "./stages/UploadGeoJsonComponentsStage";
import RevalidateEcsStage, {
  RevalidateEcsStageType,
} from "./stages/RevalidateEcsStage";
import { useFirmCreateImport } from "./hooks";

export type UseFirmImportModalProps = {
  existingFirm?: Pick<Firm, "id" | "name" | "source" | "type">;
  onScheduledFirmImport: () => void;
  useDropzones?: UseDropzones;
};

export const useFirmCreateImportModal = ({
  existingFirm,
  onScheduledFirmImport,
}: UseFirmImportModalProps) => {
  const [showFirmCreateImportModal, hideFirmCreateImportModal] = useModal(
    () => (
      <Modal onRequestClose={hideFirmCreateImportModal}>
        <FirmCreateImportForm
          existingFirm={existingFirm}
          onCancel={hideFirmCreateImportModal}
          onScheduledFirmImport={onScheduledFirmImport}
        ></FirmCreateImportForm>
      </Modal>
    )
  );

  return [showFirmCreateImportModal, hideFirmCreateImportModal] as const;
};

export enum FIRM_CREATE_IMPORT_STAGE {
  CREATE_FIRM,
  GEOJSON_COMPONENT_UPLOAD,
  REVALIDATE_ECS,
}

export type FirmCreateImportFormType = CreateFirmStageType &
  UploadGeoJsonComponentsStageType &
  RevalidateEcsStageType;

export type FirmCreateImportFormProps = UseFirmImportModalProps & {
  onCancel: () => void;
};

export const FirmCreateImportForm = ({
  existingFirm,
  onCancel,
  onScheduledFirmImport,
  useDropzones,
}: FirmCreateImportFormProps) => {
  const formMethods = useForm<FirmCreateImportFormType>({
    defaultValues: {
      name: existingFirm?.name,
      source: existingFirm?.source,
      type: existingFirm?.type,
      useForRegulation: false,
      revalidateEcs: false,
      components: DefaultComponents,
    },
  });

  const [formStage, setFormStage] = useState<FIRM_CREATE_IMPORT_STAGE>(
    existingFirm
      ? existingFirm.source === FirmSource.GEOJSON
        ? FIRM_CREATE_IMPORT_STAGE.GEOJSON_COMPONENT_UPLOAD
        : FIRM_CREATE_IMPORT_STAGE.REVALIDATE_ECS
      : FIRM_CREATE_IMPORT_STAGE.CREATE_FIRM
  );

  const {
    createFIRM,
    uploadFiles,
    scheduleFirmImport,
    s3FileUploadsLoading,
    scheduleFirmImportLoading,
  } = useFirmCreateImport({
    existingFirm,
    formMethods,
    onCancel,
    onScheduledFirmImport,
  });

  const handleCreateFirmAndScheduleImport = async ({
    name,
    type,
    source,
    useForRegulation,
    components,
    revalidateEcs,
    issueDate,
  }: FirmCreateImportFormType) => {
    if (existingFirm) {
      if (existingFirm.source === FirmSource.GEOJSON) {
        return await uploadFiles({
          variables: {
            componentNames: components!
              .filter(component => component.blob)
              .map(component => component.name),
          },
        });
      } else {
        return await scheduleFirmImport({
          variables: {
            data: {
              firmId: existingFirm.id,
              revalidateEcs: revalidateEcs,
              issueDate: issueDate ? new Date(issueDate) : undefined,
            },
          },
        });
      }
    } else {
      return await createFIRM({
        variables: {
          data: {
            name,
            type,
            source,
            useForRegulation,
          },
        },
      });
    }
  };

  const firmSource = formMethods.watch("source");

  return (
    <FormProvider {...formMethods}>
      {formStage === FIRM_CREATE_IMPORT_STAGE.CREATE_FIRM && (
        <CreateFirmStage
          onNext={
            firmSource === FirmSource.GEOJSON
              ? () =>
                  setFormStage(
                    FIRM_CREATE_IMPORT_STAGE.GEOJSON_COMPONENT_UPLOAD
                  )
              : () => setFormStage(FIRM_CREATE_IMPORT_STAGE.REVALIDATE_ECS)
          }
          onCancel={onCancel}
        />
      )}
      {formStage === FIRM_CREATE_IMPORT_STAGE.GEOJSON_COMPONENT_UPLOAD && (
        <UploadGeoJsonComponentsStage
          onCancel={onCancel}
          onBack={
            existingFirm
              ? undefined
              : () => setFormStage(FIRM_CREATE_IMPORT_STAGE.CREATE_FIRM)
          }
          onNext={() => setFormStage(FIRM_CREATE_IMPORT_STAGE.REVALIDATE_ECS)}
          useDropzones={useDropzones}
        />
      )}
      {formStage === FIRM_CREATE_IMPORT_STAGE.REVALIDATE_ECS && (
        <RevalidateEcsStage
          firmType={formMethods.watch("type")}
          loading={s3FileUploadsLoading || scheduleFirmImportLoading}
          onBack={
            firmSource === FirmSource.GEOJSON
              ? () =>
                  setFormStage(
                    FIRM_CREATE_IMPORT_STAGE.GEOJSON_COMPONENT_UPLOAD
                  )
              : existingFirm
              ? undefined
              : () => setFormStage(FIRM_CREATE_IMPORT_STAGE.CREATE_FIRM)
          }
          onCancel={onCancel}
          onImport={formMethods.handleSubmit(handleCreateFirmAndScheduleImport)}
        />
      )}
    </FormProvider>
  );
};
