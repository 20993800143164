import { CoreRow } from "@tanstack/react-table";
import React, { useContext } from "react";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import { formatDocumentUploadName } from "common-client/utils/documentUploads";
import { ExpandableCell } from "../ExpandableCell";
import { IconNames } from "../Icons/LucideIcons";

export const filesCell = ({
  row,
  isSubmission,
}: {
  row: CoreRow<any>["original"];
  isSubmission?: boolean;
}): Maybe<JSX.Element> => {
  const { authorized } = useContext(AuthContext);
  const canViewDocumentDetail = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "view_details",
  });

  const pills = row?.map(
    (f: { issuedAt: string; createdAt: string; type: string; id: string }) => {
      let href;

      if (canViewDocumentDetail) {
        href = buildLink(
          "documentUploadDetail",
          {
            id: f.id,
          },
          {}
        );
      } else {
        href = buildLink("documentUploadFile", { id: f.id }, {});
      }

      return {
        label: formatDocumentUploadName({
          isSubmission,
          createdAt: f.createdAt,
          issuedAt: f.issuedAt,
          accountDocumentTypeName: f.type,
        }),
        iconName: "file-text",
        href,
      };
    }
  );

  return <ExpandableCell pills={pills} />;
};

export const pillsCell = ({
  row,
  iconName,
}: {
  row: CoreRow<any>["original"];
  iconName?: IconNames;
}): Maybe<JSX.Element> => {
  const pills = row?.map((pill: string) => ({
    label: pill,
    iconName,
  }));

  return <ExpandableCell pills={pills} />;
};
