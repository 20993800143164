import React from "react";
import {
  GetActivitiesQuery,
  useDeleteActivityMutation,
  useDeleteCommentMutation,
} from "../../generated/graphql";
import { CommonModal } from "../Common/CommonModal";
import { Body } from "../Common/Typography";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { isCommentActivity } from "./types";

type DeleteActivityConfirmationProps = {
  activity: GetActivitiesQuery["activities"][number];
  closeModal: () => void;
  onUpdate: (deletedId?: string) => void;
};

export const DeleteActivityConfirmation = ({
  activity: activity,
  closeModal,
  onUpdate,
}: DeleteActivityConfirmationProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const onCompleted = () => {
    addSuccessToast("The activity was deleted");
    onUpdate(activity.id);
  };
  const onError = () => {
    addErrorToast(
      `There was an error deleting this activity, please try again. If the problem persists, email us at support@withforerunner.com`
    );
  };

  const [deleteComment, { loading: deletingComment }] =
    useDeleteCommentMutation({
      onCompleted,
      onError,
    });
  const [deleteActivity, { loading: deletingActivity }] =
    useDeleteActivityMutation({
      variables: {
        activityId: activity.id,
      },
      onCompleted,
      onError,
    });

  const handleSubmit = async () => {
    if (deletingComment || deletingActivity) return;

    if (isCommentActivity(activity)) {
      await deleteComment({
        variables: {
          id: activity.targetInfo.id,
        },
      });
    } else {
      await deleteActivity();
    }
  };

  return (
    <CommonModal
      height="short"
      dataTestId="delete-activity-confirmation"
      title={"Delete activity"}
      onSubmit={handleSubmit}
      primaryButtonText="Delete"
      autoFocusCancelButton={true}
      variant={"destructive"}
      onCancel={closeModal}
      loading={deletingComment}
      size="small"
      content={
        <Body size={"default"} type={"regular"}>
          Are you certain you would like to delete this activity?{" "}
          <Body size={"default"} type={"emphasis"} as={"span"}>
            This action is irreversible.
          </Body>
        </Body>
      }
    />
  );
};
