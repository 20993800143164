import { captureMessage } from "@sentry/browser";
import { buildLink } from "common/routing";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  AiAction,
  AiActionRunStatus,
  useGetAiActionRunLazyQuery,
  useRunSubmissionAiActionMutation,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { CommonModal } from "../Common/CommonModal";
import SelectionCardGroup from "../Inputs/SelectionCardGroup";

export type RunAIActionProps = {
  submission: {
    id: string;
    submissionTypeVersion: {
      submissionType: {
        aiActions?: Array<AiAction>;
      };
    };
  };
  prevLocation?: string;
  closeModal: () => void;
};

const AIActionList = ({
  aiActions,
  setSelectedAIAction,
}: {
  aiActions: Array<AiAction>;
  setSelectedAIAction: (aiAction: Maybe<AiAction>) => void;
}) => {
  return (
    <SelectionCardGroup
      orientation="vertical"
      options={aiActions.map(aiAction => ({
        id: aiAction.id,
        title: aiAction.name,
        description: aiAction.description,
        value: aiAction.id,
      }))}
      onChange={value =>
        setSelectedAIAction(
          aiActions.find(aiAction => aiAction.id === value) ?? null
        )
      }
    />
  );
};

const RunAIAction = ({ submission, closeModal }: RunAIActionProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [waitingForAiActionRun, setWaitingForAiActionRun] = useState(false);

  const history = useHistory();

  const [selectedAIAction, setSelectedAIAction] =
    useState<Maybe<AiAction>>(null);

  const [fetchAiActionRun, { stopPolling, loading: aiActionRunLoading }] =
    useGetAiActionRunLazyQuery({
      onCompleted: data => {
        const { results, status } = data.aiActionRun;

        if (status === AiActionRunStatus.FAILED) {
          addErrorToast(
            "There was an issue running the AI action. Please try again. If the problem persists, please email us at support@withforerunner.com"
          );
          stopPolling();
          closeModal();
          return;
        }

        if (status !== AiActionRunStatus.SUCCEEDED) {
          return;
        }

        setWaitingForAiActionRun(false);

        if (
          results[0]?.__typename ===
          "SubmissionAIActionDocumentGenerationRunResult"
        ) {
          addSuccessToast("AI action ran successfully.");
          history.push(
            buildLink("documentUploadDetail", {
              id: results[0].document.id,
            })
          );
        } else {
          captureMessage("Running AI action returned unexpected result");
          addErrorToast(
            "There was an issue running the AI action. Please try again. If the problem persists, please email us at support@withforerunner.com"
          );
        }

        stopPolling();
        closeModal();
      },
      onError: () => {
        stopPolling();
        addErrorToast(
          "There was an issue running the AI action. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
        closeModal();
      },
    });

  const [runAiAction, { loading: runAiActionLoading }] =
    useRunSubmissionAiActionMutation({
      variables: {
        data: {
          submissionId: submission.id,
          aiActionId: selectedAIAction?.id!,
        },
      },
      onCompleted: data => {
        const id = data.runSubmissionAIAction;
        setWaitingForAiActionRun(true);
        void fetchAiActionRun({ variables: { id }, pollInterval: 2000 });
      },
      onError: () => {
        closeModal();
        addErrorToast(
          "There was an issue running the AI action. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      },
    });

  return (
    <CommonModal
      title={"AI Actions"}
      subtitle={"Choose the AI Action you'd like to run"}
      content={
        <AIActionList
          aiActions={
            submission.submissionTypeVersion.submissionType.aiActions ?? []
          }
          setSelectedAIAction={setSelectedAIAction}
        />
      }
      onCancel={closeModal}
      disabled={!selectedAIAction}
      onSubmit={async () => {
        await runAiAction({
          variables: {
            data: {
              submissionId: submission.id,
              aiActionId: selectedAIAction!.id,
            },
          },
        });
      }}
      primaryButtonText={"Next"}
      // TODO: figure out why loading isn't showing a loading state
      loading={
        waitingForAiActionRun || aiActionRunLoading || runAiActionLoading
      }
    />
  );
};

export default RunAIAction;
