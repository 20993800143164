import React, { ReactNode, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SANITIZED_SUBMISSION_FORMATS } from "common/services/submissionHelpers";
import { difference, isEmpty, sortBy } from "lodash";

import {
  EC_EXPORT_FORMAT,
  QUERY_DESCRIPTION_EXPORT_FORMAT,
  SANITIZED_SAVED_VIEW_FORMATS,
  SUBMISSION_EXPORT_FORMAT,
} from "common/constants";

import SelectionCardGroup from "../Inputs/SelectionCardGroup";
import { Body } from "../Common/Typography";
import { Checkbox, Select } from "../Inputs/react-hook-form";
import { CommonModal, ModalStep, MultiStepModal } from "../Common/CommonModal";
import { DateRange, Label } from "../Inputs";
import { spacing } from "../../stitches.config";
import {
  AccountFirMsQuery,
  SavedViewExportFormat,
  SubmissionType,
  useAccountFirMsQuery,
  useExportEcsMutation,
  useExportQueryDescriptionMutation,
  useExportSavedViewMutation,
  useExportSubmissionsMutation,
} from "../../generated/graphql";
import Banner from "../Common/Banner";

import { FlexColumn } from "../Common/__styles__/Layout";
import { Emphasis } from "../Common/__styles__/Typography";
import {
  ClientSideConfigEntry,
  isZipExportEnabled,
} from "common/utils/queryBuilder";
import { decorateSubmissionTypeNameWithIntakeSource } from "common/utils/submissions";
import { FORM_TYPES, INITIAL_SUBMISSION_TYPE_REPORTS } from "./constants";
import { Account } from "../Authorization/types";
import { makeGenericInspectionQuery } from "./utils";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { SavedView } from "../Common/FullWidthTable/TableSettings/utils";

export type ReportType = {
  name: string;
  formType: FORM_TYPES;
  successMessage: ({
    format,
    savedViewName,
  }: {
    format?: string;
    savedViewName?: string;
  }) => string;
  exportFormats: Array<{
    name: string;
    format: string;
  }>;
  submissionTypeId?: string;
};

export const buildInitialReportTypes = ({
  initialReportTypes = [],
  submissionTypeNames = [],
  account,
}: {
  initialReportTypes?: Array<ReportType>;
  submissionTypeNames?: Array<INITIAL_SUBMISSION_TYPE_REPORTS>;
  account?: Maybe<Account>;
}) => {
  const reportTypes = [...initialReportTypes];

  if (!isEmpty(submissionTypeNames)) {
    submissionTypeNames.forEach(submissionTypeName => {
      const submissionType = account?.submissionTypes.find(
        submissionType => submissionTypeName === submissionType.name
      );

      if (submissionType) {
        reportTypes.push(buildReportTypeForSubmissionType(submissionType));
      }
    });
  }

  return sortBy(reportTypes, "name");
};

export const buildReportTypeForSubmissionType = (
  submissionType: Pick<
    SubmissionType,
    "id" | "name" | "modules" | "intakeSource"
  >
): ReportType => {
  return {
    name: decorateSubmissionTypeNameWithIntakeSource({
      submissionTypeName: submissionType.name,
      modules: submissionType.modules,
      intakeSource: submissionType.intakeSource,
    }),
    exportFormats: [
      {
        name: "Spreadsheet (.csv)",
        format: SUBMISSION_EXPORT_FORMAT.CSV,
      },
      {
        name: "Geopackage (.gpkg)",
        format: SUBMISSION_EXPORT_FORMAT.GEOPACKAGE,
      },
      {
        name: "Shapefile (.shp)",
        format: SUBMISSION_EXPORT_FORMAT.SHAPEFILE,
      },
    ],
    submissionTypeId: submissionType.id,
    formType: FORM_TYPES.SUBMISSION,
    successMessage: ({ format }: { format?: string }) =>
      `Your ${submissionType.name} ${format} export is currently processing. We'll email you a link to download your file.`,
  };
};

const buildReportTypes = ({
  currentView,
  columnConfig,
}: {
  currentView?: Maybe<SavedView>;
  columnConfig?: Array<ClientSideConfigEntry>;
}): Array<ReportType> => {
  let reportTypes: Array<ReportType> = [];

  if (currentView) {
    let exportFormats = [
      {
        name: "Geopackage (.gpkg)",
        format: QUERY_DESCRIPTION_EXPORT_FORMAT.GPKG,
      },
      {
        name: "Spreadsheet (.csv)",
        format: QUERY_DESCRIPTION_EXPORT_FORMAT.CSV,
      },
    ];

    if (
      isZipExportEnabled({
        query: currentView.query,
        columnConfig,
      })
    ) {
      exportFormats.push({
        name: "Zip (.zip)",
        format: QUERY_DESCRIPTION_EXPORT_FORMAT.ZIP,
      });
    }

    reportTypes.push({
      name: "Current saved view",
      exportFormats,
      formType: FORM_TYPES.SAVED_VIEW,
      successMessage: ({
        savedViewName,
        format,
      }: {
        savedViewName?: string;
        format?: string;
      }) =>
        `Your ${savedViewName} ${format} export is currently processing. Once your data is ready, we'll email you a link to download your file.`,
    });
  }

  return reportTypes;
};

const ExportFormatDsiclaimer = ({ children }: { children: ReactNode }) => {
  return <Banner>{children}</Banner>;
};

type ExportDocumentsFormStructure = {
  format: string;
  dateRange: {
    startDate: Maybe<Date>;
    endDate: Maybe<Date>;
  };
  finishedConstructionOnly: boolean;
  firmId: Maybe<string>;
};

export const ExportDataForm = ({
  closeModal,
  firms,
  currentView,
  columnConfig,
  initialReportTypes = [],
}: {
  closeModal?: () => void;
  firms?: NonNullable<AccountFirMsQuery["account"]>["firms"];
  currentView?: Maybe<SavedView>;
  columnConfig?: Array<ClientSideConfigEntry>;
  initialReportTypes?: Array<ReportType>;
}) => {
  const [reportType, setReportType] = useState<ReportType | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<Maybe<string>>(null);

  const { data: dataFIRMs, loading: loadingFIRMs } = useAccountFirMsQuery({
    skip: !!firms,
  });

  const { control, handleSubmit, setValue, watch } =
    useForm<ExportDocumentsFormStructure>({
      defaultValues: {
        format: reportType?.exportFormats[0]?.format,
        dateRange: { startDate: null, endDate: null },
        finishedConstructionOnly: false,
        firmId: null,
      },
    });

  const [exportECs, { loading: loadingECsExport }] = useExportEcsMutation({
    onCompleted: data => {
      if (data.exportECs && reportType) {
        setSuccessMessage(reportType.successMessage({}));
      } else {
        setError("No certificates were found matching your selection.");
      }
    },
    onError: () => {
      setError("An error occurred. Please try again.");
    },
  });

  const [exportSubmissions, { loading: loadingSubmissionsExport }] =
    useExportSubmissionsMutation({
      onCompleted: data => {
        if (data.exportSubmissions && reportType) {
          setSuccessMessage(
            reportType.successMessage({
              format:
                SANITIZED_SUBMISSION_FORMATS[
                  watch("format") as SUBMISSION_EXPORT_FORMAT
                ],
            })
          );
        } else {
          setError("No submissions were found matching your selection.");
        }
      },
      onError: () => {
        setError("An error occurred. Please try again.");
      },
    });

  const [exportSavedView, { loading: loadingSavedViewExport }] =
    useExportSavedViewMutation({
      onCompleted: () => {
        setSuccessMessage(
          reportType!.successMessage({
            savedViewName: currentView!.name,
            format:
              SANITIZED_SAVED_VIEW_FORMATS[
                watch("format") as QUERY_DESCRIPTION_EXPORT_FORMAT
              ],
          })
        );
      },
      onError: () => {
        setError("An error occurred. Please try again.");
      },
    });

  const [exportQueryDescription, { loading: loadingQueryDescriptionExport }] =
    useExportQueryDescriptionMutation({
      onCompleted: () => {
        setSuccessMessage(
          reportType!.successMessage({
            format:
              SANITIZED_SAVED_VIEW_FORMATS[
                watch("format") as QUERY_DESCRIPTION_EXPORT_FORMAT
              ],
          })
        );
      },
      onError: () => {
        setError("An error occurred. Please try again.");
      },
    });

  useEffect(() => {
    // clear out inputs for those fields that don't apply
    // to the selected export type
    if (reportType?.formType !== FORM_TYPES.EC) {
      setValue("finishedConstructionOnly", false);
      setValue("firmId", null);
    }
    if (reportType?.formType === FORM_TYPES.SDE) {
      setValue("dateRange", { startDate: null, endDate: null });
    }
  }, [reportType]);

  if (loadingFIRMs) {
    return <div>Loading...</div>;
  }

  firms = firms ?? dataFIRMs?.account?.firms;

  if (!firms) {
    return null;
  }

  if (successMessage) {
    return (
      <CommonModal
        title="Export data"
        content={
          <Body type="regular" size="default">
            {successMessage}
          </Body>
        }
        onCancel={closeModal!}
        size="medium"
      />
    );
  }

  const reportTypes = initialReportTypes.concat(
    buildReportTypes({
      currentView,
      columnConfig,
    })
  );

  const onSubmit = handleSubmit(data => {
    if (!reportType) {
      return;
    }

    if ([FORM_TYPES.EC, FORM_TYPES.SDE].includes(reportType.formType)) {
      void exportECs({
        variables: {
          data: {
            ...data.dateRange,
            firmId: data.firmId,
            format: data.format as EC_EXPORT_FORMAT,
            finishedConstructionOnly: data.finishedConstructionOnly,
          },
        },
      });
    } else if (reportType.formType === FORM_TYPES.SUBMISSION) {
      void exportSubmissions({
        variables: {
          data: {
            startDate: data.dateRange.startDate,
            endDate: data.dateRange.endDate,
            submissionTypeId: reportType.submissionTypeId!,
            format: data.format as SUBMISSION_EXPORT_FORMAT,
          },
        },
      });
    } else if (reportType.formType === FORM_TYPES.SAVED_VIEW) {
      void exportSavedView({
        variables: {
          data: {
            id: currentView?.id!,
            format: data.format as SavedViewExportFormat,
          },
        },
      });
    } else if (reportType.formType === FORM_TYPES.QUERY_DESCRIPTION) {
      const query = makeGenericInspectionQuery({
        reportType,
        clientConfig: columnConfig!,
      });

      void exportQueryDescription({
        variables: {
          data: {
            queryDescription: query,
            nameOfExport: reportType.name,
            format: data.format as QUERY_DESCRIPTION_EXPORT_FORMAT,
          },
        },
      });
    }
  });

  const steps: Array<ModalStep> = [
    {
      title: "Export data",
      content: ({ handleNext }) => {
        const onChange = (value: string) => {
          const reportType = reportTypes.find(
            reportType => reportType.name === value
          );
          reportType && setReportType(reportType);
          handleNext && handleNext();
          setValue("format", reportType?.exportFormats[0]?.format!);
        };

        const savedViewReportTypes = reportTypes.filter(
          reportType => reportType.formType === FORM_TYPES.SAVED_VIEW
        );

        const remainingReportTypes = difference(
          reportTypes,
          savedViewReportTypes
        );

        return (
          <FlexColumn css={{ gap: spacing.m }}>
            {currentView && (
              <SelectionCardGroup
                options={savedViewReportTypes.map((reportType, index) => ({
                  value: reportType.name,
                  title: reportType.name,
                  id: index.toString(),
                  iconName: "file-text",
                }))}
                onChange={onChange}
                data-orientation="vertical"
                spacingStyle="compact"
                borderStyle={"ghost"}
                cardTitleProps={{
                  type: "regular",
                  size: "small",
                  style: { marginTop: `${spacing["2xs"]}` },
                }}
              />
            )}

            {arrayHasAtLeastOneItem(remainingReportTypes) && (
              <FlexColumn css={{ gap: spacing.s }}>
                <Body size="small" type="emphasis">
                  Reports
                </Body>
                <SelectionCardGroup
                  options={remainingReportTypes.map((reportType, index) => ({
                    value: reportType.name,
                    title: reportType.name,
                    id: index.toString(),
                    iconName: "file-text",
                  }))}
                  onChange={onChange}
                  data-orientation="vertical"
                  spacingStyle="compact"
                  borderStyle={"ghost"}
                  cardTitleProps={{
                    type: "regular",
                    size: "small",
                    style: { marginTop: `${spacing["2xs"]}` },
                  }}
                />
              </FlexColumn>
            )}
          </FlexColumn>
        );
      },
      overflows: false,
    },
    {
      title: reportType?.name!,
      content: () => {
        if (!reportType) {
          return null;
        }

        const { formType, exportFormats } = reportType;

        const isECExport = formType === "EC";
        const isSDEExport = formType === "SDE";
        const isSavedViewExport = formType === "SAVED_VIEW";
        const isQueryDescriptionExport = formType === "QUERY_DESCRIPTION";

        const shouldShowGPKGDisclaimer = [
          SUBMISSION_EXPORT_FORMAT.GEOPACKAGE,
          QUERY_DESCRIPTION_EXPORT_FORMAT.GPKG,
        ].includes(
          watch("format") as
            | SUBMISSION_EXPORT_FORMAT
            | QUERY_DESCRIPTION_EXPORT_FORMAT
        );

        const shouldShowZipDisclaimer = [
          EC_EXPORT_FORMAT.ZIP,
          QUERY_DESCRIPTION_EXPORT_FORMAT.ZIP,
        ].includes(
          watch("format") as EC_EXPORT_FORMAT | QUERY_DESCRIPTION_EXPORT_FORMAT
        );

        const formFields = [
          <FlexColumn css={{ gap: spacing.s }}>
            <Select
              label="File type"
              control={control}
              name="format"
              options={exportFormats.map(format => {
                return {
                  label: format.name!,
                  value: format.format!,
                };
              })}
              autoFocus={true}
              onChange={() => {
                setError(null);
              }}
              disabled={exportFormats.length === 1}
            />
            {shouldShowGPKGDisclaimer && (
              <ExportFormatDsiclaimer>
                <Emphasis>
                  Geopackage exports will only contain geocoded data.
                </Emphasis>{" "}
                Any processing files or files that failed to geocode will not be
                included.
              </ExportFormatDsiclaimer>
            )}

            {shouldShowZipDisclaimer && (
              <ExportFormatDsiclaimer>
                Zip exports only contain processed files. Any processing files
                will not be included.
              </ExportFormatDsiclaimer>
            )}
          </FlexColumn>,
        ];

        if (isECExport) {
          const sfhaOptions = firms.map(firm => {
            return { value: firm.id, label: `${firm.name} SFHA` };
          });

          formFields.push(
            <Select
              label="FIRM location"
              control={control}
              name="firmId"
              options={[{ value: null, label: "Anywhere" }, ...sfhaOptions]}
              disabled={!isECExport}
            />,
            <Checkbox
              name="finishedConstructionOnly"
              control={control}
              disabled={!isECExport}
              label="Finished construction only"
            />
          );
        }

        if (!isSDEExport && !isSavedViewExport && !isQueryDescriptionExport) {
          formFields.push(
            <FlexColumn>
              <Controller
                control={control}
                name="dateRange"
                render={({ field }) => (
                  <>
                    <Label text="Date Range" />
                    <DateRange
                      onChange={field.onChange}
                      value={field.value}
                      disabled={isSDEExport}
                    />
                  </>
                )}
              />
              <Body
                type="regular"
                size="small"
                style={{ marginTop: `${spacing.s}` }}
              >
                Note: For Elevation Certificates, the date range is based on the
                certificate's issue date. For other types, the date range is
                based on when the data was created on the platform.
              </Body>
            </FlexColumn>
          );
        }

        return (
          <FlexColumn css={{ gap: spacing.m }}>
            {formFields.map((field, index) => (
              <div key={index}>{field}</div>
            ))}
            {error && (
              <Body type="regular" size="default" color="contentCritical">
                {error}
              </Body>
            )}
          </FlexColumn>
        );
      },
      overflows: true,
      disabled:
        loadingECsExport ||
        loadingSubmissionsExport ||
        loadingSavedViewExport ||
        loadingQueryDescriptionExport,
      primaryButtonText: "Export",
    },
  ];

  return (
    <MultiStepModal
      size="medium"
      onSubmit={onSubmit}
      onCancel={closeModal!}
      steps={steps}
    />
  );
};
