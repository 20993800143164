import React, { useContext } from "react";
import { AuthContext } from "../../../Authorization/AuthContext";
import { TooltipContainer } from "../__styles__/";
import { MarkdownTooltip } from "../../../Common/Tooltip";

export const PropertyOverviewTooltip = ({
  tooltipMarkdown,
  isGuestOnly = true,
}: {
  tooltipMarkdown?: Maybe<string>;
  isGuestOnly?: boolean;
}) => {
  const { isGuest } = useContext(AuthContext);
  if (!tooltipMarkdown || (!isGuest && isGuestOnly)) {
    return <></>;
  }

  return (
    <TooltipContainer data-testid="tooltip">
      <MarkdownTooltip tooltipText={tooltipMarkdown} place="right" />
    </TooltipContainer>
  );
};
