import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";
import PropertyOptionsButton from "./PropertyOptionsButton";
import { buildLink } from "common/routing";
import { getCurrentURL } from "../../../utils/url";
import { AddressPanelProperty } from "../types";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { useDeletePropertyModal } from "./DeletePropertyModal";
import { track } from "../../../utils/tracking";
import { HeaderParcel } from "./types";
import { useHidePropertyModal } from "./HidePropertyModal";
import { LayerContext } from "../../Maps/layers";
import { CreatePropertyParamsInput } from "../../Property/CreateProperty";

export interface PropertyOptionsProps {
  latitude: number;
  longitude: number;
  parcel: HeaderParcel;
  property?: Maybe<AddressPanelProperty>;
}

const PropertyOptions = ({
  latitude,
  longitude,
  parcel,
  property,
}: PropertyOptionsProps) => {
  const location = useLocation();
  const history = useHistory();
  const { authorized, account } = useContext(AuthContext);
  const { updateMap } = useContext(LayerContext);

  const createQueryParams = {
    latitude: latitude,
    longitude: longitude,
    zoom: 18,
    ...(parcel?.id ? { parcelId: parcel.id } : {}),
  } satisfies CreatePropertyParamsInput;

  const createPropertyURL = {
    pathname: buildLink("createProperty"),
    search: `?${queryString.stringify(createQueryParams)}`,
    state: {
      prevLocation: getCurrentURL(location),
    },
  };

  const canCreateProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "create",
  });

  const canUpdateProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "update",
  });

  const canDeleteProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "delete",
  });

  const canUpdatePropertyHiddenFromPublic =
    authorized({
      resource: RESOURCE_NAME.PROPERTY,
      permission: "hide",
    }) && account?.publicPortal.enabled;

  const [showDeletePropertyModal, hideDeletePropertyModal] =
    useDeletePropertyModal({
      updateMap,
      closeModal: () => {
        hideDeletePropertyModal();
      },
      property: property!,
      onDelete: () => {
        history.push(buildLink("map"));
      },
    });

  const [showHidePropertyModal, closeHidePropertyModal] = useHidePropertyModal({
    onCancel: () => {
      closeHidePropertyModal();
    },
    onSubmit: () => {
      closeHidePropertyModal();
    },
    property: property!,
  });

  if (!property)
    return (
      <PropertyOptionsButton
        onClick={() => {
          history.push(createPropertyURL);
        }}
        disabled={!canCreateProperty}
        showCaret={false}
      />
    );

  const hidePropertyToggleLabel = property.hiddenFromPublic
    ? "Display property to public"
    : "Hide property from public";

  const actions: Array<ActionsProps> = [
    {
      label: "Edit property details",
      to: {
        pathname: buildLink("editProperty", {
          propertyId: property.id,
        }),
        state: {
          prevLocation: getCurrentURL(location),
        },
      },
      disabled: !canCreateProperty,
    },
    {
      label: "Add new property",
      to: createPropertyURL,
      disabled: !canCreateProperty,
    },
  ];

  if (canUpdatePropertyHiddenFromPublic) {
    actions.push({
      label: hidePropertyToggleLabel,
      onClick: () => {
        track(`${hidePropertyToggleLabel} clicked`, {
          streetAddress: property.streetAddress,
          city: property.city,
        });
        showHidePropertyModal();
      },
      disabled: !canUpdatePropertyHiddenFromPublic,
      variant: "red",
    });
  }

  actions.push({
    label: "Delete property",
    onClick: () => {
      track("Delete property clicked", {
        streetAddress: property.streetAddress,
        city: property.city,
      });
      showDeletePropertyModal();
    },
    disabled: !canDeleteProperty,
    variant: "red",
  });

  return (
    <DropdownMenu
      customButton={PropertyOptionsButton}
      disabled={!canCreateProperty && !canUpdateProperty}
      position={"below-right"}
      actions={actions}
    />
  );
};

export default PropertyOptions;
