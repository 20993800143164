import React, { ReactNode, useEffect } from "react";

import HeaderNav from "./HeaderNav";
import { Button } from "../Common/Button";

import { Container, FormContainer } from "./__styles__/FullPageFormLayout";
import { useIsKeyboardOpen } from "../../hooks/useWindowSize";

export interface FullPageFormLayoutProps {
  subtitle: string;
  prevLocation: { pathname: string; search?: string } | string;
  children: ReactNode;
  onSave?: () => void;
  buttonText?: string;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  centered?: boolean;
  overflows?: boolean;
  rightContainer?: JSX.Element;
  middleContainer?: JSX.Element;
  width?: "regular" | "wide" | "auto";
}

export default ({
  subtitle,
  prevLocation,
  children,
  onSave,
  buttonText = "Save",
  buttonDisabled = false,
  buttonLoading = false,
  centered = true,
  overflows = false,
  width = "regular",
  rightContainer,
  middleContainer,
}: FullPageFormLayoutProps) => {
  const isKeyboardOpen = useIsKeyboardOpen();

  if (!rightContainer && onSave !== undefined) {
    rightContainer = (
      <Button
        size="small"
        styleVariant="primary"
        onClick={onSave}
        disabled={buttonDisabled}
        loading={buttonLoading}
      >
        {buttonText}
      </Button>
    );
  }

  useEffect(() => {
    if (isKeyboardOpen) {
      document.activeElement?.scrollIntoView();
    }
  }, [isKeyboardOpen]);

  return (
    <Container centered={centered} overflows={overflows} hasHeaderNav>
      <HeaderNav
        subtitle={subtitle}
        prevLocation={prevLocation}
        rightContainer={rightContainer}
        middleContainer={middleContainer}
      />
      <FormContainer centered={centered} width={width}>
        {children}
      </FormContainer>
    </Container>
  );
};
