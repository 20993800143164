import React, { useContext, useEffect, useRef } from "react";
import { get, isNil } from "lodash";
import { cloneDeep } from "lodash";
import { FieldProps } from "@rjsf/utils";
import { FileRejection } from "react-dropzone";
import { ErrorCode } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { SubmissionFormContext } from "../Form";
import { AuthContext } from "../../Authorization/AuthContext";
import { MAX_SIZE_PER_MIME_TYPE_IN_MB } from "common/utils/documentUploads";
import {
  CertificateUploadStatus,
  CancelationReason,
  useGeneratePresignedUrlMutation,
  useValidateScratchDocumentUploadMutation,
} from "../../../generated/graphql";
import Wrapper from "../../Inputs/Wrapper";
import { CANCELATION_REASON_TEXT } from "../../DocumentUploads/Settings";
import { DropzoneComponent } from "../../FileUploads";
import {
  BrowseStyling,
  DropzonePositioner,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableData,
  IconPositioner,
  AdditionalInfo,
  TagWrapper,
  FileNameLinkWrapper,
  OverflowText,
} from "./__styles__/DocumentUploadField";
import {
  Block,
  Info,
} from "../../DocumentUploads/__styles__/UploadDocumentsForm";
import { EmptyFileContainer } from "../../FileUploads/__styles__/FileUploads";
import { Input } from "../__styles__/Inputs";
import { MIME_TYPE } from "common/constants";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Tag } from "../../Common/Tag";
import { FlexRow } from "../../Common/__styles__/Layout";
import { DynamicOverflowWrapper } from "../../Common/DynamicOverflowWrapper";
import { ViewDocumentLink } from "../../AddressPanel/DocumentUploads/ViewDocumentLink";
import {
  Icon,
  IconNames,
  Icon as LucideIcon,
} from "../../Common/Icons/LucideIcons";
import { colors } from "common-client/utils/styling";
import { EmptyState } from "../../Common/EmptyState";
import { ScreenOnly } from "../../Common/__styles__/ScreenOnly";

type DocumentUploadStatus =
  | CertificateUploadStatus
  | "valid"
  | "invalid"
  | "loading";

export interface DocumentUpload {
  id: Maybe<string>;
  scratchId?: string;
  originalFilename?: string;
  mimetype?: MIME_TYPE;
  status?: "valid" | "invalid" | "loading";
  hiddenFromPublic?: boolean;
}

const getIconProps = ({
  documentStatus,
  formDataValue,
}: {
  documentStatus?: Maybe<DocumentUploadStatus>;
  formDataValue: DocumentUpload;
}): {
  iconName: IconNames;
  color: keyof typeof colors;
  "aria-labelledby"?: string;
} => {
  if (isNil(formDataValue.id)) {
    switch (documentStatus) {
      case "valid":
        return {
          iconName: "check-circle",
          color: "contentSuccess",
          "aria-labelledby": "checkCircle",
        };
      case "invalid":
        return {
          iconName: "x-circle",
          color: "contentCritical",
          "aria-labelledby": "xCircle",
        };
      case "loading":
        return {
          iconName: "loading",
          color: "contentPlaceholder",
          "aria-labelledby": "loading",
        };
      default:
        break;
    }
  }

  return documentStatus === CertificateUploadStatus.CANCELED ||
    documentStatus === CertificateUploadStatus.PROCESSING
    ? {
        iconName: "info",
        color: "contentAttention",
        "aria-labelledby": "info",
      }
    : {
        iconName: "check-circle",
        color: "contentSuccess",
        "aria-labelledby": "checkCircle",
      };
};
const FileNameCell = ({
  fileName,
  status,
  cancelationReason,
  documentUploadId,
  hiddenFromPublic,
}: {
  fileName: string;
  status?: DocumentUploadStatus;
  cancelationReason?: Maybe<CancelationReason>;
  documentUploadId?: Maybe<string>;
  hiddenFromPublic?: boolean;
}) => {
  const { account, isGuest } = useContext(AuthContext);

  const isProcessing = status === CertificateUploadStatus.PROCESSING;
  const reasonText = CANCELATION_REASON_TEXT[cancelationReason ?? "default"];

  const fileNameLink =
    documentUploadId && !isProcessing ? (
      <DynamicOverflowWrapper
        Link={ViewDocumentLink}
        linkProps={{
          documentUploadId,
          isDocumentUploadField: true,
        }}
        text={fileName}
      />
    ) : (
      <OverflowText>{fileName}</OverflowText>
    );

  return (
    <TableData>
      <>
        <FlexRow>
          <FileNameLinkWrapper>{fileNameLink}</FileNameLinkWrapper>
          {!isNil(hiddenFromPublic) &&
            !isGuest &&
            status !== "loading" &&
            account?.publicPortal.enabled && (
              <ScreenOnly>
                <TagWrapper>
                  <Tag styleVariant="neutral">
                    {hiddenFromPublic
                      ? "Hidden on public website"
                      : "Displayed on public website"}
                  </Tag>
                </TagWrapper>
              </ScreenOnly>
            )}
        </FlexRow>
        {(status === CertificateUploadStatus.CANCELED ||
          status === CertificateUploadStatus.PROCESSING) && (
          <AdditionalInfo>
            {status === CertificateUploadStatus.CANCELED
              ? reasonText
              : "File processing..."}
          </AdditionalInfo>
        )}
      </>
    </TableData>
  );
};

const ExistingDocuments = ({
  documentUploadValues,
  setDocumentUploadValues,
  onChange,
  disabled,
  emptyFilesMessage,
}: {
  documentUploadValues: Array<DocumentUpload>;
  setDocumentUploadValues: (args: Array<DocumentUpload> | undefined) => void;
  onChange: any;
  disabled: boolean;
  emptyFilesMessage?: string;
}) => {
  const { isGuest } = useContext(AuthContext);
  const { documentUploads } = useContext(SubmissionFormContext);

  const documentUploadValuesToDisplay = isGuest
    ? documentUploadValues.filter(documentUpload => {
        const existingDocumentUpload = documentUploads?.find(
          existingDocumentUpload =>
            existingDocumentUpload.id === documentUpload.id
        );
        return !(existingDocumentUpload?.hiddenFromPublic ?? false);
      })
    : documentUploadValues;

  if (!documentUploadValuesToDisplay.length) {
    return disabled ? (
      <EmptyState
        compact={true}
        title={emptyFilesMessage || "No files uploaded"}
      />
    ) : null;
  }

  return (
    <>
      <Table css={{ marginTop: "15px" }}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell css={{ "@media print": { display: "none" } }} />
            <TableHeaderCell alignLeft={true}>File name</TableHeaderCell>
            {!disabled && (
              <TableHeaderCell css={{ "@media print": { display: "none" } }}>
                Remove
              </TableHeaderCell>
            )}
          </TableRow>
        </TableHeader>
        <tbody>
          {documentUploadValuesToDisplay.map((formDataValue, index) => {
            const existingDocumentUpload = documentUploads?.find(
              documentUpload => formDataValue.id === documentUpload.id
            );

            const status =
              existingDocumentUpload?.certificateUpload?.status ??
              formDataValue.status;
            const iconProps = getIconProps({
              documentStatus: status,
              formDataValue,
            });

            return (
              <TableRow key={index}>
                <TableData
                  css={{ width: "35px", "@media print": { display: "none" } }}
                >
                  <IconPositioner loading={status === "loading"} status>
                    <LucideIcon
                      size={16}
                      testId="document-status-icon"
                      {...iconProps}
                    />
                  </IconPositioner>
                </TableData>
                <FileNameCell
                  status={status}
                  cancelationReason={
                    existingDocumentUpload?.certificateUpload?.cancelationReason
                  }
                  fileName={
                    (existingDocumentUpload?.originalFilename ??
                      formDataValue.originalFilename)!
                  }
                  documentUploadId={existingDocumentUpload?.id}
                  hiddenFromPublic={
                    existingDocumentUpload?.hiddenFromPublic ??
                    formDataValue.hiddenFromPublic
                  }
                />
                <TableData
                  css={{
                    height: "16px",
                    width: "16px",
                    textAlign: "right",
                  }}
                  printOnly
                >
                  {status !== CertificateUploadStatus.PROCESSING &&
                    !disabled && (
                      <IconPositioner remove>
                        <Icon
                          iconName="x-circle"
                          color="contentSecondary"
                          size={16}
                          testId="remove-document"
                          onClick={() => {
                            let modifiedUploads:
                              | Array<DocumentUpload>
                              | undefined = cloneDeep(documentUploadValues);
                            modifiedUploads.splice(index, 1);

                            if (modifiedUploads.length === 0) {
                              modifiedUploads = undefined;
                            }

                            setDocumentUploadValues(modifiedUploads);
                            onChange(modifiedUploads);
                          }}
                        />
                      </IconPositioner>
                    )}
                </TableData>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const Information = (props: FieldProps) => {
  return (
    <Info>
      <Block icon={"limit"}>{props.uiSchema!["ui:fileTypeDescription"]}</Block>
      <Block icon={"pages"}>
        {props.uiSchema!["ui:fileQuantityDescription"]}
      </Block>
      <Block icon={"notify"}>{props.uiSchema!["ui:fileSizeDescription"]}</Block>
    </Info>
  );
};

export const DocumentUploadInput = (props: FieldProps) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast } = useStatusToasts();
  useEffect(() => {
    const strippedFormData =
      props.formData?.filter((obj: Object) => Object.keys(obj).length !== 0) ??
      [];
    props.onChange(strippedFormData);
  }, [props.formData]);

  let existingFormData = props.formData?.filter(
    (value: DocumentUpload | Object) => Object.keys(value).length !== 0
  );

  if (!existingFormData?.length) {
    existingFormData = undefined;
  }

  const [documentUploadValues, setDocumentUploadValues] = React.useState<
    Array<DocumentUpload & { clientKey?: string }> | undefined
  >(existingFormData);
  const documentUploadValuesRef = useRef(documentUploadValues);

  useEffect(() => {
    documentUploadValuesRef.current = documentUploadValues;
  }, [documentUploadValues]);

  const [generatePresignedUrl] = useGeneratePresignedUrlMutation({});
  const [validateScratchDocumentUpload] =
    useValidateScratchDocumentUploadMutation();

  const DropzoneContent = () => (
    <>
      <h5>Drop your {props.uiSchema!["ui:documentTypeName"]} here</h5>
      <p>
        or <BrowseStyling>browse</BrowseStyling> to choose an{" "}
        {props.uiSchema!["ui:documentTypeAbbreviation"]}
      </p>
    </>
  );

  const accountDocumentTypeId = get(
    props.schema,
    "items.properties.accountDocumentTypeId.enum",
    []
  )[0];
  const accountDocumentType = account?.accountDocumentTypes.find(
    documentType => accountDocumentTypeId === documentType.id
  );

  const maxFiles = props.schema.maxItems;

  async function onDrop(files: Array<File & { clientKey?: string }>) {
    if (
      maxFiles &&
      files.length + (documentUploadValues?.length ?? 0) > maxFiles
    ) {
      const fileText = maxFiles === 1 ? "file" : "files";
      addErrorToast(`Only ${maxFiles} ${fileText} can be uploaded at a time.`);
    }

    files.forEach(file => {
      file.clientKey = uuidv4();
    });

    const newFormValue = [
      ...(documentUploadValues ?? []),
      ...files.map(file => ({
        ...file,
        id: null,
        accountDocumentTypeId,
        originalFilename: file.name,
        mimetype: file.type as MIME_TYPE,
        status: "loading" as const,
        hiddenFromPublic: accountDocumentType?.hiddenFromPublicByDefault,
      })),
    ];

    setDocumentUploadValues(newFormValue);
    props.onChange(newFormValue);

    for (const file of files) {
      await generatePresignedUrl({
        variables: {
          data: {
            accountDocumentTypeId,
            clientMimeType: file.type,
            clientKey: file.clientKey!,
          },
        },
        onCompleted: async data => {
          const result = await fetch(data.generatePresignedUrl.presignedUrl, {
            method: "PUT",
            body: file,
            headers: { "Content-Type": file.type },
          });

          if (!result.ok) {
            throw new Error(`Received a ${result.status} when uploading file`);
          }
          await validateScratchDocumentUpload({
            variables: {
              scratchId: data.generatePresignedUrl.scratchId,
              accountDocumentTypeId,
              mimetype: file.type as MIME_TYPE,
            },
            onCompleted: () => {
              const fileToUpdate = documentUploadValuesRef.current?.find(
                f => f.clientKey === data.generatePresignedUrl.clientKey
              );
              if (fileToUpdate) {
                fileToUpdate.status = "valid";
                fileToUpdate.scratchId = data.generatePresignedUrl.scratchId;
                setDocumentUploadValues(documentUploadValuesRef.current);
                props.onChange(documentUploadValuesRef.current);
              }
            },
            onError: () => {
              const fileToUpdate = documentUploadValuesRef.current?.find(
                f => f.clientKey === data.generatePresignedUrl.clientKey
              );
              if (fileToUpdate) {
                fileToUpdate.status = "invalid";
                setDocumentUploadValues(documentUploadValuesRef.current);
                props.onChange(documentUploadValuesRef.current);
              }
            },
          });
        },
        onError: () => {
          const fileToUpdate = documentUploadValuesRef.current?.find(
            f => f.clientKey === file.clientKey
          );
          if (fileToUpdate) {
            fileToUpdate.status = "invalid";
            setDocumentUploadValues(documentUploadValuesRef.current);
            props.onChange(documentUploadValuesRef.current);
          }
        },
      });
    }
  }

  const onDropRejected = (fileWithErrors: Array<FileRejection>) => {
    fileWithErrors.forEach(fileWithError => {
      const errorCodesForFile = fileWithError.errors.map(error => error.code);
      if (errorCodesForFile.includes(ErrorCode.FileTooSmall)) {
        addErrorToast(`${fileWithError.file.name} is an empty file`);
      }
      if (errorCodesForFile.includes(ErrorCode.FileTooLarge)) {
        addErrorToast(`${fileWithError.file.name} is too large`);
      }
      if (errorCodesForFile.includes(ErrorCode.FileInvalidType)) {
        addErrorToast(`${fileWithError.file.name} is the wrong file type`);
      }
    });

    if (
      fileWithErrors.some(file =>
        file.errors.some(error => error.code.includes(ErrorCode.TooManyFiles))
      )
    ) {
      const fileText = maxFiles === 1 ? "file" : "files";
      addErrorToast(`Only ${maxFiles} ${fileText} can be uploaded at a time.`);
    }
  };

  const shouldShowDropzone =
    !props.disabled &&
    maxFiles &&
    (documentUploadValues?.length ?? 0) < maxFiles;

  return (
    <Input id={props.idSchema.$id}>
      <Wrapper
        name={props.name}
        required={props.required && !props.disabled}
        label={props.schema.title}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        tooltip={props.uiSchema?.["ui:tooltipText"]}
      >
        <div style={{ width: "100%" }}>
          {shouldShowDropzone && (
            <EmptyFileContainer>
              <DropzonePositioner>
                <DropzoneComponent
                  border={false}
                  onDrop={onDrop}
                  onDropRejected={onDropRejected}
                  maxSizePerMimeType={MAX_SIZE_PER_MIME_TYPE_IN_MB}
                  maxFiles={maxFiles}
                  allowedMimeTypes={
                    accountDocumentType?.allowedMimeTypes ?? [MIME_TYPE.PDF]
                  }
                  content={DropzoneContent}
                />
              </DropzonePositioner>
              {!documentUploadValues?.length && <Information {...props} />}
            </EmptyFileContainer>
          )}

          <ExistingDocuments
            onChange={props.onChange}
            documentUploadValues={documentUploadValues ?? []}
            setDocumentUploadValues={setDocumentUploadValues}
            disabled={props.disabled}
            emptyFilesMessage={props.uiSchema!["ui:emptyFilesMessage"]}
          />
        </div>
      </Wrapper>
    </Input>
  );
};
