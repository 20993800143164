import React, { createRef, useContext } from "react";
import {
  SummaryRow,
  SummaryTable,
  PhotoGallery,
  CellWithTooltip,
  Sections,
  DivWithTooltip,
} from "./__styles__/SDESummary";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../Common/__styles__/StripedTable";
import { SummaryProps } from ".";
import { formatCurrency, formatPercent } from "common/utils/strings";
import Disclaimer from "../../Common/Disclaimer";
import { isNotNil } from "common/utils/tools";
import { buildLink } from "common/routing";
import { get } from "lodash";
import { Body, Title } from "../../Common/Typography";
import {
  formatSDESummary,
  walkSchema,
} from "common/services/formBuilderService";
import { Section } from "../../Common/Layout";
import { InformationalTooltip } from "../../Common/Tooltip";
import Divider from "../../Common/Divider";
import { AuthContext } from "../../Authorization/AuthContext";
import { setTitle } from "../../../utils/title";
import { ScreenOnly } from "../../Common/__styles__/ScreenOnly";
import { FormComponent } from "../Form";
import { PrintOnly } from "../../Common/__styles__/PrintOnly";
import { useGetSisdRuleDataQuery } from "../../../generated/graphql";
import { SUBMISSION_TYPE_MODULE } from "common/constants";

const isSDESummary = (
  summary: SummaryProps["submission"]["summaries"][number]
) => {
  return (
    summary.__typename === "SDESubmissionSummary" ||
    summary.__typename === "SDESubmissionSummaryV2"
  );
};

type SDESummaryLabel =
  | ReturnType<typeof formatSDESummary>[
      | "percentageSummaryRows"
      | "rawValuesSummaryRows"][number]["label"];

const LABEL_TO_TOOLTIP: Record<SDESummaryLabel, string> = {
  "Percent damaged (Estimated damage cost/Market value)":
    "Value will surface on the corresponding SI/SD record on the property",
};

const SummaryRowLabel = ({
  label,
  tooltipContent,
}: {
  label: React.ReactNode;
  tooltipContent: React.ReactNode;
}) => {
  return (
    <DivWithTooltip>
      <Body size={"default"} type={"regular"}>
        {label}
      </Body>
      <ScreenOnly>
        {tooltipContent && (
          <InformationalTooltip tooltipText={tooltipContent} place={"top"} />
        )}
      </ScreenOnly>
    </DivWithTooltip>
  );
};

const SummaryRowValue = ({ children }: { children: React.ReactNode }) => {
  return (
    <Body size={"default"} type={"emphasis"}>
      {children}
    </Body>
  );
};

const BoldTableCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableCell>
      <Body size={"default"} type={"emphasis"} as={"div"}>
        {children}
      </Body>
    </TableCell>
  );
};

export const SDESummary: React.FC<SummaryProps> = ({ submission }) => {
  const { isGuest } = useContext(AuthContext);
  if (isGuest) {
    setTitle("SDE Summary");
  }
  const { formData, documentUploads, summaries } = submission;
  const version = submission.submissionTypeVersion;
  const summary = summaries.find(isSDESummary);

  if (!summary) {
    throw new Error(
      "SDESummary not found in submission's summaries, which indicates that this summary page was rendered incorrectly."
    );
  }

  const submitFormRef = createRef<HTMLButtonElement>();

  const images =
    formData.images
      ?.map(image => {
        return documentUploads.find(doc => doc.id === image.id);
      })
      .filter(isNotNil) || [];

  const notes = walkSchema<Record<string, string>>({
    formStructure: version.formStructure,
    visit: ({ type, path, uiSchema, titles }) => {
      if (type.includes("string") && uiSchema?.["ui:widget"] === "textarea") {
        const text = get(formData, path);
        if ((text?.trim().length ?? 0) < 1) {
          return {};
        }

        return { [titles.join(" > ")]: get(formData, path) };
      }

      return {};
    },
    merge: (acc, result) => ({ ...acc, ...result }),
  });

  const { elementBreakdowns, totalCosts, totalDamages } = summary;
  const { percentageSummaryRows, rawValuesSummaryRows } = formatSDESummary({
    summary,
  });

  const propertyId = submission.property?.id ?? "";

  const submissionTypeModules =
    submission.submissionTypeVersion.submissionType.modules;

  const { data: sisdRuleData, loading: sisdRuleDataLoading } =
    useGetSisdRuleDataQuery({
      variables: { propertyId },
      skip:
        !submissionTypeModules.includes(
          SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
        ) || !propertyId,
    });

  if (sisdRuleDataLoading) {
    return <div />;
  }

  return (
    <Sections>
      <Section>
        <Title size={"small"} type={"semiBold"}>
          Estimated damage summary
        </Title>
        <SummaryTable>
          {percentageSummaryRows.map(({ label, value }) => {
            return (
              <SummaryRow key={label}>
                <SummaryRowLabel
                  label={label}
                  tooltipContent={LABEL_TO_TOOLTIP[label]}
                />
                <SummaryRowValue>{value} </SummaryRowValue>
              </SummaryRow>
            );
          })}
          <Divider />
          {rawValuesSummaryRows.map(({ label, value }) => {
            return (
              <SummaryRow key={label}>
                <SummaryRowLabel
                  label={label}
                  tooltipContent={LABEL_TO_TOOLTIP[label]}
                />
                <SummaryRowValue>{value} </SummaryRowValue>
              </SummaryRow>
            );
          })}
        </SummaryTable>
      </Section>
      <Section>
        <Title size={"small"} type={"semiBold"}>
          Estimated computed damages breakdown
        </Title>
        <Table>
          <TableBody>
            <TableRow>
              <BoldTableCell>Element</BoldTableCell>
              <BoldTableCell>
                <CellWithTooltip>
                  <span>Structure %</span>
                  <ScreenOnly>
                    <InformationalTooltip
                      tooltipText="Building element value as a percentage of total appraised value, based on structure information provided"
                      place={"top"}
                    />
                  </ScreenOnly>
                </CellWithTooltip>
              </BoldTableCell>
              <BoldTableCell>Element Cost</BoldTableCell>
              <BoldTableCell>Damage</BoldTableCell>
              <BoldTableCell>Damage Value</BoldTableCell>
            </TableRow>
            {elementBreakdowns.map(row => {
              return (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.structurePercentage}</TableCell>
                  <TableCell>{formatCurrency(row.cost)}</TableCell>
                  <TableCell>{formatPercent(row.damagePercentage)}</TableCell>
                  <TableCell>{formatCurrency(row.damageValue)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <BoldTableCell>Totals</BoldTableCell>
              <TableCell></TableCell>
              <BoldTableCell>{formatCurrency(totalCosts)}</BoldTableCell>
              <TableCell></TableCell>
              <BoldTableCell>{formatCurrency(totalDamages)}</BoldTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Section>
      <Disclaimer message={DISCLAIMER_TEXT} />
      <Notes notes={notes} />
      <Photos images={images} />
      <PrintOnly data-testid={`print-only-sde-form-${submission.id}`}>
        <FormComponent
          disabled={true}
          submissionType={{
            currentVersion: submission.submissionTypeVersion,
            modules: submission.submissionTypeVersion.submissionType.modules,
          }}
          existingSubmission={submission}
          relatedSubmissions={submission.relatedSubmissions}
          onSubmit={() => {}}
          onChange={() => {}}
          submitFormRef={submitFormRef}
          account={sisdRuleData?.account}
          property={{ FIRMInfo: sisdRuleData?.FIRMInfo }}
        />
      </PrintOnly>
    </Sections>
  );
};

const Notes = ({ notes }: { notes: Record<string, string> }) => {
  if (!Object.keys(notes).length) return null;

  const noteSections = Object.entries(notes).map(([key, value]) => {
    return (
      <Section key={key}>
        <Title size={"small"} type={"semiBold"}>
          {key}
        </Title>
        <Body size={"default"} type={"regular"}>
          {value}
        </Body>
      </Section>
    );
  });

  return <>{noteSections}</>;
};

const Photos = ({
  images,
}: {
  images: Array<{ id: string; originalFilename: string }>;
}) => {
  const { isGuest } = useContext(AuthContext);
  if (!images.length) return null;

  const link = isGuest ? "guestDocumentUploadFile" : "documentUploadFile";

  return (
    <Section>
      <Title size={"small"} type={"semiBold"}>
        Photos
      </Title>
      <PhotoGallery>
        {images.map(image => {
          return (
            <img
              key={image.id}
              src={buildLink(link, { id: image.id })}
              alt={image.originalFilename}
            />
          );
        })}
      </PhotoGallery>
    </Section>
  );
};

const DISCLAIMER_TEXT =
  "Disclaimer: Forerunner's substantial damage estimate summaries are designed to provide preliminary estimates based on the information provided by the user and Forerunner's embedded algorithms. These estimates are intended for general guidance purposes only and should not be construed as definitive or accurate assessments of actual damage costs.";
