import { useModal } from "react-modal-hook";
import Modal from "../../Common/Modal";
import React, { useContext, useState } from "react";

import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
  SecondaryButtons,
} from "../../Common/__styles__/Modal";
import { Button } from "../../Common/Button";
import { useForm } from "react-hook-form";
import { Checkbox, Select, Text, Textarea } from "../../Inputs/react-hook-form";
import { FlexColumn, FlexRow } from "../../Common/__styles__/Layout";
import { AuthContext } from "../../Authorization/AuthContext";
import { buildGuestDocumentUploadDetailURL } from "common/utils/url";
import { track } from "../../../utils/tracking";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import {
  InformationRequestContactType,
  useGenerateShareMessageLazyQuery,
  useShareDocumentUploadMutation,
} from "../../../generated/graphql";
import { SuccessView } from "../../Common/ShareItem/SuccessView";
import { CONTACT_TYPE_OPTIONS } from "../../Common/ShareItem/utils";
import { isValidEmail } from "common/utils/strings";
import { isEmpty } from "lodash";
import { spacing } from "../../../stitches.config";
import { Icon } from "../../Common/Icons/LucideIcons";

type ShareFileModalProps = {
  onCancel: () => void;
  documentUpload: {
    id: string;
    originalFilename: string;
    property?: Maybe<{ id: string }>;
    elevationCertificate: {
      id: string;
      certifierInfo?: Maybe<{
        name?: Maybe<string>;
        email?: Maybe<string>;
      }>;
    };
  };
};

export const useShareFileModal = () => {
  const [documentUpload, setDocumentUpload] =
    useState<Maybe<ShareFileModalProps["documentUpload"]>>(null);

  const [rawShowShareFileModal, closeShareFileModal] = useModal(
    () => (
      <Modal onRequestClose={closeShareFileModal}>
        <ShareFileModal
          onCancel={closeShareFileModal}
          documentUpload={{
            ...documentUpload!,
          }}
        />
      </Modal>
    ),
    [documentUpload]
  );

  const showShareFileModal = (
    documentUpload: ShareFileModalProps["documentUpload"]
  ) => {
    setDocumentUpload(documentUpload);
    rawShowShareFileModal();
  };

  return [showShareFileModal, closeShareFileModal] as const;
};

type ShareFileFormStructure = {
  name: string;
  role: InformationRequestContactType;
  email: string;
  message?: Maybe<string>;
  copyYourself: boolean;
  createLog: boolean;
};

export const ShareFileModal = ({
  clipboard = navigator.clipboard,
  documentUpload,
  onCancel,
}: ShareFileModalProps & {
  clipboard?: Pick<typeof navigator.clipboard, "writeText">;
}) => {
  const { account, admin, user } = useContext(AuthContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [residentLogId, setResidentLogId] = useState<Maybe<string>>(null);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const surveyorName =
    documentUpload.elevationCertificate?.certifierInfo?.name || undefined;
  const surveyorEmail =
    documentUpload.elevationCertificate?.certifierInfo?.email || undefined;

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<ShareFileFormStructure>({
    defaultValues: {
      role:
        surveyorEmail || surveyorName
          ? InformationRequestContactType.SURVEYOR
          : undefined,
      name: surveyorName,
      email: surveyorEmail,
    },
  });

  const [shareDocumentUpload, { loading }] = useShareDocumentUploadMutation({
    onCompleted: response => {
      setResidentLogId(response.shareDocumentUpload.log?.id ?? null);
      setShowSuccess(true);
    },
    onError: () => {
      addErrorToast(
        "There was an error sharing this file. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  });

  const [generateShareMessage, { loading: generatingMessage }] =
    useGenerateShareMessageLazyQuery({
      fetchPolicy: "network-only",
    });

  const fileURL = buildGuestDocumentUploadDetailURL({
    subdomain: account!.subdomain,
    documentUploadId: documentUpload.id,
  });

  const onSubmit = (formData: ShareFileFormStructure) => {
    track("Emailed Link", {
      documentUploadId: documentUpload.id,
      logCreated: formData.createLog,
      userCCd: formData.copyYourself,
    });

    void shareDocumentUpload({
      variables: {
        data: {
          documentUploadId: documentUpload.id,
          recipientName: formData.name,
          recipientEmail: formData.email,
          recipientType: formData.role,
          message: formData.message,
          copyYourself: formData.copyYourself,
          createLog: formData.createLog,
        },
      },
    });
  };

  const onGenerateMessage = () => {
    track("Generated EC share message");
    const onError = () => {
      addErrorToast(
        "Failed to generate message. Please contact support@withforerunner.com"
      );
    };

    return generateShareMessage({
      variables: {
        certificateId: documentUpload.elevationCertificate.id,
        recipientName: getValues("name"),
      },
      onCompleted: data => {
        const message = data.generateECShareMessage;
        if (!isEmpty(message)) {
          setValue("message", message);
        } else {
          onError();
        }
      },
      onError,
    });
  };

  if (showSuccess) {
    return (
      <SuccessView
        headerText="Share file"
        recipientEmail={getValues("email")}
        residentLogId={residentLogId}
        onClose={onCancel}
      />
    );
  }

  return (
    <Container>
      <HeaderSection>
        <h1>Share file</h1>
        <h2>{documentUpload.originalFilename}</h2>
      </HeaderSection>
      <ContentSection>
        <FlexColumn style={{ gap: "16px" }}>
          <FlexRow style={{ gap: "16px" }}>
            <Text
              {...register("name", { required: "Name is required" })}
              label="Name"
              error={errors.name?.message}
              required
            />
            <Select
              control={control}
              name="role"
              label="Role"
              error={errors.role?.message}
              rules={{ required: "Role is required" }}
              required
              options={CONTACT_TYPE_OPTIONS}
            />
          </FlexRow>
          <Text
            {...register("email", {
              required: "Email is required",
              validate: val => {
                if (!isValidEmail(val)) {
                  return "Please provide a valid email address";
                }
                return;
              },
            })}
            label="Email address"
            error={errors.email?.message}
            required
          />
          <FlexRow style={{ gap: "16px", alignItems: "flex-start" }}>
            <div style={{ flex: 1 }}>
              <Textarea
                {...register("message")}
                label="Message"
                error={errors.message?.message}
                disabled={generatingMessage}
              />
              <FlexRow
                style={{
                  gap: spacing.xs.value,
                  marginTop: spacing.s.value,
                  alignItems: "center",
                }}
              >
                <Icon iconName="sparkles" color="contentSecondary" size={14} />
                Summarize file issues using AI.
                <Button
                  onClick={onGenerateMessage}
                  styleVariant="buttonLink"
                  size="small"
                  disabled={generatingMessage}
                  style={{ padding: 0, height: "auto" }}
                >
                  {generatingMessage ? "Generating..." : "Generate summary"}
                </Button>
              </FlexRow>
            </div>
          </FlexRow>
          <Checkbox
            name="copyYourself"
            control={control}
            label={`Copy yourself on email (${user?.email ?? admin?.email})`}
          />
          {!!documentUpload.property?.id && (
            <Checkbox
              name="createLog"
              control={control}
              label="Create new log"
            />
          )}
        </FlexColumn>
      </ContentSection>
      <ButtonSection>
        <SecondaryButtons>
          <Button
            styleVariant="ghost"
            size="small"
            leftIconName="link"
            onClick={async () => {
              track("Copied Link", { documentUploadId: documentUpload.id });

              await clipboard.writeText(fileURL);
              addSuccessToast("Link copied to clipboard");
            }}
          >
            Copy public link
          </Button>
        </SecondaryButtons>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            size="small"
            onClick={handleSubmit(onSubmit)}
            disabled={loading || generatingMessage}
          >
            Send
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
