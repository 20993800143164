import React, { useContext } from "react";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import {
  formatParcelCurrency,
  parcelFieldNameOverridesByKey,
} from "common-client/utils/parcels";
import { FieldNameOverride } from "common/utils/parcel";
import {
  GetParcelDataQuery,
  GetPropertyParcelDataOverridesQuery,
  useGetParcelDataQuery,
  useGetPropertyParcelDataOverridesQuery,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { AddressPanelContext } from "../AddressPanelContext";
import { PropertyOverviewContext } from "./PropertyOverviewContext";
import { Account } from "../../Authorization/types";
import { Body } from "../../Common/Typography";
import { EmptyState } from "../../Common/EmptyState";

import { AddressPanelTitle } from "../__styles__/AddressPanel";
import {
  ParcelTable,
  ParcelTableRow,
  ParcelTableCell,
} from "./__styles__/ParcelData";

const Row = <Value extends string | number>({
  parcelFieldNameOverride,
  value,
}: {
  parcelFieldNameOverride: [string, FieldNameOverride];
  value: Value | undefined | null;
}) => {
  const { isGuest } = useContext(AuthContext);
  const [key, field] = parcelFieldNameOverride;

  if (isNil(value) || (isGuest && !(field.isPublic ?? true))) return null;

  return (
    <ParcelTableRow>
      <ParcelTableCell data-testid="parcel-info-label">
        {field.value}
      </ParcelTableCell>
      <ParcelTableCell data-testid="parcel-info-value">
        {formatParcelCurrency({ key, value })}
      </ParcelTableCell>
    </ParcelTableRow>
  );
};

const ParcelData = ({
  section,
}: {
  section: Account["propertyInformationSections"][number];
}) => {
  const { account } = useContext(AuthContext);
  const { property, loadingProperty } = useContext(AddressPanelContext);
  const { latitude, longitude } = useContext(PropertyOverviewContext);
  const { t } = useTranslation();

  const propertyId = property?.id;
  const parcelFieldNameOverrides = parcelFieldNameOverridesByKey({
    parcelFieldNameOverrides: account?.parcelFieldNameOverrides,
  });

  const { data, loading } = useGetParcelDataQuery({
    variables: { latitude, longitude, propertyId },
    skip: loadingProperty,
    fetchPolicy: "cache-and-network",
  });

  const { data: propertyOverrides, loading: loadingPropertyOverrides } =
    useGetPropertyParcelDataOverridesQuery({
      variables: { propertyId: propertyId! },
      skip: !propertyId,
      fetchPolicy: "cache-and-network",
    });

  const isLoading = loadingProperty || loading || loadingPropertyOverrides;

  if (isLoading) {
    return <em>{t("common-loading")}</em>;
  }

  const parcel = data?.parcel;

  if (!parcel) {
    return (
      <div>
        <Body size="large" type="emphasis" style={{ maxWidth: "50%" }}>
          {section.label}
        </Body>
        <EmptyState compact title={t("property-parcel-info-empty-state")} />
      </div>
    );
  }

  return (
    <>
      <AddressPanelTitle>
        <Body size="large" type="emphasis">
          {section.label}
        </Body>
      </AddressPanelTitle>
      <ParcelTable>
        {Object.entries(parcelFieldNameOverrides).map(
          (parcelFieldNameOverride, index) => (
            <Row
              parcelFieldNameOverride={parcelFieldNameOverride}
              key={index}
              value={
                propertyOverrides?.property?.[
                  parcelFieldNameOverride[0] as keyof GetPropertyParcelDataOverridesQuery["property"]
                ] ??
                parcel![
                  parcelFieldNameOverride[0] as keyof GetParcelDataQuery["parcel"]
                ] ??
                parcel!.additionalFields?.[parcelFieldNameOverride[0]]
              }
            />
          )
        )}
      </ParcelTable>
    </>
  );
};

export default ParcelData;
