import React from "react";
import { spacing, styled } from "../../../stitches.config";
import { Body, BodyType } from "../../Common/Typography";

export const RelevantFIRMsContainer = styled("div");

export const RelevantFIRMsList = styled("ul", {
  padding: `${spacing.s} 0px ${spacing.s} ${spacing.s}`,
  margin: `0px 0px 0px ${spacing.s}`,
});

export const RelevantFIRMsItem = ({
  children,
}: {
  children: React.ReactNode;
}) => <li>{children}</li>;

export const RelevantFIRMsItemText = ({
  children,
  type = "regular",
  style,
}: {
  children: React.ReactNode;
  type?: BodyType;
  style?: React.CSSProperties;
}) => (
  <Body as="span" size="default" type={type} style={style} color="inherit">
    {children}
  </Body>
);

export const RelevantFIRMsItemTextSeparator = () => (
  <RelevantFIRMsItemText
    type={"regular"}
    style={{ padding: `0px ${spacing.xs}` }}
  >
    -
  </RelevantFIRMsItemText>
);
