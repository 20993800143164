import { styled } from "../../../stitches.config";

export const ScreenOnly = styled("div", {
  "@media print": {
    display: "none",
  },
  variants: {
    width: {
      full: {
        width: "100%",
      },
    },
  },
});
