import React, { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { HelpText, WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import OptionsInput from "./OptionsInput";
import { Button } from "../../../../../../Common/Button";
import { FieldSettingConfig } from "./shared";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import { SubmissionsBuilderFieldSettings } from "../../types";

const TagsWidgetSettings = ({
  fieldPath,
  isModuleInput,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { setValue, watch } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  let options = watch(`${fieldPath}.enum`);

  const [dropdownOptions, setDropdownOptions] = useState<string[]>(
    options?.length
      ? options.filter((value): value is string => {
          // json schema ensures that enum is an array of strings
          return typeof value === "string";
        })
      : [""]
  );

  const setDropdownOption = (index: number, value: string) => {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[index] = value;
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const addDropdownOption = () => {
    const updatedOptions = [...dropdownOptions, ""];
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const removeDropdownOption = (index: number) => {
    dropdownOptions.splice(index, 1);
    // creating a shallow copy of the array since splice modifies in place
    const updatedOptions = [...dropdownOptions];
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const checkBoxChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      setValue(`${fieldPath}.minItems`, 1, { shouldValidate: true });
    } else {
      setValue(`${fieldPath}.minItems`, 0, { shouldValidate: true });
    }
    setValue(`${fieldPath}.required`, evt.target.checked, {
      shouldValidate: true,
    });
  };

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={isModuleInput}
        onRequiredChange={checkBoxChanged}
        readOnly={readOnly}
      />
      <div>
        <div>
          <Label
            style={{ marginBottom: "4px" }}
            required
            text={"Tag options"}
          />
          <HelpText style={{ marginTop: "0px", paddingBottom: "8px" }}>
            Provide options for users to select from; enter each option on a new
            field
          </HelpText>
        </div>
        <OptionsInput
          dropdownOptions={dropdownOptions}
          setDropdownOption={setDropdownOption}
          removeDropdownOption={removeDropdownOption}
          disabled={isModuleInput || readOnly}
        />
        <div style={{ paddingTop: "8px" }}>
          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={addDropdownOption}
            disabled={isModuleInput || readOnly}
          >
            Add option
          </Button>
        </div>
      </div>
    </WidgetSettingsContainer>
  );
};

TagsWidgetSettings.displayName = "TagsWidgetSettings";

export default TagsWidgetSettings;
