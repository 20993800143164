import React, { useContext, useEffect, useState } from "react";
import {
  ColumnWrapper,
  TabGroupWrapper,
} from "../__styles__/SubmissionsBuilder";
import FormSettingsTab from "./FormSettingsTab";
import FormJSONPreview from "./JSONPreviewTab";
import TabGroup from "../../../../../Common/TabGroup";
import { FieldsTab } from "./FieldsTab";
import { SubmissionsBuilderContextInstance } from "../context";
import { SUBMISSIONS_BUILDER_SETTINGS_MODE } from "../reducer";
import FieldSettingsView from "./FieldSettingsView";

type SidebarTabValue = "fields" | "settings" | "jsonPreview";

const DEFAULT_TABS: Array<{ label: string; value: SidebarTabValue }> = [
  { label: "Fields", value: "fields" },
  { label: "Settings", value: "settings" },
];

const TabNameToTabBodyMap: Record<SidebarTabValue, () => JSX.Element> = {
  fields: FieldsTab,
  settings: FormSettingsTab,
  jsonPreview: FormJSONPreview,
};

export const SidebarColumn = () => {
  const { jsonPreviewEnabled, settingsMode, readOnly } = useContext(
    SubmissionsBuilderContextInstance
  );
  const [activeTab, setActiveTab] = useState<SidebarTabValue>(
    readOnly ? "settings" : "fields"
  );

  useEffect(() => {
    if (readOnly) {
      setActiveTab("settings");
    }
  }, [readOnly]);

  let tabs: Array<{ label: string; value: SidebarTabValue }> = [];
  if (!readOnly) {
    tabs = [...DEFAULT_TABS];
    if (jsonPreviewEnabled) {
      tabs.push({ label: "JSON Preview", value: "jsonPreview" });
    }
  }

  const TabBody = TabNameToTabBodyMap[activeTab];

  return (
    <ColumnWrapper role="complementary" left>
      {settingsMode === SUBMISSIONS_BUILDER_SETTINGS_MODE.FIELD ? (
        <FieldSettingsView readOnly={readOnly} />
      ) : (
        <div>
          <TabGroupWrapper>
            <TabGroup<SidebarTabValue>
              currentTab={activeTab}
              setTab={setActiveTab}
              tabs={tabs}
            />
          </TabGroupWrapper>
          <TabBody />
        </div>
      )}
    </ColumnWrapper>
  );
};
