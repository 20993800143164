import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { MIME_TYPE } from "common/constants";
import { isEmpty, omit } from "lodash";

import { AuthContext } from "../../../Authorization/AuthContext";
import { Button } from "../../../Common/Button";
import { Section } from "../../__styles__/Content";
import { Checkbox, SingleFileUpload } from "../../../Inputs/react-hook-form";
import { useDispatchBulkPropertyCreateScriptJobMutation } from "../../../../generated/graphql";
import { useMultipartFileUpload } from "../../../../hooks/useMultipartFileUpload";
import FullPageFormLayout from "../../../Common/FullPageFormLayout";
import { SectionDivider } from "../../../Common/__styles__/FullPageFormLayout";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { Body, Title } from "../../../Common/Typography";
import { FormTitleWrapper, List, ListItem } from "./__styles__/Operations";
import { Emphasis } from "../../../Common/__styles__/Typography";
import { spacing } from "../../../../stitches.config";

type DispatchBulkPropertyActionFormData = {
  fileUpload: { blob: File };
  thoroughStandardize: boolean;
};

const INCLUDE_THOROUGH_STANDARDIZE = process.env.NODE_ENV !== "staging";

const DispatchBulkPropertyActionForm = () => {
  const { account } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const formMethods = useForm<DispatchBulkPropertyActionFormData>({
    defaultValues: {
      thoroughStandardize: false,
    },
  });
  const { control, getValues, handleSubmit, register, watch } = formMethods;
  const [hasDispatchedOperation, setHasDispatchedOperation] = useState(false);

  const [uploadSourceFile, { loading: isUploading }] = useMultipartFileUpload({
    onError: () => addErrorToast("Failed to upload input file!"),
  });

  const [dispatch, { loading: isDispatching }] =
    useDispatchBulkPropertyCreateScriptJobMutation({
      onCompleted: () => {
        addSuccessToast("Dispatched bulk property operation!");
        setHasDispatchedOperation(true);
      },
      onError: () => {
        addErrorToast("Failed to dispatch bulk property operation!");
      },
    });

  const onSubmit = async (formData: DispatchBulkPropertyActionFormData) => {
    uploadSourceFile({
      file: getValues("fileUpload.blob"),
      onCompleted: async key => {
        await dispatch({
          variables: {
            data: {
              accountId: account!.id,
              inputFileKey: key,
              thoroughStandardize: formData.thoroughStandardize,
            },
          },
        });
      },
    });
  };

  const fileUploadValue = watch("fileUpload");
  const missingRequiredField = isEmpty(fileUploadValue);
  const isSubmitting = isUploading || isDispatching;
  const submitIsDisabled =
    isSubmitting || missingRequiredField || hasDispatchedOperation;

  return (
    <FullPageFormLayout
      subtitle="Bulk property actions"
      prevLocation={"/settings/data-imports/property-operations"}
      rightContainer={
        <Button
          size={"small"}
          styleVariant={"primary"}
          onClick={handleSubmit(onSubmit)}
          disabled={submitIsDisabled}
          loading={isSubmitting}
        >
          Create
        </Button>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <Section>
            <FormTitleWrapper>
              <Title size="large" type="semiBold">
                Bulk property actions
              </Title>
            </FormTitleWrapper>
            <Body size={"default"} type={"regular"} color="contentSecondary">
              This form will allow you to upload a CSV file containing property
              information and dispatch a script to find or create those
              properties within Forerunner. This script can also be used to bulk
              add warnings or SI/SD data.
              <br />
              <br />
              When the script is complete you will receive an email with a link
              to download a CSV containing any errors that occurred during the
              operation.
            </Body>
          </Section>
          <SectionDivider />
          <Section>
            <FormTitleWrapper>
              <Title size={"small"} type={"regular"}>
                Property creation
              </Title>
            </FormTitleWrapper>
            <List>
              <ListItem>
                <Body type="regular" size="default" color="contentSecondary">
                  The first row of your CSV should contain the column headers
                </Body>
              </ListItem>
              <ListItem>
                <Body type="regular" size="default" color="contentSecondary">
                  There must be address/propertyId columns and they must come in
                  at least one of the following combinations:
                </Body>
                <pre>fullAddress</pre>
                <Body type="emphasis" size="default" color="contentSecondary">
                  OR
                </Body>
                <pre>streetAddress, city, state, zip</pre>
                <Body type="emphasis" size="default" color="contentSecondary">
                  OR
                </Body>
                <pre>streetNumber, streetName, suffix, city, state, zip</pre>
                <Body type="emphasis" size="default" color="contentSecondary">
                  OR
                </Body>
                <pre>propertyId</pre>
              </ListItem>
              <ListItem>
                <Body type="regular" size="default" color="contentSecondary">
                  Including longitude and latitude columns is strongly
                  encouraged.
                </Body>
              </ListItem>
            </List>
          </Section>
          <Section>
            <FormTitleWrapper>
              <Title size={"small"} type={"regular"}>
                Updating existing properties
              </Title>
            </FormTitleWrapper>
            <Body size={"default"} type={"regular"} color="contentSecondary">
              If you'd like to alter a set of existing Forerunner properties,
              you may supply a <Emphasis>propertyId</Emphasis> column in your
              CSV and omit all address columns. In this mode, no new properties
              will be created. <br />
              <br />
              Note: Address columns can be used to update existing properties,
              however, propertyId will guarantee that we avoid creating
              duplicate properties if address standardization differs.
            </Body>
          </Section>
          <Section>
            <FormTitleWrapper>
              <Title size={"small"} type={"regular"}>
                Warnings
              </Title>
            </FormTitleWrapper>
            <Body size={"default"} type={"regular"} color="contentSecondary">
              To add a warning for each property, include a{" "}
              <Emphasis>warnings</Emphasis> column in your CSV with a comma
              delimited list of warning titles.
            </Body>
          </Section>
          <SectionDivider />
          <Section>
            <SingleFileUpload
              {...omit(register("fileUpload"), "ref")}
              control={control}
              allowedMimeTypes={[MIME_TYPE.CSV]}
              name={"fileUpload"}
              useDropzone={useDropzone}
              label={"Source file"}
              description={"Upload a CSV file"}
              required
            />
          </Section>
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: spacing.m.toString(),
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              {INCLUDE_THOROUGH_STANDARDIZE ? (
                <Checkbox
                  name="thoroughStandardize"
                  control={control}
                  label="Use Google recommended addresses"
                  description="Please note that using this feature may incur additional costs."
                />
              ) : (
                <Body type="regular" size="default" color="contentSecondary">
                  We've removed the option to standardize addresses via Google
                  in staging to avoid incurring unnecessary costs.
                </Body>
              )}
            </div>
          </Section>
        </form>
      </FormProvider>
    </FullPageFormLayout>
  );
};

export default DispatchBulkPropertyActionForm;
