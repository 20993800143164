import React, { ComponentProps } from "react";
import { capitalize } from "lodash";
import ListItem, { PartialImprovement } from "./ListItem";
import { formatCurrency } from "common/utils/strings";

type ImprovementListItemProps = Pick<
  ComponentProps<typeof ListItem>,
  "address" | "onUpdate"
> & { item: PartialImprovement };

const ImprovementListItem = ({
  address,
  onUpdate,
  item,
}: ImprovementListItemProps) => {
  const title = capitalize(item.kind);
  const info = `${(item.progress ? item.progress * 100 : 0 * 100).toFixed(
    2
  )}% (${formatCurrency(item.value)})`;

  return (
    <ListItem
      address={address}
      onUpdate={onUpdate}
      item={item}
      key={item.id}
      date={item.date}
      title={title}
      info={info}
      excluded={item.exclusion!.excluded}
    />
  );
};

export default ImprovementListItem;
