import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../Common/__styles__/StripedTable";
import { Label } from "../AddressPanel/PropertyOverview/__styles__";
import { ObjectInfoPanelTabProps } from "./types";
import { ContentWrapper } from "./__styles__/ObjectInformationPanel";

export const OVERVIEW_LABEL = "Object properties";

const Overview = ({ geometry, customMap }: ObjectInfoPanelTabProps) => {
  const order =
    customMap.config.uiSchema["ui:order"] ??
    Object.keys(geometry.additionalFields);

  return (
    <ContentWrapper>
      <Table spacing="negative">
        <TableHeader>
          <TableRow header>
            <TableCell>{OVERVIEW_LABEL}</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {order.map(key => (
            <TableRow header={false} key={key}>
              <TableCell containsTooltip>
                <div style={{ overflowX: "hidden", whiteSpace: "normal" }}>
                  <Label size="narrow">{key}</Label>
                </div>
              </TableCell>
              <TableCell style={{ whiteSpace: "normal" }}>
                <span>{geometry.additionalFields[key]}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ContentWrapper>
  );
};

export default Overview;
