import React, { useContext } from "react";

import { Button } from "../../../Button";
import { useCreateSavedViewModal } from "./CreateModal";
import { Actions } from "../../../ActionCell";
import { TableContext } from "../../TableContext";
import { useEditSavedViewModal } from "./EditModal";
import { canUpdateSavedView } from "common/authorization/customPolicies";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { RoleName } from "common/authorization";

export const SaveButton = ({
  addingNewView,
  disabled,
  onSave,
  onCancel,
}: {
  addingNewView: boolean;
  disabled: boolean;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { user, admin } = useContext(AuthContext);
  const { currentView } = useContext(TableContext);
  const [showCreateModal] = useCreateSavedViewModal({ onSave });
  const showEditSavedViewModal = useEditSavedViewModal();

  const ActionButton = ({
    onClick,
    disabled,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
  }) => (
    <Button
      onClick={onClick}
      disabled={disabled}
      styleVariant="primary"
      size="small"
      rightIconName={!addingNewView ? "chevron-down" : undefined}
    >
      Save view
    </Button>
  );

  if (addingNewView) {
    return (
      <>
        <Button onClick={onCancel} styleVariant="secondary" size="small">
          Cancel
        </Button>
        <ActionButton onClick={showCreateModal} />
      </>
    );
  }

  const userEntity = user || admin;

  const canUpdate = canUpdateSavedView({
    user: { role: userEntity?.role.name as RoleName, id: userEntity?.id! },
    createdById: currentView?.createdById,
  });

  const actions = [];

  if (canUpdate) {
    actions.push({
      label: "Update existing view",
      onClick: () => showEditSavedViewModal(currentView!),
    });
  }

  actions.push({
    label: "Save as new view",
    onClick: showCreateModal,
  });

  return (
    <Actions
      actions={actions}
      customButton={ActionButton}
      disabled={disabled}
    />
  );
};
