import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { useLocation } from "react-router";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

import { RESOURCE_NAME } from "common/authorization";
import { INFO_PANEL_TAB_NAME, buildLink } from "common/routing";
import {
  AiAction,
  Property,
  Submission,
  SubmissionStatus,
  SubmissionType,
} from "../../generated/graphql";
import DeleteConfirmation from "../AddressPanel/Submissions/DeleteConfirmation";
import EditVisibility from "../AddressPanel/Submissions/EditVisibility";
import { AuthContext } from "../Authorization/AuthContext";
import Modal from "../Common/Modal";
import { Actions } from "../Common/ActionCell";
import {
  ActionsProps,
  shouldShowViewOnMapAction,
} from "../Inputs/DropdownMenu";
import { track } from "../../utils/tracking";
import { SUBMISSION_CATEGORY, SUBMISSION_TYPE_MODULE } from "common/constants";
import { SUBMISSION_CATEGORY_TO_TAB } from "../../utils/submissions";
import RunAIAction from "./RunAIAction";

export type SubmissionActionButtonProps = {
  isEditView?: boolean;
  isSummaryView?: boolean;
  submission: Pick<Submission, "id" | "status"> & {
    property?: Maybe<
      Pick<Property, "id" | "fullAddress" | "longitude" | "latitude">
    >;
    submissionTypeVersion: {
      submissionType: Pick<SubmissionType, "category" | "name"> & {
        modules: Array<SUBMISSION_TYPE_MODULE>;
        aiActions?: Array<AiAction>;
      };
    };
    hasSummary: boolean;
    hiddenFromPublic?: boolean;
    relatedSubmissions: Array<
      Pick<Submission, "id"> & {
        submissionTypeVersion: {
          submissionType: Pick<SubmissionType, "modules" | "name">;
        };
      }
    >;
    category: SUBMISSION_CATEGORY;
  };
  onUpdate?: () => void;
  additionalActions?: Array<ActionsProps>;
  objectIsHiddenFromPublic?: boolean;
};

const getSubmissionTypeName = ({
  modules,
  name,
}: Pick<SubmissionType, "name" | "modules">) => {
  if (modules.includes(SUBMISSION_TYPE_MODULE.SUBSTANTIAL_DAMAGE_ESTIMATE)) {
    return "SDE";
  } else if (
    modules.includes(
      SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
    )
  ) {
    return "SD";
  } else {
    return name;
  }
};

const ActionButton = ({
  isEditView = false,
  isSummaryView = false,
  submission,
  onUpdate,
  additionalActions = [],
  objectIsHiddenFromPublic,
}: SubmissionActionButtonProps) => {
  const { pathname, search, state } = useLocation<{
    prevLocation?: Maybe<string>;
  }>();
  const { t } = useTranslation();
  const { authorized, account, isGuest } = useContext(AuthContext);

  const canRunAiActions = authorized({
    permission: "run",
    resource: RESOURCE_NAME.AI_ACTION,
  });

  const isDetailView = isSummaryView || isEditView;
  const actionPrevLocation = isDetailView
    ? state?.prevLocation ?? ""
    : undefined;

  const [showDeleteSubmissionModal, hideDeleteSubmissionModal] = useModal(
    () => (
      <Modal onRequestClose={hideDeleteSubmissionModal}>
        <DeleteConfirmation
          submission={submission}
          prevLocation={actionPrevLocation}
          closeModal={hideDeleteSubmissionModal}
          onUpdate={onUpdate}
        />
      </Modal>
    )
  );

  const [showEditVisibilityModal, hideEditVisibilityModal] = useModal(
    () => (
      <Modal onRequestClose={hideEditVisibilityModal}>
        <EditVisibility
          submission={{
            ...submission,
            hiddenFromPublic: submission.hiddenFromPublic!,
          }}
          prevLocation={actionPrevLocation}
          closeModal={hideEditVisibilityModal}
          submissionTypeName={
            submission.submissionTypeVersion.submissionType.name
          }
        />
      </Modal>
    ),
    [submission.hiddenFromPublic]
  );

  const aiActions = submission.submissionTypeVersion.submissionType.aiActions;
  const [showRunAIActionModal, hideRunAIActionModal] = useModal(
    () => (
      <Modal onRequestClose={hideRunAIActionModal}>
        <RunAIAction
          submission={submission}
          prevLocation={actionPrevLocation}
          closeModal={hideRunAIActionModal}
        />
      </Modal>
    ),
    aiActions
  );

  const prevLocation = `${pathname}${search}`;
  const recordTab = SUBMISSION_CATEGORY_TO_TAB[submission.category];
  const hasSummary = submission.hasSummary;

  const canDeleteSubmission =
    authorized({
      resource: RESOURCE_NAME.SUBMISSION,
      permission: "delete",
    }) && submission.status !== SubmissionStatus.PROCESSING;

  const canUpdateVisibility = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "updateVisibility",
  });

  const actions: Array<ActionsProps> = [];

  if (!isDetailView) {
    actions.push({
      href: buildLink("viewSubmission", { submissionId: submission.id }, {}),
      label: t("property-record-view"),
      target: "_blank",
      track: () => {
        track("Submission viewed", {
          submissionId: submission.id,
          "Submission Category":
            submission.submissionTypeVersion.submissionType.category,
          "Submission Type":
            submission.submissionTypeVersion.submissionType.name,
        });
      },
    });
  }

  if (isSummaryView && !isGuest) {
    actions.push({
      label: "Edit",
      to: {
        pathname: buildLink("editSubmission", {
          submissionId: submission.id,
        }),
        state: { prevLocation },
      },
    });
  }

  if (isEditView && hasSummary && !isGuest) {
    actions.push({
      label: "View summary",
      href: buildLink("submissionSummary", { submissionId: submission.id }, {}),
      target: "_blank",
    });
  }

  const relatedSubmissions = submission.relatedSubmissions;
  if (relatedSubmissions.length && !isGuest) {
    actions.push(
      ...relatedSubmissions.map(relatedSubmission => {
        return {
          label: `View ${getSubmissionTypeName(
            relatedSubmission.submissionTypeVersion.submissionType
          )} record`,
          href: buildLink(
            "viewSubmission",
            {
              submissionId: relatedSubmission.id,
            },
            {}
          ),
          target: "_blank",
        };
      })
    );
  }

  const property = submission.property;

  if (shouldShowViewOnMapAction(property) && !isGuest) {
    actions.push({
      label: "View on map",
      href: buildLink(
        "map",
        { accountId: account?.id! },
        {
          address: property.fullAddress,
          propertyId: property.id,
          lat: property.latitude.toString(),
          lng: property.longitude.toString(),
          tab: INFO_PANEL_TAB_NAME.RECORDS,
          recordTab,
        }
      ),
      target: "_blank",
    });
  }

  if (isSummaryView || isEditView) {
    actions.push({
      label: "Print",
      onClick: () => {
        window.print();
      },
    });
  }

  if (aiActions && aiActions.length > 0 && canRunAiActions) {
    actions.push({
      label: "AI Actions",
      onClick: () => {
        showRunAIActionModal();
      },
    });
  }

  actions.push(...additionalActions);

  let shouldShowVisibilityAction;
  if (
    // Tables aren't guaranteed to return hiddenFromPublic
    isNil(submission.hiddenFromPublic) ||
    !account?.publicPortal.enabled ||
    isGuest ||
    objectIsHiddenFromPublic
  ) {
    shouldShowVisibilityAction = false;
  } else if (!account.publicPortal.hideSISD) {
    shouldShowVisibilityAction = true;
  }
  // Saved Views don't have modules loaded by default, so if SI/SD is hidden from the account
  // and we aren't sure if the submission type is SI/SD, hide the button to be safe
  else if (!submission.submissionTypeVersion.submissionType.modules) {
    shouldShowVisibilityAction = false;
  } else if (
    submission.submissionTypeVersion.submissionType.modules.includes(
      SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
    )
  ) {
    shouldShowVisibilityAction = false;
  } else {
    shouldShowVisibilityAction = true;
  }

  if (shouldShowVisibilityAction) {
    actions.push({
      label: submission.hiddenFromPublic
        ? "Show on public website"
        : "Hide on public website",
      onClick: () => showEditVisibilityModal(),
      disabled: !canUpdateVisibility,
      track: () => {
        track("Submission visibility updated", {
          submissionId: submission.id,
          "Submission Category":
            submission.submissionTypeVersion.submissionType.category,
          "Submission Type":
            submission.submissionTypeVersion.submissionType.name,
        });
      },
    });
  }

  if (!isGuest) {
    actions.push({
      label: "Delete",
      onClick: () => showDeleteSubmissionModal(),
      disabled: !canDeleteSubmission,
      variant: "red",
      track: () => {
        track("Submission deleted", {
          submissionId: submission.id,
          "Submission Category":
            submission.submissionTypeVersion.submissionType.category,
          "Submission Type":
            submission.submissionTypeVersion.submissionType.name,
        });
      },
    });
  }

  return actions.length ? (
    <Actions
      actions={actions}
      buttonText={isDetailView ? "Actions" : undefined}
    />
  ) : (
    <></>
  );
};

export default ActionButton;
