import React, { useState, ComponentProps } from "react";
import { partition } from "lodash";
import { useTranslation } from "react-i18next";

import ListItem, { PartialImprovement } from "./ListItem";
import { EmptyState } from "../../Common/EmptyState";
import ImprovementListItem from "./ImprovementListItem";
import { ImprovementExclusionReason } from "../../../generated/graphql";

import {
  Container,
  EmptyContainer,
  ExclusionGroupTitle,
  MoreButton,
  Table,
} from "./__styles__/List";

type ListProps = Pick<
  ComponentProps<typeof ListItem>,
  "address" | "onUpdate"
> & {
  improvements: Array<PartialImprovement>;
};

const List = ({
  address,
  improvements: allImprovements,
  onUpdate,
}: ListProps) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  const [excludedImprovements, includedImprovements] = partition(
    allImprovements,
    "exclusion.excluded"
  );

  const hiddenCount = excludedImprovements.length;

  if (!allImprovements.length) {
    return (
      <EmptyContainer>
        <EmptyState compact title={t("property-sisd-no-entries")} />
      </EmptyContainer>
    );
  }

  const [manualExcludedImprovements, autoExcludedImprovements] = partition(
    excludedImprovements,
    improvement =>
      improvement.exclusion!.reason === ImprovementExclusionReason.MANUAL
  );

  return (
    <Container>
      <Table data-testid="improvements-table" noExclusions={!hiddenCount}>
        {includedImprovements
          .sort((a, b) => a.date - b.date)
          .map(item => (
            <ImprovementListItem
              address={address}
              item={item}
              onUpdate={onUpdate}
              key={item.id}
            />
          ))}
        {showAll && !!manualExcludedImprovements.length && (
          <>
            <ExclusionGroupTitle>
              {t("property-sisd-manual")}
            </ExclusionGroupTitle>
            {manualExcludedImprovements
              .sort((a, b) => a.date - b.date)
              .map(item => (
                <ImprovementListItem
                  address={address}
                  item={item}
                  onUpdate={onUpdate}
                  key={item.id}
                />
              ))}
          </>
        )}
        {showAll && !!autoExcludedImprovements.length && (
          <>
            <ExclusionGroupTitle>
              {t("property-sisd-tracking-period")}
            </ExclusionGroupTitle>
            {autoExcludedImprovements
              .sort((a, b) => a.date - b.date)
              .map(item => (
                <ImprovementListItem
                  address={address}
                  item={item}
                  onUpdate={onUpdate}
                  key={item.id}
                />
              ))}
          </>
        )}
      </Table>
      {!!hiddenCount && (
        <MoreButton onClick={() => setShowAll(!showAll)}>
          {t(
            showAll
              ? "property-sisd-hide-excluded-records"
              : "property-sisd-show-excluded-records",
            { count: hiddenCount }
          )}
        </MoreButton>
      )}
    </Container>
  );
};

export default List;
