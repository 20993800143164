import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import IdentifyUser from "./IdentifyUser";

const UserLandingPage = () => {
  let history = useHistory();
  const handleKeyPress = useCallback(event => {
    if (event.key === "Escape") {
      history.push("/admin/login");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return <IdentifyUser />;
};

export default UserLandingPage;
