import React, { ReactNode } from "react";

import { Body, Title } from "./Typography";
import { spacing } from "../../stitches.config";

import { FlexColumn, FlexRow } from "./__styles__/Layout";
import { Box } from "./__styles__/Settings";
import Banner from "./Banner";

export const SettingsPanel = ({
  title,
  children,
  showInsufficientPermissionsBanner = false,
  actionButton,
}: {
  title: string;
  children: ReactNode;
  showInsufficientPermissionsBanner?: boolean;
  actionButton?: ReactNode;
}) => (
  <FlexColumn style={{ gap: `${spacing.l}`, marginBottom: `${spacing.l}` }}>
    {showInsufficientPermissionsBanner && <InsufficientPermissionsBanner />}
    <Box>
      <FlexRow style={{ justifyContent: "space-between" }}>
        <Title size="small" type="semiBold">
          {title}
        </Title>
        {actionButton && actionButton}
      </FlexRow>
      {children}
    </Box>
  </FlexColumn>
);

export const SettingsSection = ({
  title,
  subtitle,
  children,
  actionButton,
}: {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  actionButton?: ReactNode;
}) => {
  return (
    <FlexColumn style={{ gap: `${spacing.m}` }}>
      <FlexRow style={{ justifyContent: "space-between" }}>
        <FlexColumn style={{ gap: `${spacing.xs}` }}>
          <Body size="large" type="emphasis">
            {title}
          </Body>
          {subtitle && (
            <Body size="default" type="regular" color="contentSecondary">
              {subtitle}
            </Body>
          )}
        </FlexColumn>
        {actionButton && actionButton}
      </FlexRow>
      {children}
    </FlexColumn>
  );
};

export const InsufficientPermissionsBanner = () => {
  return (
    <div style={{ margin: `${spacing.m} 0` }}>
      <Banner>
        You do not have permission to edit these account settings. Please reach
        out to your Team manager to make changes.
      </Banner>
    </div>
  );
};
