import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Checkbox, Text } from "../../../../../../Inputs/react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import {
  ActionIconContainer,
  HelpText,
  InputContainer,
  LabelContainer,
  LVLabel,
  OptionsContainer,
  ValueInputContainer,
  WidgetSettingsContainer,
} from "./__styles__/WidgetSettings";
import { Button } from "../../../../../../Common/Button";
import { FieldSettingConfig } from "./shared";
import { SubmissionTagDisplay } from "../../../../../../../generated/graphql";
import { omit } from "lodash";
import TextInput from "../../../../../../Inputs/Text";
import { Icon } from "../../../../../../Common/Icons/LucideIcons";
import {
  Option,
  useCreateEditDropdownOptionModal,
} from "./CreateEditDropdownOptionModal";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import { SubmissionsBuilderFieldSettings } from "../../types";

const DropdownWidgetSettings = ({
  fieldPath,
  isModuleInput,
  readOnly,
}: SelectedSubmissionsBuilderField & SubmissionsBuilderFieldSettings) => {
  const { register, setValue, watch, control } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  let options = watch(`${fieldPath}.enum`);
  let optionsAsObjects = options?.map((option: string | Option) => {
    if (typeof option === "string") {
      return {
        label: option,
        value: option,
      };
    }
    return option;
  });

  const [dropdownOptions, setDropdownOptions] = useState<Array<Option>>(
    optionsAsObjects?.length ? optionsAsObjects : [{ label: "", value: "" }]
  );

  useEffect(() => {
    setValue(`${fieldPath}.enum`, dropdownOptions, { shouldValidate: true });
  }, [JSON.stringify(dropdownOptions)]); // Have to stringify, because the array can change lengths and useEffect doesn't like that

  const setDropdownOption = (index: number, updatedOption: Option) => {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[index] = updatedOption;
    setDropdownOptions(updatedOptions);
  };

  const addDropdownOption = (newOption: Option) => {
    const updatedOptions = [...dropdownOptions, newOption];
    setDropdownOptions(updatedOptions);
  };

  const removeDropdownOption = (index: number) => {
    dropdownOptions.splice(index, 1);
    // creating a shallow copy of the array since splice modifies in place
    const updatedOptions = [...dropdownOptions];
    setDropdownOptions(updatedOptions);
  };

  const useAsTag = watch(`${fieldPath}.useAsTag`) ?? false;
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }
    if (!useAsTag) {
      const updatedOptions = dropdownOptions.map(option =>
        omit(option, "display")
      );
      setDropdownOptions(updatedOptions);
    } else {
      const updatedOptions = dropdownOptions.map(option => ({
        ...option,
        display: SubmissionTagDisplay.NEUTRAL,
      }));
      setDropdownOptions(updatedOptions);
    }
  }, [useAsTag]);

  const [showCreateEditDropdownOptionModal] =
    useCreateEditDropdownOptionModal();

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={isModuleInput}
        readOnly={readOnly}
      />
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} disabled={readOnly} />
      </div>
      <div>
        <Label text={"Helper text"} htmlFor={`${fieldPath}.helperText`} />
        <Text {...register(`${fieldPath}.helperText`)} disabled={readOnly} />
      </div>
      <div>
        <div>
          <Label
            style={{ marginBottom: "4px" }}
            required
            text={"Dropdown options"}
          />
          <HelpText style={{ marginTop: "0px", paddingBottom: "8px" }}>
            Labels are visible to users, while values are backend identifiers
            and hidden from them.
          </HelpText>
        </div>
      </div>
      <Checkbox
        control={control}
        name={`${fieldPath}.useAsTag`}
        label="Display options as status tag on information panel"
        disabled={readOnly}
      />
      <div>
        <div>
          <LabelContainer>
            <LVLabel text={"Label"} />
            <LVLabel text={"Value"} />
          </LabelContainer>
          {dropdownOptions.map((option, index) => (
            <OptionsContainer key={`option.${index}`}>
              <InputContainer>
                <TextInput
                  name={`label.${index}`}
                  value={option.label}
                  disabled
                />
              </InputContainer>
              <ValueInputContainer>
                <TextInput
                  name={`value.${index}`}
                  value={option.value}
                  disabled
                />
              </ValueInputContainer>
              {!readOnly && (
                <ActionIconContainer>
                  <Icon
                    iconName="pencil"
                    color="contentSecondary"
                    size="18"
                    onClick={() => {
                      showCreateEditDropdownOptionModal({
                        useAsTag,
                        existingOption: option,
                        onDelete:
                          dropdownOptions.length > 1 && !isModuleInput
                            ? () => removeDropdownOption(index)
                            : undefined,
                        onSave: (props: Option) => {
                          setDropdownOption(index, props);
                        },
                        valueEditingDisabled: isModuleInput,
                      });
                    }}
                  />
                </ActionIconContainer>
              )}
            </OptionsContainer>
          ))}
        </div>
        <div style={{ paddingTop: "8px" }}>
          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={() =>
              showCreateEditDropdownOptionModal({
                useAsTag,
                onSave: addDropdownOption,
                // If you can create the option, you should be allowed to edit the value
                valueEditingDisabled: false,
              })
            }
            disabled={isModuleInput || readOnly}
          >
            Add option
          </Button>
        </div>
      </div>
    </WidgetSettingsContainer>
  );
};

DropdownWidgetSettings.displayName = "DropdownWidgetSettings";

export default DropdownWidgetSettings;
