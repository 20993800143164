import React, { useContext, useEffect, useState } from "react";

import { FieldProps } from "@rjsf/utils";
import { Input } from "../__styles__/Inputs";
import { ComplexRadio, Number } from "../../Inputs";
import {
  Multiplier,
  NumericInputs,
  SummaryBox,
  SummaryRow,
  SummaryText,
} from "./__styles__/PropertyMarketValueField";
import Wrapper from "../../Inputs/Wrapper";
import {
  get,
  identity,
  isNil,
  keys,
  merge,
  pick,
  times,
  values,
  zipObject,
} from "lodash";
import { formatCurrency } from "common/utils/strings";
import { SubmissionFormContext } from "../Form";
import { isNotNil } from "common/utils/tools";
import { calculateFullAssessedValue } from "common/utils/improvements";
import {
  PropertyMarketValueFormData as FormData,
  PropertyMarketValueSchema as FullSchema,
  extractErrorsFromErrorSchema,
} from "common-client/utils/submissions";
import { useDefaultedValues } from "../utils";
import { ScreenOnly } from "../../Common/__styles__/ScreenOnly";
import { PrintOnly } from "../../Common/__styles__/PrintOnly";
import { Body } from "../../Common/Typography";
import { FlexColumn } from "../../Common/__styles__/Layout";

const PropertyMarketValue = (
  props: FieldProps<FormData, FullSchema["schema"], FullSchema["uiSchema"]> & {
    isSubmissionBuilder?: boolean;
  }
) => {
  const { onChange, formData, uiSchema, schema, errorSchema } = props;
  const { defaultValues } = useContext(SubmissionFormContext);
  // filter out automatedAssessedValue if not all fields are defaulted,
  // since we can't compute a value then
  const options = schema.oneOf.filter((option, index) => {
    if (option.name === "automatedAssessedValue") {
      return values(uiSchema?.oneOf[index]).every(value => {
        const defaultPath = value["ui:defaultValuePath"];
        return !defaultPath || !isNil(get(defaultValues, defaultPath));
      });
    } else {
      return true;
    }
  });

  const initialOption = options[0]!.name;

  const [initialFormData] = useState(formData ?? { type: initialOption });

  const setSelectedOption = (newSelectedOption: FormData["type"]) => {
    // if the user is editing a form, we need to make sure we don't *lose*
    // the data they had if they switch between the options
    const initialData =
      initialFormData.type === newSelectedOption ? initialFormData : {};

    // force value to `null` if it's not in the initial data so
    // validations apply if you switch between options *before* entering data
    onChange({ value: null, ...initialData, type: newSelectedOption });
  };

  const errors = extractErrorsFromErrorSchema<FormData>(errorSchema);

  return (
    <Input
      isSubmissionBuilder={props.isSubmissionBuilder}
      id={props.idSchema.$id}
    >
      <Wrapper
        name={props.schema.title}
        required={props.required}
        label={props.schema.title}
        compactLabel
        description={props.schema.description}
        tooltip={uiSchema?.["ui:tooltipText"]}
      >
        <div>
          <ScreenOnly width="full">
            {options.map((option, index) => {
              const checked = (formData?.type || initialOption) === option.name;
              const Component = OptionToScreenComponent[
                option.name
              ] as unknown as React.FC<
                SubcomponentProps<
                  | "automatedAssessedValue"
                  | "otherValueEstimate"
                  | "squareFootageEstimate"
                >
              >;

              return (
                <ComplexRadio
                  key={option.name}
                  value={option.name}
                  name={option.name}
                  label={option.title}
                  checked={checked}
                  onChange={setSelectedOption}
                  description={option.description}
                  disabled={props.disabled}
                >
                  {checked && (
                    <Component
                      disabled={props.disabled}
                      {...option}
                      onChange={(newValue: Partial<FormData>) => {
                        const propertiesOnOption = keys(option.properties);

                        // required to mark all fields on the option as dirty
                        // for validation purposes, even if you only edited
                        // one field
                        const emptyDefaults = zipObject(
                          propertiesOnOption,
                          times(propertiesOnOption.length, () => null)
                        );
                        const existingValues = pick(
                          formData,
                          propertiesOnOption
                        );

                        const finalValue = merge(
                          {},
                          emptyDefaults,
                          existingValues,
                          newValue,
                          {
                            type: option.name,
                          }
                        );

                        onChange(finalValue);
                      }}
                      formData={formData ?? {}}
                      uiSchema={uiSchema!.oneOf[index]}
                      errors={errors}
                    />
                  )}
                </ComplexRadio>
              );
            })}
          </ScreenOnly>
          <PrintOnly
            width="full"
            data-testid={`print-only-${props.schema.title}-${formData?.type}`}
          >
            {formData && (
              <FlexColumn style={{ gap: "16px" }}>
                <FlexColumn style={{ gap: "4px" }}>
                  <Body size="default" type="emphasis">
                    Estimate type
                  </Body>
                  <Body size="default" type="regular">
                    {options.find(opt => opt.name === formData.type)?.title ||
                      formData.type}
                  </Body>
                </FlexColumn>

                {options.map((option, index) => {
                  if (option.name === formData.type) {
                    const Component = OptionToPrintComponent[
                      option.name
                    ] as unknown as React.FC<
                      SubcomponentProps<
                        | "automatedAssessedValue"
                        | "otherValueEstimate"
                        | "squareFootageEstimate"
                      >
                    >;

                    return (
                      <React.Fragment key={option.name}>
                        <Component
                          onChange={() => {}}
                          {...option}
                          formData={formData}
                          uiSchema={uiSchema!.oneOf[index]}
                          errors={errors}
                        />
                      </React.Fragment>
                    );
                  }

                  return null;
                })}
              </FlexColumn>
            )}
          </PrintOnly>
        </div>
      </Wrapper>
    </Input>
  );
};

type SubcomponentProps<T extends FormData["type"]> = Extract<
  FullSchema["schema"]["oneOf"][number],
  { name: T }
> & {
  onChange: (value: Partial<Extract<FormData, { type: T }>>) => void;
  formData: Omit<Extract<FormData, { type: T }>, "type">;
  uiSchema: Omit<
    Extract<FullSchema["uiSchema"]["oneOf"][number], { _name: T }>,
    "_name"
  >;
  errors?: {
    [key in keyof Omit<Extract<FormData, { type: T }>, "type">]?: Maybe<string>;
  };
  disabled?: boolean;
};

const SquareFootageEstimate = (
  props: SubcomponentProps<"squareFootageEstimate">
) => {
  const { formData, onChange, properties, errors } = props;
  const { sqftOfProperty, costPerSqft, adjustmentRatio } = properties;

  useEffect(() => {
    if (
      isNotNil(formData.sqftOfProperty) &&
      isNotNil(formData.costPerSqft) &&
      isNotNil(formData.adjustmentRatio)
    ) {
      onChange({
        value:
          formData.sqftOfProperty *
          formData.costPerSqft *
          formData.adjustmentRatio,
      });
    } else {
      onChange({ value: null });
    }
  }, [formData.adjustmentRatio, formData.costPerSqft, formData.sqftOfProperty]);

  const fields = ["sqftOfProperty", "costPerSqft", "adjustmentRatio"];
  const hasError = values(pick(errors, fields)).some(identity);

  return (
    <div>
      <NumericInputs>
        <Number
          compactLabel
          title={sqftOfProperty.title}
          value={formData.sqftOfProperty ?? null}
          name={"sqftOfProperty"}
          label={sqftOfProperty.title}
          onChange={sqftOfProperty => onChange({ sqftOfProperty })}
          error={errors?.sqftOfProperty}
          disabled={props.disabled}
        />
        <Multiplier hasError={hasError}>X</Multiplier>
        <Number
          compactLabel
          title={costPerSqft.title}
          value={formData.costPerSqft ?? null}
          name={"costPerSqft"}
          label={costPerSqft.title}
          onChange={costPerSqft => onChange({ costPerSqft })}
          error={errors?.costPerSqft}
          disabled={props.disabled}
        />
        <Multiplier hasError={hasError}>X</Multiplier>
        <Number
          compactLabel
          title={adjustmentRatio.title}
          value={formData.adjustmentRatio ?? null}
          name={"adjustmentRatio"}
          label={adjustmentRatio.title}
          onChange={adjustmentRatio => onChange({ adjustmentRatio })}
          error={errors?.adjustmentRatio}
          disabled={props.disabled}
        />
      </NumericInputs>
      <SummaryBox>
        <SummaryRow>
          <SummaryText>Estimated Value</SummaryText>
          <SummaryText>
            {isNotNil(formData.value) ? formatCurrency(formData.value) : "--"}
          </SummaryText>
        </SummaryRow>
      </SummaryBox>
    </div>
  );
};

const OtherValueEstimate = (props: SubcomponentProps<"otherValueEstimate">) => {
  const { onChange, formData, errors } = props;

  return (
    <NumericInputs>
      <Number
        compactLabel
        value={formData.value ?? null}
        name={"otherValueEstimate"}
        onChange={value => onChange({ value })}
        prefix={"$"}
        error={errors?.value}
        disabled={props.disabled}
      />
    </NumericInputs>
  );
};

const AutomatedAssessedValue = (
  props: SubcomponentProps<"automatedAssessedValue">
) => {
  const { formData, uiSchema, onChange } = props;
  const { improvementValue, adjustmentRatio } = useDefaultedValues({
    uiSchema: uiSchema,
    values: formData,
    onChange: onChange,
    skipIfEditing: false,
  });
  useEffect(() => {
    if (!isNil(improvementValue) && !isNil(adjustmentRatio)) {
      onChange({
        value: calculateFullAssessedValue(improvementValue, adjustmentRatio),
      });
    }
  }, [improvementValue, adjustmentRatio]);

  return (
    <SummaryBox>
      <SummaryRow>
        <SummaryText bold={false}>Improvement Value</SummaryText>
        <SummaryText bold={false}>
          {formatCurrency(improvementValue)}
        </SummaryText>
      </SummaryRow>
      <SummaryRow underlined>
        <SummaryText bold={false}>Adjustment Ratio</SummaryText>
        <SummaryText bold={false}>{adjustmentRatio}</SummaryText>
      </SummaryRow>
      <SummaryRow>
        <SummaryText>Assessed Value</SummaryText>
        <SummaryText>{formatCurrency(formData.value)}</SummaryText>
      </SummaryRow>
    </SummaryBox>
  );
};

const OptionToScreenComponent = {
  squareFootageEstimate: SquareFootageEstimate,
  automatedAssessedValue: AutomatedAssessedValue,
  otherValueEstimate: OtherValueEstimate,
};

const SquareFootagePrintComp = ({
  formData,
  properties,
}: SubcomponentProps<"squareFootageEstimate">) => {
  const { sqftOfProperty, costPerSqft, adjustmentRatio } = properties;

  return (
    <>
      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          {sqftOfProperty.title}
        </Body>
        <Body size="small" type="regular">
          {formData.sqftOfProperty || "--"}
        </Body>
      </FlexColumn>

      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          {costPerSqft.title}
        </Body>
        <Body size="small" type="regular">
          {formData.costPerSqft || "--"}
        </Body>
      </FlexColumn>

      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          {adjustmentRatio.title}
        </Body>
        <Body size="small" type="regular">
          {formData.adjustmentRatio || "--"}
        </Body>
      </FlexColumn>

      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          Estimated value
        </Body>
        <Body size="small" type="regular">
          {isNotNil(formData.value) ? formatCurrency(formData.value) : "--"}
        </Body>
      </FlexColumn>
    </>
  );
};

const AutomatedValuePrintComp = ({
  formData,
  uiSchema,
}: SubcomponentProps<"automatedAssessedValue">) => {
  const { improvementValue, adjustmentRatio } = useDefaultedValues({
    uiSchema: uiSchema,
    values: formData,
    onChange: () => {},
    skipIfEditing: false,
  });

  return (
    <>
      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          Improvement Value
        </Body>
        <Body size="small" type="regular">
          {isNotNil(improvementValue) ? formatCurrency(improvementValue) : "--"}
        </Body>
      </FlexColumn>

      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          Adjustment Ratio
        </Body>
        <Body size="small" type="regular">
          {adjustmentRatio || "--"}
        </Body>
      </FlexColumn>

      <FlexColumn style={{ gap: "4px" }}>
        <Body size="small" type="emphasis">
          Estimated value
        </Body>
        <Body size="small" type="regular">
          {isNotNil(formData.value) ? formatCurrency(formData.value) : "--"}
        </Body>
      </FlexColumn>
    </>
  );
};

const OtherValuePrintComp = ({
  formData,
}: SubcomponentProps<"otherValueEstimate">) => {
  const { value } = formData;

  return (
    <FlexColumn style={{ gap: "4px" }}>
      <Body size="small" type="emphasis">
        Entered value
      </Body>
      <Body size="default" type="regular">
        {isNotNil(value) ? formatCurrency(value) : "--"}
      </Body>
    </FlexColumn>
  );
};

const OptionToPrintComponent = {
  squareFootageEstimate: SquareFootagePrintComp,
  automatedAssessedValue: AutomatedValuePrintComp,
  otherValueEstimate: OtherValuePrintComp,
};

// get around some weird TS issue with `FieldProps`
export const PropertyMarketValueInput = (props: FieldProps) => {
  return <PropertyMarketValue {...(props as any)} />;
};
